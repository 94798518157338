import {
  IconButton,
  Modal,
} from "@mui/material";
import React, { useEffect, useRef, useState } from "react";
import "../../Dashboard/Dashboard.css";
import "react-responsive-carousel/lib/styles/carousel.min.css";
import { useCookies } from "react-cookie";
import { Box } from "@mui/system";
import CloseIcon from "@mui/icons-material/Close";
import { useDispatch, useSelector } from "react-redux";
import { getDataForNotes } from "../../Dashboard/DashboardReducer";
import ListingTable from "../../../../listing/listing";
import { Form } from "../../../../form/Form";
import { TableSkeleton } from "../../../../helper/TableSkeleton";


export default function FDNotes({ openMoadl, closeModal,noteData }) {
  const [userInfo, setuserInfoCookie] = useCookies(["userinfo"]);
  const dispatch = useDispatch();

  let DNotesData = useSelector((state) =>
    state.DashboardReducer?.DNotesData ? state.DashboardReducer.DNotesData : {}
  );

  const loading = useSelector((state) =>
    state.DashboardReducer?.loading ? state.DashboardReducer.loading : false
  );

  console.log("data for notes in notea", DNotesData);

  useEffect(() => {
    let reqbody = {
      condition: {
        limit: 5,
        skip: 0,
      },
      sort: {
        field: "createdon_datetime",
        type: "desc",
      },
      searchcondition: {},
      project: {},
      token: "",
      count: false,
    };
    dispatch(getDataForNotes(reqbody));
  }, []);

  var modifyTableHeaders = [
    { val: "notes", name: "Note" },
    { val: "added_name", name: "Added By" },

    {
      val: "createdon_datetime",
      name: "Added On",
      type: "datetime",
      format: "MM/DD/YYYY hh:mm:ss",
    },
  ];

  let tableNotesData = useRef({
    tableId: "noteTableCoustom",
    tableTitle: "Notes Table",
    showReload: true,
    showFilter: true,
    api: {
      url: process.env.REACT_APP_API_URL,
      endPoint: "note/note-list-count",
      deleteSingleUserEndpoint: "",
      tableCountEndpoint: "note/note-list-count",
    },

    reqBody: {
      condition: {
        limit: 5,
        skip: 0,
      },
      sort: {
        field: "createdon_datetime",
        type: "desc",
      },
      searchcondition: {},
      project: {},
      token: "",
      count: false,
    },
    deleteSingleUserReqBody: {
      source: "users",
      secret: "AZ|lepL`",
    },
    deleteMultipleReqBody: {
      source: "users",
      secret: "AZ|lepL`",
    },

    //******************************** By Default Rows per page option will be available**************************//

    rowsPerPageOptions: [5, 10, 20, 30],
    sortFields: ["name", "status", "createdon_datetime", "updatedon_datetime"],
    paginationType: "stackPagination",
    enableRowActionLoader: true,
    customBtnHead: [
     
    ],
    buttons: [
      {
        id: "deletetable01",
        type: "delete",
        label: "Delete",
        name: "delete",
        condField: "notes_by",
      },
    ],
    deleteModal: {
      modalClassName: "modalblock",
    },
    searchData: {
      heading: "This is Search Bar",
      type: "drawer",
      formData: {
        id: "searchForm",
        formWrapperClass: "formWrapperClass", // Can be undefined default will be formCls
        formInlineCLass: "userForm", // Can be undefined default will be formInline
        formButtonClass: "submitbtnsection",
        submitBtnEndIcon: "search",
        resetBtnEndIcon: "replay",
        resetForm: true,
        formAdditionalSubmissionData: {},
        fields: [
          {
            id: 1,
            label: "Search By Notes",
            name: "name",
            type: "autoComplete",
            className: "list_search_item countDiv__column--col6 ",
            base_url: process.env.REACT_APP_API_URL + "",
            reqBody: {
              condition: {
                limit: 10,
                skip: 0,
              },
              sort: {
                type: "asc",
                field: "name",
              },
              searchcondition: {},
            },
            sx: { m: 1, minWidth: 300 },
            
          },        
        
        ],
      },
    },
  });

  const dataform = {
    id: "notesAdd",
    api_url: process.env.REACT_APP_API_URL,
    endPoint: "note/add-note",

    urlPathOnSuccessfulFormSubmission: "",
    cancelBtnName: "Cancel",
    className: "inputBlock inputBlockline",
    formButtonClass: "Formsubmit_button",
    formWrapperClass: "backend_addform addAdmin",
    formInlineClass: "backendTableInline_addform testwrpr trainingcata",

    formAdditionalSubmissionData: {
    },

    fields: [
      {
        id: 3,
        heading: "Notes",
        name: "description",
        placeholder: "Please type here...",
        className: "inputBlock inputBlock1line",
        type: "textarea",
        rules: { required: true },
        errorMessage: {
          required: "This Field is Required",
        },
      },
    ],
    customButtons: [],
  };

  return (
    <>
      {!loading && (
        <div>
          <Modal
            classname="image_card_modal modalBaseStyle"
            open={openMoadl}
            onClose={closeModal}
            closeAfterTransition
          >
            <Box className="modalBaseBox largeModal">
              <IconButton className="modal_icon_button" onClick={closeModal}>
                <CloseIcon className="modal_close_icon" />
              </IconButton>
              <h2 className="notes_details_custom_head">Notes For:</h2>
              <div className="dashboard_con3 note_list_head">
                {!loading ? (
                  <ListingTable
                    tableData={tableNotesData.current}
                    dataset={noteData}
                    modifyHeaders={modifyTableHeaders}
                  />
                ) : (
                  <TableSkeleton count={5} />
                )}
              </div>

              <div className="dataform2_wrapper">
                <div className="adminformbody">
                  <Form formData={dataform} />
                </div>
              </div>
            </Box>
          </Modal>
        </div>
      )}
    </>
  );
}
