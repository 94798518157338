import {Drawer} from '@mui/material'
import React from 'react'
import BtnsComponent from './BtnsComponent'
import CloseIcon from "@mui/icons-material/Close";
import { IconButton, Paper, Tooltip } from "@mui/material";

const BtnDower = ({openBtnDrower,handleCloseDrawer,openModal,setOpenMoadlforNotes,dataRef,setContractPreviewModal}) => {
  return (
    <>
    <Drawer
        anchor={"bottom"}
        open={openBtnDrower}
        onClose={handleCloseDrawer}
        >
    <Paper
      elevation={7}
      className="list_search team_management_list_search search_img"
      style={{paddingBottom:"30px"}}
    >
      <div className="searchBarHead DrawerBox" style={{marginBottom:"30px"}}>
        <h2 className="searchBarHeading">Action Options</h2>
        <span className="searchBarCloseIcon">
          <Tooltip title="Close">
            <IconButton onClick={() => handleCloseDrawer()}>
              <CloseIcon className="closeDrawer" />
            </IconButton>
          </Tooltip>
        </span>
      </div>
      <div style={{display:"flex",justifyContent:"center",flexWrap:"wrap"}}>
      <BtnsComponent dataRef={dataRef} openModal={openModal} setOpenMoadlforNotes={setOpenMoadlforNotes} fromList={true} setContractPreviewModal={setContractPreviewModal}/>
      </div>
      
    </Paper>
      
    </Drawer>
    </>
  )
}

export default BtnDower