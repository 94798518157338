import React, { useEffect, useRef, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import { TableSkeleton } from "../../../helper/TableSkeleton";
import ListingTable from "../../../listing/listing";
import "./../../../assets/List.css";

import store from "../../../store";
import {
  clearleaddata,
  getleaddata,
  getleadeditdata,
  clearfatchViewData,
  fatchViewData,
  FetchStages,
  FetchTagDetails,
  followUpCallList,
  getDatafollowUpCallList,
  tempRowId,
  setTempRowId,
  setStatesBlank,
  ShowStageList,
  getLeadTags,
  gettagalldata,
  getcalenderinvitationlink,
  clearinvationdata,
  getconversiondone,
  clearconversiondata,
} from "./LeadReducer";
import { resolvefunction } from "../../../helper/helperFunctions";
import { resetresolvenew } from "../../../helper/ResolveReducer";
import { fetchUserData } from "../userManagement/fetchUserDataReducer";
import PopupModal from "../../../commoncomponents/modal/popupmodal";

import AddIcon from "@mui/icons-material/Add";

import { LinearProgress } from "@mui/material";
import { Box, display } from "@mui/system";
import { Cookies, useCookies } from "react-cookie";
import LeadStageModal from "./LeadStageModal";
import LeadTagModal from "./LeadTagModal";
import FollowUpcall from "./FollowUpcall";
import { setSnackbar } from "../../Layout/layoutReducer";
import EmailSendDrower from "./EmailSendDrower";
import {
  clearEmailTemplatedata,
  getEmailTeamplatedata,
} from "../EmailTemplateManagement/EmailTemplateReducer";
import {
  fetchContract,
  fetchContractTemplate,
} from "../userManagement/userList/userListReducer";
import ContractViewModal from "../userManagement/userList/contractViewModal";
import ContactDrawer from "../userManagement/userList/sendcontractDrawer";
import ShowStageListModal from "./ShowStageListModal";
import ShowTagListModal from "./ShowTagListModal";

import { setEnableRowLoader } from "../../../listing/listReducer";
import { folderViewVisitLog } from "../userManagement/folderView/folderViewReducer";
import SnackBarComp from "../../../commoncomponents/snackbar/snackBar";
import { clearinformationlistdata, getInformationListData } from "../InformationlinkModule/InformationReducer";
import InformationSentOption from "./InformationSentOption";

function LeadList(props) {
  // ---------------------------------------------Invoking Hooks--------------------------------------------- //
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const cookies = new Cookies().getAll();
  const { role, id } = useParams();

  // ----------------------------------------Global State from Redux---------------------------------------- //
  const formsubmissionstate = useSelector((state) => state.formSlice?.formSubmissionState ? state.formSlice.formSubmissionState : null);
  const successMsgFollowUpCall = useSelector((state) => state.formSlice.message?.followUpcallForm); // for snackbar massge
  const successStatusFollowUpCall = useSelector((state) => state.formSlice.status?.followUpcallForm);
  const endpointLoading = useSelector((state) => state.userManagment?.loading ? state.userManagment.loading : false); // ------ during endpoint hit loading status -----
  const endpointLoader = useSelector((state) => state.fetchUserDataReducer?.loading ? state.fetchUserDataReducer.loading : false); // ------ during endpoint hit loading status -----
  const notesForId = useSelector((state) => state.tableSlice?.tempRowData?.userTable?._id);
  const loggedUserId = useSelector((state) => state.loginSlice.userInfo._id);
  const goBackClickedForFollowUpCall = useSelector((state) => state.formSlice?.customButtonClicked?.followUpcallForm?.goback001);
  const leaddataforlist = useSelector((state) => state.LeadReducer?.leaddata && state.LeadReducer?.leaddata.length > 0 ? state.LeadReducer.leaddata : []);
  const loader = useSelector((state) => state.LeadReducer?.loading ? state.LeadReducer.loading : false);
  const resolved = useSelector((state) => state.ResolveReducer.resolved["/lead-list"] ? state.ResolveReducer.resolved["/lead-list"] : false);
  const editData = useSelector((state) => state.tableSlice?.tempRowData?.leaddata ? state.tableSlice.tempRowData.leaddata : {});
  const editClicked = useSelector((state) => state.tableSlice?.customButtonClicked?.leaddata?.editlead ? state.tableSlice.customButtonClicked.leaddata.editlead : false);
  const folder_view_click = useSelector((state) => state.tableSlice?.customButtonClicked?.leaddata?.folder_view_click ? state.tableSlice.customButtonClicked.leaddata.folder_view_click : false); // ------ Folder View Btn Click Status ------
  const manageStageBtn = useSelector((state) => state.tableSlice?.customButtonClicked?.leaddata?.manage_stage ? state.tableSlice.customButtonClicked.leaddata.manage_stage : false);
  const fetchedStateData = useSelector((state) => state.LeadReducer?.FetchStages ? state.LeadReducer.FetchStages : []); // ------- FetchedStateData
  const TagManageBtn = useSelector((state) => state.tableSlice?.customButtonClicked?.leaddata && state.tableSlice?.customButtonClicked?.leaddata?.Tag_Manage ? state.tableSlice?.customButtonClicked?.leaddata?.Tag_Manage : false); // ------ TagManageBtn ------
  const TagManageModalData = useSelector((state) => state.LeadReducer?.FetchTagDetailsData ? state.LeadReducer?.FetchTagDetailsData : []);

  // const notesAddedbyId = useSelector((state)=> state.tableSlice?.tableData?.notesTable?.notes_by)
  // const userRoles = useSelector((state)=> state.loginSlice?.user_main_role)
  // const tagManageModalDataFlag = useSelector((state)=> state.LeadReducer?.fetchTagDetailsDataFlag ?  state.LeadReducer.fetchTagDetailsDataFlag: 0 )
  // ------ TagManageData ------

  // const stateManageModalDataFlag = useSelector((state)=> state.LeadReducer?.fetchStageDetailsDataFlag? state.LeadReducer.fetchStageDetailsDataFlag : 0)

  // const showStageListBtn = useSelector((state) =>
  //   state.tableSlice?.customButtonClicked?.leaddata?.show_stage_list
  //     ? state.tableSlice.customButtonClicked.leaddata.show_stage_list
  //     : false
  // ); 


  // const showTagListBtn = useSelector((state) =>
  //   state.tableSlice?.customButtonClicked?.leaddata &&
  //   state.tableSlice?.customButtonClicked?.leaddata?.show_tag_list
  //     ? state.tableSlice?.customButtonClicked?.leaddata?.show_tag_list
  //     : false
  // ); 

  /************for calender management link******** */
  const mainUserInfo = useSelector((state) => state.loginSlice?.userInfo?.main_role)
  const sendCalenderInvitationLinkButtonClicked = useSelector((state) => state.tableSlice?.customButtonClicked?.leaddata && state.tableSlice?.customButtonClicked?.leaddata?.send_calender_link ? state.tableSlice.customButtonClicked.leaddata.send_calender_link : false)
  const calenderInvitationData = useSelector((state) => state.LeadReducer?.calenderinvitation ? state.LeadReducer?.calenderinvitation : null);


  /************ for mark as conversion done********(for this we will use mainUserInfo for role type id named as send_by_id) ************ */
  const markAsConversionDoneButtonClicked = useSelector((state) => state.tableSlice?.customButtonClicked?.leaddata && state.tableSlice?.customButtonClicked?.leaddata?.mark_as_conversion_done ? state.tableSlice.customButtonClicked.leaddata.mark_as_conversion_done : false)
  const markAsConversionDoneData = useSelector((state) => state.LeadReducer?.markconversion ? state.LeadReducer?.markconversion : null);


  //----stageList Life Cycle ----------------
  const showStageListBtnOpen = useSelector(state => state.tableSlice?.customButtonClicked?.leaddata?.show_stage_list ? state.tableSlice.customButtonClicked.leaddata.show_stage_list : false); // ------ ShowStageListBtn ------
  const showStagebtnData = useSelector(state => (state.tableSlice?.tempRowData?.leaddata && Object.keys(state.tableSlice.tempRowData.leaddata).length > 0) ? state.tableSlice.tempRowData.leaddata : null); //-----showStatgeTable Data ------
  const fetchStagelistData = useSelector(state => (state.LeadReducer?.ShowStageListData) ? state.LeadReducer.ShowStageListData : []) // ------- FetchedStagelistData


  // ------ TagListData ------

  // ----- On viewBtnAction trigger ----
  // const viewBtn = useSelector(state => (state.tableSlice?.customButtonClicked?.leaddata && state.tableSlice?.customButtonClicked?.leaddata?.viewBtnAction) ? state.tableSlice?.customButtonClicked?.leaddata?.viewBtnAction : false);
  // const viewModalData = useSelector(state => state.LeadReducer?.fatchViewData ? state.LeadReducer.fatchViewData : {});

  // ----------------------------------------Components Private State---------------------------------------- //
  const [tagManageModal, setTagManageModal] = useState(false);
  const [UserId, setUserId] = useState(null);
  const [userInfo, setuserInfoCookie] = useCookies(["userinfocookies"]);
  const [statusAction, setStatusAction] = useState(null); //--- after Confirmtion modal cofirm button clicked ----//

  /************************For FollowUp calls************************************** */
  const [openMoadlForFollwCall, setOpenMoadlForFollwCall] = React.useState(false);
  const [addedForId, setAddedForId] = useState({}); //***FOR ROW DATA****//
  const [dataCopy, setDataCopy] = useState({}); //--- dataCopy  ----//
  const [stageModal, setStageModal] = useState(false);

  const [showstageList, setshowstageList] = useState(false);
  const [showtagListModal, setshowtagListModal] = useState(false);
  const [showstageListAction, setshowstageListAction] = useState(null); //--- after Confirmtion modal cofirm button clicked ----//

  const addedById = useSelector((state) => state.loginSlice?.userInfo?._id);
  const followUpClicked = useSelector((state) => state.tableSlice.customButtonClicked?.leaddata?.followUpCall);
  let loaderForFollowUp = useSelector((state) => state.LeadReducer?.loaderForFollowUp);
  let followUpCallList = useSelector((state) => state.LeadReducer?.followUpCallData && state.LeadReducer?.followUpCallData.length > 0 ? state.LeadReducer.followUpCallData : []);

  // const addedby = useSelector((state)=> state.loginSlice.userInfo.roles)

  /************************ For Email Send Section ************************************** */
  const [openDrowerForEmail, setOpenDrowerForEmail] = React.useState(false);
  const sendMailClicked = useSelector((state) => state.tableSlice.customButtonClicked?.leaddata?.sendMail);
  let emailtemplateData = useSelector((state) => state.EmailTemplateReducer?.emailtemplateListData && state.EmailTemplateReducer?.emailtemplateListData.length > 0 ? state.EmailTemplateReducer.emailtemplateListData : []);
  let loaderForsendmail = useSelector((state) => state.LeadReducer?.loaderForsendmail);
  let submistionstate = useSelector((state) => state.LeadReducer?.submistionstate);
  let loaderforEmaildata = useSelector((state) => state.EmailTemplateReducer?.loading ? state.EmailTemplateReducer.loading : false);
  /************************ For Email Send Section End ********************************** */




  /************************ For information link Send Section start ************************************ */
  const [openDrowerForInfoLink, setOpenDrowerForInfoLink] = React.useState(false);
  const informationsendClicked = useSelector((state) => state.tableSlice.customButtonClicked?.leaddata?.informationLink);
  let infoLinksData = useSelector((state) => state.informationReducer?.informationlistdata && state.informationReducer?.informationlistdata.length > 0 ? state.informationReducer.informationlistdata : []);
  let loaderForInformationSend = useSelector((state) => state.informationReducer?.loading);
  /************************ For information link Send Section end ************************************** */




  /************************ For Registration IP Info Section start ************************************ */
  const reg_ip_info_click = useSelector(state => state?.tableSlice?.customButtonClicked?.leaddata?.registration_ip_info ? state.tableSlice.customButtonClicked.leaddata.registration_ip_info : false);
  useEffect(() => {
    // console.log("registration_ip_info btn click");

    if (reg_ip_info_click) {
      dispatch(setEnableRowLoader({ tableId: "leaddata", rowId: dataCopy?._id ? dataCopy._id : editData?._id, load: true }));

      // let reqbody = { };
      // dispatch(getIPAddress(reqbody));
    }
  }, [reg_ip_info_click])

  /************************ For Registration IP Info Section end ************************************** */





  const [contractDrawer, setContractDrawer] = useState(false);
  const [contractPreviewModalNew, setContractPreviewModalNew] = useState(false);

  // ------- sendContractBtn -------
  const sendContractBtn = useSelector((state) => state.tableSlice?.customButtonClicked?.leaddata?.send_contract ? state.tableSlice.customButtonClicked.leaddata.send_contract : false);
  // ----- On DataRef on every btn trigger ----
  const dataRef = useSelector((state) => state.tableSlice?.tempRowData && state.tableSlice?.tempRowData?.leaddata ? state.tableSlice?.tempRowData?.leaddata : {});

  const contractData = useSelector((state) => state.userManagment?.fetchContractData ? state.userManagment.fetchContractData : []);
  const contractTemplateData = useSelector((state) => state.userManagment?.contractTemplate ? state.userManagment.contractTemplate : {});

  // const [modalopen, setModalOpen] = useState(false);

  //----------ShwoTagLife Cycle Data------------------------
  const taglifecyclemodalopen = useSelector((state) => state.tableSlice?.customButtonClicked?.leaddata?.show_tag_list_new ? state.tableSlice.customButtonClicked.leaddata.show_tag_list_new : false);
  const tagshowbtndata = useSelector((state) => state.tableSlice?.tempRowData?.leaddata && Object.keys(state.tableSlice.tempRowData.leaddata).length > 0 ? state.tableSlice.tempRowData.leaddata : null);
  let taglifecycledatas = useSelector((state) => state.LeadReducer?.taglifecycledata && state.LeadReducer?.taglifecycledata.length > 0 ? state.LeadReducer?.taglifecycledata : []);

  /**********************************************for calender Invitation link button push******* */

  useEffect(() => {
    console.log("mainUserInfo", mainUserInfo);
    if (mainUserInfo == "is_bdd") {
      console.log("INNNN");
      tableDataForUsers.current.buttons.push({
        id: "send_calender_link",
        label: "Send Calender Booking Link",
        type: "other",
        name: " insert_invitation_icon",
        condField: "calender_access_form_submit_time",
        condVal: 1,
      },
        {
          id: "send_calender_link",
          label: "Resend Calender Booking Link",
          type: "other",
          name: " insert_invitation_icon",
          condField: "calender_access_form_submit_time",
          condVal: 2,

        }
      )


    }

  }, [])


  //----------ShwoTagLife Cycle Data------------------------
  useEffect(() => {
    if (tagshowbtndata && taglifecyclemodalopen) {
      calltagfetchapi(tagshowbtndata._id);

      dispatch(
        setEnableRowLoader({
          tableId: "leaddata",
          rowId: tagshowbtndata._id,
          load: true,
        }))


    }
  }, [taglifecyclemodalopen, JSON.stringify(tagshowbtndata)]);

  const calltagfetchapi = async (val) => {
    const data = {
      _id: val,
    };

    await dispatch(gettagalldata(data));

    dispatch(
      setEnableRowLoader({
        tableId: "leaddata",
        rowId: tagshowbtndata._id,
        load: false,
      })
    )

    setshowtagListModal(true);
  };



  //----------ShwoStageLife Cycle Data------------------------
  useEffect(() => {
    if (showStagebtnData && showStageListBtnOpen) {
      callstagefetchapi(showStagebtnData._id)

      dispatch(
        setEnableRowLoader({
          tableId: "leaddata",
          rowId: showStagebtnData._id,
          load: true,
        })
      )

    }



  }, [showStageListBtnOpen, JSON.stringify(showStagebtnData)])

  const callstagefetchapi = async (val) => {
    const data = {
      "_id": val
    }
    await dispatch(ShowStageList(data))

    dispatch(
      setEnableRowLoader({
        tableId: "leaddata",
        rowId: showStagebtnData._id,
        load: false,
      })
    )

    setshowstageList(true)
  }
  // --------------------------------------------Other Veriables-------------------------------------------- //

  useEffect(() => {
    setContractPreviewModalNew(false);

    if (sendContractBtn) {
      setDataCopy(dataRef);
      setContractPreviewModalNew(false);

      const data = {
        condition: {},
        sort: {
          field: "name",
          type: "asc",
        },
        searchcondition: {
          status: 1,
          rep_only: { $ne: 1 },
        },
        project: { name: 1 },
        token: "",
        data: { user_id: dataRef._id },
        source: "google_event",
      };

      dispatch(fetchContract(data));
    }
  }, [sendContractBtn]);

  useEffect(() => {
    if (contractData.length > 1) {
      setContractPreviewModalNew(false);
      setContractDrawer(true);
    } else if (contractData.length == 1) {
      setContractDrawer(false);
      setContractPreviewModalNew(false);

      console.warn("<<<<<<<<< contractData goes here >>>>>>>>>", contractData);

      const payload = {
        searchcondition: {
          user_id: dataCopy._id,
          contract_id: contractData[0]._id,
        },
      };
      dispatch(fetchContractTemplate(payload));
    } else {
      setContractDrawer(false);
    }
  }, [contractData]);








  var modifyTableHeaders = [
    { val: "lead_uniq_id", name: "Lead ID" },
    { val: "fullname", name: "Name" },
    { val: "email", name: "Email" },
    { val: "phone", name: "Contact Number" },
    { val: "status", name: "Status", customVal: { 0: "Inactive", 1: "Active" } },
    { val: "associated_bdd_rep_hm", name: "Associated BDD/REP/HM" },
    { val: "createdon_datetime", name: "Joined On", type: "datetime", format: "DD/MM/YYYY hh:mm:ss" },
  ];

  const [reqbody, setreqbody] = useState({
    condition: {
      limit: 10,
      skip: 0,
    },
    sort: {
      field: "createdon_datetime",
      type: "desc",
    },
    searchcondition: {},
    project: {},
    token: "",
    count: false,
  });


  let tableDataForUsers = useRef({
    tableId: "leaddata",
    tableTitle: "Lead List",
    showReload: true,
    showFilter: true,
    // adduser: true,
    api: {
      url: process.env.REACT_APP_API_URL,
      endPoint: "lead-manage/lead-list-count",
      deleteSingleUserEndpoint: "lead-manage/delete-lead",
      tableCountEndpoint: "lead-manage/lead-list-count",
    },

    reqBody: reqbody,
    deleteSingleUserReqBody: {
      source: "role_list",
      secret: "AZ|lepL`",
    },
    deleteMultipleReqBody: {
      source: "users",
      secret: "AZ|lepL`",
    },

    //******************************** By Default Rows per page option will be available**************************//

    rowsPerPageOptions: [10, 20, 30],
    sortFields: [
      "lead_uniq_id",
      "fullname",
      "email",
      "status",
      "createdon_datetime",
    ],
    paginationType: "stackPagination",
    enableRowActionLoader: true,
    customBtnHead: [
      {
        id: "delete",
        label: "Multi Delete",
        type: "api",
        category: "modal",
        icon_type: "delete",
        className: "delete_data",
        modalClassName: "delete_modal",
        modalHeader: "<p> Alert !!</p>",
        modalTitle: "<p>Are you sure you want to delete this record (s)?</p>",
        btn_label: ["Yes", "No"],
        api_data: {
          base_url: process.env.REACT_APP_API_URL,
          endpoint: "lead-manage/delete-lead",
          body: {
            source: "users",
            secret: "AZ|lepL`",
            token:
              "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJmb28iOiJiYXIiLCJleHAiOjE2NTIxNjM3MDUsImlhdCI6MTY1MjA3NzMwNX0.3kB4iRr5JbmV81Dl5gKA7fiTaMEEp0xbsWoO-wA0XUo",
          },
        },
      },
      {
        id: "status",
        label: "Status",
        type: "api",
        category: "status",
        icon_type: "toggle_off",
        className: "status_data",
        modalClassName: "delete_modal",
        modalTitle: "<p>Are you sure you want to change the status ?</p>",
        modalHeader: "<p>Alert !!</p>",
        btn_label: ["Active", "Inactive"],
        api_data: {
          base_url: process.env.REACT_APP_API_URL,
          endpoint: "lead-manage/status-update",
          body: {
            source: "users",
            secret: "AZ|lepL`",
            token:
              "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJmb28iOiJiYXIiLCJleHAiOjE2NTIxNjM3MDUsImlhdCI6MTY1MjA3NzMwNX0.3kB4iRr5JbmV81Dl5gKA7fiTaMEEp0xbsWoO-wA0XUo",
          },
        },
      },

      // {
      //     id: "manage_stage",
      //     label: "Manage Stage",
      //     type: "clear_all_icon",
      //     category: "clear_all_icon",
      //     icon_type: "clear_all_icon",
      //     className: "status_data",
      // },
    ],

    // Action Buttons in use
    buttons: [
      {
        id: "manage_stage",
        type: "other",
        name: "clear_all_icon",
        label: "Manage Stage",
      },

      {
        id: "Tag_Manage",
        type: "other",
        name: "local_offer_icon",
        label: "Tag Manage",
      },

      {
        id: "send_contract",
        type: "other",
        name: "article_icon",
        label: "Send Contract",
        condField: "contract_send_flag",
        // condVal: 1,
      },

      {
        id: "editlead",
        type: "other",
        icon_type: "edit_icon",
        label: "Edit",
        name: "edit",
      },

      {
        id: "delete",
        label: "Delete",
        type: "api",
        category: "modal",
        icon_type: "delete",
        className: "delete_data",
        modalClassName: "delete_modal",
        modalTitle:
          "<p>Are you sure you want to delete this record? This can't be undone.</p>",
        modalHeader: "<p>Alert !!</p>",
        btn_label: ["Yes", "No"],
        api_data: {
          base_url: process.env.REACT_APP_API_URL,
          endpoint: "lead-manage/delete-lead",
          body: {
            source: "google_events",
            secret: "AZ|lepL`",
            token:
              "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJmb28iOiJiYXIiLCJleHAiOjE2NTIxNjM3MDUsImlhdCI6MTY1MjA3NzMwNX0.3kB4iRr5JbmV81Dl5gKA7fiTaMEEp0xbsWoO-wA0XUo",
          },
        },
      },

      {
        id: "status",
        label: "Status",
        type: "api",
        category: "status",
        icon_type: "toggle_off",
        className: "status_data",
        modalClassName: "delete_modal",
        modalTitle: "<p>Are you sure you that want to change the status?</p>",
        modalHeader: "<p>Alert !!</p>",
        btn_label: ["Active", "Inactive"],
        api_data: {
          base_url: process.env.REACT_APP_API_URL,
          endpoint: "lead-manage/status-update",
          body: {
            source: "google_events",
            secret: "AZ|lepL`",
            token:
              "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJmb28iOiJiYXIiLCJleHAiOjE2NTIxNjM3MDUsImlhdCI6MTY1MjA3NzMwNX0.3kB4iRr5JbmV81Dl5gKA7fiTaMEEp0xbsWoO-wA0XUo",
          },
        },
      },

      {
        id: "folder_view_click",
        label: "Folder View",
        type: "other",
        icon_type: "insert_drive_file_icon",
        name: "folderview",
      },

      {
        id: "viewBtnAction",
        label: "Preview",
        type: "api",
        category: "view",
        icon_type: "preview",
        className: "preview",
        modalTitle: "Details",
        api_data: {
          base_url: process.env.REACT_APP_API_URL,
          endpoint: "lead-manage/lead-list-count",
          body: {
            source: "",
            condition: { limit: 1, skip: 0 },
            sort: { type: "asc", field: "fullname" },
            searchcondition: {},
          },
        },
        headers: [
          { val: "lead_uniq_id", label: "Lead ID" },
          { val: "fullname", label: "Name" },
          { val: "phone", label: "Contact Number" },
          { val: "time_zone", label: "Time Zone" },
          { val: "program_name", label: "Program Name" },
          { val: "hiring_manager_name", label: "Hiring Manager" },
          { val: "rep_name", label: "REP Name" },
          { val: "bdd_name", label: "BDD Name" },

          {
            val: "createdon_datetime",
            label: "Added On",
            type: "datetime",
            format: "MM/DD/YYYY hh:mm:ss A",
          },
        ],
      },

      {
        id: "notesBadge", // for unique id of button//
        label: "Notes", //on hover show//
        type: "note", //****button category */
        icon_type: "summarize_icon", //for the button icon from mui icons  https://mui.com/material-ui/material-icons///
        name: "note_inbuilt", //as your wish///
        className: "forminline", //for modal top level tag class name//
        count_key: "notescount", //count of notes, view in badge, value should be in row data object//
        notes_for_key: "fullname", //modal title notes for dynamic data, value should be in row data object
        noteaddbaseurl: process.env.REACT_APP_API_URL, //for end point base path//
        noteaddendpoint: "note/add-note", //final path of end point//
        noteaddbody: {
          //default body to add notes//
          data: {
            notes_by: loggedUserId,
            collection_name: "google_events",
          },
        },
        body: {
          source: "",
          condition: { limit: 5, skip: 0 },
          sort: { type: "desc", field: "assigned_datetime" },
          searchcondition: {},
        }, //for initial listing end point call body//
        tableData: {
          //config for notes table in side modal like normal table config//
          tableId: "notesTable",
          tableTitle: " Notes Table",
          no_data_html: "<p>No Data Found </p>",
          payload_key: "notes_for",
          showReload: true,
          showFilter: true,
          api: {
            url: process.env.REACT_APP_API_URL,
            endPoint: "note/note-list-count",
            deleteSingleUserEndpoint: "note/delete-notes",
            tableCountEndpoint: "note/note-list-count",
          },
          reqBody: {
            condition: {
              limit: 5,
              skip: 0,
            },
            searchcondition: {},
            sort: {
              type: "desc",
              field: "createdon_datetime",
            },
          },
          deleteSingleUserReqBody: {
            source: "users",
            secret: "AZ|lepL`",
            token:
              "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJmb28iOiJiYXIiLCJleHAiOjE2NTIxNjM3MDUsImlhdCI6MTY1MjA3NzMwNX0.3kB4iRr5JbmV81Dl5gKA7fiTaMEEp0xbsWoO-wA0XUo",
          },
          rowsPerPageOptions: [5, 10, 20, 30],
          sortFields: ["notesby_names", "createdon_datetime", "notes"],
          paginationType: "stackPagination", ////////////////////// By Default Rows per page option will be available////////////,
          customBtnHead: [],
          buttons: [
            // {
            //   id: "deletetable01",
            //   type: "delete",
            //   label: "Delete",
            //   name: "delete",
            //   condField: "notes_by",
            //   condVal: loggedUserId,
            // }

            {
              id: "delete",
              label: "Multi Delete",
              type: "api",
              category: "modal",
              condField: "notes_by",
              condVal: loggedUserId,
              icon_type: "delete",
              className: "delete_data",
              modalClassName: "delete_modal",
              modalHeader: "<p> Alert !!</p>",
              modalTitle:
                "<p>Are you sure you want to delete this record? This can't be undone.</p>",
              btn_label: ["Yes", "No"],
              api_data: {
                base_url: process.env.REACT_APP_API_URL,
                endpoint: "note/delete-notes",
                body: {
                  source: "users",
                  secret: "AZ|lepL`",
                  token:
                    "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJmb28iOiJiYXIiLCJleHAiOjE2NTIxNjM3MDUsImlhdCI6MTY1MjA3NzMwNX0.3kB4iRr5JbmV81Dl5gKA7fiTaMEEp0xbsWoO-wA0XUo",
                },
              },
            },
          ],
          deleteModal: {
            modalClassName: "Delete Note",
          },

          searchBarData: {
            formWrapperClass: "backendTableWrapper", // Can be undefined default will be formCls
            formInlineCLass: "backendTableInline test_one", // Can be undefined default will be formInline
            formButtonClass: "submitbuttonwrapper test searchRefreshIconBtn",
            endPointBodyWrapper: "searchcondition",
            heading: "Search Notes",
            class: "draWerp",

            api: {
              url: process.env.REACT_APP_API_URL,
              endPoint: "note/note-list-count",
              tableCountEndpoint: "note/note-list-count",
              reqBody: {
                source: "",
                condition: {
                  limit: 5,
                  skip: 0,
                },
                sort: {
                  type: "desc",
                  field: "_id",
                },
                searchcondition: {
                  notes_for: notesForId,
                },
              },
            },
            searchSettings: {
              datesearch: [
                {
                  id: 0,
                  // heading: "Search By Joining Date",
                  startdatelabel: "Start Date",
                  enddatelabel: "End Date",
                  fullDay: true,
                  className: "inputblock4line",
                  field: "createdon_datetime",
                },
              ],

              textsearch: [
                {
                  id: 1,
                  //   heading: "Search By Note",
                  label: "Search By Note",
                  field: "notes",
                  className: "inputblock4line",
                  value: "",
                },

                // {
                //     id: 2,
                //     // heading: "Search Added By",
                //     label: "Search Added By",
                //     field: "added_name",
                //     className: "inputblock4line",
                //     value: "",
                // }
              ],
              autoCompleteSearch: [
                // {
                //     id: 5,
                //     label: "Search By Notes",
                //     field: "notes",
                //     values: "",
                //     multiple: false,
                //     preload: true,
                //     className: 'form_item list_search_item countDiv__column--col6 "',
                //     serverSearchData: {

                //         base_url: process.env.REACT_APP_API_URL,
                //         endpoint: "api/autocompletenotestitle",
                //         reqBody: {
                //             "condition": {
                //                 "limit": 10,
                //                 "skip": 0
                //             },
                //             "sort": {
                //                 "type": "desc",
                //                 "field": "_id"
                //             },
                //             "searchcondition": {}
                //         },
                //     },
                // },

                {
                  id: 6,
                  label: "Search Notes Added By ",
                  field: "notes_for",
                  values: "",
                  multiple: false,
                  preload: true,
                  className: "inputblock4line",
                  serverSearchData: {
                    base_url: process.env.REACT_APP_API_URL,
                    endpoint: "note/auto-complete-node-added-by",
                    reqBody: {
                      condition: {
                        limit: 10,
                        skip: 0,
                      },
                      sort: {
                        type: "asc",
                        field: "name",
                      },
                      searchcondition: {},
                    },
                  },
                },
              ],

              selectsearch: [],
            },
          },
        },
        headers: [
          { val: "notes", name: "Note" },
          { val: "added_name", name: "Added By" },

          {
            val: "createdon_datetime",
            name: "Added On",
            type: "datetime",
            format: "MM/DD/YYYY hh:mm:ss",
          },
        ],
      },

      {
        id: "followUpCall",
        label: "Follow UP Call",
        type: "other",
        name: "follow_the_signs_icon",
      },

      {
        id: "sendMail",
        label: "Send Email",
        type: "other",
        name: "forward_to_inbox_two_tone_icon",
      },

      {
        id: "show_stage_list",
        label: "Stage Lifecycle",
        type: "other",
        name: "view_day_icon",
      },

      {
        id: "show_tag_list_new",
        label: "Tag Lifecycle",
        type: "other",
        name: "data_saver_off_icon",
      },

      {
        id: "mark_as_conversion_done",
        label: "Mark As Conversion Done",
        type: "other",
        name: "how_to_reg",
      },

      // {
      //   id: "send_calender_link",
      //   label: "Send Calender Invitation Link",
      //   type: "other",
      //   name: " insert_invitation_icon",
      // },
      {
        id: "informationLink",
        label: "Send Information Link",
        type: "other",
        name: "ios_share_icon",
      }
    ],

    deleteModal: {
      modalClassName: "modalblock",
    },

    searchData: {
      heading: "Search Lead",
      type: "drawer",
      condition_type: "$or",
      formData: {
        id: "searchForm",
        formWrapperClass: "formWrapperClass", // Can be undefined default will be formCls
        formInlineCLass: "userForm", // Can be undefined default will be formInline
        formButtonClass: "submitbtnsection",
        submitBtnEndIcon: "search",
        resetBtnEndIcon: "replay",
        resetBtnName: "Refresh",
        submitBtnName: "Search",
        resetForm: true,
        formAdditionalSubmissionData: {},
        fields: [
          {
            id: 0,
            label: "Search by Lead ID",
            name: "lead_uniq_id",
            type: "text",
            inputType: "number",
            className: "inputblock2line",
            payloadFormat: { key: "lead_uniq_id" },
          },

          {
            id: 1,
            label: "Search By Name",
            name: "fullname",
            type: "autoComplete",
            className: "inputblock2line",
            base_url:
              process.env.REACT_APP_API_URL +
              "lead-manage/auto-complete-lead-name",
            reqBody: {
              source: "lead_list",
              sort: {
                type: "desc",
                field: "name",
              },
              condition: {
                limit: 10,
                skip: 0,
              },
              searchcondition: {},
            },
            payloadFormat: { key: "_id" },
          },

          {
            id: 2,
            label: "Search By Program",
            name: "program_name",
            type: "autoComplete",
            className: "inputblock2line",
            base_url:
              process.env.REACT_APP_API_URL +
              "program-manage/auto-complete-program-list",
            reqBody: {
              source: "lead_list",
              sort: {
                type: "desc",
                field: "name",
              },
              condition: {
                limit: 10,
                skip: 0,
              },
              searchcondition: {},
            },
            payloadFormat: { key: "_id" },
          },

          {
            id: 3,
            label: "Search By Tag",
            name: "tag_names",
            type: "autoComplete",
            className: "inputblock2line",
            base_url:
              process.env.REACT_APP_API_URL +
              "tag-management/auto-complete-tag",
            reqBody: {
              source: "lead_list",
              sort: {
                type: "desc",
                field: "name",
              },
              condition: {
                limit: 10,
                skip: 0,
              },
              searchcondition: {},
            },
            payloadFormat: { key: "_id" },
          },

          {
            id: 4,
            label: "Search By Hiring Manager",
            name: "hiring_manager",
            type: "autoComplete",
            className: "inputblock2line",
            base_url:
              process.env.REACT_APP_API_URL + "lead-manage/auto-complete-lead",
            reqBody: {
              source: "lead_list",
              condition: {
                limit: 10,
                skip: 0,
              },
              sort: {
                field: "name",
                type: "asc",
              },
              searchcondition: {},
              project: {},
              autocomplete_for: "hiring_manager",
            },
            payloadFormat: { key: "hiring_manager" },
          },

          {
            id: 5,
            label: "Search By REP",
            name: "rep",
            type: "autoComplete",
            className: "inputblock2line",
            base_url:
              process.env.REACT_APP_API_URL + "lead-manage/auto-complete-lead",
            reqBody: {
              source: "lead_list",
              condition: {
                limit: 10,
                skip: 0,
              },
              sort: {
                field: "name",
                type: "asc",
              },
              searchcondition: {},
              project: {},
              autocomplete_for: "rep",
            },
            payloadFormat: { key: "rep_id" },
          },

          {
            id: 6,
            label: "Search By BDD",
            name: "bdd",
            type: "autoComplete",
            className: "inputblock2line",
            base_url:
              process.env.REACT_APP_API_URL + "lead-manage/auto-complete-lead",
            reqBody: {
              source: "lead_list",
              condition: {
                limit: 10,
                skip: 0,
              },
              sort: {
                field: "name",
                type: "asc",
              },
              searchcondition: {},
              project: {},
              autocomplete_for: "bdd",
            },
            payloadFormat: { key: "bdd_id" },
          },

          {
            id: 7,
            label: "Search By AER",
            name: "aer",
            type: "autoComplete",
            className: "inputblock2line",
            base_url:
              process.env.REACT_APP_API_URL + "lead-manage/auto-complete-lead",
            reqBody: {
              source: "lead_list",
              condition: {
                limit: 10,
                skip: 0,
              },
              sort: {
                field: "name",
                type: "asc",
              },
              searchcondition: {},
              project: {},
              autocomplete_for: "aer",
            },
            payloadFormat: { key: "aer_id" },
          },

          {
            id: 8,
            label: "Search by Start Date",
            name: "createdon_datetime",
            className: "datePicker",
            type: "datePicker",
            sx: { m: 1, width: 300 },
            // format: 'dd/MM/yyyy', //////////////////////////// MM should be capital //////////////////
            className: "inputblock2line",
            payloadFormat: { key: "createdon_datetime", param: "$gte" },
          },
          {
            id: 9,
            label: "Search by End Date",
            name: "createdon_datetime1",
            className: "inputblock2line",
            type: "datePicker",
            fullDay: true,
            sx: { m: 1, width: 300 },
            // format: 'dd/MM/yyyy'
            payloadFormat: { key: "createdon_datetime", param: "$lte" },
          },

          // {
          //     id: 3,
          //     label: "Search By Name",
          //     name: "fullname",
          //     type: "autoComplete",
          //     className: "inputblock2line",
          //     base_url: process.env.REACT_APP_API_URL + "lead-manage/auto-complete-lead-search",
          //     reqBody: {
          //         source: "lead_list",
          //         sort: {
          //             type: "desc",
          //             field: "fullname",
          //         },
          //         "searchcondition": {

          //         }
          //     },

          //     payloadFormat: { key: "fullname", param: '$regex', options: 'i' },

          // },

          {
            id: 10,
            label: "Search By Email",
            name: "email",
            type: "autoComplete",
            className: "inputblock2line",
            base_url:
              process.env.REACT_APP_API_URL +
              "lead-manage/auto-complete-lead-search",
            reqBody: {
              source: "lead_list",
              sort: {
                type: "desc",
                field: "email",
              },
              searchcondition: {},
            },

            payloadFormat: { key: "email", param: "$regex", options: "i" },
          },

          {
            id: 11,
            label: "Search by Status",
            name: "status",
            type: "select",
            multiple: false,
            values: [
              { val: 1, name: "Active" },
              { val: 0, name: "Inactive" },
            ],
            sx: { m: 1, minWidth: 120 },
            className: "inputblock2line",
            payloadFormat: { key: "status" },
          },

          {
            id: 0,
            label: "Search by Contact Number",
            name: "phone",
            type: "text",
            inputType: "phone",
            className: "inputblock2line",
            payloadFormat: { key: "phone", param: "$regex", options: "i" },
          },
        ],
      },
    },
  });

  // listing endpoint body
  // let reqbody = {
  //     "condition": {
  //         "limit": 10,
  //         "skip": 0
  //     },
  //     "sort": {
  //         "field": "createdon_datetime",
  //         "type": "desc"
  //     },
  //     "searchcondition": {},
  //     "project": {},
  //     "token": ""
  // }

  // --------------------------------------------store.subscribe Section-------------------------------------------- //
  store.subscribe(() => {
    const storeData = store.getState();

    if (
      storeData.tableSlice.tempRowData.leaddata?._id != undefined ||
      storeData.tableSlice.tempRowData.leaddata?._id != null
    ) {
      setAddedForId(storeData.tableSlice.tempRowData.leaddata._id);
      // console.log("addedForId------->", addedForId);
      // store.dispatch(setTempRowId(addedForId))
    }
  });

  // -------------------------------------------useEffect Section------------------------------------------- //
  useEffect(() => {
    setContractDrawer(false);
    closeDrower();

    // if (cookies.userinfo.main_role == 'is_rep') {
    //     reqbody.searchcondition['rep_id'] = cookies.userinfo._id
    // }
    // if (cookies.userinfo.main_role == 'hr_manager') {
    //     reqbody.searchcondition['hiring_manager'] = cookies.userinfo._id
    // }

    if (cookies?.userinfo?.main_role && cookies.userinfo.main_role === "is_admin") {
      console.log();
      tableDataForUsers.current.buttons.push({
        id: "registration_ip_info",
        label: "Registration IP Info",
        type: "other",
        name: "info_icon",
      })
    }

    if (
      cookies.userinfo.main_role == "is_rep" &&
      !reqbody.searchcondition["rep_id"]
    ) {
      reqbody.searchcondition["rep_id"] = cookies.userinfo._id;
    }
    if (
      cookies.userinfo.main_role == "hr_manager" &&
      !reqbody.searchcondition["hiring_manager"]
    ) {
      reqbody.searchcondition["hiring_manager"] = cookies.userinfo._id;
    }
    if (
      cookies.userinfo.main_role == "is_bdd" &&
      !reqbody.searchcondition["associated_bdd"]
    ) {
      reqbody.searchcondition["associated_bdd"] = cookies.userinfo._id;
    }

    if (!resolved && !(role && id)) store.dispatch(getleaddata(reqbody));
    setStageModal(false);
    setStatusAction(null);
    setTagManageModal(false);
    setshowstageList(false);
    setshowstageListAction(null);
    setshowtagListModal(null);

    return () => {
      store.dispatch(clearleaddata());
      store.dispatch(resetresolvenew("/lead-list"));
      setStageModal(false);
      setStatusAction(null);
      setTagManageModal(false);
      dispatch(setStatesBlank());
      setshowstageList(false);
      setshowstageListAction(null);
      setshowtagListModal(null);
    };
  }, []);

  // For Folder View Button Click
  useEffect(() => {
    if (folder_view_click && editData && Object.keys(editData).length > 0) {
      setDataCopy(dataRef);
      dispatch(
        setEnableRowLoader({
          tableId: "leaddata",
          rowId: dataCopy._id,
          load: true,
        })
      ); //---- row loadder true----

      const payload = {
        user_for: dataRef._id,
        user_by: userInfo?.userinfo._id,
      };
      dispatch(folderViewVisitLog(payload));
      resolvefunction(
        navigate,
        "/lead-folder-view/" + editData?._id,
        getleadeditdata(editData._id)
      );

      dispatch(
        setEnableRowLoader({
          tableId: "leaddata",
          rowId: dataCopy._id,
          load: false,
        })
      ); //---- row loadder false----
    }
  }, [folder_view_click]);

  // useEffect(() => {
  //     if (role && id) {
  //         let reqBody = { ...reqbody }
  //         reqBody.searchcondition["rep_id"] = id

  useEffect(() => {
    if (role && id) {
      let reqBody = { ...reqbody };
      if (role === "is_rep") {
        reqBody.searchcondition["rep_id"] = id;
      }
      if (role === "hr_manager") {
        reqBody.searchcondition["hiring_manager"] = id;
      }
      if (role === "is_bdd") {
        reqBody.searchcondition["associated_bdd"] = id;
      }
      setreqbody(reqBody);
      store.dispatch(getleaddata(reqbody));
    }
  }, [role, id]);

  // ---- on managestagebtn click  thunk call-----I
  useEffect(() => {
    if (manageStageBtn === true) {
      setDataCopy(editData);
      dispatch(
        setEnableRowLoader({
          tableId: "leaddata",
          rowId: editData._id,
          load: true,
        })
      );
      const data = {
        condition: {},
        sort: {},
        searchcondition: { added_for: editData._id, roles: { $in: ["is_lead"] } },
        project: { subject: 1 },
        token: "",
        count: false,
      };

      dispatch(FetchStages(data));
    }
  }, [manageStageBtn]);

  /**********************After Click Tag Management Custom Btn Section start********************** */
  useEffect(() => {
    if (TagManageBtn) {
      setUserId(editData._id);
      setDataCopy(editData);
      dispatch(setEnableRowLoader({ tableId: "leaddata", rowId: editData._id, load: true }));

      const data = {
        "_id": dataRef._id,
        "roles": { $in: ["is_lead"] },
        "project": { "subject": 1 },
      }
      dispatch(FetchTagDetails(data));
    }
  }, [TagManageBtn]);

  //  on sucessfully data fetch TAG Modal open
  useEffect(() => {
    if (TagManageModalData?.length > 0) {
      setTimeout(() => dispatch(setEnableRowLoader({ tableId: "leaddata", rowId: dataCopy._id ? dataCopy._id : editData._id, load: false })), 1000);
      setTagManageModal(true);
    };
    setTimeout(() => dispatch(setEnableRowLoader({ tableId: "leaddata", rowId: dataCopy._id ? dataCopy._id : editData._id, load: false })), 1000);

  }, [TagManageModalData]);
  /**********************After Click Tag Management Custom Btn Section End********************** */




  // modal after fetchedStateData fetched -----
  useEffect(() => {
    console.log("fetchedStateData", fetchedStateData);
    if (fetchedStateData.length > 0) {
      // setUserId(dataRef._id)
      dispatch(setEnableRowLoader({ tableId: "leaddata", rowId: dataCopy._id, load: false }))
      setStageModal(true);
    }
    setTimeout(() => dispatch(setEnableRowLoader({ tableId: "leaddata", rowId: dataCopy._id ? dataCopy._id : editData._id, load: false })), 1000);

  }, [fetchedStateData]);










  // ---- on show stage list-----
  // useEffect(() => {
  //   if (showStageListBtn === true) {
  //     setshowstageList(true);

  //     setDataCopy(editData);
  //     const data = {
  //       condition: {},
  //       sort: {},
  //       searchcondition: {
  //         "added_for": editData._id


  //       },
  //       project: {},
  //       token: "",
  //       count: false,
  //     };

  //     dispatch(ShowStageList(data));
  //   }
  // }, [showStageListBtn]);

  // modal after fetchedStageListData fetched -----
  // useEffect(() => {
  //     console.log("fetchStatelistData", fetchStatelistData);
  //     if (fetchStatelistData.length > 0) {
  //         setUserId(dataRef._id)
  //         setshowstageList(true)
  //     }
  // }, [fetchStatelistData])



  // useEffect(() => {

  //     if (ShowTagListModalData.length > 0) {
  //         console.log("ShowTagListModalData", ShowTagListModalData);
  //         setshowtagListModal(true)
  //     }
  // }, [ShowTagListModalData])

  useEffect(() => { }, [loader, leaddataforlist]);

  useEffect(() => {
    if (editClicked && Object.keys(editData).length > 0) {
      resolvefunction(
        navigate,
        `/lead-edit/${editData._id}`,
        getleadeditdata(editData._id)
      );
    }
  }, [editClicked]);

  // ------ viewBtn triigred -------
  // useEffect(() => {
  //     if (viewBtn === true) {
  //         dispatch(fatchViewData(editData._id)) //-- fetchUserData endpoint call from this func --
  //     }
  // }, [viewBtn])




  /**********************Follow Up Section Start********************************* */
  // -----Follow Up button click-----
  useEffect(() => {
    if (followUpClicked === true) {
      let reqbody = {
        condition: { limit: 5, skip: 0 },
        sort: { field: "_id", type: "desc" },
        searchcondition: { added_for: addedForId },
        count: false,
        source: "",
        // "project": {},
        // "token": "",
      };
      store.dispatch(getDatafollowUpCallList(reqbody));
      setOpenMoadlForFollwCall(true);
    }
  }, [followUpClicked]);

  useEffect(() => {
    if (goBackClickedForFollowUpCall === true) {
      setOpenMoadlForFollwCall(false);
    }
  }, [goBackClickedForFollowUpCall]);

  useEffect(() => {
    if (formsubmissionstate.followUpcallForm === 2) {
      store.dispatch(
        setSnackbar({
          open: true,
          message: successMsgFollowUpCall,
          status: successStatusFollowUpCall,
        })
      );
      setOpenMoadlForFollwCall(false);
    }
  }, [formsubmissionstate]);

  const closeModal = () => setOpenMoadlForFollwCall(false);
  const openModal = () => setOpenMoadlForFollwCall(true);
  /**********************Follow Up Section end********************************* */



  /**********************Information Link Section start********************************* */
  useEffect(() => {
    if (informationsendClicked === true) {
      dispatch(setEnableRowLoader({ tableId: "leaddata", rowId: dataCopy?._id ? dataCopy._id : editData?._id, load: true }));

      let reqbody = {
        condition: { limit: 5, skip: 0 },
        sort: { field: "_id", type: "desc" },
        searchcondition: {
          // "added_for": addedForId
        },
        count: false,
        source: "",
      };

      store.dispatch(getInformationListData(reqbody));
    }
  }, [informationsendClicked]);

  useEffect(() => {
    if (infoLinksData.length > 0) {
      setOpenDrowerForInfoLink(true);
    }

    setTimeout(() => dispatch(setEnableRowLoader({ tableId: "leaddata", rowId: dataCopy?._id ? dataCopy._id : editData?._id, load: false })), 3000);
  }, [infoLinksData]);

  const closeDrowerForInfoLink = () => {
    setOpenDrowerForInfoLink(false);
    dispatch(clearinformationlistdata());
  };
  /**********************Information Link Section Stop********************************* */



  /**********************For Email Send start********************************* */
  useEffect(() => {
    if (sendMailClicked === true) {
      dispatch(setEnableRowLoader({ tableId: "leaddata", rowId: dataCopy._id ? dataCopy._id : editData._id, load: true }))  // Start Row Loader

      let reqbody = {
        condition: { limit: 5, skip: 0 },
        sort: { field: "_id", type: "desc" },
        searchcondition: {
          // "added_for": addedForId
          "roles": { $in: ["is_lead"] }
        },
        count: false,
        source: "",
      };

      store.dispatch(getEmailTeamplatedata(reqbody));
    }
  }, [sendMailClicked]);

  useEffect(() => {
    if (emailtemplateData.length > 0) {
      setOpenDrowerForEmail(true);
    }
    setTimeout(() => dispatch(setEnableRowLoader({ tableId: "leaddata", rowId: dataCopy._id ? dataCopy._id : editData._id, load: false })), 1000);  // Stop Row Loader
  }, [emailtemplateData]);

  const closeDrower = () => {
    setOpenDrowerForEmail(false);
    dispatch(clearEmailTemplatedata());
  };
  /**********************Email Send Stop********************************* */






  /***********************************UseEffect For send Calender Invitation Link */

  useEffect(() => {
    console.log("TagManageBtn", sendCalenderInvitationLinkButtonClicked);
    if (sendCalenderInvitationLinkButtonClicked == true) {
      setUserId(editData._id);
      setDataCopy(editData);
      dispatch(
        setEnableRowLoader({
          tableId: "leaddata",
          rowId: editData._id,
          load: true,
        })
      );
      const data = {
        _id: editData._id,
        org_id: loggedUserId
      };

      dispatch(getcalenderinvitationlink(data));
      // setSnackFire(true)
      // setResMessage(endpointResMsg)
      // dispatch( setEnableRowLoader({
      //   tableId: "leaddata",
      //   rowId:  dataCopy._id,
      //   load: false,
      // }))
    }
  }, [sendCalenderInvitationLinkButtonClicked]);

  /****************************for calender invitation data fetch************ */
  useEffect(() => {
    if (calenderInvitationData) {
      console.log("calenderInvitationData", calenderInvitationData);
      dispatch(setEnableRowLoader({
        tableId: "leaddata",
        rowId: dataCopy._id,
        load: false,
      }))
      dispatch(
        setSnackbar({ open: true, message: 'Calender Invitation Send', status: 'success' })
      );
      dispatch(clearinvationdata())

    }
  }, [calenderInvitationData]);


  /***********************************UseEffect For send mark as conversion Link */

  useEffect(() => {
    console.log(" markAsConversionDoneButtonClicked", markAsConversionDoneButtonClicked);
    if (markAsConversionDoneButtonClicked == true) {
      setUserId(editData._id);
      setDataCopy(editData);
      dispatch(
        setEnableRowLoader({
          tableId: "leaddata",
          rowId: editData._id,
          load: true,
        })
      );
      const datac = {
        'data': {
          user_id: editData._id,
          send_by_id: loggedUserId
        }

      };

      dispatch(getconversiondone(datac));

    }
  }, [markAsConversionDoneButtonClicked]);

  /****************************for UseEffect For send mark as conversion Linkh************ */
  useEffect(() => {
    if (markAsConversionDoneData) {
      console.log("markAsConversionDoneData", markAsConversionDoneData);
      dispatch(setEnableRowLoader({
        tableId: "leaddata",
        rowId: dataCopy._id,
        load: false,
      }))
      dispatch(
        setSnackbar({ open: true, message: 'Marked as Convertion Done', status: 'success' })
      );
      dispatch(clearconversiondata())

    }
  }, [markAsConversionDoneData]);




  // useEffect(() => {
  //     // console.log("RoleList", resolve);
  //     if (!resolved) store.dispatch(getContractListData(reqbody));

  //     return () => {

  //         store.dispatch(clearContractdata())
  //         store.dispatch(resetresolvenew('/contract-list'))
  //     }
  // }, [])

  // useEffect(() => {
  //     if (Object.keys(viewModalData).length> 0) {
  //         setModalOpen(true);
  //     }
  // }, [viewModalData])

  // useEffect(() => {
  //     setModalOpen(false);

  // }, [])

  // ------ viewBtn triigred -------
  // useEffect(() => {
  //     if (viewBtn === true) {
  //         dispatch(fatchViewData(editData._id)) //-- fetchUserData endpoint call from this func --
  //     }
  // }, [viewBtn])

  // --------------------------------------------Other Handlers Goes Here-------------------------------------------- //

  //----------------------------------------------return-----------------------------------------------//
  return (
    <>
      <div className="rolelist_mainwrp">


        {props.source !== "userFolderView" ? (<div className="listing_heading">
          <h1>Lead Management</h1>
          <button className="addrole_btn" onClick={() => navigate("/lead-add")}>
            <AddIcon />{" "}
          </button>
        </div>) : ""}



        <div className="userlisttable">
          {/* ----- Loader ---- */}
          {endpointLoading || endpointLoader ? (
            <Box
              sx={{
                width: "100%",
                position: "sticky",
                top: "0px",
                background: "#68aad7",
                zIndex: 1,
                padding: "10px",
              }}
              className="loader"
            >
              <LinearProgress />
            </Box>
          ) : (
            ""
          )}
          {/* ------------------- */}

          {loaderforEmaildata && <LinearProgress />}

          {/* <button onClick={()=>store.dispatch(changeTestData("loooool"))}>click me</button> */}
          {!loader ? (
            <ListingTable
              tableData={tableDataForUsers.current}
              dataset={leaddataforlist}
              modifyHeaders={modifyTableHeaders}
            />
          ) : (
            <TableSkeleton count={5} />
          )}
        </div>
      </div>




      {/* -------- LeadStageModal ------ */}
      {stageModal ? (
        <LeadStageModal
          toogle={stageModal}
          tooglefunc={setStageModal}
          stage={stageModal}
          UserId={dataCopy._id}
          UserName={dataCopy.fullname}

        />
      ) : (
        ""
      )}
      {/* ---------------------- */}

      {/* ---- ConfigureTagsModal------ */}
      {tagManageModal ? (
        <LeadTagModal
          toogle={tagManageModal}
          tooglefunc={setTagManageModal}
          setModalResponse={setStatusAction}
          tags={TagManageModalData}
          UserId={UserId}
          UserName={dataCopy.fullname}



        />
      ) : (
        ""
      )}

      {contractPreviewModalNew ? (
        <ContractViewModal
          toogle={contractPreviewModalNew}
          tooglefunc={contractPreviewModalNew}
          contractdata={contractTemplateData}
          userinfo={dataCopy}
          source="lead"
        />
      ) : (
        ""
      )}

      {contractDrawer ? (
        <ContactDrawer
          open={contractDrawer}
          setcontractdrawer={setContractDrawer}
          contractdata={contractData}
          userid={dataCopy._id}
          userinfo={dataCopy}
          className="contract-drawer"
          source="lead"
        />
      ) : (
        ""
      )}

      {/* ---------------------------- */}

      {/* -------- ShowStageListModal ------ */}

      {
        showstageList ? (<ShowStageListModal
          open={showstageList}
          toogle={showstageList}
          tooglefunc={setshowstageList}
          setModalResponse={setshowstageListAction}
          stagesdata={fetchStagelistData}
          UserName={dataCopy.fullname}
        />) : ''

      }

      {/* {showstageList ? (
        <ShowStageListModal
          open={showstageList}
          toogle={showstageList}
          tooglefunc={setshowstageList}
          setModalResponse={setshowstageListAction}
          stage={showstageList}
          UserId={dataCopy._id}
          UserName={dataCopy.fullname}
        />
      ) : (
        ""
      )} */}

      {/* ---- ShowTagListModal------ */}
      {showtagListModal ? (
        <ShowTagListModal
          toogle={showtagListModal}
          tooglefunc={setshowtagListModal}
          setModalResponse={setStatusAction}
          tagsdata={taglifecycledatas}
          UserName={dataCopy.fullname}
        />
      ) : (
        ""
      )}

      {/* ---------------------- */}

      {/* ------- Popup Modal ------------ */}
      {/* {Object.keys(viewModalData).length> 0?
                (<PopupModal
                    popupdata={viewModalData}
                    popuptoogle={modalopen}
                    popuptooglefunc={setModalOpen}
                    type="profileInfo"
                />) : ""} */}
      {/* ----------------- */}

      <FollowUpcall
        open={openMoadlForFollwCall}
        close={closeModal}
        addedById={addedById}
        addedForId={addedForId}
        listData={followUpCallList}
        loading={loaderForFollowUp}
      />




      <InformationSentOption
        open={openDrowerForInfoLink}
        close={closeDrowerForInfoLink}
        data={infoLinksData}
        addedById={addedById}
        addedForId={addedForId}
        loading={loaderForInformationSend}


      />

      {openDrowerForEmail ? (
        <EmailSendDrower
          open={openDrowerForEmail}
          close={closeDrower}
          data={emailtemplateData}
          addedById={addedById}
          addedForId={addedForId}
          loading={loaderForsendmail}
          submistionstate={submistionstate}
        />
      ) : (
        ""
      )}


      {/* ------------ */}
    </>
  );
}
export default LeadList;
