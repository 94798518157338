import Snackbar from "@mui/material/Snackbar";
import Modal from "@mui/material/Modal";
import Typography from "@mui/material/Typography";

import store from "../../../../store";
import CloseIcon from "@mui/icons-material/Close";

// import * as React from 'react';
import { format } from 'date-fns'
import React, { useEffect, useRef, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { Button, Chip, Grid, IconButton, LinearProgress, List, Tooltip } from "@mui/material";
// import { timeConverter } from "../../helper/helperFunctions";
import { Box, display } from "@mui/system";
// import { useCookies, Cookies } from "react-cookie";
// import { clearViewData } from "../../listing/listReducer";
// import { clearViewMaterialData } from "../../Pages/Backend/Training/TrainingMaterial/TrainingMaterialReducer";
import { Carousel } from "react-responsive-carousel";

const TrainingStatusModal = (props) => {
    const dispatch = useDispatch(); // ---- Dispatch -----
    const navigate = useNavigate(); //**** FOR PAGE NAVIGATION****//

    // ---- States ----
    const [loading, setLoading] = useState(true); //*****FOR LODING****//

    // ---- Cookies ---
    // const [userInfo, setuserInfoCookie] = useCookies(["userinfocookies"]);

    // // ---- UseSelectors ----
    // const users = useSelector((state) => state.userManagment.usersList)
    // const usersToken = useSelector((state) => state.loggedinuser.usertoken)

    console.log("<<<<< props from popupToogle modal compo >>>>", props);

    // // ------- onModalClose --------
    function ModalClose() {
        props.popuptooglefunc(false);

    }


    return (
        <>
            {/* ------ Confirmation Modal ------ */}

            <Modal
                className=" modalblock userList_modal"
                open={props.popuptoogle}
                onClose={ModalClose}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
            >
                <Box
                    className="BoxMui_modal other_BoxMui_modal"
                    sx={{
                        position: "absolute",
                        top: "50%",
                        left: "50%",
                        transform: "translate(-50%, -50%)",
                        width: 400,
                        bgcolor: "background.paper",
                        border: "2px solid #000",
                        boxShadow: 24,
                        p: 4,
                        textAlign: "center",
                    }}
                >

                    <div className=" ModalBox_scroll">
                        <div className="heading">
                            <h3>
                                Training Status For {props?.userTrainingData?.firstname} {props?.userTrainingData?.lastname}
                            </h3>
                        </div>

                        {
                            <div className="listflexblock training-block-per-user">
                                {props.userTrainingData?.done_training_percentage ? (
                                    <div className="total-percentage" >
                                        <h3>Total Training Completed   {Math.round(props?.userTrainingData?.done_training_percentage)}%</h3>
                                        <LinearProgress variant="determinate" value={props?.userTrainingData?.done_training_percentage} />

                                    </div>
                                ) : (
                                    ""
                                )}


                                {props.popupdata?.length > 0 ? props.popupdata?.map((x) =>
                      
                                        <ul className='TS-block'>
                                            <li>Category: {x?.category_name}  </li>
                                            {/* <li>Total Lesson Count: {x.total_lesson_count}  </li>
                                            <li>Done Lesson Count: {x.done_lesson_count}  </li> */}
                                            <li>Training Completed: <LinearProgress className="progressBarNew" variant="determinate" value={x.training_percentage}/> {x.training_percentage}%  </li>
                                            <li>Updated On: {format(x.updated_datetime, 'dd-mm-yyyy')} </li>

                                            
                                        </ul>

                                    ) : <p> No Data Found !</p>}


                            </div>
                        }
                    </div>



                    <Tooltip title="Close" placement="right-start">
                        <IconButton className="modalCloseBtn" onClick={ModalClose}>
                            <CloseIcon />
                        </IconButton>
                    </Tooltip>


                </Box>
            </Modal>
        </>
    );
};

export default TrainingStatusModal;
