import {
  Button,
  LinearProgress,
  Modal,
  Snackbar,
  Tooltip,
  Typography,
  Dialog
} from "@mui/material";
// import { Box } from "@mui/system";
import React, { useEffect, useRef, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { resolvefunction } from "../../helper/helperFunctions";
import { resetresolvenew } from "../../helper/ResolveReducer";
import { TableSkeleton } from "../../helper/TableSkeleton";
import ListingTable from "../../listing/listing";
import CloseIcon from "@mui/icons-material/Close";
import store from "../../store";
import Box from '@mui/material/Box';
import {
  clearcampaignDatadata,
  cleareditdata,
  getCampaignEdit,
  getCampaignListData,
} from "./CampaignReducer";
import { Form } from "../../form/Form";
import UserNameCreate from "./UserNameCreate";
import { CookiesProvider, withCookies, Cookies, useCookies } from "react-cookie";
import copy from "copy-to-clipboard";
import { setSnackbar } from "../Layout/layoutReducer";
import CampainManageModal from "../Backend/CustomLinks/CampainManageModal";


function CampaingList({ open, close, user_id, user_name, landingpage_id, page_url }) {

  console.log('CAMPAIGN LIST ============>>', landingpage_id, page_url)

  const cookies = new Cookies();
  const [getAllcookies, setgetcookies] = useState(cookies.getAll());
  const [openSnacAddEdit, setOpenSnacAddEdit] = useState(false); //------for snackbar-----//

  const [openUserModal, setUserModal] = useState(true);
  const [valueOfCampainModal, setvalueOfCampainModal] = useState(false);
  const [openSnack, setOpenSnack] = useState(false);
  // const user_id = useSelector((state) => state.loginSlice.userInfo?._id ? state.loginSlice.userInfo._id : null);
  // ----------------------------------------Global State from Redux---------------------------------------- //
  let campaingDataforlist = useSelector((state) =>
    state.camPaignManagementReducer?.campaignData &&
      state.camPaignManagementReducer?.campaignData.length > 0
      ? state.camPaignManagementReducer.campaignData
      : []
  );

  let loader = useSelector((state) =>
    state.camPaignManagementReducer?.loading
      ? state.camPaignManagementReducer.loading
      : false
  );

  const resolved = useSelector((state) =>
    state.ResolveReducer.resolved["/list-campaign"]
      ? state.ResolveReducer.resolved["/list-campaign"]
      : false
  );

  const navigate = useNavigate();
  const dispatch = useDispatch();
  const editData = useSelector((state) =>
    state.tableSlice?.tempRowData?.campaigntdataTable
      ? state.tableSlice.tempRowData.campaigntdataTable
      : {}
  );


  // const editClicked = useSelector((state) =>
  //   state.tableSlice?.customButtonClicked?.campaigntdataTable?.edittable9
  //     ? state.tableSlice.customButtonClicked.campaigntdataTable.edittable9
  //     : false
  // );

  // const copyClicked = useSelector((state) =>
  //   state.tableSlice?.customButtonClicked?.campaigntdataTable?.cpoyurlbtn
  //     ? state.tableSlice.customButtonClicked.campaigntdataTable.cpoyurlbtn
  //     : false
  // );



  const successMsg = useSelector(
    (state) => state.formSlice.message?.CampaignAddEdit
  ); //for snackbar massge

  const formsubmissionstate = useSelector((state) =>
    state.formSlice?.formSubmissionState
      ? state.formSlice.formSubmissionState
      : null
  );

  var modifyTableHeaders = [
    { val: "name", name: "Campaign Name" },
    { val: "landing_page_name", name: "Landing" },
    {
      val: "status",
      name: "Status",
      customVal: { 0: "Inactive", 1: "Active" },
    },

    {
      val: "updatedon_datetime",
      name: "Updated On",
      type: "datetime",
      format: "DD/MM/YYYY",
    },
  ];

  let tableDataCampaign = useRef({
    tableId: "campaigntdataTable",
    tableTitle: "Campaign List",
    showReload: true,
    showFilter: true,
    // adduser: true,
    api: {
      url: process.env.REACT_APP_API_URL,
      endPoint: "campaign-manage/user-list-or-count",
      deleteSingleUserEndpoint: "",
      tableCountEndpoint: "campaign-manage/user-list-or-count",
    },

    reqBody: {
      condition: {
        limit: 10,
        skip: 0,
      },
      sort: {
        field: "updatedon_datetime",
        type: "desc",
      },
      searchcondition: {
        "landing_page_id": landingpage_id?.page_id && landingpage_id.page_id,
        // "user_name": user_name,
        "user_id": user_id
      },
      project: {},
      token: "",
      count: false,
    },
    deleteSingleUserReqBody: {
      source: "users",
      secret: "AZ|lepL`",
    },
    deleteMultipleReqBody: {
      source: "users",
      secret: "AZ|lepL`",
    },

    //******************************** By Default Rows per page option will be available**************************//

    rowsPerPageOptions: [10, 20, 30],
    sortFields: ["name", "status", "createdon_datetime", "updatedon_datetime"],
    paginationType: "stackPagination",
    enableRowActionLoader: true,
    customBtnHead: [
      {
        id: "delete",
        label: "Multi Delete",
        type: "api",
        category: "modal",
        icon_type: "delete",
        className: "delete_data",
        modalClassName: "delete_modal",
        modalTitle:
          "<p>Are you sure that you want to delete this record(s)?</p>",
        modalHeader: "<p> Alert !!</p>",
        btn_label: ["Yes", "No"],
        api_data: {
          base_url: process.env.REACT_APP_API_URL,
          endpoint: "campaign-manage/delete-campaign",
          body: {
            source: "users",
            secret: "AZ|lepL`",
            token:
              "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJmb28iOiJiYXIiLCJleHAiOjE2NTIxNjM3MDUsImlhdCI6MTY1MjA3NzMwNX0.3kB4iRr5JbmV81Dl5gKA7fiTaMEEp0xbsWoO-wA0XUo",
          },
        },
      },
      {
        id: "status",
        label: "Status",
        type: "api",
        category: "status",
        icon_type: "toggle_off",
        className: "status_data",
        modalClassName: "delete_modal",
        modalTitle: "<p>Are you sure you want to change the status ?</p>",
        modalHeader: "<p> Alert !!</p>",
        btn_label: ["Active", "Inactive"],
        api_data: {
          base_url: process.env.REACT_APP_API_URL,
          endpoint: "campaign-manage/status-update",
          body: {
            source: "users",
            secret: "AZ|lepL`",
            token:
              "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJmb28iOiJiYXIiLCJleHAiOjE2NTIxNjM3MDUsImlhdCI6MTY1MjA3NzMwNX0.3kB4iRr5JbmV81Dl5gKA7fiTaMEEp0xbsWoO-wA0XUo",
          },
        },
      },
    ],
    buttons: [
      // {
      //   id: "edittable9",
      //   type: "other",
      //   icon_type: "edit_icon",
      //   label: "Edit",
      //   name: "edit",
      // },


      {
        id: "delete",
        label: "Delete",
        type: "api",
        category: "modal",
        icon_type: "delete",
        className: "delete_data",
        modalClassName: "delete_modal",
        modalTitle:
          "<p>Are you sure that you want to delete this record(s)?</p>",
        modalHeader: "<p> Alert !!</p>",
        btn_label: ["Yes", "No"],
        api_data: {
          base_url: process.env.REACT_APP_API_URL,
          endpoint: "campaign-manage/delete-campaign",
          body: {
            source: "users",
            secret: "AZ|lepL`",
            token:
              "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJmb28iOiJiYXIiLCJleHAiOjE2NTIxNjM3MDUsImlhdCI6MTY1MjA3NzMwNX0.3kB4iRr5JbmV81Dl5gKA7fiTaMEEp0xbsWoO-wA0XUo",
          },
        },
      },
      {
        id: "status",
        label: "Status",
        type: "api",
        category: "status",
        icon_type: "toggle_off",
        className: "status_data",
        modalClassName: "delete_modal",
        modalTitle: "<p>Are you sure you that want to change the status?</p>",
        modalHeader: "<p> Alert !!</p>",
        btn_label: ["Active", "Inactive"],
        api_data: {
          base_url: process.env.REACT_APP_API_URL,
          endpoint: "campaign-manage/status-update",
          body: {
            source: "users",
            secret: "AZ|lepL`",
            token:
              "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJmb28iOiJiYXIiLCJleHAiOjE2NTIxNjM3MDUsImlhdCI6MTY1MjA3NzMwNX0.3kB4iRr5JbmV81Dl5gKA7fiTaMEEp0xbsWoO-wA0XUo",
          },
        },
      },
      // {
      //   id: "cpoyurlbtn",
      //   type: "other",
      //   icon_type: "content_copy_icon",
      //   label: "Copy Url",
      //   name: "content_copy_icon",
      // },
    ],
    deleteModal: {
      modalClassName: "modalblock",
    },

    searchBarData: {
      heading: "Search Campaign ",
      api: {
        url: process.env.REACT_APP_API_URL,
        endPoint: "campaign-manage/user-list-or-count",
        tableCountEndpoint: "campaign-manage/user-list-or-count",
        reqBody: {
          condition: {
            limit: 5,
            skip: 0,
          },
          sort: {
            field: "_id",
            type: "desc",
          },
          searchcondition: {
            "landing_page_id": landingpage_id?.page_id && landingpage_id.page_id,
            //"user_name": user_name,
            "user_id": user_id
          },
          project: {},
          token: "",
          count: false,
        },
      },

      searchSettings: {
        datesearch: [
          {
            id: 0,
            heading: "Search By Created On Time",
            startdatelabel: "Start Date",
            enddatelabel: "End Date",
            className: "inputblock2line",
            submit: "Search",
            field: "createdon_datetime",
          },
          {
            id: 1,
            heading: "Search By Updated On Time",
            startdatelabel: "Start Date",
            enddatelabel: "End Date",
            className: "inputblock2line",
            submit: "Search",
            field: "createdon_datetime",
          },
        ],
        selectsearch: [
          {
            id: 2,
            // heading: "Search By Status",
            label: "Search By Status",
            field: "status",
            type: "select",
            className: "inputblock2line",
            values: ["Active", "Inactive"],
            // value: "",
          },
        ],

        autoCompleteSearch: [
          {
            id: 3,
            heading: "Search By Campaign Name",
            label: "Search By Campaign Name",
            field: "name",

            //  values: ["Surgical Wound","Pressure Injuries"],
            // values: "",
            preload: true,
            multiple: false,
            className: "inputblock2line",
            serverSearchData: {
              base_url: process.env.REACT_APP_API_URL,
              endpoint: "campaign-manage/auto-complete-campaign",
              reqBody: {
                source: "campaign_list",
                sort: {
                  type: "asc",
                  field: "key",
                },
                searchcondition: {},
              },
            },
          },
        ],
        textsearch: [
          // {
          //     id: 4,
          //     heading: "Search By Contract Slag Name",
          //     label: "Search By Contract Slag Name ",
          //     field: "name",
          //     className: "formGroup firstname countDiv__column--col4",
          //     value: "",
          // },
        ],
      },
    },

    // searchData: {
    //   heading: "Search Campaign",
    //   type: "drawer",
    //   condition_type: "$or",
    //   formData: {
    //     id: 'searchForm',
    //     formWrapperClass: "formWrapperClass",// Can be undefined default will be formCls
    //     formInlineCLass: "userForm", // Can be undefined default will be formInline
    //     formButtonClass: "submitbtnsection",
    //     submitBtnEndIcon: "search",
    //     resetBtnEndIcon: "replay",
    //     resetForm: true,
    //     formAdditionalSubmissionData: {},
    //     fields: [
    //       {
    //         id: 0,
    //         label: "Search By Campaign Name",
    //         name: "name",
    //         type: "autoComplete",
    //         className: 'inputblock2line',
    //         base_url: process.env.REACT_APP_API_URL + "campaign-manage/auto-complete-campaign",
    //         reqBody: {
    //           // "formId": 'teamsearch',
    //           "condition": {
    //             "limit": 10,
    //             "skip": 0
    //           },
    //           "sort": {
    //             "type": "asc",
    //             "field": "name"
    //           },
    //           "searchcondition": {

    //           }
    //         },
    //         sx: { m: 1, minWidth: 300 },
    //         // payloadFormat: { key: "_id", param: "$regex", options: "i" },
    //         payloadFormat: { key: "_id" },

    //       },

    //       {
    //         id: 3,
    //         label: "Search by Status",
    //         name: "status_show",
    //         type: 'select',
    //         multiple: false,
    //         values: [
    //           { val: "Active", name: "Active" },
    //           { val: "Inactive", name: "Inactive" }
    //         ],
    //         sx: { m: 1, minWidth: 120 },
    //         className: 'inputblock2line',
    //         payloadFormat: { key: "status" },
    //       },
    //       {
    //         id: 8,
    //         label: "Search by Start Date",
    //         name: "start_date",
    //         className: 'datePicker',
    //         type: 'datePicker',
    //         sx: { m: 1, width: 300 },
    //         className: 'inputblock2line',
    //         payloadFormat: { key: "createdon_datetime", param: "$gte" }
    //       },
    //       {
    //         id: 9,
    //         label: "Search by End Date",
    //         name: "end_date",
    //         className: 'inputblock2line',
    //         type: 'datePicker',
    //         fullDay: true,
    //         sx: { m: 1, width: 300 },
    //         payloadFormat: { key: "createdon_datetime", param: "$lte" }
    //       },
    //     ]
    //   }
    // },
  });

  let reqbody = {
    condition: {
      limit: 10,
      skip: 0,
    },
    sort: {
      field: "updatedon_datetime",
      type: "desc",
    },
    searchcondition: {
      "landing_page_id": landingpage_id?.page_id && landingpage_id.page_id,
     // "user_name": user_name,
      "user_id": user_id
    },
    project: {},
    token: "",
  };

  useEffect(() => {
    // console.log("RoleList", resolve);
    store.dispatch(getCampaignListData(reqbody));

    return () => {
      store.dispatch(clearcampaignDatadata());
    };
  }, []);




  // const copyToClipboard = (e) => {
  //   copy(e);
  //   dispatch(setSnackbar({ open: true, message: "Copy To Clipboard !", status: 'success' }))
  // }

  // useEffect(() => {
  //   console.warn("COPY DATA =======>", editData)
  //   if (copyClicked && Object.keys(editData).length > 0) {
  //     let copyVal = landingpage_id.p_url;
  //     copyVal = copyVal + getAllcookies.userinfo.user_name + editData.sub_id;
  //     copyToClipboard(copyVal)
  //   }

  // }, [copyClicked]);

  useEffect(() => {
    if (formsubmissionstate.CampaignAddEdit === 2) {
      console.log("formsubmissionstate............", formsubmissionstate);
      // dispatch(setReloadTableFlag({ tableId: 'userTable' }));
      // dispatch(cleareditdata());
      setOpenSnacAddEdit(true);
    }

    console.log("true==========>");
  }, [formsubmissionstate]);







  return (
    <>
      {/* <Dialog
            classname="hmmodal"
            open={open}
            onClose={() => close}
  
          > */}
      {/* <Box className='hmmodal_user_Box' > */}
      <div className="rolelist_mainwrp">
        {/* <div className="listing_heading">
          <h1>Campaign Management</h1>
          <Tooltip title="Add Campaign">
            <button
              className="addrole_btn"
              onClick={() => resolvefunction(navigate, "/add-campaign")}
            >
              +
            </button>
          </Tooltip>
        </div> */}

        <div className="rolelist_mainwrp_Table">
          {!loader ? (
            <ListingTable
              tableData={tableDataCampaign.current}
              dataset={campaingDataforlist}
              modifyHeaders={modifyTableHeaders}
            />
          ) : (
            <TableSkeleton count={5} />
          )}
        </div>

        <Snackbar
          open={openSnacAddEdit}
          onClose={() => setOpenSnacAddEdit(false)}
          autoHideDuration={2000}
          message={successMsg}
        />


      </div>



      
    </>


  );
}

export default CampaingList;
