import { LinearProgress } from '@mui/material';
import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';
import { resolvefunction } from '../../../helper/helperFunctions'
import { Form } from '../../../form/Form'
import { geteditHmGroupdata, cleareditHmGroupdata, getHmGroupListdata, fetchSingleHMgroup } from './HmGroupReducer';
import { setSnackbar } from '../../Layout/layoutReducer'
import store from '../../../store'
export default function HmGroupAddEdit() {

  const { id } = useParams();
  // ============= Data Set For Api Call ============== //
  let reqbodyforHMgroup = {
    "condition": {
      "limit": 5,
      "skip": 0
    },
    "sort": {
      "field": "_id",
      "type": "desc"
    },
    "searchcondition": {

    },
    "project": {},
    "token": ""
  }


  let reqbody = {
    "searchcondition": {
      "_id": id
    }
  }




  const dispatch = useDispatch();

  const navigate = useNavigate()
  const user_id = useSelector((state) => state.loginSlice.userInfo?._id ? state.loginSlice.userInfo._id : null);
  const goBackClicked = useSelector(state => state.formSlice?.customButtonClicked?.HmGroupAddEditForm?.goback001 ? state.formSlice.customButtonClicked.HmGroupAddEditForm.goback001 : false); // ########### Click Function For GO BACK Button ########### //

  // const editdata = useSelector(state => (state.HmGroupReducer?.fetchSingleHMgroup && state.HmGroupReducer?.fetchSingleHMgroup.length > 0) ? state.HmGroupReducer.fetchSingleHMgroup[0] : null); // ########## Fetch Edit Value From Store Which Display in Form ########### //

  const editdata = useSelector(state => (state.HmGroupReducer?.fetchSingleHMgroupData ) ? state.HmGroupReducer.fetchSingleHMgroupData : null); // ########## Fetch Edit Value From Store Which Display in Form ########### //


  useEffect(() => {
    console.log('editdata', editdata)
    if (id) {
      store.dispatch(fetchSingleHMgroup(reqbody));
    }
    return () => {dispatch(cleareditHmGroupdata()) };
  

  }, [])


  // ============= return changes to the store =========== //




  // useEffect(() => {

  //   store.dispatch(cleareditHmGroupdata())
  //   // }
  // }, [])




  const submitbtnclick = useSelector(state => state.formSlice.formSubmissionState.HmGroupAddEditForm ? state.formSlice.formSubmissionState.HmGroupAddEditForm : 0)


  useEffect(() => {
    if (id !== undefined && id !== null && editdata !== null) {
      if (submitbtnclick == 2) {
        dispatch(setSnackbar({ open: true, message: "Update Successfully !", status: 'success' }))
      };
    }
    else {
      if (submitbtnclick == 2) {
        dispatch(setSnackbar({ open: true, message: "Add Successfully !", status: 'success' }))
      };


    }


  }, [submitbtnclick])



  // ============= Set URL For GO BACK Button Click ============ //
  useEffect(() => {
    if (goBackClicked) {

      resolvefunction(navigate, '/hm-group-list', geteditHmGroupdata(reqbodyforHMgroup))
      // resolvefunction(navigate, '/hm-group-list')
    }

  }, [goBackClicked])


  const dataform = {
    id: 'HmGroupAddEditForm',
    api_url: process.env.REACT_APP_API_URL,
    endPoint: "manage-hm-group/add-update-hm-group",

    urlPathOnSuccessfulFormSubmission: "/hm-group-list",
    submitBtnName: editdata ? "Update" : "Submit",
    cancelBtnName: "Cancel",
    className: 'inputBlock inputBlock3line',
    formButtonClass: "Formsubmit_button",
    formWrapperClass: "backend_addform addAdmin",
    formInlineClass: "backendTableInline_addform testwrpr trainingcata",

    formAdditionalSubmissionData: {
      added_by: user_id,
      _id: (id !== undefined && id !== null) ? id : undefined
    },

    fields: [
      {
        id: 0,
        heading: "Group Name",
        label: "Group Name",
        name: "group_name",
        // className: "inputBlock inputBlock1line",
        className: 'inputBlock inputBlock3line',
        type: "text",
        rules: { required: true },
        errorMessage: {
          required: "This Field is Required",
        },
        //   minLength: "Minimum length should be 15",
        //   custom: "Value is Invalid"
        defaultValue: (editdata && Object.keys(editdata).length > 0 && editdata.group_name !== undefined) ? editdata.group_name : undefined,

      },




      {
        id: 1,
        heading: "Select Hiring Manager",
        label: "Select Hiring Manager",
        name: "hr_managers",
        className: 'inputBlock inputBlock3line',
        type: 'autoComplete',
        base_url: process.env.REACT_APP_API_URL,
        endPoint: "users/auto-complete-user-name",
        multiple: true,
        reqBody: {
          "condition": {
            limit: 10
          },
          "sort": {},
          "searchcondition": {}
        },
        // rules: { required: true },
        errorMessage: {
          required: "This Field is Required",
          //   custom: "Value is Invalid"
        },

        defaultValue: (editdata && Object.keys(editdata).length > 0 && editdata.hr_managers !== undefined) ? editdata.hr_managers : undefined,
      },






      {
        id: 2,
        heading: "Description",
        name: "desc",
        placeholder: "Please type here...",
        className: 'inputBlock inputBlock1line',
        type: 'textarea',
        rules: { required: true },
        errorMessage: {
          required: "This Field is Required"
        },
        defaultValue: (editdata && Object.keys(editdata).length > 0 && editdata.desc !== undefined) ? editdata.desc : undefined,

        //   minLength: "Minimum length should be 15",
        //   custom: "Value is Invalid"


      },




      {
        id: 3,
        // heading: "Status",
        name: "status",
        className: "inputBlock1line statusinput",
        type: 'singleCheckbox',
        values: { key: 0, val: 'Active' },
        // rules: { required: true },
        // errorMessage: {
        //   required: "This Field is Required"
        // },
        defaultValue: (editdata && Object.keys(editdata).length > 0 && editdata.status !== undefined) ? editdata.status : undefined,

        //   minLength: "Minimum length should be 15",
        //   custom: "Value is Invalid"



      },







    ],
    customButtons: [
      {
        id: 'goback001',
        label: 'Go Back',
        className: 'default',
        type: 'button',
      }
    ]

  }




  return (
    <div>

      <h1 className='page_heading'>Hm Group  {id !== undefined ? 'Edit' : 'Add'} </h1>




      <div className="dataform2_wrapper">
        {/* {loading == true ? <LinearProgress /> : false} */}
        <div className='adminformbody'>
          {/* <Form formData={dataform} /> */}
          {id !== undefined && editdata && (editdata.length > 0 || Object.keys(editdata).length > 0) ? <> <Form formData={dataform} /> </> : !id && <Form formData={dataform} />}
        </div>
      </div>


    </div>
  )
}
