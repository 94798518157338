import Snackbar from '@mui/material/Snackbar';
import Modal from '@mui/material/Modal';
import Typography from '@mui/material/Typography';
import CloseIcon from '@mui/icons-material/Close';
import store from "../../../../store";

// import * as React from 'react';
import React, { useEffect, useRef, useState } from "react";
import { useNavigate, useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { Button, Checkbox, Chip, FormControl, FormControlLabel, Grid, IconButton, InputLabel, LinearProgress, List, ListItemText, MenuItem, OutlinedInput, Select, TextareaAutosize, TextField, Tooltip } from '@mui/material';

import { Box, display, Stack } from '@mui/system';
import { useCookies, Cookies } from "react-cookie";
import { addUserRoles, sendContract, signContract, upgradeRole } from '../../userManagement/userList/userListReducer';
import SnackBarComp from '../../../../commoncomponents/snackbar/snackBar';
import { ContractLogsInfo } from '../../userManagement/folderView/folderViewReducer';
import DownloadIcon from '@mui/icons-material/Download';


const ContractSuccess = (props) => {

    const dispatch = useDispatch(); // ---- Dispatch -----
    const navigate = useNavigate(); //**** FOR PAGE NAVIGATION****//

    // ---- States ----
    const [loading, setLoading] = useState(false);  //*****FOR LODING****//

    // ---- Cookies ---
    const [userInfo, setuserInfoCookie] = useCookies(["userinfocookies"]);
    const [contractSubmitData, setContractSubmitData] = useCookies(["contractSubmitData"]);


    const [snackFire, setSnackFire] = useState(false);  //--- Open SnackBar ----//
    const [resMessage, setResMessage] = useState(null);  //--- resMessage  ----//
    const [endpointResStatus, setEndpointResStatus] = useState(null);  //--- resMessage  ----//
    const [signature, setSignature] = useState('');  //--- resMessage  ----//
    const [contractSendSucess, setContractSendSucess] = useState(false);  //--- contractSendSucess  ----//

    console.warn("<<<<<< cookies goes here >>>>>", userInfo)


    // ---- for loader -----
    const loader = useSelector(state => (state.userManagment?.loading) ? state.userManagment?.loading : false)
    const contractSend = useSelector(state => (state.userManagment?.contractSend) ? state.userManagment?.contractSend : false)






    return (
        <>

            {/* ------ Confirmation Modal ------ */}

            <div className="BoxMui_modal other_BoxMui_modal view_contract_modal">

                <div className="heading">
                    <h2> Contract Signed Successfully</h2>
                </div>


            </div>


        </>
    )
}


export default ContractSuccess;



