import React, { useRef, useState } from 'react'
import "./LandingPage1.css"
import { Form } from '../../../../form/Form';
import { useEffect } from 'react';
import store from '../../../../store';
import { useNavigate, useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import StateArray from '../../../../assets/json/state';
import { resolvefunction } from '../../../../helper/helperFunctions';
import { fetchCalenderEvents } from '../../../Calendar/calenderReducer';
import { setLeadinfo } from '../../../login/loginReducer';
import { Box } from '@mui/system';
import { LinearProgress } from '@mui/material';
import { getlandingpageoneuserdata, addlandingpageonedata, clearlandingpagesubmittionstate, clearaddedinfo, addlandingpagetwodatalead, updateStages, updateTags, getlandingpagedata, addconverstiondata } from '../LandingpageReducer';

import { setSnackbar } from '../../../Layout/layoutReducer';




function LandingPage1() {
    const scollToRef = useRef();
    const scollToRef2 = useRef();
    const scollToRef3 = useRef();
    const scollToRef4 = useRef();
    const scollToRef5 = useRef();
    const scollToRef6 = useRef();



    const navigate = useNavigate();
    const dispatch = useDispatch();

    const [locationArry, setLocationArry] = useState([]);
    const [submissionState, setSubmissionState] = useState(0);
    const [loading, setloading] = useState(false);
    const [loader, setLoader] = useState(false)


    const formInputData = useSelector((state) =>
        state.formSlice.formData.userForm2 ? state.formSlice.formData.userForm2 : null
    );
    const { uniquename } = useParams();
    console.log("uniquename******", uniquename);
    //  console.warn("process.env>>>>>>>",process.env);

    // console.log("loading", loading)
    let reqbodyforjobcategory = {}
    if (typeof uniquename != 'undefined') {
        reqbodyforjobcategory = {
            "uniquename": uniquename,
        }
    }
    else {
        reqbodyforjobcategory = {
            "page_slug": process.env.REACT_APP_LANDINGPAGE_SLUG,
        }
        console.warn("process.env>>>>>>>", process.env);
    }

    const userData = useSelector(state => (state.LandingpagefontReducer?.landingpageoneuserdata && Object.keys(state.LandingpagefontReducer.landingpageoneuserdata).length > 0) ? state.LandingpagefontReducer.landingpageoneuserdata : {});
    const landingpageformsubmitionstate = useSelector(state => (state.LandingpagefontReducer?.landingpagesubmittionstate) ? state.LandingpagefontReducer.landingpagesubmittionstate : 0)
    const landingpageformdeta = useSelector(state => (state.formSlice?.formData?.userForm2) ? state.formSlice.formData.userForm2 : null)

    const addedData = useSelector(state => (state.LandingpagefontReducer?.addedinfo && Object.keys(state.LandingpagefontReducer?.addedinfo).length > 0) ? state.LandingpagefontReducer.addedinfo : {});

    // const fetchedStateData = useSelector(state => (state.LandingpagefontReducer?.Stagesdata) ? state.LandingpagefontReducer.Stagesdata : [])
    const fetchedStateData = useSelector(state => (state.userManagment?.FetchStages) ? state.userManagment?.FetchStages : [])


    const tagsDetails = useSelector(state => (state.userManagment?.FetchTagDetailsData) ? state.userManagment?.FetchTagDetailsData : [])
    const ipinfo = useSelector(state => (state.loginSlice?.ipInfo && Object.keys(state.loginSlice.ipInfo).length > 0) ? state.loginSlice.ipInfo : null)
    const [conversionapicall, setConversionapicall] = useState(false);

    console.log("fetchedStateData", fetchedStateData);



    let currTime = new Date().getTime();
    let dayAfterTomorrow = (currTime + (48 * 3600 * 1000));
    const [slotListBody, setSlotListBody] = useState({
        "condition": {
            "limit": 10,
            "skip": 0
        },
        "sort": {
            "field": "start_datetime_unix",
            "type": "desc"
        },
        "searchcondition": {
            // userid: getcookies.userInfo?._id,
            "start_datetime_unix": {
                "$gte": dayAfterTomorrow
            }
        }
        // "slot_creation_type": props.type === 'np' ? 2 : 1
    })

    useEffect(() => {
        if (userData && ipinfo) {
            console.log("userData", userData);
            let body = {
                // "campaign_id":userData.campaign_id,
                "associated_program": userData.associated_program,
                "landing_page_id": userData.landing_page_id,
                "landing_page_slug": userData.landing_page_slug,
                // "campaign_added_by":userData.user_id,
                // "hiring_manager":userData.hm_id,
                "ipInfo": ipinfo
            }
            if (userData.campaign_id) body['campaign_id'] = userData.campaign_id;
            if (userData.user_id) body['campaign_added_by'] = userData.user_id;
            if (userData.hm_id) body['hiring_manager'] = userData.hm_id;

            console.log("+++++++++++++++++++", body);
            if (body && body.landing_page_id && !conversionapicall) {
                dispatch(addconverstiondata(body))
                setConversionapicall(true)

            }

            //    let bodyc= Object.keys(body).forEach(key => body[key] === undefined && delete body[key])
            //    console.log("bodyc",bodyc);
        }

    }, [JSON.stringify(ipinfo), JSON.stringify(userData)])


    useEffect(() => {
        if (uniquename) {
            store.dispatch(getlandingpageoneuserdata(reqbodyforjobcategory));
        }
        else {
            store.dispatch(getlandingpagedata(reqbodyforjobcategory));
        }
    }, [])









    useEffect(() => {
        console.log("userData", landingpageformsubmitionstate, addedData);
        if (landingpageformsubmitionstate && landingpageformsubmitionstate == 2) {
            dispatch(setSnackbar({ open: true, message: "Thank you for Submitting !", status: 'success' }))
            if (userData.calendar_access && userData.calendar_access == 1) {
                if (userData?.hm_id) {
                    slotListBody.searchcondition['userid'] = userData.hm_id
                    slotListBody['organiser_role'] = 'hm'
                }
                slotListBody['_id'] = addedData._id;
                resolvefunction(navigate, `/calendar/${addedData._id}/hm/${userData.hm_id}`, [
                    fetchCalenderEvents(slotListBody),
                ]);
            }
            else {
                navigate(`/thankyou`);
            }

            dispatch(setLeadinfo(addedData));
            store.dispatch(clearlandingpagesubmittionstate())





        }




    }, [landingpageformsubmitionstate, fetchedStateData])

    const [dataCopy, setDataCopy] = useState({});  //--- dataCopy  ----//

    useEffect(() => {
        console.log("addedData", addedData);

    }, [addedData])



    useEffect(() => {
        if (landingpageformsubmitionstate && landingpageformsubmitionstate == 2) {
            setDataCopy(addedData)
            const data = {
                stage_slug: '5QH6nS8FXNawY',
                added_by: addedData._id,
                added_for: addedData._id,
                is_delete: 0,
                status: 1
            }
            console.log("data===========================>", data)
            // return
            dispatch(updateStages(data));


            const tagdata = {
                added_by: addedData._id,
                added_for: addedData._id,
                key: "Wnv14tRS9FlmV",
                label: "hm-policiesandcompliance-signed-success",
                is_delete: 0,
                status: 1
            }
            console.log("tagdata===========================>", tagdata)
            // return
            dispatch(updateTags(tagdata));


        }


    }, [landingpageformsubmitionstate])






    useEffect(() => {
        let Newarr = [];
        if (StateArray && StateArray.length > 0) {
            for (let i in StateArray) {
                Newarr.push({ val: StateArray[i].name, name: StateArray[i].name });
            }
            setLocationArry(Newarr);
        }
    }, []);


    useEffect(() => {
        if (submissionState && submissionState === 4) {
            addapicall(formInputData);
            console.log("formInputData", formInputData, submissionState);
        }
    }, [submissionState]);

    const addapicall = async (val) => {
        console.log("val>>>", val);

        val = { ...val }
        let body = {
            "firstname": val.firstname,
            "lastname": val.lastname,
            "email": val.email,
            "phone": val.phone,
            "city": val.city,
            "state": val.state,
            "zip": val.zip,
            "married": val.married,
            "campaign_id": userData.campaign_id,
            "associated_program": userData.associated_program,
            "page_id": userData.landing_page_id,
            "page_slug": userData.landing_page_slug,
            "added_by": userData.user_id,
            "hiring_manager": userData.hm_id,
            "createdon_datetime": new Date().getTime(),
            "status": 0,
            "roles": { is_rep: 1 },
            "aer_id": userData.aer_id,
            "associated_bdd": userData.associated_bdd,
            "added_type": 'landingpage',
            "calender_access_form_submit_time": userData.calendar_access ? 2 : 1,
            "ipinfo":ipinfo,
            

            // is_rep: true,
        }

        if (Object.keys(body).length > 0) {
            console.log("body-----", body);

            setLoader(true)
            setloading(true)
            await store.dispatch(addlandingpageonedata(body));
            // setActive(false)
            setLoader(false)
            setloading(false)


        }
    };

    const formDataL2 = {
        id: 'userForm2',
        formtype: "add",
        // api_url: process.env.REACT_APP_API_URL,
        // endPoint: "landing-page-manage/add-user",
        // endPointBodyWrapper: "data",
        // urlPathOnSuccessfulFormSubmission: "/calendar",
        msgOnFailedFormSubmission: "Something Went Wrong",
        formWrapperClass: "formWrapperClass",// Can be undefined default will be formCls
        formInlineCLass: "userForm", // Can be undefined default will be formInline
        formButtonClass: "submitbtnsection",
        submitBtnName: "Submit",
        // submitBtnStartIcon: 'image_search_icon',
        // submitBtnEndIcon: 'search_icon',
        resetBtnName: "Reset",
        formAdditionalSubmissionData: {
            type: "admin",
            status: "active",

        },
        fields: [
            //////////////////////////////////// Text Input ///////////////////////////////
            {
                id: 0,
                // heading: "Full Name",
                label: "First Name",
                name: "firstname",
                className: 'formfild',
                type: "text",
                rules: { required: true },
                errorMessage: {
                    required: "This Field is Required",
                    custom: "Value is Invalid"
                },
            },
            {
                id: 1,
                // heading: "Full Name",
                label: "Last Name",
                name: "lastname",
                className: 'formfild',
                type: "text",
                rules: { required: true },
                errorMessage: {
                    required: "This Field is Required",
                    custom: "Value is Invalid"
                },
            },
            {
                id: 2,
                // heading: "Full Name",
                label: "Email",
                name: "email",
                className: 'formfild',
                type: "text",
                inputType: "email",
                rules: { required: true },
                errorMessage: {
                    required: "This Field is Required",
                    custom: "Value is Invalid"
                },

            },
            {
                id: 3,
                // heading: "Full Name",
                label: "Phone No",
                name: "phone",
                className: 'formfild',
                type: "text",
                inputType: "phone",
                rules: { required: true, minLength: 10 },
                errorMessage: {
                    required: "This Field is Required",
                    custom: "Value is Invalid"
                },

            },

            // {
            //     id: 4,
            //     heading: "Full Name",
            //     label: "Address",
            //     name: "address",
            //     className: 'formfild',
            //     type: "text",
            //     inputType: "text",/////////////// If not mentioned default will be text //////////////
            //     rules: { required: true },
            //     errorMessage: {
            //         required: "This Field is Required",
            //         custom: "Value is Invalid"
            //     },
            // },




            {
                id: 5,
                // heading: "Full Name",
                label: "City",
                name: "city",
                className: 'formfild',
                type: "text",
                rules: { required: true },
                errorMessage: {
                    required: "This Field is Required",
                    custom: "Value is Invalid"
                },

            },
            {
                id: 6,
                // heading: "Full Name",
                label: "State",
                name: "state",
                className: 'formfild state',
                type: "select",
                // other: true,
                values: locationArry,
                rules: { required: true },
                errorMessage: {
                    required: "This Field is Required",
                    custom: "Value is Invalid"
                },
                defaultValue: "",
            },
            {
                id: 7,
                // heading: "Full Name",
                label: "Zip",
                name: "zip",
                className: 'formfild',
                type: "text",
                inputType: "text",
                rules: { required: true, maxLength: 5, minLength: 5 },
                errorMessage: {
                    required: "This Field is Required",
                    custom: "Value is Invalid",
                    maxLength: "Maxlength length 5 allowed",
                    minLength: "Minlength length 5 allowed",
                },

            },

            {
                id: 16,
                type: "html",
                className: "form_text",
                value: "<p>How many established relationships do you have with C - Level executives, decision- makers, and / or business owners?</p>"
            },
            {
                id: 17,
                // heading: "Married",
                name: "married",
                className: 'form_radio',
                values: [{ key: 0, val: '0-1' }, { key: 1, val: '2-9' }, { key: 3, val: '10-49' }, { key: 4, val: '50-99' }, { key: 4, val: '100+' }],
                type: 'radio',
                hint: 'Yes/No',
                rules: { required: true },
                errorMessage: {
                    required: "This Field is Required",
                    custom: "Value is Invalid"
                },
                // defaultValue: "No"
            },

        ]

    };

    store.subscribe(() => {
        const storeData = store.getState();
        if (storeData && storeData.formSlice && storeData.formSlice.formSubmissionState && storeData.formSlice.formSubmissionState.userForm2) {
            setSubmissionState(storeData.formSlice.formSubmissionState.userForm2);
        }
        // console.log(setSubmissionState, "setSubmissionState")
        // console.log("landing store", storeData)
    })

    console.log("*****************", process.env.REACT_APP_IMG_URL);

    return (
        <>
            <div className='landing_page1_bannerblock'>
                <div className='landing-container'>
                    <div className='landing-col-container'>
                        <div className='landing-banner-left'>
                            <div className='lpage_logowrapper'>
                                <img src={`${process.env.REACT_APP_IMG_URL}l_page1_logo.webp`} />
                            </div>
                            <div className='lpage_banner_titlewrapper'>
                                <h1>
                                    Aspire Partners is a
                                    <span> UNIQUE</span>,
                                </h1>
                                <h1>
                                    <span> PROFITABLE</span>, and RISK-FREE
                                </h1>
                                <h1>program created for </h1>
                                <h1>business professionals</h1>
                            </div>
                            <div className='lpage_banner_sub_titlewrapper'>
                                <h3>
                                    We are looking for business professionals to deliver our leading-edge, cost savings solutions to companies nationwide.
                                </h3>
                            </div>
                            <div className='lpage_banner_textwrapper'>
                                <p>
                                    Aspire Partners is the
                                    <span> trusted solution, </span> and we are looking for experienced professionals to connect these businesses to our timely solutions.
                                </p>
                            </div>
                            <div className='lpage_arrow_wrapper'>
                                <h2>Aspire Partners is the trusted solution, and we are looking for experienced professionals to connect these businesses to our timely solutions.</h2>
                            </div>


                        </div>
                        <div className='landing-banner-right landingOne'>
                            <div className='formwrapper'>
                                <div className='form_title_wrapper'>
                                    <h2>
                                        <span>Fill Out The Form</span>
                                        <span>Below To Speak With One</span>
                                        <span>Of Our Team Members</span>
                                    </h2>
                                </div>
                                <div className='form_style'>
                                    <Form formData={formDataL2} />
                                    {loading ? (<Box className="loaderProgress" sx={{ width: "100%", mt: "0px" }}><LinearProgress /></Box>) : (null)}

                                </div>

                            </div>
                        </div>
                    </div>
                    <div className='lpage_banner_list_wrapper'>

                        <div className='lpage_banner_list_item'>
                            <div className='lpage_banner_list_number'>01</div>
                            <h2>No investment required</h2>
                            <div className='lpage_banner_list_icon'>
                                <img src={`${process.env.REACT_APP_IMG_URL}l_page1_banner_icon1.png`} />
                            </div>
                        </div>
                        <div className='lpage_banner_list_item'>
                            <div className='lpage_banner_list_number'>02</div>
                            <h2>No employees to manage</h2>
                            <div className='lpage_banner_list_icon'>
                                <img src={`${process.env.REACT_APP_IMG_URL}l_page1_banner_icon2.png`} />
                            </div>
                        </div>
                        <div className='lpage_banner_list_item'>
                            <div className='lpage_banner_list_number'>03</div>
                            <h2>No overhead</h2>
                            <div className='lpage_banner_list_icon'>
                                <img src={`${process.env.REACT_APP_IMG_URL}l_page1_banner_icon3.png`} />
                            </div>
                        </div>
                        <div className='lpage_banner_list_item'>
                            <div className='lpage_banner_list_number'>04</div>
                            <h2>No risk</h2>
                            <div className='lpage_banner_list_icon'>
                                <img src={`${process.env.REACT_APP_IMG_URL}l_page1_banner_icon4.png`} />
                            </div>
                        </div>

                    </div>
                </div>
            </div>
            <div className='landing_page1_block2'>
                <div className='landing-container'>
                    <div className='landing_page1_block2_colcontainer'>
                        <div className='landing_page1_block2_left'>
                            <div className='title_wrapper'>
                                <h2>
                                    <span>The Answer Resides Right In Your Existing</span>
                                    <span>Business Network</span>
                                </h2>
                            </div>
                            <div className='text_wrapper'>
                                <p>In today’s climate professionals are concerned about improving their Financial health. Whether through being a business owner, getting a higher paying job or just want to improve their retirement income. With the concerns and risks of leaving the security of their corporate job or the financial requirements of starting and maintaining a business. The question is, where do you go? Or What do you do?</p>
                            </div>
                            <div className='landing_page1_block2_subtitle'>
                                <h3>Here is a short video explaining how the answer to all these questions resides right in your existing business network.</h3>
                            </div>
                        </div>
                        <div className='landing_page1_block2_right'>
                            <div className='landing_page1_block2_video_wrapper'>
                                <iframe width="" height="" src="https://player.vimeo.com/video/718787736?color&autopause=0&loop=0&muted=0&title=0&portrait=0&byline=0&h=cd4c0d0ed4#t=" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
                            </div>
                        </div>
                    </div>

                </div>
            </div>

            <div className='landing_page1_block3'>
                <div className='landing-container'>
                    <div className='landing_page1_block3_subwrapper'>
                        <div className='landing_page1_block3_left'>
                            <div className='landing_page1_block3_imgwrapper'>
                                <img src={`${process.env.REACT_APP_IMG_URL}l_page1_image3.jpg`} />
                            </div>
                        </div>
                        <div className='landing_page1_block3_right'>
                            <div className='text_wrapper'>
                                <h2>A Business for Professionals Created by
                                    Business Professionals</h2>
                                <p>In today’s environment, professionals are concerned about improving their financial health, whether through being a business owner, getting a higher-paying job, or wanting to improve their retirement income. The question is, what do you do?</p>
                                <p>
                                    The answer resides with your existing business contacts. You can work with Aspire Partners ALONGSIDE your current position or business to help you retire
                                    <span> SOONER </span> and earn a
                                    <span> FIVE,</span>
                                    <span> SIX, </span> or even
                                    <span> SEVEN-FIGURE </span> income over five, ten, or more years. You can leverage your business relationships without changing your job, with little time, zero financial investment, and no reputational risk.
                                </p>

                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div className='landing_page1_block4'>
                <div className='landing-container'>
                    <div className='landing_page1_block4_subwrapper'>
                        <div className='text_wrapper'>
                            <div className='title_main_wrapper'>
                                <div className='title_sub_wrapper'>
                                    <h2>About Aspire Partners</h2>
                                    <img src={`${process.env.REACT_APP_IMG_URL}l_page1_icon1.png`} />
                                </div>
                            </div>
                            <p>
                                Aspire Partners has pioneered innovative
                                <span> B2B services </span> that create operational efficiencies and cost savings for companies of all sizes and industries.
                            </p>
                            <p>Our solutions address inflation companies are currently facing, profitability, and skyrocketing expenses. These services are at no cost to you or your clients.</p>

                        </div>
                    </div>
                </div>
            </div>
            <div className='landing_page1_block5'>
                <div className='landing-container'>
                    <div className='landing_page1_block5_subwrapper'>
                        <img src={`${process.env.REACT_APP_IMG_URL}l_page1_image5.png`} />
                        <div className='landing_page1_block5_text'>
                            <h3>We are a <span>100%</span>  performance-driven business.</h3>
                            <p>We only make money by helping companies save money.</p>
                        </div>

                    </div>
                </div>
            </div>
            <div className='landing_page1_block6'>
                <div className='landing-container'>
                    <div className='landing_page1_block6_subwrapper'>
                        <div className='text_wrapper'>
                            <div className='title_main_wrapper'>
                                <div className='title_sub_wrapper'>
                                    <h2>How Aspire Partners Works</h2>
                                    <img src={`${process.env.REACT_APP_IMG_URL}l_page1_icon2.png`} />
                                </div>
                            </div>
                            <p>
                                When a company implements a service that you introduced the company to, you earn a monthly commission for the next five, ten, or even twenty years, depending on the service. Plus, you are paid for the life of the client contract without any further commitment and is even willable to your estate. Some tell us that this sounds too good to be true, but we’ll take that as a compliment!
                            </p>
                            <p>
                                <span>YOUR BUSINESS CONTACTS WILL BE IMPLEMENTING THESE SERVICES</span>...
                                Will you be the first to introduce these free cost-savings solutions to your business connections?
                            </p>

                        </div>
                    </div>
                </div>
            </div>
            <div className='landing_page1_block7'>
                <div className='landing-container'>
                    <div className='landing_page1_block7_subwrapper'>
                        <div className='text_wrapper'>
                            <div className='title_main_wrapper'>
                                <div className='title_sub_wrapper'>
                                    <h2>A Company You Can Trust</h2>
                                    <img src={`${process.env.REACT_APP_IMG_URL}l_page1_icon2.png`} />
                                </div>
                            </div>
                            <p>
                                With decades of experience Aspire Partners service providers have pioneered the most innovative services in the country.
                            </p>
                            <div className='landing_page1_list_wrapper'>
                                <h4>We successfully:</h4>
                                <p>Turn AP Payable departments into profit centers, at <span>NO COST</span> by automating and monetizing payments to vendors. We have helped thousands of organizations globally generate significant efficiencies and financial rebates by paying their vendors.</p>
                                <p>Specialize in helping companies reduce expenses and save money by reducing costs associated with credit card merchant processing, cellular/wireless, utilities, industrial laundry, and workers’ comp insurance premiums without changing vendors or providers.</p>
                                <p>Offer access to 100s of tax incentatives that every company can benefit from.</p>

                            </div>
                            <p>Here's the best part…</p>
                            <p><span>You make money every time a company saves money by using one of our services
                                that you introduced.</span></p>

                        </div>
                    </div>
                </div>
            </div>
            <div className='landing_page1_block8'>
                <div className='landing-container'>
                    <div className='landing_page1_block8_subwrapper'>
                        <h2>Build For Tomorrow, Today!</h2>
                        <div className='landing_page1_block8_colcontainer'>
                            <div className='col'>
                                <img src={`${process.env.REACT_APP_IMG_URL}l_page1_icon4.png`} />
                                <h3>Simply make a
                                    connection</h3>
                            </div>
                            <div className='col'>
                                <img src={`${process.env.REACT_APP_IMG_URL}l_page1_icon5.png`} />
                                <h3>Training Provided</h3>
                            </div>
                            <div className='col'>
                                <img src={`${process.env.REACT_APP_IMG_URL}l_page1_icon6.png`} />
                                <h3>Willable
                                    To Your Estate</h3>
                            </div>
                            <div className='col'>
                                <img src={`${process.env.REACT_APP_IMG_URL}l_page1_icon7.png`} />
                                <h3>No Earning
                                    Caps</h3>
                            </div>
                            <div className='col'>
                                <img src={`${process.env.REACT_APP_IMG_URL}l_page1_icon8.png`} />
                                <h3>Alongside,
                                    Part-Time, or
                                    Full-Time</h3>
                            </div>
                            <div className='col'>
                                <img src={`${process.env.REACT_APP_IMG_URL}l_page1_icon9.png`} />
                                <h3>High
                                    Compensation
                                    Potential</h3>
                            </div>
                        </div>

                    </div>
                </div>
            </div>
            <div className='landing_page1_block9'>
                <div className='landing-container'>
                    <div className='landing_page1_block9_subwrapper'>
                        <div className='text_wrapper'>
                            <div className='title_main_wrapper'>
                                <div className='title_sub_wrapper'>
                                    <h2>Are your established relationships in one or more of the following industries:</h2>
                                </div>
                            </div>
                            <div className='landing_page1_list_wrapper'>
                                <p>Retail</p>
                                <p>Manufacturing</p>
                                <p>Oil And Gas</p>
                                <p>Hotel</p>
                                <p>Restaurant</p>
                                <p>Medical</p>
                                <p>Hospital</p>
                                <p>Construction</p>
                                <p>Service Organizations</p>
                            </div>

                        </div>
                    </div>
                </div>
            </div>

            <div className='landing_page1_block10'>
                <div className='landing-container'>
                    <div className='title_main_wrapper'>
                        <div className='title_sub_wrapper'>
                            <h2>How You Earn Revenue With Those Relationships</h2>
                        </div>
                    </div>
                    <div className='landing_page1_block10_subwrapper'>
                        <div className='logo_col'>
                            <div className='logo_col_sub'>
                                <div className='logo_col_logo'>
                                    <img src={`${process.env.REACT_APP_IMG_URL}l_page1_logo1.png`} />
                                </div>
                                <div className='logo_col_btnwrapper'>
                                    <div className="landing_page1_list_wrapper">
                                        <p>Automates AP Departments</p>
                                        <p>Increases efficiency and reduces fraud</p>
                                        <p>Transforms AP Departments into Profit Centers</p>
                                        <p>Monetizes payments to vendors</p>
                                    </div>
                                    <button onClick={() => scollToRef.current.scrollIntoView()}>See Compensation Examples Below</button>
                                </div>
                            </div>
                        </div>
                        <div className='logo_col'>
                            <div className='logo_col_sub'>
                                <div className='logo_col_logo'>
                                    <img src={`${process.env.REACT_APP_IMG_URL}l_page1_logo2.png`} />
                                </div>
                                <div className='logo_col_btnwrapper'>
                                    <div className="landing_page1_list_wrapper">
                                        <p>Helps companies reduce expenses and save money by reducing costs associated with credit card merchant processing, cellular/wireless, utilities, and workers’ comp insurance premiums</p>
                                        <p>Does not require changing the company's vendors or providers</p>
                                    </div>
                                    <button onClick={() => scollToRef2.current.scrollIntoView()}>See Compensation Examples Below</button>
                                </div>
                            </div>
                        </div>
                        <div className='logo_col'>
                            <div className='logo_col_sub'>
                                <div className='logo_col_logo'>
                                    <img src={`${process.env.REACT_APP_IMG_URL}l_page1_logo3.png`} />
                                </div>
                                <div className='logo_col_btnwrapper'>
                                    <div className="landing_page1_list_wrapper">
                                        <p>Reduces laundry bills by 30-50% without changing provider</p>
                                        <p>Provides clients with a refund, rate savings, and ongoing rate protection</p>
                                        <p>95% success rate of finding savings for companies</p>
                                    </div>
                                    <button onClick={() => scollToRef3.current.scrollIntoView()}>See Compensation Examples Below</button>
                                </div>
                            </div>
                        </div>
                        <div className='logo_col'>
                            <div className='logo_col_sub'>
                                <div className='logo_col_logo'>
                                    <img src={`${process.env.REACT_APP_IMG_URL}l_page1_logo4.png`} />
                                </div>
                                <div className='logo_col_btnwrapper'>
                                    <div className="landing_page1_list_wrapper">
                                        <p>Offers access to hundreds of tax incentives that every company can benefit from</p>
                                        <p>Provides a proprietary TMS platform that puts the power of funding directly into the hands of the client</p>
                                        <p>With one login, companies access hundreds of tax credits and incentives that the system will qualify, track, and claim</p>
                                    </div>
                                    <button onClick={() => scollToRef4.current.scrollIntoView()}>See Compensation Examples Below</button>
                                </div>
                            </div>
                        </div>
                        <div className='logo_col'>
                            <div className='logo_col_sub'>
                                <div className='logo_col_logo'>
                                    <img src={`${process.env.REACT_APP_IMG_URL}l_page1_logo5.png`} />
                                </div>
                                <div className='logo_col_btnwrapper'>
                                    <div className="landing_page1_list_wrapper">
                                        <p>Lorem Ipsum is simply dummy text of the printing and typesetting industry.</p>
                                        <p>Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, </p>
                                        <p>when an unknown printer took a galley of type and scrambled it to make a type specimen book. </p>
                                    </div>
                                    <button onClick={() => scollToRef5.current.scrollIntoView()}>See Compensation Examples Below</button>
                                </div>
                            </div>
                        </div>
                        <div className='logo_col'>
                            <div className='logo_col_sub'>
                                <div className='logo_col_logo'>
                                    <img src={`${process.env.REACT_APP_IMG_URL}l_page1_logo6.png`} />
                                </div>
                                <div className='logo_col_btnwrapper'>
                                    <div className="landing_page1_list_wrapper">
                                        <p>Lorem Ipsum is simply dummy text of the printing and typesetting industry. </p>
                                        <p>Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, </p>
                                        <p>when an unknown printer took a galley of type and scrambled it to make a type specimen book. </p>
                                    </div>
                                    <button onClick={() => scollToRef6.current.scrollIntoView()}>See Compensation Examples Below</button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div className='left-video-andtext' ref={scollToRef}>
                <div className='landing-container'>
                    <div className='left-video-andtext-subwrapper'>
                        <div className='left-video-leftblock'>
                            <div className='left-video-wrapper'>
                                <iframe width="" height="" src="https://player.vimeo.com/video/536156709?color&autopause=0&loop=0&muted=0&title=0&portrait=0&byline=0&h=a2125c5c19#t=" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
                            </div>
                        </div>
                        <div className='left-video-rightblock'>
                            <div className='title_main_wrapper'>
                                <div className='title_sub_wrapper'>
                                    <h2>Aspire Commercial Payments</h2>
                                    <p>Aspire Commercial Payments service automates companies’ Account Payables (AP), reduces AP operating expense and fraud, and generates revenue when paying suppliers.</p>
                                </div>
                            </div>
                            <div className='landing_page1_list_wrapper'>
                                <p>B2B checks are facing extinction. Industry experts are projecting that within 2 to 4 years, every company will convert their Accounts Payable department</p>
                                <p>There are approximately 200,000 companies in the U.S. with revenues of $50M to $1B. With only 4% market penetration, that is a market of 192,000 mid-sized companies alone that WILL be converting ​</p>
                                <p>The U.S. market alone is enormous...​</p>
                                <p>The competition cannot keep pace. We are the premier independent firm in the U.S. offering this solution</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div className='logoandtext leftlogo-andtext'>
                <div className='landing-container'>
                    <div className='logoandtext-colcontainer'>
                        <div className='left-logowrapper'>
                            <img src={`${process.env.REACT_APP_IMG_URL}l_page1_logo1.png`} />
                        </div>
                        <div className='right-textwrapper'>
                            <h3>Real-Life Examples of what your C-Level relationships could be worth to you</h3>
                            <div className='text-colcontainer'>
                                <div className='col'>
                                    <div className='sublist-title'>
                                        <span>
                                            <img src={`${process.env.REACT_APP_IMG_URL}l_page1_icon10.png`} />
                                        </span>
                                        <h4>Aspire Account Executive introduces the service to a CEO of a hospital*</h4>
                                    </div>
                                    <div className='sub-list'>
                                        <p>Employees: 1500</p>
                                        <p>Accounts Payable converted to Virtual Cards Payments: $264,000,000</p>
                                        <p>Annual Rebate: $3,168,000</p>
                                        <h5>Aspire Account Executive Income:</h5>
                                        <p>Monthly Income: $11,500-$15,400</p>
                                        <p>Annual Income: $132,000-$184,800</p>
                                        <p>Five Year Income: $660,000-$924,000</p>
                                        <p>Ten Year Income: $1,192,500-$1,560,000</p>
                                    </div>
                                </div>
                                <div className='col'>
                                    <div className='sublist-title'>
                                        <span>
                                            <img src={`${process.env.REACT_APP_IMG_URL}l_page1_icon11.png`} />
                                        </span>
                                        <h4>The Aspire Concept is introduced to a real estate developer*</h4>
                                    </div>
                                    <div className='sub-list'>
                                        <p>Employees: 300</p>
                                        <p>Accounts Payable converted to Virtual Cards Payments: $62,000,000</p>
                                        <p>Annual Rebate: $744,000</p>
                                        <h5>Aspire Account Executive Income:</h5>
                                        <p>Monthly Income: $2,583-$3,616</p>
                                        <p>Annual Income: $31,000-$43,400</p>
                                        <p>Five Year Income: $155,000-$217,000</p>
                                        <p>Ten Year Income: $270,000-$487,000</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>


            <div className='left-video-andtext' ref={scollToRef2}>
                <div className='landing-container'>
                    <div className='left-video-andtext-subwrapper'>
                        <div className='left-video-leftblock'>
                            <div className='left-video-wrapper'>
                                <iframe width="" height="" src="https://player.vimeo.com/video/775893777?color&autopause=0&loop=0&muted=0&title=0&portrait=0&byline=0&h=32cd0ccabc#t=" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
                            </div>
                        </div>
                        <div className='left-video-rightblock'>
                            <div className='title_main_wrapper'>
                                <div className='title_sub_wrapper'>
                                    <h2>Aspire Expense Reduction</h2>
                                    <p>Aspire Expense Reduction specializes in helping companies reduce operating expenses by saving companies money and time at no cost to the company.</p>
                                </div>
                            </div>
                            <div className='landing_page1_list_wrapper'>
                                <p>Identifies and reduces operating expenses by auditing several different categories; Workers' Compensation premiums, cellular/wireless, credit card merchant processing fees, and utilities, such as, electricity, water/sewer, natural gas, trash removal and telecom</p>
                                <p>Creates additional profits through the recovery of overcharges identified during the expense reduction audit process ​</p>
                                <p>Protects and maintains existing vendor relationships</p>
                                <p>Audits conducted at NO COST and rates and fee are continually monitored</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>


            <div className='logoandtext rightlogo-andtext'>
                <div className='landing-container'>
                    <div className='logoandtext-colcontainer'>
                        <div className='left-logowrapper'>
                            <img src={`${process.env.REACT_APP_IMG_URL}l_page1_logo2.png`} />
                        </div>
                        <div className='right-textwrapper'>
                            <h3>Real-Life Examples of what your C-Level relationships could be worth to you</h3>
                            <div className='text-colcontainer'>
                                <div className='col'>
                                    <div className='sublist-title'>
                                        <span>
                                            <img src={`${process.env.REACT_APP_IMG_URL}l_page1_icon12.png`} />
                                        </span>
                                        <h4>Aspire Account Executive introduces Aspire Expense Reduction to a Quick Service Restaurant</h4>
                                    </div>
                                    <div className='sub-list'>
                                        <p>$50M Annual Revenue</p>
                                        <p>Savings identified in credit card merchant processing and utilities</p>
                                        <p>$300K in total savings</p>
                                        <h5>Aspire Account Executive Income:</h5>
                                        <p>$15K Annually</p>
                                        <p>$45K over three years</p>
                                        <p>$75K over five years</p>
                                    </div>
                                </div>
                                <div className='col'>
                                    <div className='sublist-title'>
                                        <span>
                                            <img src={`${process.env.REACT_APP_IMG_URL}l_page1_icon13.png`} />
                                        </span>
                                        <h4>Aspire Account Executive introduces Aspire Expense Reduction to one mid-sized manufacturer and one large franchise group</h4>
                                    </div>
                                    <div className='sub-list'>
                                        <p>Combined savings of $2M</p>
                                        <p>Savings identified in workers' comp, wireless, utilities, and credit card merchant processing</p>
                                        <h5>Aspire Account Executive Income:</h5>
                                        <p>$100K Annually</p>
                                        <p>$300K over three years</p>
                                        <p>$500K over five years</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>



            <div className='left-video-andtext' ref={scollToRef3}>
                <div className='landing-container'>
                    <div className='left-video-andtext-subwrapper'>
                        <div className='left-video-leftblock'>
                            <div className='left-video-wrapper'>
                                <iframe width="" height="" src="https://player.vimeo.com/video/775893915?color&autopause=0&loop=0&muted=0&title=0&portrait=0&byline=0&h=236f71df6f#t=" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
                            </div>
                        </div>
                        <div className='left-video-rightblock'>
                            <div className='title_main_wrapper'>
                                <div className='title_sub_wrapper'>
                                    <h2>Aspire Uniform & Linen Audit</h2>
                                    <p>Aspire Uniform & Linen Audit specializes in auditing laundry services programs such as uniforms, linens, and towels. With our proprietary technology and over 25 years in the industry, we are a niche player within a $19B industry.</p>
                                </div>
                            </div>
                            <div className='landing_page1_list_wrapper'>
                                <p>Provides a comprehensive review of a company's laundry service bill to identify any billing errors, overcharges, or future savings opportunities</p>
                                <p>Audits every invoice and reviews them for contract compliance</p>
                                <p>Typically reduces laundry bills by 30-50% without changing a company's provider</p>
                                <p>NO COST to companies</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>





            <div className='logoandtext leftlogo-andtext'>
                <div className='landing-container'>
                    <div className='logoandtext-colcontainer'>
                        <div className='left-logowrapper'>
                            <img src={`${process.env.REACT_APP_IMG_URL}l_page1_logo3.png`} />
                        </div>
                        <div className='right-textwrapper'>
                            <h3>Real-Life Examples of what your C-Level relationships could be worth to you</h3>
                            <div className='text-colcontainer'>
                                <div className='col'>
                                    <div className='sublist-title'>
                                        <span>
                                            <img src={`${process.env.REACT_APP_IMG_URL}l_page1_icon14.png`} />
                                        </span>
                                        <h4>A CFO of a Medical Group is introduced to Aspire Uniform & Linen Audits</h4>
                                    </div>
                                    <div className='sub-list'>
                                        <p>Medical Group saves $400K</p>
                                        <p>Aspire Account Executive annual income: $20K</p>
                                        <p>Aspire Account Executive income over three years: $60K</p>
                                        <p>Aspire Account Executive income over five years: $100K</p>
                                    </div>
                                </div>
                                <div className='col'>
                                    <div className='sublist-title'>
                                        <span>
                                            <img src={`${process.env.REACT_APP_IMG_URL}l_page1_icon15.png`} />
                                        </span>
                                        <h4>A CEO of a large regional chain of auto dealerships is introduced to Aspire Uniform & Linen Audits</h4>
                                    </div>
                                    <div className='sub-list'>
                                        <p>Combined Savings $4M</p>
                                        <p>Aspire Account Executive annual income: $200K</p>
                                        <p>Aspire Account Executive income over three years: $600K</p>
                                        <p>Aspire Account Executive income over five years: $1M</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>





            <div className='left-video-andtext' ref={scollToRef4}>
                <div className='landing-container'>
                    <div className='left-video-andtext-subwrapper'>
                        <div className='left-video-leftblock'>
                            <div className='left-video-wrapper'>
                                <iframe width="" height="" src="https://player.vimeo.com/video/775894058?color&autopause=0&loop=0&muted=0&title=1&portrait=1&byline=1&h=1a8e7d926f#t=" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
                            </div>
                        </div>
                        <div className='left-video-rightblock'>
                            <div className='title_main_wrapper'>
                                <div className='title_sub_wrapper'>
                                    <h2>Aspire Specialized Tax Savings</h2>
                                    <p>Aspire Specialized Tax Savings TMS software and program sources hundreds of tax credits for companies.</p>
                                </div>
                            </div>
                            <div className='landing_page1_list_wrapper'>
                                <p>Online platform that puts the power of funding directly into the hands of the client</p>
                                <p>TMS allows businesses with employees or facilities to qualify, track, and claim hundreds of tax credits and incentives with one simple login</p>
                                <p>The system works on an ongoing basis, continually locating and qualifying clients for funding they are missing out on while INCREASING THEIR CASH FLOW daily</p>
                                <p>The system works as a business organization tool, keeping the client engaged and allowing us to continually work on their behalf to get the funding on an ongoing basis</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>


            <div className='logoandtext rightlogo-andtext'>
                <div className='landing-container'>
                    <div className='logoandtext-colcontainer'>
                        <div className='left-logowrapper'>
                            <img src={`${process.env.REACT_APP_IMG_URL}l_page1_logo4.png`} />
                        </div>
                        <div className='right-textwrapper'>
                            <h3>Real-Life Examples of what your C-Level relationships could be worth to you</h3>
                            <div className='text-colcontainer'>
                                <div className='col'>
                                    <div className='sublist-title'>
                                        <span>
                                            <img src={`${process.env.REACT_APP_IMG_URL}l_page1_icon16.png`} />
                                        </span>
                                        <h4>A Software IT company with 278 employees used our tax management system (TMS) for the Employee Retention Credit (ERC) and the Research & Developement (R&D) tax incentives</h4>
                                    </div>
                                    <div className='sub-list'>
                                        <p>ERC benefit to company $3,650,250</p>
                                        <p>Aspire Account Executive income for ERC: $21,901</p>
                                        <p>R&D benefit to company $600,000</p>
                                        <p>Aspire Account Executive income for R&D: $8,400</p>
                                    </div>
                                </div>
                                <div className='col'>
                                    <div className='sublist-title'>
                                        <span>
                                            <img src={`${process.env.REACT_APP_IMG_URL}l_page1_icon17.png`} />
                                        </span>
                                        <h4>Apartment complex owner utilizing the tax management system (TMS) implemented a Cost Segregation study for the buildings and Employee Retention Credit (ERC) for their 30 employees</h4>
                                    </div>
                                    <div className='sub-list'>
                                        <p>Cost Segregation benefit to company $6,765,589</p>
                                        <p>Aspire Account Executive income for Cost Seg: $40,593</p>
                                        <p>ERC benefit to company $730,050</p>
                                        <p>Aspire Account Executive income for ERC: $4,380</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>




            <div className='left-video-andtext' ref={scollToRef5}>
                <div className='landing-container'>
                    <div className='left-video-andtext-subwrapper'>
                        <div className='left-video-leftblock'>
                            <div className='left-video-wrapper'>
                                <iframe width="" height="" src="https://www.youtube.com/embed/nc5Lj90BzSQ" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
                            </div>
                        </div>
                        <div className='left-video-rightblock'>
                            <div className='title_main_wrapper'>
                                <div className='title_sub_wrapper'>
                                    <h2>Aspire Medical Savings</h2>
                                    <p>Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. </p>
                                </div>
                            </div>
                            <div className='landing_page1_list_wrapper'>
                                <p>Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, </p>
                                <p>Lorem Ipsum is simply dummy text of the printing and typesetting industry.</p>
                                <p>Lorem Ipsum is simply dummy text of the</p>
                                <p>Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>





            <div className='logoandtext leftlogo-andtext'>
                <div className='landing-container'>
                    <div className='logoandtext-colcontainer'>
                        <div className='left-logowrapper'>
                            <img src={`${process.env.REACT_APP_IMG_URL}l_page1_logo5.png`} />
                        </div>
                        <div className='right-textwrapper'>
                            <h3>Real-Life Examples of what your C-Level relationships could be worth to you</h3>
                            <div className='text-colcontainer'>
                                <div className='col'>
                                    <div className='sublist-title'>
                                        <span>
                                            <img src={`${process.env.REACT_APP_IMG_URL}l_page1_icon10.png`} />
                                        </span>
                                        <h4>Lorem Ipsum is simply dummy text of the printing and typesetting industry.</h4>
                                    </div>
                                    <div className='sub-list'>
                                        <p>Lorem Ipsum is simply</p>
                                        <p>Lorem Ipsum is simply dummy text of the printing</p>
                                        <p>Lorem Ipsum is simply dummy text of the printing and typesetting industry.</p>
                                        <p>Lorem Ipsum is simply dummy text of the printing and</p>
                                    </div>
                                </div>
                                <div className='col'>
                                    <div className='sublist-title'>
                                        <span>
                                            <img src={`${process.env.REACT_APP_IMG_URL}l_page1_icon11.png`} />
                                        </span>
                                        <h4>Lorem Ipsum is simply dummy text of the printing and typesetting industry.</h4>
                                    </div>
                                    <div className='sub-list'>
                                        <p>Lorem Ipsum is simply</p>
                                        <p>Lorem Ipsum is simply dummy text of the printing</p>
                                        <p>Lorem Ipsum is simply dummy text of the printing and typesetting industry.</p>
                                        <p>Lorem Ipsum is simply dummy text of the printing and</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>




            <div className='left-video-andtext' ref={scollToRef6}>
                <div className='landing-container'>
                    <div className='left-video-andtext-subwrapper'>
                        <div className='left-video-leftblock'>
                            <div className='left-video-wrapper'>
                                <iframe width="" height="" src="https://www.youtube.com/embed/nc5Lj90BzSQ" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
                            </div>
                        </div>
                        <div className='left-video-rightblock'>
                            <div className='title_main_wrapper'>
                                <div className='title_sub_wrapper'>
                                    <h2>Aspire Pharmacy Savings</h2>
                                    <p>Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. </p>
                                </div>
                            </div>
                            <div className='landing_page1_list_wrapper'>
                                <p>Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, </p>
                                <p>Lorem Ipsum is simply dummy text of the printing and typesetting industry.</p>
                                <p>Lorem Ipsum is simply dummy text of the</p>
                                <p>Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>





            <div className='logoandtext rightlogo-andtext'>
                <div className='landing-container'>
                    <div className='logoandtext-colcontainer'>
                        <div className='left-logowrapper'>
                            <img src={`${process.env.REACT_APP_IMG_URL}l_page1_logo6.png`} />
                        </div>
                        <div className='right-textwrapper'>
                            <h3>Real-Life Examples of what your C-Level relationships could be worth to you</h3>
                            <div className='text-colcontainer'>
                                <div className='col'>
                                    <div className='sublist-title'>
                                        <span>
                                            <img src={`${process.env.REACT_APP_IMG_URL}l_page1_icon12.png`} />
                                        </span>
                                        <h4>Lorem Ipsum is simply dummy text of the printing and typesetting industry.</h4>
                                    </div>
                                    <div className='sub-list'>
                                        <p>Lorem Ipsum is simply</p>
                                        <p>Lorem Ipsum is simply dummy text of the printing</p>
                                        <p>Lorem Ipsum is simply dummy text of the printing and typesetting industry.</p>
                                        <p>Lorem Ipsum is simply dummy text of the printing and</p>
                                    </div>
                                </div>
                                <div className='col'>
                                    <div className='sublist-title'>
                                        <span>
                                            <img src={`${process.env.REACT_APP_IMG_URL}l_page1_icon13.png`} />
                                        </span>
                                        <h4>Lorem Ipsum is simply dummy text of the printing and typesetting industry.</h4>
                                    </div>
                                    <div className='sub-list'>
                                        <p>Lorem Ipsum is simply</p>
                                        <p>Lorem Ipsum is simply dummy text of the printing</p>
                                        <p>Lorem Ipsum is simply dummy text of the printing and typesetting industry.</p>
                                        <p>Lorem Ipsum is simply dummy text of the printing and</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>














            <div className='landing_page1_block11'>
                <div className='landing-container'>
                    <div className='landing_page1_block11_subwrapper'>

                        <h2>READY TO LEARN MORE...ASK YOURSELF</h2>
                        <p>Do I have established relationships with decision-makers such as CEOs, Presidents, Finance Directors, and/or Business Owners?</p>
                        <p>If your answer is yes, tap into this enormous early adoption stage market and prepare for your future! Use your earnings to build your retirement fund, or your whatever fund.</p>
                        <img src={`${process.env.REACT_APP_IMG_URL}l_page1_image11.png`} />
                    </div>
                </div>
            </div>


        </>
    )
}

export default LandingPage1
