
import Snackbar from '@mui/material/Snackbar';
import Modal from '@mui/material/Modal';
import Typography from '@mui/material/Typography';
import CloseIcon from '@mui/icons-material/Close';
import store from "../../../../store";

// import * as React from 'react';
import React, { useEffect, useRef, useState } from "react";
import { useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { Button, Checkbox, Chip, FormControl, Grid, InputLabel, LinearProgress, List, ListItemText, MenuItem, OutlinedInput, Select, TextField, Tooltip } from '@mui/material';

import { Box, display, Stack } from '@mui/system';
import { useCookies, Cookies } from "react-cookie";
import { addUserRoles, roundRobinEdit } from './userListReducer';



const ConfigureRoundRobinModal = (props) => {



    const dispatch = useDispatch(); // ---- Dispatch -----
    const navigate = useNavigate(); //**** FOR PAGE NAVIGATION****//

    // ---- States ----
    const [loading, setLoading] = useState(false);  //*****FOR LODING****//

    // ---- Cookies ---
    const [userInfo, setuserInfoCookie] = useCookies(["userinfocookies"]);

    const [priority, setPriority] = useState('');
    const [value, setValue] = useState('');


    // console.warn("<<<<<< configureRoundRobin props goes here >>>>>>", props)
    // console.warn("<<<<<< cookies goes here >>>>>", userInfo)

    // ---- for loader -----
    const loader = useSelector(state => (state.userManagment?.loading) ? state.userManagment?.loading : false)


    const success = useSelector(state => (state.userManagment?.resMessage) ? state.userManagment?.resMessage : null)




    useEffect(() => {
        props.setModalResponse(false);
    }, [])


    // ------- onModalClose --------
    function ModalClose() {
        // console.warn("<<< modal close hit >>>")
        props.setModalResponse(false);
        props.tooglefunc(false);

    }



    // ------- onAddButton Trigger ------
    function formSubmit(event) {
        event.preventDefault();

        const priority_new = priority !== '' ? priority : props?.data?.priority
        const value_new = value !== '' ? value : props?.data?.value

        console.warn('Priority:', priority_new, 'Value: ', value_new)


        if (priority_new !== "" && value_new !== "") {

            const data = {
                "_id": props?.data?._id,
                "priority": priority_new,
                "value": value_new
            }

            dispatch(roundRobinEdit(data))

            setTimeout(() => {
                ModalClose();
            }, 2000);
        }


    }
    // ----------------------------------------







    return (
        <>

            {/* ------ Confirmation Modal ------ */}


            <Modal
                className="modalblock"
                open={props.toogle}
                onClose={ModalClose}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
            >

                <Box className="BoxMui_modal configure-role" sx={{
                    position: "absolute",
                    top: "50%",
                    left: "50%",
                    transform: "translate(-50%, -50%)",
                    width: 400,
                    bgcolor: "background.paper",
                    border: "2px solid #000",
                    boxShadow: 24,
                    p: 4,
                    textAlign: "center",
                }}>
                    <Typography id="modal-modal-title" variant="h6" component="h3">
                        {loader ? <LinearProgress /> : ""}

                        Manage Round Robin
                    </Typography>


                    {/* ----- Dropdown for selecting roles ----- */}

                    <form className='configRoundRobinModal' onSubmit={(event) => formSubmit(event)}>

                        <FormControl sx={{ m: 1, width: '100%' }} className="feildContainer">

                            <TextField id="outlined-basic"
                                name='priority'
                                onInput={e => setPriority(e.target.value)}
                                className='inputBlock textF '
                                type="number"
                                label="Priority"
                                variant="outlined"
                                defaultValue={props?.data?.priority} 
                                sx={{ mr: 1}}
                                />

                            <TextField id="outlined-basic"
                                name='value'
                                onInput={e => setValue(e.target.value)}
                                className='inputBlock textF'
                                type="number"
                                label="Value"
                                variant="outlined"
                                defaultValue={props?.data?.value}
                                sx={{ mr: 1}}
                            />


                        </FormControl>
                        <Button variant="contained" type='submit' className="modalBTN">SUBMIT</Button>


                        {/* <Button variant="contained" onClick={ModalClose} className="modalBTN">Cancel</Button> */}
                    </form>


                    {/* -------------------------------------- */}



                    <Button onClick={ModalClose} className="closeModalBtn">
                        <Tooltip title="Close" placement="right-start">

                            <CloseIcon />
                        </Tooltip>
                    </Button>
                </Box>
            </Modal>




            {/* {success != null ? (<Snackbar
                open={open}
                autoHideDuration={6000}
                // onClose={() => setopen(false)}
                message={success}
            // action={action}
            />) : ""} */}

        </>
    )
}


export default ConfigureRoundRobinModal;



