import { Button, IconButton, Modal } from "@mui/material";
import { Box } from "@mui/system";
import React from "react";
import CloseIcon from "@mui/icons-material/Close";


function Footer() {

  const [openMoadl, setOpenMoadl] = React.useState(false);

  const closeModal = () => {
    setOpenMoadl(false);
  };

  return (
    <>
      <div className="footerBody" onClick={() => setOpenMoadl(true)}>
   
          <span >Terms & Conditions</span>
        
        | <span >Privacy Policy</span>  Copyright@2022
      </div>

      <div>
        <Modal
          classname="image_card_modal modalBaseStyle"
          open={openMoadl}
          onClose={closeModal}
          closeAfterTransition
        >
          <Box className="modalBaseBox largeModal">
            <IconButton className="modal_icon_button" onClick={closeModal}>
              <CloseIcon className="modal_close_icon" />
            </IconButton>
            <h1>Cooming Soon</h1>
          </Box>
        </Modal>
      </div>
    </>
  );
}

export default Footer;
