import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";






export const getprogramdata = createAsyncThunk("getprogramdata", async (reqBody) => {

    if (!reqBody) {

        reqBody = {
            "condition": {
                "limit": 10,
                "skip": 0
            },
            "sort": {
                "field": "updatedon_datetime",
                "type": "desc"
            },
            "searchcondition": {

            },
            "project": {},
            "token": "",
            "count": false
        }
    }

    console.log("reqbody==>", reqBody);
    let req_body = {};
    req_body = { ...reqBody };
    console.log("req_body==", req_body);
    const requestOptions = {
        method: 'post',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify(req_body)
    };
    console.log("process.env==>", process.env);
    const response = await fetch(process.env.REACT_APP_API_URL + "program-manage/program-list-count", requestOptions);
    const respdata = await response.json();
    // console.log("respdata after endpoint fetch==>", respdata);
    return respdata;




})


export const getprogrameditdata = createAsyncThunk("editrole", async (id) => {
    let reqBody={
        "condition": { },
        "sort": {
            "field": "name",
            "type": "desc"
        },
        "searchcondition": {"_id":id},
        "project": {},
        "token": ""
    }
    console.log("reqbody==>", reqBody);
    let req_body = {};
    req_body = { ...reqBody };
    console.log("req_body==", req_body);
    const requestOptions = {
        method: 'post',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify(req_body)
    };
    console.log("process.env==>", process.env);
    const response = await fetch(process.env.REACT_APP_API_URL + "program-manage/fetch-programs", requestOptions);
    const respdata = await response.json();
    // console.log("respdata after endpoint fetch==>", respdata);
    return respdata;


})

















const ProgramReducer = createSlice({
name: 'ProgramReducer',
initialState: {

    submitting: false,
    loading: false,
    editloading: false,
    programdata: [],
    editprogramdata:[],
    testdata: '',


},
reducers: {
    changeTestData(state, action) {
        state.testdata = action.payload


    },
    clearprogramdata(state, action) {
        state.programdata=[]
    },
    cleareditprogramdata(state, action) {
        state.editprogramdata=[]
    }
    // resetendpointSUccess(state, action) {
    //     state.endpointsuccess= {}
    // },
    // cleartrainingcatagorydata(state, action) {
    //     state.trainingcatagorydata= []
    // }
},
extraReducers: {
    /// #################### Training Listing ###############################
    [getprogramdata.rejected]: (state, action) => {
        state.endpointsuccess[action.type] = false
        // state.loading = false;
        state.successAction = false;

    },
    [getprogramdata.pending]: (state, action) => {


        console.log("getprogramdata=========================>")
        state.loading = true;

    },
    [getprogramdata.fulfilled]: (state, action) => {

        console.log("action from storedata", action)
        if (action.payload.status === 'success') {
        
            state.programdata = action.payload.results.res;
            state.loading = false;

        }

    },
    [getprogrameditdata.rejected]: (state, action) => {
        state.endpointsuccess[action.type] = false
        // state.loading = false;
        // state.successAction = false;

    },
    [getprogrameditdata.pending]: (state, action) => {


        console.log("getroleeditdata=========================>")
        state.editloading = true;

    },
    [getprogrameditdata.fulfilled]: (state, action) => {

        console.log("action from storedata", action)
        if (action.payload.status === 'success') {
        
            state.editprogramdata = action.payload.results.res;
            state.editloading = false;

        }

    }

}

})

export default ProgramReducer.reducer;
export const { changeTestData,clearprogramdata,cleareditprogramdata } = ProgramReducer.actions;


