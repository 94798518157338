import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { Form } from '../../../../form/Form';
import { resolvefunction } from '../../../../helper/helperFunctions'
import { resetresolvenew } from '../../../../helper/ResolveReducer';
import store from '../../../../store';
import { getjobcategorydata, clearjobcategorydata, getjobcategoryeditdata, cleareditjobcategorydata, getroledata, clearroledata } from '../JobTicketReducer';
import './JobticketsCategory.css'


import { useState } from 'react';
import { setSnackbar } from '../../../Layout/layoutReducer';




function JobticketsCategoryAddEdit() {
  const navigate = useNavigate()
  const pathname = useLocation();
  const { id } = useParams();
  const [selecttypeArry, setselecttypeArry] = useState([]);
  // const [locationArry, setLocationArry] = useState([]); //######### For Sate List ######### //


  let reqbodyforlead = {
    "condition": {
      "limit": 5,
      "skip": 0
    },
    "sort": {
      "field": "_id",
      "type": "desc"
    },
    "searchcondition": {},
    "project": {},
    "token": ""
  }
  let reqbodyforrole ={
    "condition": {
      "limit": 100,
      "skip": 0
    },
    "sort": {
      "field": "_id",
      "type": "desc"
    },
    "searchcondition": {"status":1 },
    "project": { },
    "token": ""
  }


  const goBackClicked = useSelector(state => state.formSlice?.customButtonClicked?.JobticketsCategoryAddEditForm?.jobgoback001 ? state.formSlice.customButtonClicked.JobticketsCategoryAddEditForm.jobgoback001 : false);
  const editdata = useSelector(state => (state.JobTicketReducer?.editjobcategorydata && state.JobTicketReducer?.editjobcategorydata.length > 0) ? state.JobTicketReducer.editjobcategorydata[0] : null); // ########## Fetch Edit Value From Store Which Display in Form ########### //
  let roledata = useSelector(state => (state.JobTicketReducer?.roledata && state.JobTicketReducer?.roledata?.length > 0) ? state.JobTicketReducer.roledata : []);
  const resolved = useSelector(state => state.ResolveReducer?.resolved[`/edit-jobtickets-category/${id}`] ? state.ResolveReducer.resolved[`/edit-jobtickets-category/${id}`] : false)

  const submitbtnclick = useSelector(state => state.formSlice.formSubmissionState.JobticketsCategoryAddEditForm ? state.formSlice.formSubmissionState.JobticketsCategoryAddEditForm :0)

  useEffect(() => {
    store.dispatch(getroledata(reqbodyforrole));
    if (!resolved) {
      if (id !== undefined && pathname !== null && pathname.pathname !== undefined) {
        console.log("ppp>>", editdata)
        store.dispatch(getjobcategoryeditdata(id));

      }
      //store.dispatch(getallprogramdata(reqbodyforlead));
    }
    return () => {
      store.dispatch(cleareditjobcategorydata())
      store.dispatch(resetresolvenew('/edit-jobtickets-category/:id'))

    }
  }, [])


  const dispatch = useDispatch();

  useEffect(() => {
    if (id !== undefined && id !== null && editdata !== null){
        if (submitbtnclick == 2) {
          console.log("submitbtnclick", submitbtnclick);
          dispatch(setSnackbar({ open: true, message: "Update Successfully !", status: 'success' }))
        };
    }
    else{
      if (submitbtnclick == 2) {
        console.log("submitbtnclick", submitbtnclick);
        dispatch(setSnackbar({ open: true, message: "Add Successfully !", status: 'success' }))
      };
    }
    

  }, [submitbtnclick])



  console.log("resolved>>>", resolved);

  // useEffect(() => {

  //   if (id !== undefined && pathname !== null && pathname.pathname !== undefined) {
  //     console.log("ppp>>", editdata)
  //     store.dispatch(getjobcategoryeditdata(id));

  //   }
  //   store.dispatch(getroledata(reqbodyforlead));

  //   return () => {
  //     store.dispatch(cleareditjobcategorydata())

  //   }
  // }, [])

  useEffect(() => {
   
      if (roledata && roledata.length > 0) {
        let arr = [];
        for (let i in roledata) {
          arr.push({ val: roledata[i]._id, name: roledata[i].name })
        }
        setselecttypeArry(arr)
      }

    

    

  }, [roledata])
  console.log("roledata---->>", roledata);

  useEffect(() => {
    if (goBackClicked) {
        resolvefunction(navigate, '/jobticket-category', getjobcategorydata(reqbodyforlead))
    }

}, [goBackClicked])

  console.log("editdata>>>", editdata);
  console.log("selecttypeArry>>>", roledata);

  const dataform = {
    id: 'JobticketsCategoryAddEditForm',
    api_url: process.env.REACT_APP_API_URL,
    endPoint: "lead-manage/addorupdatejobcat",
    urlPathOnSuccessfulFormSubmission: "/jobticket-category",
    submitBtnName: "Submit",
    cancelBtnName: "Cancel",
    formButtonClass: "Formsubmit_button",
    formWrapperClass: "backend_addform addAdmin",
    formInlineClass: "backendTableInline_addform testwrpr trainingcata",
    formAdditionalSubmissionData: {

      _id: (id !== undefined && id !== null) ? id : undefined
    },

    fields: [
      {
        id: 0,
        heading: "Category Name",
        label: "Category Name",
        name: "category_name",
        // className: "inputBlock inputBlock1line",
        className: 'inputBlock inputBlock1line',
        type: "text",
        rules: { required: true },
        errorMessage: {
          required: "This Field is Required",
        },
        //   minLength: "Minimum length should be 15",
        //   custom: "Value is Invalid"
        // defaultValue: (editdata && Object.keys(editdata).length > 0 && editdata.category_name !== undefined) ? editdata.category_name : undefined,
        defaultValue: (editdata && Object.keys(editdata).length > 0 && editdata.category_name !== undefined) ? editdata.category_name : undefined,

      },
      {
        id: 1,
        heading: "Description",
        label: "SunEditor",
        name: "category_des",
        type: "sunEditor",
        className: "inputBlock1line",
        rules: { required: true },
        errorMessage: {
          required: "This Field is Required",
          //   minLength: "Minimum length should be 15",
          //   custom: "Value is Invalid"
        },

        placeholder: "Please type here...",
        ////////////////////////////// Mandatory or no options will be available /////////////////////
        editorWidth: 300,
        buttonListOptions: [
          ['undo', 'redo', 'font', 'fontSize', 'formatBlock'],
          ['bold', 'underline', 'italic', 'strike', 'subscript', 'superscript', 'removeFormat'],
          ['fontColor', 'hiliteColor', 'outdent', 'indent', 'align', 'horizontalRule', 'list', 'table'],
          ['link', 'image', 'video', 'fullScreen', 'showBlocks', 'codeView', 'preview', 'print', 'save']
        ],
        defaultValue: (editdata && editdata.category_des !== undefined) ? editdata.category_des : undefined,

      },

      {
        id: 2,
        heading: "Select User Roll",
        label: "Select User Roll",
        name: "user_type",
        className: ' inputBlock inputBlock2line',
        type: 'select',
        // multiple: true,
        checkbox: false,
        other: false,
        rules: { required: true},
        values: selecttypeArry,
        defaultValue: (editdata && editdata.user_type !== undefined) ? editdata.user_type : undefined,
        errorMessage: {
          required: "This Field is Required",
        },

      },

      {
        id: 3,
        heading: "Priority",
        label: "priority",
        name: "priority",
        className: 'inputBlock inputBlock2line',
        type: "text",
        inputType: "number",/////////////// If not mentioned default will be text //////////////
        rules: { required: true },
        errorMessage: {
          required: "This Field is Required",
          maxLength: "MaxLength length 20 allowed",
          validate: "Lastnameeeeeeee is not valid",
          custom: "Value is Invalid"
        },
        defaultValue: (editdata && editdata.priority !== undefined) ? editdata.priority : undefined,
      },
      {
        id: 4,
        // heading: "Status",
        // label: "Status",
        name: "status",
        className: 'inputBlock1line',
        type: 'singleCheckbox',
        values: { key: 1, val: 'Active' },
        // rules: { required: true },
        // errorMessage: {
        //   required: "This Field is Required",
        // },
        defaultValue: (editdata && editdata.status !== undefined) ? Boolean(editdata.status) : false,

      },


    ],
    customButtons: [
      {
        id: 'jobgoback001',
        label: 'Go Back',
        className: 'default',
        type: 'button',
    },
    ]


  }




  return (
    <>
      <h1 className='page_heading'>ADD JOBTICKET CATEGORY</h1>
      <div className="dataform2_wrapper">
        <div className='adminformbody'>
          {id !== undefined && id !== null && editdata !== null ? <Form formData={dataform} /> : (id === undefined ? <Form formData={dataform} /> : '')}
        </div>
      </div>

      
    </>
  )
}

export default JobticketsCategoryAddEdit
