import { Button } from '@mui/material';
import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import { Form } from '../../form/Form';
import { getCIFForm, sendCIF_Mail } from '../Backend/LeadManagement/LeadReducer';
import './ClintInfoForm.css'
import { setSnackbar } from '../Layout/layoutReducer'

const ClintInfoForm = ({ formData, otherData }) => {
  // ---------------------------------------------Invoking Hooks--------------------------------------------- //
  const dispatch = useDispatch();
  const { lead_id, form_id, id } = useParams();   // Here id is the lead id

  // ----------------------------------------Global State from Redux---------------------------------------- //
  const clintInfoForm = useSelector((state) => state.LeadReducer?.info_CIF ? state.LeadReducer.info_CIF : null);
  const sendMail = useSelector((state) => state.formSlice?.customButtonClicked?.CIF_form?.send_cif_mail ? state.formSlice.customButtonClicked.CIF_form.send_cif_mail : false);

  // --------------------------------------------Other Veriables-------------------------------------------- //
  const cif_form = {
    id: 'CIF_form',
    api_url: process.env.REACT_APP_API_URL,
    endPoint: "sample/add-update-clint-info",
    urlPathOnSuccessfulFormSubmission: "/lead-list",
    submitBtnName: "Submit",
    cancelBtnName: "Cancel",
    formButtonClass: "Formsubmit_button",
    formWrapperClass: "cif_form cif_contact_form",
    formInlineClass: "backendTableInline_addform testwrpr trainingcata",
    formAdditionalSubmissionData: { form_id: form_id ? form_id : undefined, lead_id: lead_id ? lead_id : id, associated_program_id: otherData?.associated_program },
    fields: formData ? formData : (clintInfoForm ? clintInfoForm : []),
    customButtons: [{
      id: 'send_cif_mail',
      label: 'Send Mail',
      className: 'default',
      type: 'button',
    },]
  }

  // -------------------------------------------useEffect Section------------------------------------------- //
  useEffect(() => {
    // console.log("ClintInfoForm test ==========>", lead_id, form_id, id, formData, otherData);

    // For getting CIF form config
    let body = { searchcondition: { _id: form_id } } // Here _id is the form id
    !formData && dispatch(getCIFForm(body));
  }, []);

  useEffect(() => {
    if (sendMail) {
      dispatch(sendCIF_Mail());
      dispatch(setSnackbar({ open: true, message: 'Mail sent successfully !!', status: 'success' }));
    }

  }, [sendMail]);

  //----------------------------------------------return-----------------------------------------------//
  return (
    <>
      <div className='form_container'>
        <div className='form_contain_wraper'>
          {cif_form?.fields && cif_form.fields.length > 0 ? <h3>Client Information Form</h3> : <h3>Client Information Form</h3>}
          {cif_form?.fields && cif_form.fields.length > 0 && <Form formData={cif_form} />}
        </div>
      </div>
    </>
  )
}

export default ClintInfoForm