import React, { useCallback, useEffect, useRef, useState } from "react";
import {
  Autocomplete,
  Checkbox,
  CircularProgress,
  FormControl,
  FormHelperText,
  InputLabel,
  LinearProgress,
  TextField,
} from "@mui/material";
import CheckBoxOutlineBlankIcon from "@mui/icons-material/CheckBoxOutlineBlank";
import CheckBoxIcon from "@mui/icons-material/CheckBox";
import debounce from "lodash.debounce";
import { useDispatch, useSelector } from "react-redux";
import {
  fetchAutoCompleteList,
  resetFetchAutoCompleteListState,
  resetFormFieldError,
  resetOtherAutocompleteFlag,
  resetOtherData,
  resetTempFormUpdateData,
  setAutocompleteQuary,
  setOtherAutocompleteFlag,
} from "../formReducer";
import store from "../../store";
import lodash from "lodash";

///////////////////// Temp Import /////////////////
import { BASE_URL, reqBody } from "../config";
import { randomId } from "../../helper/helperFunctions";
import { json } from "react-router-dom";

export const AutoCompleteField = ({
  fielddata,
  value,
  setValue,
  getValues,
  errors,
  clearErrors,
  formId,
  setError,
  reset,
  setReset,
}) => {
  const dispatch = useDispatch();

  const [val, setVal] = useState(null);
  const [data, setData] = useState(null);
  // const [query, setQuery] = useState(null);
  const query = useRef(null);
  // const [options, setOptions] = useState([]);
  const options = useSelector((state) =>
    state.formSlice.autoCompleteList &&
    state.formSlice.autoCompleteList[formId] &&
    state.formSlice.autoCompleteList[formId][fielddata.name]
      ? state.formSlice.autoCompleteList[formId][fielddata.name]
      : []
  );
  const [loading, setLoading] = useState(false);
  const [formInputData, setFormInputData] = useState(null);
  const [tempFormInputData, setTempFormInputData] = useState(null);
  const [customError, setCustomError] = useState(null);
  // const [inputelement, setInputelement] = useState(null);
  const inputelement = useRef(null);

  const otherVal = useSelector((state) =>
    state.formSlice.otherData[formId] &&
    state.formSlice.otherData[formId][fielddata.name]
      ? state.formSlice.otherData[formId][fielddata.name]
      : null
  );
  const autoCompleteListState = useSelector((state) =>
    state.formSlice.autoCompleteListState[formId] &&
    state.formSlice.autoCompleteListState[formId][fielddata.name]
      ? state.formSlice.autoCompleteListState[formId][fielddata.name]
      : 0
  );
  const autoCompleteList = useSelector((state) =>
    state.formSlice.autoCompleteList[formId] &&
    state.formSlice.autoCompleteList[formId][fielddata.name]
      ? state.formSlice.autoCompleteList[formId][fielddata.name]
      : null
  );
  const quaryValue = useSelector((state) =>
    state.formSlice.autocompleteQuary[formId] &&
    state.formSlice.autocompleteQuary[formId][fielddata.name]
      ? state.formSlice.autocompleteQuary[formId][fielddata.name]
      : null
  );

  useEffect(() => {
    console.log(
      "autoCompleteListState===============================>",
      autoCompleteListState,
      autoCompleteList
    );
    if (autoCompleteListState === 2 && fielddata.defaultValue) {
      if (
        fielddata.multiple !== undefined &&
        fielddata.multiple === true &&
        Array.isArray(fielddata.defaultValue)
      ) {
        const newData = autoCompleteList
          .map((option) => {
            if (fielddata.defaultValue.includes(option.key)) {
              return { key: option.key, label: option.label };
            } else return;
          })
          .filter((data) => data !== undefined);
        console.log("newData=======================++>", newData);
        setData(newData);
        setValue(
          fielddata.name,
          newData.map((data) => data.key)
        );
      }

      if (
        (fielddata.multiple === undefined || fielddata.multiple === false) &&
        !Array.isArray(fielddata.defaultValue)
      ) {
        console.log("autoCompleteList=====================>", autoCompleteList);
        const newData = autoCompleteList.find(
          (option) => option.key == fielddata.defaultValue
        );
        console.log("newData=======================++>", newData);
        if (newData) {
          setData(newData.label);
          setValue(fielddata.name, newData.key);
        }
      }
    }

    if (autoCompleteListState !== 0 && autoCompleteListState !== 1)
      setTimeout(
        () =>
          dispatch(
            resetFetchAutoCompleteListState({
              formId,
              autoCompleteId: fielddata.name,
            })
          ),
        1000
      );
  }, [autoCompleteListState]);

  // useEffect(() => {
  //   setQuery(quaryValue);
  // //  dispatch(setAutocompleteQuary({ formId: formId, fieldName: fielddata.name, value: quaryValue }))

  // }, [quaryValue])

  useEffect(() => {
    if (query.current !== null && inputelement.current !== null) {
      inputelement.current.target.value = query.current;

      console.log("options", options);
      inputelement.current.target.setAttribute("dd", "dddd");

      setTimeout(() => {
        inputelement.current.target.value = query.current;

        console.log(
          "debounce  vvvv==============",
          query.current,
          inputelement.current.target.value
        );
      }, 1000);
    }
  }, [JSON.stringify(options)]);

  store.subscribe(() => {
    const storeData = store.getState();

    if (
      Object.keys(storeData.formSlice.tempFormUpdateData).length > 0 &&
      storeData.formSlice.tempFormUpdateData[formId] !== undefined &&
      storeData.formSlice.tempFormUpdateData[formId][fielddata.name] !==
        undefined
    ) {
      console.log("storing====>");
      setVal(storeData.formSlice.tempFormUpdateData[formId][fielddata.name]);
    }
    if (storeData.formSlice.loading[fielddata.name] !== undefined) {
      setLoading(storeData.formSlice.loading[fielddata.name]);
    }
    // if (
    //   storeData.formSlice.autoCompleteList[formId] &&
    //   storeData.formSlice.autoCompleteList[formId][fielddata.name]
    // ) {
    //   setOptions(storeData.formSlice.autoCompleteList[formId][fielddata.name]);
    // }

    if (storeData.formSlice.formData[formId]) {
      console.log(
        "----------------------",
        storeData.formSlice.formData[formId]
      );
      setFormInputData(storeData.formSlice.formData[formId]);
    }

    //////////////////////////////////////////// Set Error from Outside ///////////////////////////////////////////////////////////
    if (
      Object.keys(storeData.formSlice.tempFormFieldError).length > 0 &&
      storeData.formSlice.tempFormFieldError[formId] !== undefined &&
      storeData.formSlice.tempFormFieldError[formId][fielddata.name] !==
        undefined
    ) {
      console.log("storing====>");
      setCustomError(
        storeData.formSlice.tempFormFieldError[formId][fielddata.name]
      );
    }
  });

  //////////////////////////////////// LOADASH DEBOUNCE ////////////////////////////////////////////////////
  const debouncedFetchData = useCallback(
    debounce((body) => {
      dispatch(
        fetchAutoCompleteList({
          BASE_URL: fielddata.base_url,
          endPoint: fielddata.endPoint,
          reqBody: body,
          restResponseAttachment: { autoCompleteId: fielddata.name, formId },
        })
      );
    }, 1000),
    []
  );

  const debouncedFetchAutocompleteQuary = useCallback(
    debounce((body) => {
      // console.log("body111", body);
      // setQuery(body)
      query.current = body;
      inputelement.current.target.value = query.current;

      if (query.current !== null && query.current !== undefined) {
        // console.log("From auto complete", fielddata);
        // let fieldData = {...fielddata}
        const reqBody = lodash.cloneDeep(fielddata.reqBody);

        // console.log('From auto complete', BASE_URL, reqBody)
        if (reqBody.searchcondition === undefined)
          reqBody["searchcondition"] = {};
        if (reqBody.searchcondition.label === undefined)
          reqBody.searchcondition["label"] = {};

        ///////////////// For Query Search //////////////////////////
        reqBody.searchcondition["label"]["$regex"] = query.current;
        reqBody.searchcondition["label"]["$options"] = "i";

        // console.log("reqBody==============", reqBody);

        dispatch(
          fetchAutoCompleteList({
            BASE_URL: fielddata.base_url,
            endPoint: fielddata.endPoint,
            reqBody: reqBody,
            restResponseAttachment: { autoCompleteId: fielddata.name, formId },
          })
        );

        // debouncedFetchData(reqBody);

        // inputelement.current.target.value = "ddddd";
        // inputelement.current.target.setAttribute("value", "fff");

      }

      // dispatch(setAutocompleteQuary({ formId: formId, fieldName: fielddata.name, value: query.current }))
    }, 1000),
    []
  );

  ////////////////////////////////////////////////////////////////////////
  useEffect(() => {
    if (reset === true) {
      setData(getValues(fielddata.name));
      setReset(false);
    }
  }, [reset]);
  /////////////////////////////////////////////////////

  useEffect(() => {
    if (otherVal !== null) {
      if (fielddata.multiple !== undefined && fielddata.multiple === true) {
        setData((prev) => [...prev, otherVal]);
        if (
          getValues(fielddata.name) &&
          Array.isArray(getValues(fielddata.name))
        )
          setValue(fielddata.name, [...getValues(fielddata.name), otherVal]);
        else setValue(fielddata.name, [otherVal]);
      }
      if (fielddata.multiple === undefined || fielddata.multiple === false) {
        setData(otherVal);
        setValue(fielddata.name, otherVal);
      }
      dispatch(resetOtherData({ formId, fieldName: fielddata.name }));
    }
  }, [otherVal]);

  useEffect(() => {
    if (val !== null) {
      if (fielddata.multiple !== undefined && fielddata.multiple === true) {
        setData((prev) => [...prev, ...val]);
        setValue(
          fielddata.name,
          val.map((cur) => cur.key)
        );
      }
      if (fielddata.multiple !== undefined && fielddata.multiple === false) {
        setData(val);
        setValue(fielddata.name, val.key);
      }
      dispatch(resetTempFormUpdateData({ formId, fieldName: fielddata.name }));
    }
  }, [val]);

  //////////////////////////////////////// Effect the field error from outside //////////////////////////////
  useEffect(() => {
    if (customError !== null) {
      setError(fielddata.name, customError);
      dispatch(resetFormFieldError({ formId, fieldName: fielddata.name }));
    }
  }, [customError]);

  //////////////////////////////////////////////// Checking Value ///////////////////////////////////////////
  // useEffect(() => {
  //   console.log("query1111", quaryValue);

  //   if (quaryValue !== null && quaryValue !== undefined) {
  //     console.log('From auto complete', fielddata)
  //     // let fieldData = {...fielddata}
  //     const reqBody = lodash.cloneDeep(fielddata.reqBody)

  //     console.log();
  //     // console.log('From auto complete', BASE_URL, reqBody)
  //     if (reqBody.searchcondition === undefined) reqBody["searchcondition"] = {}
  //     if (reqBody.searchcondition.label === undefined) reqBody.searchcondition["label"] = {}

  //     ///////////////// For Query Search //////////////////////////
  //     reqBody.searchcondition['label']["$regex"] = quaryValue;
  //     reqBody.searchcondition['label']["$options"] = "i";

  //     // console.log("reqBody==============", reqbody)

  //     // debouncedFetchData(reqBody);
  //   }
  // }, [quaryValue]);

  useEffect(() => {
    let req = fielddata.reqBody;
    // if (!fielddata.reqBody.searchcondition) fielddata.reqBody.searchcondition = {}
    if (fielddata.defaultValue)
      fielddata.reqBody.searchcondition.key = Array.isArray(
        fielddata.defaultValue
      )
        ? { $in: fielddata.defaultValue }
        : fielddata.defaultValue;
    console.log("fielddata123", fielddata);

    dispatch(
      fetchAutoCompleteList({
        BASE_URL: fielddata.base_url,
        endPoint: fielddata.endPoint,
        endPointType: fielddata.endPointType,
        extractionMethod: fielddata.extractionMethod,
        recieveResponseParam: fielddata.recieveResponseParam,
        reqBody: fielddata.reqBody,
        restBodyAttachment: { autoCompleteId: fielddata.name },
        restResponseAttachment: { autoCompleteId: fielddata.name, formId },
      })
    );
  }, []);

  useEffect(() => {
    if (
      formInputData !== null &&
      fielddata.extraParam !== undefined &&
      fielddata.extraParam.length > 0
    ) {
      console.log("formInputData", formInputData);
      let tempParamData = {};
      const reqBody = lodash.cloneDeep(fielddata.reqBody);
      fielddata.extraParam.forEach((param) => {
        if (formInputData[param] !== undefined)
          tempParamData[param] = formInputData[param];
      });
      if (Object.keys(tempParamData).length > 0) {
        Object.keys(tempParamData).forEach((param) => {
          reqBody.searchcondition[param] = {};

          /////////////////////////// For String Input ///////////////////////////
          if (
            typeof tempParamData[param] === "string" ||
            typeof tempParamData[param] === "number"
          )
            reqBody.searchcondition[param]["$regex"] = tempParamData[param];

          ///////////////////////// For Array Input /////////////////////////////
          if (Array.isArray(tempParamData[param]) === true)
            reqBody.searchcondition[param]["$in"] = tempParamData[param];
        });
        debouncedFetchData(reqBody);
      }
      console.log("search additional data", reqBody);
    }
  }, [formInputData]);

  // useEffect(() => {
  //   if (fielddata.defaultValue) {
  //     if (fielddata.multiple !== undefined && fielddata.multiple === true) {
  //       setValue(fielddata.name, fielddata.defaultValue.map(cur => cur.key));
  //       setData(fielddata.defaultValue)
  //     }

  //     if (fielddata.multiple !== undefined && fielddata.multiple === false) {
  //       setValue(fielddata.name, fielddata.defaultValue.key);
  //       setData(fielddata.defaultValue.label)
  //     }
  //   }
  // }, [fielddata.defaultValue]);

  // useEffect(()=>{
  //   console.log("getValues(fielddata.name)",getValues(fielddata.name));
  // },[getValues(fielddata.name)])
  return (
    <div className={"form_item " + fielddata.className}>
      {/* <span>{query.current}</span> */}
      {/* HEADING */}
      {fielddata.heading && (
        <InputLabel>
          <div dangerouslySetInnerHTML={{ __html: fielddata.heading }}></div>
        </InputLabel>
      )}
      {/* FIELD */}
      <FormControl>
        {/* LOADER ON FETCH OPERATION */}
        {loading ? (
          <LinearProgress className="autoCompleteProgressBar" />
        ) : null}
        <Autocomplete
          freeSolo={fielddata.freeSolo ? fielddata.freeSolo : false}
          disabled={
            fielddata.disabled !== undefined ? fielddata.disabled : false
          }
          multiple={fielddata.multiple}
          // value="[dfdffff"
          value={data !== null ? data : fielddata.multiple ? [] : ""}
          defaultValue={
            fielddata.defaultValue
              ? fielddata.defaultValue
              : fielddata.multiple
              ? []
              : ""
          }
          onChange={(e, newValue) => {
            console.log("newValue", newValue);
            clearErrors(fielddata.name);
            if (
              newValue &&
              ((Array.isArray(newValue) &&
                newValue.find((cur) => cur.key == "other00") !== undefined) ||
                (!Array.isArray(newValue) && newValue.key == "other00"))
            ) {
              dispatch(
                setOtherAutocompleteFlag({ fieldName: fielddata.name, formId })
              );
              setTimeout(
                () =>
                  dispatch(
                    resetOtherAutocompleteFlag({
                      fieldName: fielddata.name,
                      formId,
                    })
                  ),
                1000
              );
              return;
            }
            ///////////////// Value set for single selection //////////////
            if (
              !fielddata.multiple &&
              newValue !== null &&
              newValue.label !== null
            ) {
              setData(newValue);
              setValue(fielddata.name, newValue.key);
            }
            ///////////////// Value set for multiple selection //////////////
            if (fielddata.multiple && newValue !== null) {
              const tempArray = newValue.map((val) => val.key);
              setData(newValue);
              setValue(fielddata.name, tempArray);
            }
          }}
          renderOption={
            fielddata.checkbox && fielddata.checkbox == true
              ? (props, option, { selected }) => (
                  <li {...props} key={randomId()}>
                    {fielddata.checkbox && (
                      <Checkbox
                        icon={<CheckBoxOutlineBlankIcon />}
                        checkedIcon={<CheckBoxIcon />}
                        style={{ marginRight: 8 }}
                        checked={selected}
                      />
                    )}
                    {option.label}
                  </li>
                )
              : null
          }
          options={
            fielddata.other == true
              ? [...options, { key: `other00`, label: "Other" }]
              : options
          }
          limitTags={fielddata.limitTags ? fielddata.limitTags : 2}
          sx={fielddata.sx}
          renderInput={(params) => {
            return (
              <TextField
                {...params}
                ids="input1"
                // value="fffff"
                value={query.current}
                onChange={() => {
                  console.log("onChange called", query.current);
                  if (inputelement.current != null)
                    inputelement.current.target.value = query.current;
                }}
                onKeyUp={(e) => {
                  // setInputelement(e)
                  inputelement.current = e;
                  query.current = e.target.value;
                  // if (fielddata.realtime && fielddata.realtime == true) setQuery(e.target.value);
                  // setQuery(e.target.value);
                  // console.log("quaryval",e.target.value);

                  // if (e.target.value) dispatch(setAutocompleteQuary({ formId: formId, fieldName: fielddata.name, value: e.target.value }))
                  debouncedFetchAutocompleteQuary(e.target.value);

                // clearErrors(fielddata.name);
                }}
                label={fielddata.label}
              />
            );
          }}
        />
        {/* ERROR */}
        {errors[fielddata.name]?.type && (
          <FormHelperText sx={{ color: "red" }}>
            {fielddata.errorMessage[errors[fielddata.name].type]}
          </FormHelperText>
        )}
      </FormControl>
    </div>
  );
};
