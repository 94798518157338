import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";

export const stageListData = createAsyncThunk(
  "stageListData",
  async (reqBody) => {
    console.log("reqbody==>", reqBody);
    if (!reqBody) {
      reqBody = {
        condition: {
          limit: 10,
          skip: 0,
        },
        sort: {
          field: "updatedon_datetime",
          type: "desc",
        },
        searchcondition: {},
        project: {},
        token: "",
        count: false,
      };
    }

    let req_body = {};
    req_body = { ...reqBody };
    console.log("req_body==", req_body);
    const requestOptions = {
      method: "post",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify(req_body),
    };
    console.log("process.env==>", process.env);
    const response = await fetch(
      process.env.REACT_APP_API_URL + "stage-distribution/stage-list-count",
      requestOptions
    );
    const respdata = await response.json();
    // console.log("respdata after endpoint fetch==>", respdata);
    return respdata;
  }
);

export const stageEditData = createAsyncThunk("stageEditData", async (id) => {
  let reqBody = {
    "condition": {
      "limit": 1,
      "skip": 0
    },
    "sort": {
      "field": "_id",
      "type": "desc"
    },
    "searchcondition": { "_id": id },
    "project": {},
    "token": ""
}
  console.log("reqbody==>", reqBody);
  let req_body = {};
  req_body = { ...reqBody };
  console.log("req_body==", req_body);
  const requestOptions = {
    method: "post",
    headers: { "Content-Type": "application/json" },
    body: JSON.stringify(req_body),
  };
  console.log("process.env==>", process.env);
  const response = await fetch(
    process.env.REACT_APP_API_URL + "stage-distribution/stage-list-count",
    requestOptions
  );
  const respdata = await response.json();
  return respdata;
});

export const getStageData = createAsyncThunk("getStageData", async () => {
  let reqbody = {
    "condition": {
      "limit": 10,
      "skip": 0
    },
    "sort": {
      "field": "_id",
      "type": "desc"
    },
    "searchcondition": {},
    "project": {},
    "token": ""
  }
const requestOptions = {
  method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify(reqbody)
};

const response = await fetch(process.env.REACT_APP_API_URL + "stage-distribution/auto-complete-stages", requestOptions);
    const respdata = await response.json();
    // console.log("respdata after endpoint fetch==>", respdata);
    return respdata;
})

const stageReducer = createSlice({
  name: "StageReducer",
  initialState: {
    submitting: false,
    loading: false,
    editloading: false,
    stageListData: [],
    stageEditData: [],
    getstage: [],
  },
  reducers: {
    
    clearstagedata(state, action) {
        state.stageListData = []
    },
    clearstageEditData(state, action) {
      state.editStageData = []
    },
  },
  extraReducers: {

    [stageListData.rejected]: (state, action) => {
        state.endpointsuccess[action.type] = false
        // state.loading = false;
        state.successAction = false;
    },
    [stageListData.pending]: (state, action) => {
      state.loading = true;
    },
    [stageListData.fulfilled]: (state, action) => {
      console.log("action from storedata", action);
      if (action.payload.status === "success") {
        state.stageListData = action.payload.results.res;
        state.loading = false;
      }
    },

    [stageEditData.rejected]: (state, action) => {
      state.endpointsuccess[action.type] = false;
      // state.loading = false;
      // state.successAction = false;
    },
    [stageEditData.pending]: (state, action) => {
      console.log("stageEditData=========================>");
      state.editloading = true;
    },
    [stageEditData.fulfilled]: (state, action) => {
      console.log("action from storedata", action);
      if (action.payload.status === "success") {
        state.editStageData = action.payload.results.res;
        state.editloading = false;
      }
    },

    [getStageData.rejected]: (state, action) => {
      state.loading = false;
      state.successAction = false;
    },
    [getStageData.pending]: (state, action) => {
      state.loading = true;
    },
    [getStageData.fulfilled]: (state, action) => {
      if (action.payload.status === 'success') {
        state.getstage = action.payload.results.res;
        state.loading = false;
      }
    }
  },
});

export default stageReducer.reducer;
export const { clearstagedata, clearstageEditData } = stageReducer.actions;


