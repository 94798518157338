import Snackbar from '@mui/material/Snackbar';
import Modal from '@mui/material/Modal';
import Typography from '@mui/material/Typography';
import CloseIcon from '@mui/icons-material/Close';

import store from "../../store";

// import * as React from 'react';
import React, { useEffect, useRef, useState } from "react";
import { useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { Button, Checkbox, Chip, FormControl, Grid, InputLabel, LinearProgress, List, ListItemText, MenuItem, OutlinedInput, Select, TextField, Tooltip } from '@mui/material';

import { Box, display, Stack } from '@mui/system';
import { useCookies, Cookies } from "react-cookie";
import { reportIsuue } from './loginReducer';




const ReportAnIssueModal = (props) => {



    const dispatch = useDispatch(); // ---- Dispatch -----
    const navigate = useNavigate(); //**** FOR PAGE NAVIGATION****//

    // ---- States ----
    const [loading, setLoading] = useState(false);  //*****FOR LODING****//
    const [snackOpen, setSnackOpen] = useState(false);  //*****FOR LODING****//

    // ---- Cookies ---
    const [userInfo, setuserInfoCookie] = useCookies(["userinfocookies"]);

    const [email, setEmail] = useState('');
    const [issueData, setIssueData] = useState('');


    // console.warn("<<<<<< configureRoundRobin props goes here >>>>>>", props)
    // console.warn("<<<<<< cookies goes here >>>>>", userInfo)

    // ---- for loader -----
    const loader = useSelector(state => (state.loginSlice?.loading) ? state.loginSlice?.loading : false)


    const success = useSelector(state => (state.loginSlice?.resMessage) ? state.loginSlice?.resMessage : null)



    useEffect(() => {
        if (success) {
            setSnackOpen(true)
        }
    }, [success])





    // ------- onModalClose --------
    function ModalClose() {
        // console.warn("<<< modal close hit >>>")
        props.tooglefunc(false);

    }



    // ------- onAddButton Trigger ------
    function formSubmit(event) {
        event.preventDefault();

        const email_new = email !== '' ? email : ""
        const issueData_new = issueData !== '' ? issueData : ""

        console.warn('Email:', email_new, 'Issue: ', issueData_new)


        if (email_new !== "" && issueData_new !== "") {

            const data = {
                "email": email_new,
                "issue": issueData_new
            }

            dispatch(reportIsuue(data))

            setTimeout(() => {
                ModalClose();
            }, 5000);
        }


    }
    // ----------------------------------------







    return (
        <>

            {/* ------ Confirmation Modal ------ */}


            <Modal
                className="modalblock"
                open={props.toogle}
                onClose={ModalClose}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
            >

                <Box className="BoxMui_modal configure-role" sx={{
                    position: "absolute",
                    top: "50%",
                    left: "50%",
                    transform: "translate(-50%, -50%)",
                    width: 600,
                    bgcolor: "background.paper",
                    border: "2px solid #000",
                    boxShadow: 24,
                    p: 4,
                    textAlign: "center",
                }}>
                    <Typography id="modal-modal-title" variant="h6" component="h3">
                        {loader ? <LinearProgress /> : ""}

                        Report Issue
                    </Typography>


                    {/* ----- Dropdown for selecting roles ----- */}

                    <form className='ReportIssueModal' onSubmit={(event) => formSubmit(event)}>

                        <FormControl sx={{ m: 1, width: '100%' }} className="feildContainer">

                            <TextField id="outlined-basic"
                                name='reply_email'
                                onInput={e => setEmail(e.target.value)}
                                className='inputBlock textF '
                                // type="number"
                                label="Mail"
                                variant="outlined"
                            // defaultValue={props?.data?.priority} 
                            />

                            <TextField
                                placeholder="Explain your issue here"
                                multiline
                                onInput={e => setIssueData(e.target.value)}
                                className='inputBlock textF '
                                rows={2}
                                maxRows={4}
                            />

                        </FormControl>
                        <Button variant="contained" type='submit' className="modalBTN">SUBMIT</Button>


                        {/* <Button variant="contained" onClick={ModalClose} className="modalBTN">Cancel</Button> */}
                    </form>


                    {/* -------------------------------------- */}



                    <Button onClick={ModalClose} className="closeModalBtn">
                        <Tooltip title="Close" placement="right-start">

                            <CloseIcon />
                        </Tooltip>
                    </Button>
                </Box>
            </Modal>




            {success != null ? (<Snackbar
                open={snackOpen}
                autoHideDuration={6000}
                // onClose={() => setopen(false)}
                message={"Report Submited Successfully"}
            // action={action}
            />) : ""}

        </>
    )
}


export default ReportAnIssueModal;