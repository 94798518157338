const StateArray=[
    {
      "name": "Alabama",
      "val": "AL"
    },
    {
      "name": "Alaska",
      "val": "AK"
    },
    {
      "name": "Arizona",
      "val": "AZ"
    },
    {
      "name": "Arkansas",
      "val": "AR"
    },
    {
      "name": "California",
      "val": "CA"
    },
    {
      "name": "Colorado",
      "val": "CO"
    },
    {
      "name": "Connecticut",
      "val": "CT"
    },
    {
      "name": "Delaware",
      "val": "DE"
    },
    {
      "name": "Florida",
      "val": "FL"
    },
    {
      "name": "Georgia",
      "val": "GA"
    },
    {
      "name": "Hawaii",
      "val": "HI"
    },
    {
      "name": "Idaho",
      "val": "ID"
    },
    {
      "name": "Illinois",
      "val": "IL"
    },
    {
      "name": "Indiana",
      "val": "IN"
    },
    {
      "name": "Iowa",
      "val": "IA"
    },
    {
      "name": "Kansas",
      "val": "KS"
    },
    {
      "name": "Kentucky",
      "val": "KY"
    },
    {
      "name": "Louisiana",
      "val": "LA"
    },
    {
      "name": "Maine",
      "val": "ME"
    },
    {
      "name": "Maryland",
      "val": "MD"
    },
    {
      "name": "Massachusetts",
      "val": "MA"
    },
    {
      "name": "Michigan",
      "val": "MI"
    },
    {
      "name": "Minnesota",
      "val": "MN"
    },
    {
      "name": "Mississippi",
      "val": "MS"
    },
    {
      "name": "Missouri",
      "val": "MO"
    },
    {
      "name": "Montana",
      "val": "MT"
    },
    {
      "name": "Nebraska",
      "val": "NE"
    },
    {
      "name": "Nevada",
      "val": "NV"
    },
    {
      "name": "New Hampshire",
      "val": "NH"
    },
    {
      "name": "New Jersey",
      "val": "NJ"
    },
    {
      "name": "New Mexico",
      "val": "NM"
    },
    {
      "name": "New York",
      "val": "NY"
    },
    {
      "name": "North Carolina",
      "val": "NC"
    },
    {
      "name": "North Dakota",
      "val": "ND"
    },
    {
      "name": "Ohio",
      "val": "OH"
    },
    {
      "name": "Oklahoma",
      "val": "OK"
    },
    {
      "name": "Oregon",
      "val": "OR"
    },
    {
      "name": "Pennsylvania",
      "val": "PA"
    },
    {
      "name": "Rhode Island",
      "val": "RI"
    },
    {
      "name": "South Carolina",
      "val": "SC"
    },
    {
      "name": "South Dakota",
      "val": "SD"
    },
    {
      "name": "Tennessee",
      "val": "TN"
    },
    {
      "name": "Texas",
      "val": "TX"
    },
    {
      "name": "Utah",
      "val": "UT"
    },
    {
      "name": "Vermont",
      "val": "VT"
    },
    {
      "name": "Virgin Islands",
      "val": "VI"
    },
    {
      "name": "Virginia",
      "val": "VA"
    },
    {
      "name": "Washington",
      "val": "WA"
    },
    {
      "name": "West Virginia",
      "val": "WV"
    },
    {
      "name": "Wisconsin",
      "val": "WI"
    },
    {
      "name": "Wyoming",
      "val": "WY"
    }
  ]
  export default StateArray