import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";

export const fetchUserData = createAsyncThunk("fetch/editUser", async (reqBody) => {
    // console.warn("<<<<<< inside fetchUserDataReducer >>>>", reqBody)
    const reqOption = {
        method: "POST",
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({ _id: reqBody })
    };
    const response = await fetch(process.env.REACT_APP_API_URL + "users/single-fetch-data", reqOption);
    const respdata = await response.json();
    // console.log("respdata after endpoint fetch==>", respdata);
    return respdata;
});

const fetchUserDataReducer = createSlice({
    name: 'fetchUserDataReducer',
    initialState: {
        loading: false,
        success: false,
        error: false,
        successMsg: null,
        errorMsg: null,
        tokenRequired: false,
        tokenRequiredMsg: '',
        fetchUserDataReducer: null,
        userDetailsInfo: {}
    },
    reducers: {
        setInitData(state, action) {
            state.loading = false;
            state.success = false;
            state.error = false;
            state.successMsg = null;
            state.errorMsg = null;
            state.tokenRequired = false;
            state.tokenRequiredMsg = '';
            state.fetchUserDataReducer = null;
            // state.userInfo = {};
        }
    },
    extraReducers: {
        [fetchUserData.pending]: (state, action) => {
            state.loading = true
            state.fetchUserDataReducer = null
            // state.userInfo ={}

        },
        [fetchUserData.fulfilled]: (state, action) => {
            console.log("dash action ", action)
            state.loading = false;
            state.success = true;
            state.fetchUserDataReducer = action.payload?.results?.res;
            // state.userInfo = action.payload?.results?.res;

        },
        [fetchUserData.rejected]: (state, action) => {
            state.loading = false
        },
    }
});



export default fetchUserDataReducer.reducer;
export const { setInitData } = fetchUserDataReducer.actions;