import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";






export const getRoundRobinData = createAsyncThunk(
  "getRoundRobinData",
  async (reqBody) => {
    if (!reqBody) {
      reqBody = {
        condition: {
          limit: 10,
          skip: 0,
        },
        sort: {
          field: "name",
          type: "asc",
        },
        searchcondition: {},
        count: false,
      };
    }

    let req_body = {};
    req_body = { ...reqBody };
    const requestOptions = {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify(req_body),
    };
    const response = await fetch(
      process.env.REACT_APP_API_URL + "users/round-robin-listing",
      requestOptions
    );
    const respdata = await response.json();
    return respdata;
  }
);







export const editRoundRobinInputs = createAsyncThunk(
  "editRoundRobinInputs",
  async (reqbody) => {
    let req_body = {};
    req_body = { ...reqbody };
    const requestOptions = {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify(req_body),
    };
    const response = await fetch(
      process.env.REACT_APP_API_URL + "users/round-robin-edit",
      requestOptions
    );
    const respdata = await response.json();
    console.log(respdata);
    return respdata;
  }
);






const ManageRoundRodinReducer = createSlice({
  name: "ManageRoundRodinReducer",
  initialState: {
    RoundRobinData: [],
    submitionState: 0,
    loading: false,
    editLoding: false,
    priority: 0,
    value: 0,
    statusCode: null,
    messg: null,
    roundRobinDataCount: null,
    sortloder: false,
    shortCondition: false,
    shortConditionField:"_id",
    sortconditionType:"desc",
      surchstartDate:null,
      surchendDate:null,
    
    isSurch: false,
    roles:null,
    conditionArry:[]
  },
  reducers: {
    handelproperty: (state, action) => {
      state.priority = action.payload;
    },
    handelvalue: (state, action) => {
      state.value = action.payload;
    },
    clearSubmitionState: (state, action) => {
      state.submitionState = 0;
    },
  },
  extraReducers: {
    [editRoundRobinInputs.rejected]: (state, action) => {
      // state.endpointsuccess[action.type] = false;
      state.successAction = false;
      state.submitionState = 1;
    },
    [editRoundRobinInputs.pending]: (state, action) => {
      state.editLoding = true;
      state.submitionState = 2;
    },
    [editRoundRobinInputs.fulfilled]: (state, action) => {
      if (action.payload.status === "success") {
        state.editLoding = false;
        state.submitionState = 3;
        state.statusCode = "success";
        state.messg = "Updated Sucessfully";
      }

      if (action.payload.status === "error") {
        state.editLoding = false;
        state.submitionState = 3;
        state.statusCode = "error";
        state.messg = "Somthing went wrong";
      }
    },

    [getRoundRobinData.rejected]: (state, action) => {
      // state.endpointsuccess[action.type] = false;
      state.successAction = false;
      state.RoundRobinData = [];
    },
    [getRoundRobinData.pending]: (state, action) => {
      console.log("thuis is action ", action.meta.arg);
      state.shortConditionField = action.meta.arg.sort.field
      state.sortconditionType = action.meta.arg.sort.type

      if(action.meta.arg.role){
        state.roles = action.meta.arg.role
        if(!state.conditionArry.includes("role")){
          state.conditionArry.push("role")
        }
        
      }
      if(action.meta.arg.sort.field !== "_id" ){
        if(!state.conditionArry.includes("sort")){
          state.conditionArry.push("sort")
        }
        
      }

      if(action.meta.arg.searchcondition.createdon_datetime) {
        state.isSurch = true
        state.loading = true;
        if(action.meta.arg.searchcondition.createdon_datetime.$gte){
          if(!state.conditionArry.includes("start_date")){
            state.conditionArry.push("start_date")
          }
          state.surchstartDate = action.meta.arg.searchcondition.createdon_datetime?.$gte?action.meta.arg.searchcondition.createdon_datetime.$gte:null
        }
        if(action.meta.arg.searchcondition.createdon_datetime.$lte){
          if(!state.conditionArry.includes("end_date")){
            state.conditionArry.push("end_date")
          }
          
          state.surchendDate = action.meta.arg.searchcondition.createdon_datetime?.$lte?action.meta.arg.searchcondition.createdon_datetime.$lte:null
        }
       
      }else if (!action.meta.arg.sortcondition) {
        
        state.loading = true;
      } else {
        state.sortloder = true;
        state.shortCondition = true;
        state.isSurch = false
      }
    },
    [getRoundRobinData.fulfilled]: (state, action) => {
      console.log(action);
      if (action.payload.status === "success" && action.payload?.results?.res) {
        state.RoundRobinData = action.payload?.results?.res;
        state.loading = false;
        state.sortloder = false;
      }
      if (action.payload?.count) {
        state.roundRobinDataCount = action.payload?.count;
        console.log(state.roundRobinDataCount);
        state.loading = false;

      }
    },
  },
});

export default ManageRoundRodinReducer.reducer;
export const { handelproperty, handelvalue, clearSubmitionState } =
  ManageRoundRodinReducer.actions;
