import React from 'react'

import LockIcon from '@mui/icons-material/Lock';

export default function Noaccesspage() {
  return (
    <div className='Noaccesspage'>
      <div className='Noaccesspage_div'>
        <h2>Access Denied</h2>
        <p>“Sorry, You Are Not Allowed to Access This Page”</p>
        <span> <LockIcon/> </span>
        
        </div>
      
      </div>
  )
}
