import { createAsyncThunk, createSlice } from "@reduxjs/toolkit"


export const getlandingpage = createAsyncThunk("getlandingpage", async (reqBody) => {

    console.log("reqbody==>", reqBody);

    if (!reqBody) {

        reqBody = {
            "condition": {
                "limit": 10,
                "skip": 0
            },
            "sort": {
                "field": "createdon_datetime",
                "type": "desc"
            },
            "searchcondition": {
                "$and": [
                    { "status": 1 },
                    { "rep_only": 1 }
                ]
            },
            "project": {},
            "token": "",
            "count": false
        }
    }


    let req_body = {};
    req_body = { ...reqBody };
    console.log("req_body==", req_body);
    const requestOptions = {
        method: 'post',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify(req_body)
    };
    console.log("process.env==>", process.env);
    const response = await fetch(process.env.REACT_APP_API_URL + "landing-page-manage/landing-page-list", requestOptions);
    const respdata = await response.json();
     console.log("respdata after endpoint fetch==>", respdata);
    return respdata;


})

export const getHmGridData = createAsyncThunk("getHmGridData", async (reqBody) => {

    if (!reqBody) {

        reqBody = {
            "condition": {
                "limit": 10,
                "skip": 0
            },
            "sort": {
                "field": "_id",
                "type": "desc"
            },
            "searchcondition": {

            },
            "project": {},
            "token": "",
            "count": false
        }
    }


    console.log("reqbody11==>", reqBody);
    let req_body = {};
    req_body = { ...reqBody };
    console.log("req_body==", req_body);
    const requestOptions = {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify(req_body)
    };
    console.log("process.env==>", process.env);
    const response = await fetch(process.env.REACT_APP_API_URL + "users/hm-listing", requestOptions);
    const respdata = await response.json();
    console.log(" hiring managment respdata after endpoint fetch==>", respdata);
    return respdata;


})

export const getHmTypeUpdate = createAsyncThunk("getHmTypeUpdate", async (reqBody) => {

    console.log("reqbody11==>", reqBody);
    let req_body = {};
    req_body = { ...reqBody };
    console.log("req_body==", req_body);
    const requestOptions = {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify(req_body)
    };
    console.log("process.env==>", process.env);
    const response = await fetch(process.env.REACT_APP_API_URL + "users/hm-type", requestOptions);
    const respdata = await response.json();
    console.log(" hiring managment Input Radio respdata after endpoint fetch==>", respdata);
    return respdata;


})

const HmDashboardReducer = createSlice({
    name: 'HmDashboardReducer',
    initialState: {
        
        submitting: false,
        loading: false,
        editloading: false,
        getlandingpage: [],
        hmGridData: [],
        hmRadioData: [],
        formSubmit: 0,
        formSubmitByRow: {},
        message: {},
        hmGridDataCount: null,
        shortCondition: false,
        sortloder: false,
        loaderForHmData: false,
      surchstartDate:null,
      surchendDate:null,
      isSurch: false
        
    },
    reducers: {


        clearlandingpage(state, action) {
            console.log('Fueell')
            state.getlandingpage = []
        },

        clearSubmitionState:(state,action)=>{
            state.formSubmit=0
        }



    },
    extraReducers: {


        [getlandingpage.rejected]: (state, action) => {
            state.endpointsuccess[action.type] = false
            state.successAction = false;
            state.getlandingpage = [];

        },
        [getlandingpage.pending]: (state, action) => {
            // state.loading = true;
            state.getlandingpage = [];
        },
        [getlandingpage.fulfilled]: (state, action) => {
            if (action.payload.status === 'success') {
                state.getlandingpage = action.payload?.results?.res;
                // state.loading = false;
            }
        },

        [getHmGridData.rejected]: (state, action) => {
            state.endpointsuccess[action.type] = false
            state.successAction = false;
           
        },
        [getHmGridData.pending]: (state, action) => {

            if(action.meta.arg.searchcondition.createdon_datetime) {
               
                state.isSurch = true
                state.surchstartDate = action.meta.arg.searchcondition.createdon_datetime?.$gte?action.meta.arg.searchcondition.createdon_datetime.$gte:null
                state.surchendDate = action.meta.arg.searchcondition.createdon_datetime?.$lte?action.meta.arg.searchcondition.createdon_datetime.$lte:null

                console.log("---surchstartDate--",state.surchstartDate);
              }else if (!action.meta.arg.sortcondition) {
                state.loading = true;
              } else {
                state.sortloder = true;
                state.shortCondition = true;
                state.isSurch = false
              }
              
           
        },
        [getHmGridData.fulfilled]: (state, action) => {
            if (action.payload.status === 'success') {
                if (action.payload?.results?.res) {
                    state.loading = false;
                    state.sortloder = false;
                    state.hmGridData = action.payload?.results?.res;
                }
                state.loading = false;
                if (action.payload.count) {
                    state.loading = false;
                    state.hmGridDataCount = action.payload?.count;
                }
            }
        },

        [getHmTypeUpdate.rejected]: (state, action) => {
            state.endpointsuccess[action.type] = false
            state.successAction = false;
            state.formSubmit = 3;
            state.formSubmitByRow[action.meta.arg._id] = 3;




        },
        [getHmTypeUpdate.pending]: (state, action) => {
            console.log("data111111", action.meta.arg);
            state.formSubmitByRow[action.meta.arg._id] = 1;
            state.formSubmit = 1;

            //  state.hmId = action.meta.arg._id;

        },
        [getHmTypeUpdate.fulfilled]: (state, action) => {

            if (action.payload.status === 'error') {
                state.formSubmit = 2
                state.message = "Somthing Went Wrong"
               
            }

            if (action.payload.status === 'success' ) {

                state.formSubmitByRow[action.meta.arg._id] = 2;
                state.formSubmit = 2;
                state.message = "Updated Sucessfully"



            }
        },


    }
})







export default HmDashboardReducer.reducer;
export const { clearlandingpage,clearSubmitionState } = HmDashboardReducer.actions;