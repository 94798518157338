import React, { useEffect, useState } from 'react'
import PlaylistPlayIcon from '@mui/icons-material/PlaylistPlay';
import { Chip, IconButton, LinearProgress, Tooltip } from '@mui/material';
import SearchIcon from '@mui/icons-material/Search';
import { Box } from '@mui/system';
import { useDispatch, useSelector } from 'react-redux';
import QuestionMarkIcon from '@mui/icons-material/QuestionMark';
import { format } from 'date-fns'
import PlaylistAddIcon from '@mui/icons-material/PlaylistAdd';
import ManageStageModal from '../userList/manageStageModal';
import { FetchStages } from '../userList/userListReducer';
import FDSearch from './FDSearch';
import { UserActivity } from './folderViewReducer';
import { useLocation } from 'react-router-dom';
const StageLifeCycle = ({LifeCycleDataStage,folderdata}) => {
  const [queryParamId, setQueryParamId] = useState();  //*****FOR Seeting QueryP****//
  const [searchDraw, setSearchDraw] = React.useState(false); // snackbar const  
  const [openStageModal,setOpenstageModal] = useState(false)
  const loading = useSelector(state => (state?.userFolderViewReducer?.loading) ? state.userFolderViewReducer.loading : false)
  const dispatch = useDispatch()

  const submissionState = useSelector(state => (state?.formSlice?.formSubmissionState?.Folder_View_Search) ? state.formSlice.formSubmissionState.Folder_View_Search : false)
  const searchData = useSelector(state => (state?.formSlice?.formData?.Folder_View_Search) ? state.formSlice.formData.Folder_View_Search : {})
// ---- geeting and setting QueryParam -----
const queryParam = useLocation();
  
 
const paramId = queryParam.search.split("=");

console.log("data paramId",paramId);

useEffect(() => {
  if (paramId[1]) {
    setQueryParamId(paramId[1])
  }
}, [JSON.stringify(paramId)])

// -------------------------------

  useEffect(() => {
    if (submissionState === 4) {
      dispatch(UserActivity())
    }
  }, [submissionState])
  // ----------------
useEffect(()=>{
  console.log("folderdata",folderdata);
},[folderdata])

const handelStagemodal = () => {
  
  let roles = Object.keys(folderdata?.roles).map((key) => key)
  const data = {
    "condition": {},
    "sort": {},
    "searchcondition": {
        "added_for": folderdata._id,
        "roles": { $in: roles },
    },
    "project": {
        "subject": 1
    },
    "token": "",
    "count": false

}
dispatch(FetchStages(data))
setOpenstageModal(true)
}

const searchFields=[
  {
      id: 8,
      label: "Search by Start Date",
      name: "start_date",
      className: 'inputBlock inputBlock3line',
      type: 'datePicker',
      sx: { m: 1, width: 300 },
      dateFormat: 'dd/MM/yyyy',
     

    },
    {
      id: 9,
      label: "Search by End Date",
      name: "end_date",
      className: 'datePicker',
      type: 'datePicker',
      fullDay: true,
      sx: { m: 1, width: 300 },
      dateFormat: 'dd/MM/yyyy',
      className: 'inputBlock inputBlock3line',
      errorMessage: {
        dateCheck: "End Date can not be less than Start Date",
      },
    },
]


useEffect(() => {
  if (submissionState === 4) {
    setSearchDraw(false)
    let activity_time={
      "$gte":searchData?.start_date?searchData.start_date:undefined,
      "$lte":searchData?.end_date?searchData.end_date:undefined
    }

    activity_time.$lte !== " " || activity_time.$lte !== undefined &&  delete activity_time["$lte"];

    activity_time.$gte !== " " || activity_time.$gte !== undefined &&  delete activity_time["$gte"];

    

    const payload = {
      "condition": {
        "limit": 5,
        "skip": 0
      },
      "sort": {
        "field": "activity_time",
        "type": "desc"
      },
      "searchcondition": {
        "user_id": queryParamId ? queryParamId : undefined,
        "activity_time": activity_time
    

      },
      "project": {},
      "token": "",
      "email": ""
    }
    dispatch(UserActivity(payload ))
  }
}, [submissionState])


  console.log("StageLifeCycleData",LifeCycleDataStage);
  return (
    <>
    <div className='blocks'>
        <h3>
            <PlaylistPlayIcon /> Stage Life Cycle {" "}
            <div className="srchfld_wrp" style={{ marginLeft: "auto" }}>
              
              <Tooltip title="Search Email">
                <IconButton>
                  <SearchIcon onClick={() => setSearchDraw(true)}/>
                </IconButton>
              </Tooltip>
              <Tooltip title="Add Stage">
                <IconButton onClick={handelStagemodal}>
                  <PlaylistAddIcon />
                </IconButton>
              </Tooltip>
              
            </div>
          </h3>
          {loading ? (
          <Box sx={{ width: "100%", marginBottom: "10px" }}>
            <LinearProgress />
          </Box>
        ) : null}


        {LifeCycleDataStage.length > 0 ?
          LifeCycleDataStage.map((x) => (
            <span className='stageChipB'>
                            <Chip label={x.stage_name} sx={{ margin: '10px' }} variant="outlined" />
                             <QuestionMarkIcon className='qIcon' />

                          
                            <span className='chipDesBuble'>
                                {x.stage_name} <br />
                                Added by: {x.added_by_name} On {format(x.createdon_datetime, 'dd-MM-yyyy HH:mm')}
                            </span> 
            </span>
          )):
          (<p> No stage assigned!</p>)}  




    </div>
    {openStageModal ? (<ManageStageModal
                toogle={openStageModal}
                tooglefunc={setOpenstageModal}
                stage={openStageModal}
                UserId={folderdata._id}
                Userinfo={folderdata}
            />) : ''}

{searchDraw?<FDSearch 
     open={searchDraw}
     close={setSearchDraw}
     formFields={searchFields}
     searchFields={searchFields}
        type="stage_life_cycle "/>:""}
    </>
  )
}

export default StageLifeCycle