
import { IconButton, LinearProgress, Tooltip } from '@mui/material'
import React, { useEffect, useState, useLocation } from 'react'
import SearchIcon from '@mui/icons-material/Search';
import SummarizeIcon from '@mui/icons-material/Summarize';
import { Box } from '@mui/system';
import { useDispatch, useSelector } from 'react-redux';
import { format } from 'date-fns';
import LeaderboardIcon from '@mui/icons-material/Leaderboard';
import FDSearch from './FDSearch';
import { UserActivity } from './folderViewReducer';
import { getleaddata } from "../../LeadManagement/LeadReducer"
import LeadList from '../../LeadManagement/LeadList';
import { Navigate } from 'react-router-dom';
import AddIcon from "@mui/icons-material/Add";


const LeadView = ({ queryParamId }) => {
  const dispatch = useDispatch()
  const [searchDraw, setSearchDraw] = React.useState(false); // snackbar const  


  const loading = useSelector(state => (state?.userFolderViewReducer?.loading) ? state.userFolderViewReducer.loading : false)
  const submissionState = useSelector(state => (state?.formSlice?.formSubmissionState?.Folder_View_Search) ? state.formSlice.formSubmissionState.Folder_View_Search : false)
  const searchData = useSelector(state => (state?.formSlice?.formData?.Folder_View_Search) ? state.formSlice.formData.Folder_View_Search : {})
  const leaddata = useSelector((state) => state.LeadReducer?.leaddata && state.LeadReducer?.leaddata.length > 0 ? state.LeadReducer.leaddata : []);
  console.log("this is lead view data-->>", leaddata);

  // ---- geeting and setting QueryParam -----
  // const queryParam = useLocation();


  // const paramId = queryParam.search.split("=");

  // console.log("data paramId",paramId);

  // useEffect(() => {
  //   if (paramId[1]) {
  //     setQueryParamId(paramId[1])
  //   }
  // }, [JSON.stringify(paramId)])

  // // -------------------------------
  useEffect(() => {
    let reqbody = {
      "condition": {
        "limit": 10,
        "skip": 0
      },
      "sort": {
        "field": "createdon_datetime",
        "type": "desc"
      },
      "searchcondition": {
        // user_id:queryParamId?queryParamId:undefined
      },
      "project": {},
      "token": ""
    }
    dispatch(getleaddata(reqbody))
  }, [])

  
  const searchFields = [
    {
      id: 1,
      label: "Search by Start Date",
      name: "start_date",
      className: 'inputBlock inputBlock3line',
      type: 'datePicker',
      sx: { m: 1, width: 600 },
      dateFormat: 'dd/MM/yyyy',


    },
    {
      id: 2,
      label: "Search by End Date",
      name: "end_date",
      className: 'datePicker',
      type: 'datePicker',
      fullDay: true,
      sx: { m: 1, width: 600 },
      dateFormat: 'dd/MM/yyyy',
      className: 'inputBlock inputBlock3line',
      errorMessage: {
        dateCheck: "End Date can not be less than Start Date",
      },
    },
  ]

  useEffect(() => {
    if (submissionState === 4) {
      setSearchDraw(false)
      let activity_time = {
        "$gte": searchData?.start_date ? searchData.start_date : undefined,
        "$lte": searchData?.end_date ? searchData.end_date : undefined
      }

      activity_time.$lte !== " " || activity_time.$lte !== undefined && delete activity_time["$lte"];

      activity_time.$gte !== " " || activity_time.$gte !== undefined && delete activity_time["$gte"];



      const payload = {
        "condition": {
          "limit": 5,
          "skip": 0
        },
        "sort": {
          "field": "activity_time",
          "type": "desc"
        },
        "searchcondition": {
          "user_id": queryParamId ? queryParamId : undefined,
          "activity_time": activity_time


        },
        "project": {},
        "token": "",
        "email": ""
      }
      dispatch(UserActivity(payload))
    }
  }, [submissionState])


  return (
    <>

      {/* ---- Heading Goes Here ---- */}
      <div className='headingblock-FV'>

        <h3 className='heading'>
          <LeaderboardIcon /> Lead Listing{" "}
        </h3>

        {/* --- Lead button ----- */}
        {/* <button className="addrole_btn" onClick={() => Navigate("/lead-add")}>
          <AddIcon />{" "}
        </button> */}
        {/* ------------------- */}

      </div>
      {/* -------------------------- */}




      {/* ---- Lead Listing goes Here ----- */}
      <div className='mainContentBlock-FV'>
        <LeadList source="userFolderView" />
      </div>
      {/* ---------- ------------- */}


    </>
  )
}

export default LeadView