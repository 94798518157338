import { Form } from '../../../../form/Form';
import store from "../../../../store";
// import * as React from 'react';
import { format } from 'date-fns'
import React, { useEffect, useRef, useState } from "react";
import { useLocation, useNavigate, useParams, } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { setOtherData } from '../../../../form/formReducer';
import { Box, Button, Chip, Drawer, Grid, LinearProgress, Snackbar, Tooltip } from '@mui/material';
import { useCookies } from "react-cookie";
import LoginIcon from '@mui/icons-material/Login';
import FolderIcon from '@mui/icons-material/Folder';
import SearchIcon from '@mui/icons-material/Search';
import FDSearch from './FDSearch';
import IconButton from '@mui/material/IconButton';
import { UserActivity } from './folderViewReducer';
// import SearchIcon from '@mui/icons-material/Search';

const FDLoginInfo = (props) => {
   
    // const [loginData,setLoginData]=useState(props.logininfo)
    console.log("Fdlogininfo props-----", props.logininfo);
    // const [users, setusers] = useState([]);
    const dispatch = useDispatch();
    const navigate = useNavigate(); //**** FOR PAGE NAVIGATION****//
    const param = useParams();//---- userParam 

    // console.warn("<<<<<<<<<< props from addEditFrom Component >>>>>>", props)

    // ---- States here -----
    const [queryParamId, setQueryParamId] = useState();  //*****FOR Seeting QueryP****//
    const [open, setOpen] = React.useState(false); // snackbar const  
    const [searchDraw, setSearchDraw] = React.useState(false); // snackbar const  




    // ---- geeting and setting QueryParam -----
    const queryParam = useLocation();
    const paramId = queryParam.search.split("=");

    useEffect(() => {
        if (paramId[1]) {
            setQueryParamId(paramId[1])
        }
    }, [JSON.stringify( paramId)])

    // -------------------------------


    // ---- Cookies here ----
    const [cookieData, setCookieData] = useCookies();
    console.warn("<<<<< cookieData from User folderView >>>>>", cookieData)


    // --- loader --- 
    const loader = useSelector(state => (state.fetchUserDataReducer?.loading
    ) ? state.fetchUserDataReducer.loading
        : null);

    const endpointProcessState = useSelector(state => (state.formSlice?.formSubmissionState?.userFolderViewForm) ? state.formSlice.formSubmissionState.userFolderViewForm : false);

    const loading = useSelector(state => (state?.userFolderViewReducer?.loading) ? state.userFolderViewReducer.loading : false)

    const UserActivityData = useSelector(state => (state?.userFolderViewReducer?.userActivityList) ? state?.userFolderViewReducer?.userActivityList : [])

    const submissionState = useSelector(state => (state?.formSlice?.formSubmissionState?.Folder_View_Search) ? state.formSlice.formSubmissionState.Folder_View_Search : false)

    const searchData = useSelector(state => (state?.formSlice?.formData?.Folder_View_Search) ? state.formSlice.formData.Folder_View_Search : {})

   

    // console.warn("<<<<<<<<<< folderdata goes here >>>>>>>>>", folderdata)
    //--------------from input fields for search------------------------------
const searchFields=[
    {
        id: 0,
        label: "Search by I.P ",
        name: "activity_by",
        className: 'inputBlock inputBlock3line',
        type: "autoComplete",
        sx: { m: 1, width: 600 },
      },
    {
        id: 1,
        label: "Search by Start Date",
        name: "start_date",
        className: 'inputBlock inputBlock3line',
        type: 'datePicker',
        sx: { m: 1, width: 600 },
        dateFormat: 'dd/MM/yyyy',
       

      },
      {
        id: 2,
        label: "Search by End Date",
        name: "end_date",
        className: 'datePicker',
        type: 'datePicker',
        fullDay: true,
        sx: { m: 1, width: 600 },
        dateFormat: 'dd/MM/yyyy',
        className: 'inputBlock inputBlock3line',
        errorMessage: {
          dateCheck: "End Date can not be less than Start Date",
        },
      },
]

//---------------------------------------------------------------------------

//------------------get data from search fields --------------------------------
useEffect(() => {
    if (submissionState === 4) {
      setSearchDraw(false)
        let activity_time={
            "$gte":searchData?.start_date?searchData.start_date:undefined,
            "$lte":searchData?.end_date?searchData.end_date:undefined
          }
    
          activity_time.$lte !== " " || activity_time.$lte !== undefined &&  delete activity_time["$lte"];
    
          activity_time.$gte !== " " || activity_time.$gte !== undefined &&  delete activity_time["$gte"];
    
          
    
          const payload = {
            "condition": {
              "limit": 5,
              "skip": 0
            },
            "sort": {
              "field": "activity_time",
              "type": "desc"
            },
            "searchcondition": {
              "user_id": queryParamId ? queryParamId : undefined,
              "activity_by": searchData?.activity_by?searchData.activity_by:undefined,
            //   "activity_type": searchData?.activity_type?searchData.activity_type:undefined,
              "activity_time":activity_time
    
            },
            "project": {},
            "token": "",
            "email": ""
          }
      dispatch(UserActivity(payload))
    }
  }, [submissionState])
  // -------------------------------------------------------------------------------


    // snackbar Close
    const handleClose = (event, reason) => {
        if (reason === "clickaway") {
            return;
        }
        setOpen(false);
        // setErrPopup(false);
        // setErrMsgCopy(null);
    };

//    const formField={
//     <TextField id="filled-basic" label="Filled" variant="filled" />
//    }

 
    return (
        <>

            <div>
                <h3><FolderIcon /> {props.type}
                    <div className='srchfld_wrp' style={{marginLeft:"auto"}}>
                        <Tooltip title="Search">
                            <IconButton className='filtersearch'  >
                                <SearchIcon onClick={() => setSearchDraw(true)} />
                            </IconButton>
                        </Tooltip>

                    </div> </h3>
                {loading ? (<Box sx={{ width: "100%", marginBottom: '10px' }}><LinearProgress /></Box>) : null}


                {props.logininfo?.length > 0 ? props.logininfo.map((x) =>
                    <div className='user-activity' key={x._id}>

                        <ul className='ua-data-block'>
                            {/* <li>Email: {x.email ? x.email : "N/A"}</li> */}
                            <li>Login Time:  {format(x.login_time, 'dd-MM-yyyy HH:mm:ss')} </li>
                            <li>Location : {x.city ? x.city : "N/A"}, {x.region ? x.region : "N/A"}{`(${x.postal ? x.postal : "N/A"})`},{x.country ? x.country : "N/A"}</li>
                            <li>IP: {x.ip ? x.ip : "N/A"}</li>
                            <li>ISP: {x.org ? x.org : "N/A"}</li>
                            <li>Host Name: {x.hostname ? x.hostname : "N/A"}</li>
                        </ul>

                    </div>) : (
                   
                    <p> No Data Found !</p>)}

            </div>



            {open ? (<Snackbar
                open={open}
                autoHideDuration={6000}
                onClose={handleClose}
                message={queryParamId ? "Updated Sucessfully" : " "}
            // action={action}
            />) : ""}


          
        {searchDraw?<FDSearch 
     open={searchDraw}
     close={setSearchDraw}
     formFields={searchFields}
     searchFields={searchFields}
        type="login_info "/>:""}
        </>
    )
}

export default FDLoginInfo;






