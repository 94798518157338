import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";

const fileUploaderSlice = createSlice({
  name: "fileUploader",
  initialState: {
    files: {}
  },
  reducers: {
    setInitialState(state, action) {
      state.files[action.payload.fieldName] = {
        fileName: [],
        uploadURL: {},
        file: [],
        type: []
      }
    },
    updateWholeState(state, action) {
      const { fieldName, data } = action.payload;
      state.files[fieldName] = data;
    },
    updateState(state, action) {
      const { fieldName, fileDetails, type, name } = action.payload
      state.files[fieldName] = {
        ...state.files[fieldName],
        file: [...state.files[fieldName].file, fileDetails],
        type: [...state.files[fieldName].type, type],
        fileName: [...state.files[fieldName].fileName, name]
      }
    },
    deleteState(state, action) {
      const { fieldName, index } = action.payload
      delete state.files[fieldName].uploadURL[state.files[fieldName].fileName[index]];
      state.files[fieldName].fileName.splice(index, 1)
      state.files[fieldName].file.splice(index, 1)
      state.files[fieldName].type.splice(index, 1)
    },
    updateUploadURL(state, action) {
      const { url, fieldName, index } = action.payload
      state.files[fieldName] = {
        ...state.files[fieldName],
        uploadURL: {
          ...state.files[fieldName].uploadURL,
          [state.files[fieldName].fileName[index]]: url
        }
      }

    }
  },
  extraReducers: {},
});

export const { updateWholeState, setInitialState, updateState, deleteState, updateUploadURL } = fileUploaderSlice.actions;

export default fileUploaderSlice.reducer;