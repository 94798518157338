
import Snackbar from '@mui/material/Snackbar';
import Modal from '@mui/material/Modal';
import Typography from '@mui/material/Typography';
import CloseIcon from '@mui/icons-material/Close';
import store from "../../../../store";

// import * as React from 'react';
import React, { useEffect, useRef, useState } from "react";
import { useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { Button, Checkbox, Chip, FormControl, Grid, InputLabel, LinearProgress, List, ListItemText, MenuItem, OutlinedInput, Select, Tooltip } from '@mui/material';

import { Box, display, Stack } from '@mui/system';
import { useCookies, Cookies } from "react-cookie";
import { addUserRoles, FetchTagDetails, updateStages, updateTags } from './userListReducer';



const ManageStageModal = (props) => {

    const dispatch = useDispatch(); // ---- Dispatch -----


    const [open, setopen] = React.useState(false);
    const [openDropDown, setOpenDropDown] = React.useState(false);
    const [clicked, setClicked] = useState(null)
    const [UserId, setUserId] = useState();


    // console.warn("<<<<<< props goes here >>>>>>", props)
    // console.warn("<<<<<< clicked >>>>>>", clicked)

    const loader = useSelector(state => (state.userManagment?.loading) ? state.userManagment?.loading : false)
    // const tagsDetails = useSelector(state => (state.userManagment?.FetchTagDetailsData) ? state.userManagment?.FetchTagDetailsData : [])
    
    const stageData = useSelector(state => (state.userManagment?.FetchStages) ? state.userManagment?.FetchStages : [])

    const success = useSelector(state => (state.userManagment?.resMessage) ? state.userManagment?.resMessage : null)
    const loggedUserId = useSelector((state) => state.loginSlice?.userInfo?._id ? state.loginSlice.userInfo._id : null)

    useEffect(() => {
        if (success) {
            setopen(true)
        }
    }, [success])


    useEffect(() => {
        if (stageData.length > 0) {
            setOpenDropDown(false)
        }
    }, [stageData])




    useEffect(() => {
        // props.setModalResponse(false);
        setUserId(props.UserId)
    }, [])


    // ------- onModalClose --------
    function ModalClose() {
        // console.warn("<<< modal close hit >>>")
        // props.setModalResponse(false);
        props.tooglefunc(false);
        setOpenDropDown(false)

    }




    // -------------   ------------------
    useEffect(() => {
        if (clicked) {
            const data = {
             
                stage_slug: clicked?.key,
                stage_name: clicked?.label,
                added_by: loggedUserId,
                added_for: UserId? UserId:undefined,
                is_delete: clicked.status === 0 ? 0 : 1,
                status: clicked.status === 0 ? 1 : 0
            }
            console.log("data===========================>", data)

            dispatch(updateStages(data));
            setOpenDropDown(false)
        }
    }, [clicked])
    // ----------------------------


    const ITEM_HEIGHT = 48;
    const ITEM_PADDING_TOP = 0;
    const MenuProps = {
        PaperProps: {
            style: {
                maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
                width: 250,
            },
        },
    };



    return (
        <>

            {/* ------ Confirmation Modal ------ */}


            <Modal
                className="modalblock"
                open={props.toogle}
                onClose={ModalClose}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
            >

                <Box className="BoxMui_modal configure-role" 
                sx={{
                    position: "absolute",
                    top: "50%",
                    left: "50%",
                    transform: "translate(-50%, -50%)",
                    width: 400,
                    bgcolor: "background.paper",
                    border: "2px solid #000",
                    boxShadow: 24,
                    p: 4,
                    textAlign: "center",
                }}>
                    <Typography id="modal-modal-title" variant="h6" component="h4">
                        {loader ? <LinearProgress /> : ""}
                        MANAGE STAGE FOR <span className='name'>{props?.Userinfo?.name}</span>
    
                    </Typography>


                    {/* ----- Dropdown for selecting roles ----- */}

                    <form>

                        <FormControl sx={{ m: 1, width: 300 }}>
                            <InputLabel id="demo-multiple-checkbox-label">Select Stage</InputLabel>
                            <Select
                                open={openDropDown}
                                onOpen={() => setOpenDropDown(true)}
                                onClose={() => setOpenDropDown(false)}
                                labelId="demo-multiple-checkbox-label"
                                id="demo-multiple-checkbox"
                                // multiple
                                value={stageData.filter((item) => item.status === 1).map((item) => item)}
                                input={<OutlinedInput label="tag" />}
                                renderValue={(stageData) => stageData.map(stage => stage.label).join(', ')}
                                MenuProps={MenuProps}
                            >
                                {stageData.map((item) => (
                                    <MenuItem key={item.key} value={item} onClick={() => setClicked(item)}>
                                        <Checkbox checked={Boolean(item.status)} />
                                        <ListItemText primary={item.label} />
                                    </MenuItem>
                                ))}

                            </Select>

                            <Stack direction="row" spacing={1} sx={{
                                marginTop: "10px",
                            }}>

                                <div className='ChipsSet-grp' sx={{
                                    display: "flex",
                                    width: '100%',
                                    flexDirection: "row",
                                    flexWrap: "wrap",
                                    margin: "0 auto",
                                    justifyContent: "flex-start",
                                    alignItems: "flex-start",
                                }}>

                                    {stageData.length > 0
                                        ? stageData.filter((tag) => tag.status === 1).map((val) => <Chip
                                            label={val.label}
                                            variant="outlined"
                                            sx={{ margin: '3px 3px' }}
                                            // onDelete={() => deleteChips(val)}
                                        />)
                                        : (<h3> No Stage Assigned</h3>)}

                       
                                </div>

                            </Stack>

                        </FormControl>

                    </form>


                    {/* -------------------------------------- */}



                    <Button onClick={ModalClose} className="closeModalBtn">
                        <Tooltip title="Close" placement="right-start">

                            <CloseIcon />
                        </Tooltip>
                    </Button>
                </Box>
            </Modal>




            {success != null ? (<Snackbar
                open={open}
                autoHideDuration={6000}
                onClose={() => setopen(false)}
                message={success}
            // action={action}
            />) : ""}

        </>
    )
}


export default ManageStageModal;










