import { Skeleton } from '@mui/material'
import { Box } from '@mui/system'
import React from 'react'

export const TableSkeleton = ({ count, style }) => {


  return (
    <div className='skeleton_container'>
      <Box>
        {[...Array(count)].map((e, i) => {
          return <Skeleton
            key={i}
            variant="rectangular"
            animation="wave"
            sx={
              style === undefined ? {
                minHeight: "50px",
                marginBottom: "5px",
                marginTop: "5px",
                borderRadius: "10px",
              } : style
            }
          />
        })}
      </Box></div>
  )
}
