import { Public } from '@mui/icons-material'
import { Drawer, IconButton, LinearProgress, Pagination, Snackbar, TablePagination, Tooltip } from '@mui/material'
import moment from 'moment'
import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import store from '../../../store'
import { getHmGridData, getHmRadioData, getHmTypeUpdate } from '../HmDashboard/HmDashboardReducer'
import "./HiringManagment.css"
import SearchHm from './SearchHm'
import FilterListIcon from '@mui/icons-material/FilterList';
import SearchOutlinedIcon from '@mui/icons-material/SearchOutlined';
import ReplayIcon from '@mui/icons-material/Replay';
import { setSnackbar } from '../../Layout/layoutReducer'
import { clearSubmitionState } from "../HmDashboard/HmDashboardReducer";
import CodeIcon from '@mui/icons-material/Code';
import ArrowDownwardIcon from '@mui/icons-material/ArrowDownward';
import { TableSkeleton } from '../../../helper/TableSkeleton'







const HiringManagment = () => {
    const dispatch = useDispatch();
    const [sortButton, setSortButton] = useState(true)
    const [sortType, setSortType] = useState("")

    //for search functionality
    const [hmType, setHmType] = useState()
    const [openDrawer, setOpenDrawer] = React.useState(false);
    const [reload, setReload] = React.useState(false);
    const searchFormSubmissionState = useSelector(state => (state.formSlice.formSubmissionState &&
        state.formSlice.formSubmissionState.hmSearchForm === 4) ? true : false);
    const isSurch = useSelector((state) => state.HmDashboardReducer?.isSurch ? state.HmDashboardReducer.isSurch : false);
    const surchfield = {
        surchstartDate: useSelector((state => state.HmDashboardReducer?.surchstartDate ? state.HmDashboardReducer.surchstartDate : null)),
        surchendDate: useSelector((state => state.HmDashboardReducer?.surchendDate ? state.HmDashboardReducer.surchendDate : null))
    }
    console.log("Data of IsSurch ------------->>>>>>",isSurch);
    console.log("Data fo SurchField------------>>>>>>>",surchfield);
    const editData = useSelector(state => state.HmDashboardReducer?.hmGridData ? state.HmDashboardReducer.hmGridData : [])
    const hmGridDataCount = useSelector(state => state.HmDashboardReducer?.hmGridDataCount ? state.HmDashboardReducer.hmGridDataCount : 0)
    const formSubmit = useSelector(state => state.HmDashboardReducer?.formSubmit ? state.HmDashboardReducer.formSubmit : false)
    const loading = useSelector(state => state.HmDashboardReducer?.loading ? state.HmDashboardReducer.loading : false)
    const resolved = useSelector(state => state.ResolveReducer.resolved['/hiring-managment'] ? state.ResolveReducer.resolved['/hiring-managment'] : false)
    const formSubmitByRow = useSelector(state => state.HmDashboardReducer?.formSubmitByRow ? state.HmDashboardReducer.formSubmitByRow : {})
    const shortCondition = useSelector((state) => state.HmDashboardReducer?.shortCondition ? state.HmDashboardReducer.shortCondition : false);
    const sortLoder = useSelector((state) => state.HmDashboardReducer?.sortloder ? state.HmDashboardReducer.sortloder : false);
    const searchFormData = useSelector(state => state.formSlice.formData.hmSearchForm ?
        state.formSlice.formData.hmSearchForm : null);


    const [page, setPage] = React.useState(0);
    const [rowsPerPage, setRowsPerPage] = React.useState(10);
    console.log("hmGridDataCount", editData, hmGridDataCount);

    const submitionState = useSelector((state) =>
        state.HmDashboardReducer?.submitionState
            ? state.HmDashboardReducer.submitionState
            : 0
    );

    const messg = useSelector((state) =>
        state.HmDashboardReducer?.message
            ? state.HmDashboardReducer.message
            : null
    );

    useEffect(() => {
        let reqbody = {
            "condition": {
                "limit": 10,
                "skip": 0
            },
            "sort": {
                "field": "updatedon_datetime",
                "type": "desc"
            },
            "searchcondition": {},
            "project": {},
            "token": ""
        }


        if (!resolved) {


            store.dispatch(getHmGridData(reqbody));
            // let count=true;
            console.log(reqbody);

        }
        let reqBody = { ...reqbody, count: true }

        console.log("reqBody", reqBody);


        store.dispatch(getHmGridData(reqBody));

    }, [])

    useEffect(() => {
        if (hmType) {
            let reqbody = {
                "_id": hmType.id,
                [hmType.value]: true
            }
            store.dispatch(getHmTypeUpdate(reqbody));
        }




    }, [hmType])



    useEffect(() => {
        if (formSubmit === 2) {
            store.dispatch(
                setSnackbar({ open: true, message: messg, status: formSubmit })
            )
            store.dispatch(
                clearSubmitionState()
            )

        }

    }, [formSubmit]);

    useEffect(() => {
        if (formSubmit === 4) {

            let reqbody = {
                "condition": {
                    "limit": 10,
                    "skip": 0
                },
                "sort": {
                    "field": "updatedon_datetime",
                    "type": "desc"
                },
                "searchcondition": {},
                "project": {},
                "token": ""
            }
            store.dispatch(getHmGridData(reqbody));

        }


    }, [formSubmit])


    //for pagingnation

    const handleChangePage = (event, newPage) => {
        setPage(newPage);
        console.log("newPage", newPage);
        let skip = newPage * rowsPerPage
        let limit = rowsPerPage
        console.log("limit", skip, limit);

        let reqbody = null;
        console.log("conditon is surch in work or not -----------------", isSurch);
        if (isSurch) {

            reqbody = {
                condition: {
                    limit: limit,
                    skip: skip,
                },
                sort: {
                    field: "name",
                    type: sortType ? sortType : "desc",
                },
                searchcondition: {

                },
                sortcondition: true,
                project: {},
                token: "",
            };

            if (surchfield) {
                if (surchfield.surchstartDate) {
                    reqbody.searchcondition["createdon_datetime"] = { "$gte": surchfield.surchstartDate }
                }
                if (surchfield.surchendDate) {
                    reqbody.searchcondition["createdon_datetime"] = { "$lte": surchfield.surchendDate }
                }
                if (surchfield.surchendDate && surchfield.surchstartDate) {
                    reqbody.searchcondition["createdon_datetime"] = { "$gte": surchfield.surchstartDate, "$lte": surchfield.surchendDate }
                }

            }

        }

        if (shortCondition) {
            reqbody = {
                condition: {
                    limit: limit,
                    skip: skip,
                },
                sort: {
                    field: "name",
                    type: sortType,
                },
                searchcondition: {},
                sortcondition: true,
                project: {},
                token: "",
            };
        }
        if (!shortCondition && !isSurch) {
            reqbody = {
                condition: {
                    limit: limit,
                    skip: skip,
                },
                sort: {
                    field: "_id",
                    type: "desc",
                },
                searchcondition: {},
                project: {},
                token: "",
            };
        }
        store.dispatch(getHmGridData(reqbody));
    };

    const handleChangeRowsPerPage = (event) => {
        let limit = parseInt(event.target.value)
        setRowsPerPage(parseInt(event.target.value));
        console.log("handleChangeRowsPerPage", event.target.value);
        setPage(0);

        let reqbody = null;
        if (isSurch || sortType ) {

            reqbody = {
                condition: {
                    limit: limit,
                    skip: 0,
                },
                sort: {
                    field: "name",
                    type: sortType ? sortType : "desc",
                },
                searchcondition: {

                },
                sortcondition: true,
                project: {},
                token: "",
            };
            if (surchfield) {
                if (surchfield.surchstartDate) {
                    reqbody.searchcondition["createdon_datetime"] = { "$gte": surchfield.surchstartDate }
                }
                if (surchfield.surchendDate) {
                    reqbody.searchcondition["createdon_datetime"] = { "$lte": surchfield.surchendDate }
                }
                if (surchfield.surchendDate && surchfield.surchstartDate) {
                    reqbody.searchcondition["createdon_datetime"] = { "$gte": surchfield.surchstartDate, "$lte": surchfield.surchendDate }
                }
            }
        }
        if (shortCondition) {
            reqbody = {
                condition: {
                    limit: limit,
                    skip: 0,
                },
                sort: {
                    field: "name",
                    type: sortType,
                },
                searchcondition: {},
                sortcondition: true,
                project: {},
                token: "",
            };
        }

        if (!shortCondition && !isSurch) {
            reqbody = {
                condition: {
                    limit: limit,
                    skip: 0,
                },
                sort: {
                    field: "_id",
                    type: "desc",
                },
                searchcondition: {},
                project: {},
                token: "",
            };
        }
        store.dispatch(getHmGridData(reqbody));
    };

    ///for search drower

    const handleCloseDrawer = () => {
        setOpenDrawer(false)
        // setMoadlData(null)

    };


    useEffect(() => {
        if (searchFormSubmissionState == true) {
            setOpenDrawer(false)

        }


        // console.log("tempdata", imgArray);
    }, [searchFormSubmissionState]);

    ///for reload
    useEffect(() => {
        if (reload === true) {
            const body = {
                "source": "",
                "condition": {
                    "limit": 10,
                    "skip": 0
                },
                "sort": {
                    "type": "desc",
                    "field": "updatedon_datetime"
                },
                "searchcondition": {

                }
            }
            dispatch(getHmGridData(body))
            let reqBody = { ...body, count: true }


            store.dispatch(getHmGridData(reqBody));
            setReload(false)
        }
    }, [reload])


    const sorttableasc = (event, newPage) => {
        setSortButton(false)
        setSortType("asc")
        console.log("newPage", newPage);
        setPage(0)
        let skip = newPage * rowsPerPage
        let limit = rowsPerPage

        let reqbody = {
            "condition": {
                "limit": limit,
                "skip": skip
            },
            "sort": {
                "field": "name",
                "type": "asc"
            },
            "searchcondition": {},
            sortcondition: true,
            "project": {},
            "token": ""
        }
        store.dispatch(getHmGridData(reqbody));
        // let reqBody = { ...reqbody, count: true }


        // store.dispatch(getHmGridData(reqBody));

    };


    const sorttabledesc = (event, newPage) => {
        setSortButton(true)
        setSortType("desc")
        setPage(0)
        let skip = newPage * rowsPerPage
        let limit = rowsPerPage

        let reqbody = {
            "condition": {
                "limit": limit,
                "skip": skip
            },
            "sort": {
                "field": "name",
                "type": "desc"
            },
            "searchcondition": {},
            sortcondition: true,
            "project": {},
            "token": ""
        }
        store.dispatch(getHmGridData(reqbody));
        let reqBody = { ...reqbody, count: true }


        store.dispatch(getHmGridData(reqBody));

    };



    return (
        <>

            {(formSubmit === 1 || loading === true || sortLoder) &&
                <LinearProgress />
            }
            <div className='rolelist_mainwrp'>
                <div className='listing_heading'>

                    <h1>Hiring Management System</h1>


                    <div className='srchfld_wrp'>
                        <Tooltip title="Search">
                            <IconButton className='filtersearch' onClick={() => setOpenDrawer(true)}>
                                <SearchOutlinedIcon />
                            </IconButton>
                        </Tooltip>


                        <Tooltip title="Reload">
                            <IconButton className='reloadsearch' onClick={() => setReload(true)}>
                                <ReplayIcon />
                            </IconButton>
                        </Tooltip>

                    </div>

                </div>

                <div className='rolelist_mainwrp_Table'>

                    {!loading ? (
                        <table border="1" className='hm_details' id='hm_details'>

                            <th>HM Details {sortButton ? <abbr title='Sort In Ascending'> <ArrowDownwardIcon onClick={sorttableasc} className='sort_icon1' /></abbr> : <abbr title='Sort In Dscending'> <ArrowDownwardIcon onClick={sorttabledesc} className='sort_icon2' /></abbr>}</th>
                            <th>LogIn Info</th>
                            <th>Selection Book</th>

                            <tbody>
                                {
                                    editData.map(userdata => {
                                        // hmType?hmTypeId=userdata._id:""
                                        return (
                                            <tr key={userdata._id}>
                                                {/* ............This Section in only for Hiring Management Details............... */}
                                                <td title='HM Details '>
                                                    <ul className='hm_list'>
                                                        {/* <li> <span className='hm_title'>ID : </span><span>{userdata._id}</span></li> */}
                                                        <li> <span className='hm_title'>Name : </span><span>{userdata.name}  </span> </li>
                                                        <li> <span className='hm_title'>Email : </span><span>{userdata.email}</span></li>
                                                        <li> <span className='hm_title'>Phone : </span><span>{userdata.phone}</span></li>
                                                        <li> <span className='hm_title'>Address : </span><span>{userdata.address}</span></li>
                                                        <li> <span className='hm_title'>Created On : </span><span>{moment(userdata.createdon_datetime).format('dddd, MMMM Do YYYY, h:mm a')}</span></li>
                                                        <li> <span className='hm_title'>Updated On : </span><span>{moment(userdata.updatedon_datetime).format('dddd, MMMM Do YYYY, h:mm a')}</span></li>
                                                       
                                                    </ul>
                                                </td>
                                                <td title='LogIn Info'>
                                                    <ul>
                                                        <li><span>Last Loging</span> : <span>{moment(userdata.done_training_percentage_datetime).format('dddd, MMMM Do YYYY, h:mm a')}</span></li>
                                                        <li> <span>Login Count</span> : <span>{userdata.login_counts ? userdata.login_counts:"N/A"}</span></li>
                                                    </ul>
                                                </td>
                                                {/* ............This Section in only for Hiring Management Selection Option............... */}
                                                <td title='Selection Book'>
                                                    <ul className='hm_selection'>
                                                        <li>
                                                            <input
                                                                type="radio"
                                                                className='lead_only'
                                                                id={userdata._id}
                                                                name={`hiring_managment_${userdata._id}`}
                                                                value="lead_only"
                                                                onChange={e => setHmType(e.target)}
                                                                defaultChecked={userdata.lead_only === 1 ? true : false}
                                                                disabled={formSubmitByRow[userdata._id] === 1 ? true : false}

                                                            />

                                                            <label >Lead Only </label>
                                                        </li>
                                                        <li>
                                                            <input
                                                                type="radio"
                                                                className='calendar_access'
                                                                // id={`calendar_access_${userdata._id}`} 
                                                                id={userdata._id}
                                                                name={`hiring_managment_${userdata._id}`}
                                                                value="calendar_access"
                                                                onChange={e => setHmType(e.target)}
                                                                defaultChecked={userdata.calendar_access === 1 ? true : false}
                                                                disabled={formSubmitByRow[userdata._id] === 1 ? true : false}


                                                            />
                                                            <label >Calendar Access</label>
                                                        </li>
                                                    </ul>
                                                </td>
                                            </tr>

                                        )
                                    })
                                }

                            </tbody>
                        </table>
                    ) : (<TableSkeleton count={10} />)}


                </div>
                {/* <Pagination count={10} variant="outlined" shape="rounded" /> */}
                <TablePagination
                    component="div"
                    // count={100}
                    count={hmGridDataCount}
                    // count={(hmGridDataCount!==null && hmGridDataCount!== undefined)&& hmGridDataCount }
                    page={page}
                    onPageChange={handleChangePage}
                    rowsPerPage={rowsPerPage}
                    onRowsPerPageChange={handleChangeRowsPerPage} />

            </div>

            <Drawer
                anchor="bottom"
                open={openDrawer}
                onClose={handleCloseDrawer}
            >

                <SearchHm handleCloseDrawer={handleCloseDrawer} />


            </Drawer>

        </>
    )
}

export default HiringManagment