import React, { useEffect, useState } from "react";
import store from "../../../../store";
import Tooltip from "@mui/material/Tooltip";
import QuestionMarkIcon from "@mui/icons-material/QuestionMark";
import { useSelector, useDispatch } from "react-redux";
import { getRoundRobinData } from "../ManageRoundRobinReducer";
import RoundRobinRow from "./RoundRobinRow";
import { TableSkeleton } from "../../../../helper/TableSkeleton";
import { LinearProgress, TablePagination } from "@mui/material";
import UnfoldMoreIcon from "@mui/icons-material/UnfoldMore";
import ArrowDownwardIcon from "@mui/icons-material/ArrowDownward";
import ArrowUpwardIcon from "@mui/icons-material/ArrowUpward";
const ManageRoundRobinGrideView = ({reload,setReload}) => {
  const dispatch = useDispatch();
  const [usersortTooltip, setUserSortTooltip] = useState(false);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);
  const [shortType, setShortType] = useState("");
  const [buttonState, setButtonState] = useState(true);
  const surchfield={
    surchstartDate:useSelector((state=>state.ManageRoundRodinReducer?.surchstartDate?state.ManageRoundRodinReducer.surchstartDate:null)),
    surchendDate:useSelector((state=>state.ManageRoundRodinReducer?.surchendDate?state.ManageRoundRodinReducer.surchendDate:null))
  }
  const roundRobindata = useSelector((state) => state.ManageRoundRodinReducer?.RoundRobinData? state.ManageRoundRodinReducer.RoundRobinData : []);
  console.log("roundRobindata",roundRobindata);
  const loader = useSelector((state) => state.ManageRoundRodinReducer?.loading? state.ManageRoundRodinReducer.loading : false );
  const resolved = useSelector((state) => state.ResolveReducer.resolved["/manage-round-rodin"] ? state.ResolveReducer.resolved["/manage-round-rodin"]: false);
  const roundRobinDataCount = useSelector((state) => state.ManageRoundRodinReducer?.roundRobinDataCount? state.ManageRoundRodinReducer.roundRobinDataCount : 0);
  const shortCondition = useSelector((state) =>state.ManageRoundRodinReducer?.shortCondition? state.ManageRoundRodinReducer.shortCondition: false);
  const sortLoder = useSelector((state) =>state.ManageRoundRodinReducer?.sortloder? state.ManageRoundRodinReducer.sortloder: false);
  const isSurch = useSelector((state) =>state.ManageRoundRodinReducer?.isSurch? state.ManageRoundRodinReducer.isSurch: false);
  const roles = useSelector(state=>state.ManageRoundRodinReducer?.roles?state.ManageRoundRodinReducer.roles:null)
  const conditionArry = useSelector(state=>state.ManageRoundRodinReducer?.conditionArry?state.ManageRoundRodinReducer.conditionArry:[])
  const shortConditionField =useSelector(state=>state.ManageRoundRodinReducer?.shortConditionField?state.ManageRoundRodinReducer.shortConditionField:"_id")
  const sortconditionType =useSelector(state=>state.ManageRoundRodinReducer?.sortconditionType?state.ManageRoundRodinReducer.sortconditionType:"desc")






  const handelShortasc = () => {
    setShortType("asc");

    let reqbody = {
      condition: {
        limit: rowsPerPage, 
        skip: 0,
      },
      sort: {
        field: "name",
        type: "asc",
      },
      sortcondition: true,
      searchcondition: {},
      project: {},
      token: "",
    };
    conditionArry.map((item) => {
      manageConditionFuntion(item, reqbody,"asc");
    });
    store.dispatch(getRoundRobinData(reqbody));
    setButtonState(!buttonState);
  };





  
  const handelShortdesc = () => {
    setShortType("desc");

    let reqbody = {
      condition: {
        limit: rowsPerPage,
        skip: 0,
      },
      sort: {
        field: "name",
        type: "desc",
      },
      sortcondition: true,
      searchcondition: {},
      project: {},
      token: "",
    };
    conditionArry.map((item) => {
      manageConditionFuntion(item, reqbody,"desc");
    });
    store.dispatch(getRoundRobinData(reqbody));
    setButtonState(!buttonState);
  };






  useEffect(() => {
    if (reload === true) {
        const body = {
            "source": "",
            "condition": {
                "limit": 10,
                "skip": 0
            },
            "sort": {
                "type": "desc",
                "field": "name"
            },
            "searchcondition": {

            }
        }
        dispatch(getRoundRobinData(body))
        let reqBody = { ...body, count: true }


        store.dispatch(getRoundRobinData(reqBody));
        setReload(false)
    }
}, [reload])







  useEffect(() => {
    let reqbody = {
      condition: {
        limit: 10,
        skip: 0,
      },
      sort: {
        field: "_id",
        type: "desc",
      },
      searchcondition: {},
      project: {},
      token: "",
    };

    if (!resolved) {
      store.dispatch(getRoundRobinData(reqbody));
    }
    let reqBody = { ...reqbody, count: true };

    store.dispatch(getRoundRobinData(reqBody));
    
  }, []);
  console.log("conditionArry",conditionArry);






  const handleChangePage = (event, newPage) => {
    setPage(newPage);

    let skip = newPage * rowsPerPage;
    let limit = rowsPerPage;
    let reqBody = {
      condition: {
        limit: limit,
        skip: skip,
      },
      sort: {
        field: "_id",
        type: "desc",
      },
      searchcondition: {},
      project: {},
      token: "",
    };

    conditionArry.map((item) => {
      manageConditionFuntion(item, reqBody);
    });
    console.log("the functionbody",reqBody);
    store.dispatch(getRoundRobinData(reqBody));
  };






  const handleChangeRowsPerPage = (event) => {
    let limit = parseInt(event.target.value);
    setRowsPerPage(parseInt(event.target.value));

    setPage(0);

    let reqBody = {
      condition: {
        limit: limit,
        skip: 0,
      },
      sort: {
        field: "_id",
        type: "desc",
      },
      searchcondition: {},
      project: {},
      token: "",
    };
    conditionArry.map((item) => {
      manageConditionFuntion(item, reqBody);
    });

    store.dispatch(getRoundRobinData(reqBody));
  };






  const manageConditionFuntion=(condition,reqBody,type)=>{

    switch (condition) {
      case "sort":
        reqBody.sort.field = shortConditionField;
        reqBody.sort.type =type?type:sortconditionType;
        break;
      case "role":
        reqBody.searchcondition[`roles.${roles}`] = 1;
        break;
      case ("start_date" || "end_date"):

        if(surchfield.surchstartDate){
          reqBody.searchcondition["createdon_datetime"] = {"$gte":surchfield.surchstartDate }
        }
        if(surchfield.surchendDate){
          reqBody.searchcondition["createdon_datetime"] = {"$lte":surchfield.surchendDate}
        }
        if (surchfield.surchendDate && surchfield.surchstartDate){
          reqBody.searchcondition["createdon_datetime"] = {"$gte":surchfield.surchstartDate ,"$lte":surchfield.surchendDate}
        }
        break;
    }
    return reqBody
  }






  return (
    <div className="Mrr-grideview">
      {sortLoder && <LinearProgress />}
      <div className="Mrr-name"> Round Robin Listing</div>
      <div className="Mrr-list">
        {!loader ? (
          <table border="1">
            <thead>
              <tr className="Mrr-header">
                <th className="user">
                  User
                  <Tooltip
                    title={
                      usersortTooltip
                        ? "sort by ascending"
                        : "sort by descending"
                    }
                    placement="top"
                  >
                    {buttonState ? (<ArrowDownwardIcon onClick={handelShortasc} />) : (<ArrowUpwardIcon onClick={handelShortdesc} />)}
                  </Tooltip>
                </th>
                <th>Log In Info</th>
                <th>Role</th>
                <th>
                  Priority
                  <Tooltip title="you can set the Priority" placement="top">
                    <QuestionMarkIcon />
                  </Tooltip>
                </th>
                <th>
                  Value
                  <Tooltip title="you can set the value" placement="top">
                    <QuestionMarkIcon />
                  </Tooltip>
                </th>
              </tr>
            </thead>
            <tbody>
              {roundRobindata && roundRobindata.map((item) => {
                return <RoundRobinRow key={item._id} item={item} />;
              })}
            </tbody>
          </table>
        ) : (
          <TableSkeleton count={10} />
        )}

        <TablePagination
          component="div"
          // count={100}
          count={roundRobinDataCount}
          // count={(hmGridDataCount!==null && hmGridDataCount!== undefined)&& hmGridDataCount }
          page={page}
          onPageChange={handleChangePage}
          rowsPerPage={rowsPerPage}
          onRowsPerPageChange={handleChangeRowsPerPage}
        />
      </div>
    </div>
  );
};

export default ManageRoundRobinGrideView;
