import { LinearProgress } from '@mui/material';
import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { Form } from '../../../../form/Form';
import { resolvefunction } from '../../../../helper/helperFunctions';
import store from '../../../../store';
import { clearEmailSlugEditdata, emailSlugEditdata, fetchCollectName, getEmailSlagData } from '../EmailTemplateReducer';


const EmailSlugAddEdit = () => {
  const pathname = useLocation();
  const dispatch = useDispatch();
  const navigate = useNavigate()
  const { id } = useParams();
  console.log("_id++++++", id)

  const goBackClicked = useSelector(state => state.formSlice?.customButtonClicked?.EmailSlagAddEditForm?.goback001 ? state.formSlice.customButtonClicked.EmailSlagAddEditForm.goback001 : false);
  const editdata = useSelector(state => (state.EmailTemplateReducer?.editemailtemplateSlagData && state.EmailTemplateReducer?.editemailtemplateSlagData.length > 0) ? state.EmailTemplateReducer.editemailtemplateSlagData[0] : null);
  const resolved = useSelector(state => state.ResolveReducer?.resolved[`/email-template-edit/${id}`] ? state.ResolveReducer.resolved[`/email-template-edit/${id}`] : false)
  const loading = useSelector(state => state.EmailTemplateReducer?.editloading ? state.EmailTemplateReducer.editloading : false);
  const collectionData = useSelector((state) =>
  state.EmailTemplateReducer?.collectionName &&
  state.EmailTemplateReducer?.collectionName.length > 0
    ? state.EmailTemplateReducer.collectionName
    : []
);
  



  let reqbodyforEmailSlag = {
    "condition": {
      "limit": 10,
      "skip": 0
    },
    "sort": {
      "field": "updatedon_datetime",
      "type": "desc"
    },
    "searchcondition": {},
    "project": {},
    "token": ""
  }



  const dataform = {
    id: 'EmailSlagAddEditForm',
    api_url: process.env.REACT_APP_API_URL,
    endPoint: "email-slug/add-update-email-slug",

    urlPathOnSuccessfulFormSubmission: "/email-slug",
    submitBtnName: (editdata !== null) ? "Update" : "Submit",
    cancelBtnName: "Cancel",
    className: 'inputBlock inputBlock3line',
    formButtonClass: "Formsubmit_button",
    formWrapperClass: "backend_addform addAdmin",
    formInlineClass: "backendTableInline_addform testwrpr trainingcata",

    formAdditionalSubmissionData: {

      _id: (id !== undefined && id !== null) ? id : undefined
    },

    fields: [
      {
        id: 0,
        heading: "Name",
        label: "Name",
        name: "name",
        // className: "inputBlock inputBlock1line",
        className: 'inputBlock inputBlock3line',
        type: "text",
        rules: { required: true },
        errorMessage: {
          required: "This Field is Required",
        },
        //   minLength: "Minimum length should be 15",
        //   custom: "Value is Invalid"
        defaultValue: (editdata && Object.keys(editdata).length > 0 && editdata.name !== undefined) ? editdata.name : undefined,

      },
      {
        id: 1,
        heading: "Slug Variable",
        label: "Slug Variable",
        name: "slug_variable",
        // className: "inputBlock inputBlock1line",
        className: 'inputBlock inputBlock3line',
        type: "text",
        rules: { required: true },
        errorMessage: {
          required: "This Field is Required",
        },
        //   minLength: "Minimum length should be 15",
        //   custom: "Value is Invalid"
        defaultValue: (editdata && Object.keys(editdata).length > 0 && editdata.slug_variable !== undefined) ? editdata.slug_variable : undefined,

      },

      {
        id: 2,
        heading: "Database Variable",
        label: "Database Variable",
        name: "data_base_variable",
        // className: "inputBlock inputBlock1line",
        className: 'inputBlock inputBlock3line',
        type: 'text',
        rules: { required: true },
        errorMessage: {
          required: "This Field is Required"
        },
        defaultValue: (editdata && Object.keys(editdata).length > 0 && editdata.data_base_variable !== undefined) ? editdata.data_base_variable : undefined,

        //   minLength: "Minimum length should be 15",
        //   custom: "Value is Invalid"


      },
      {
        id: 8,
        heading: "Collection Name",
        label: "Collection Name",
        // label: "Select Program",
        name: "from_collection",
        className: "inputBlock inputBlock3line",

        type: "select",
        multiple: false,
        checkbox: true,
        values:collectionData,
        // rules: { required: true },
        // errorMessage: {
        //   required: "This Field is Required"
        // },
        // sx: { m: 1, minWidth: 120 },
        defaultValue:
          editdata && editdata.from_collection !== undefined
            ? editdata.from_collection
            : undefined,
      },
      {
        id: 0,
        heading: "Value",
        label: "Value",
        name: "value",
        // className: "inputBlock inputBlock1line",
        className: 'inputBlock inputBlock3line',
        type: "text",
        // rules: { required: true },
        // errorMessage: {
        //   required: "This Field is Required",
        // },
        //   minLength: "Minimum length should be 15",
        //   custom: "Value is Invalid"
        defaultValue: (editdata && Object.keys(editdata).length > 0 && editdata.value !== undefined) ? editdata.value : undefined,

      },
      {
        id: 4,
        heading: "Owned By",
        label:"Owned By",
        name: "owned_by",
        className: "inputBlock inputBlock3line",
        type: 'select',
        multiple: false,
        values: [{ val: 1, name: 'Owned by Reciever ' }, { val: 2, name: ' Owned by Sender' },{ val: 3, name: 'Other Collection' }],
        // rules: { required: true },
        // errorMessage: {
        //   required: "This Field is Required"
        // },
        defaultValue: (editdata && Object.keys(editdata).length > 0 && editdata.owned_by !== undefined) ? editdata.owned_by : undefined,

        //   minLength: "Minimum length should be 15",
        //   custom: "Value is Invalid"



      },


      {
        id: 3,
        heading: "Description",
        // label: "Description",
        placeholder: "Please type here...",
        name: "description",
        className: 'inputBlock inputBlock1line',
        type: 'textarea',
        // rules: { required: true },
        // errorMessage: {
        //   required: "This Field is Required"
        // },
        defaultValue: (editdata && Object.keys(editdata).length > 0 && editdata.description !== undefined) ? editdata.description : undefined,

        //   minLength: "Minimum length should be 15",
        //   custom: "Value is Invalid"


      },
      {
        id: 4,
        heading: "Status",
        name: "status",
        className: "inputBlock1line statusinput",
        type: 'singleCheckbox',
        values: { key: 0, val: 'Active' },
        // rules: { required: true },
        // errorMessage: {
        //   required: "This Field is Required"
        // },
        defaultValue: (editdata && Object.keys(editdata).length > 0 && editdata.status !== undefined) ? editdata.status : undefined,

        //   minLength: "Minimum length should be 15",
        //   custom: "Value is Invalid"



      },





    ],
    customButtons: [
      {
        id: 'goback001',
        label: 'Go Back',
        className: 'default',
        type: 'button',
      },
    ]

  }

  useEffect(() => {
    if (goBackClicked === true) {
      resolvefunction(navigate, '/email-slug', getEmailSlagData(reqbodyforEmailSlag))
    }

  }, [goBackClicked])

  useEffect(() => {
    dispatch(fetchCollectName());
    if (!resolved) {
      if (id !== undefined && pathname !== null && pathname.pathname !== undefined && pathname.pathname.includes('edit')) {

        dispatch(emailSlugEditdata(id))

      }
    }



    return () => {
      store.dispatch(clearEmailSlugEditdata())

    }
  }, [])

  // useEffect(() => {

  //   return () => {
  //     store.dispatch(clearEmailSlugEditdata())

  //   }
  // }, [])


  return (

    <>


      <h1 className='page_heading'>Email slug {id !== undefined ? 'EDIT' : 'ADD'} </h1>
        <div className="dataform2_wrapper">
        {loading == true ? <LinearProgress /> : false}
        <div className='adminformbody'>
        {id !== undefined && editdata && (editdata.length > 0 || Object.keys(editdata).length > 0) ?<> <Form formData={dataform} /></>: !id && <Form formData={dataform} /> }
        </div>
      </div>

    </>


  )
}

export default EmailSlugAddEdit 