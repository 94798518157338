import React, { useEffect, useState } from 'react';
import { FormControl, FormHelperText, InputLabel, TextField } from '@mui/material';
import { DesktopDatePicker, LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { useDispatch } from 'react-redux';
import { resetFormFieldError, resetTempFormUpdateData } from '../formReducer';
import store from '../../store';
import moment, { tz } from 'moment';
export const DatePickerField = ({ fielddata, value, setValue, getValues, errors, clearErrors, setError, formId }) => {
  const [val, setVal] = useState(null)
  const [customError, setCustomError] = useState(null)


  const dispatch = useDispatch()
  ///////////////////////////// Set Default Value //////////////////////////
  useEffect(() => {
    if (fielddata.defaultValue)
      setValue(fielddata.name, fielddata.defaultValue);
  }, []);

  useEffect(() => {
    if (val !== null) {
      setValue(fielddata.name, val);
      dispatch(resetTempFormUpdateData({ formId, fieldName: fielddata.name }))
    }
  }, [val])

  //////////////////////////////////////// Effect the field error from outside //////////////////////////////
  useEffect(() => {
    if (customError !== null) {
      setError(fielddata.name, customError);
      dispatch(resetFormFieldError({ formId, fieldName: fielddata.name }))
    }
  }, [customError])

  store.subscribe(() => {
    const storeData = store.getState();

    if (Object.keys(storeData.formSlice.tempFormUpdateData).length > 0 && storeData.formSlice.tempFormUpdateData[formId] !== undefined && storeData.formSlice.tempFormUpdateData[formId][fielddata.name] !== undefined) {
      console.log("storing====>")
      setVal(storeData.formSlice.tempFormUpdateData[formId][fielddata.name])
    }


    //////////////////////////////////////////// Set Error from Outside ///////////////////////////////////////////////////////////
    if (Object.keys(storeData.formSlice.tempFormFieldError).length > 0 && storeData.formSlice.tempFormFieldError[formId] !== undefined && storeData.formSlice.tempFormFieldError[formId][fielddata.name] !== undefined) {
      console.log("storing====>")
      setCustomError(storeData.formSlice.tempFormFieldError[formId][fielddata.name])
    }
  })

  const keybordtype = (val) => {
    console.log("val", val);
    let dateformat = 'MM/DD/YYYY';
    if (fielddata.format && fielddata.format !== undefined && fielddata.format == 'dd/MM/yyyy') {
      dateformat = 'DD/MM/YYYY'
    }

    if (moment(val.toString(), dateformat, true).isValid()) {
      console.log('valid date', val);

      setValue(fielddata.name, (fielddata.fullDay && fielddata.fullDay == true) ? (new Date(val).getTime() + 24 * 60 * 60 * 1000 - 1000) : new Date(val.toString()).getTime())

    } else {
      console.log('not valid date', val);
    }
  }

  return (
    <div className={"form_item " + fielddata.className}>
      {/* HEADING */}
      {fielddata.heading && <InputLabel ><div dangerouslySetInnerHTML={{ __html: fielddata.heading }}></div></InputLabel>}
      {/* FIELD */}
      <FormControl>
        <LocalizationProvider dateAdapter={AdapterDateFns}>
          <DesktopDatePicker
            disabled={fielddata.disabled !== undefined ? fielddata.disabled : false}
            label={fielddata.label}
            inputFormat={fielddata.format ? fielddata.format : 'MM/dd/yyyy'}
            value={getValues(fielddata.name) ? getValues(fielddata.name) : null}//////////// new Date set as default value ////////////////
            onChange={(value, keyboardInputValue) => {
              console.log("keyboardInputValue", value, keyboardInputValue);
              if (fielddata.rules?.required == true && (keyboardInputValue === null || value === null)) {
                setError(fielddata.name, { type: "required" }, { shouldFocus: true });
                return;
              }
              // console.log("newValue value",newValue, (new Date(newValue.toString()).getTime() + 24*60*60*1000 - 1000));
              if (keyboardInputValue != null) {
                keybordtype(keyboardInputValue)
                // setValue(fielddata.name, (fielddata.fullDay && fielddata.fullDay==true) ? (new Date(keyboardInputValue).getTime() + 24*60*60*1000 - 1000) : new Date(keyboardInputValue.toString()).getTime());
              } else if (value != null) {
                setValue(fielddata.name, (fielddata.fullDay && fielddata.fullDay == true) ? (new Date(value).getTime() + 24 * 60 * 60 * 1000 - 1000) : new Date(value.toString()).getTime());
              }

              // setValue(fielddata.name, newValue);
              clearErrors(fielddata.name);
            }}
            renderInput={(params) => <TextField {...params} />}
          />
        </LocalizationProvider>
        {/* ERROR */}
        {errors[fielddata.name]?.type && <FormHelperText sx={{ color: 'red' }}>{fielddata.errorMessage[errors[fielddata.name].type]}

        </FormHelperText>}
      </FormControl>
    </div>
  )
}
