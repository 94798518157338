
import Snackbar from '@mui/material/Snackbar';
import store from "../../store";
import Stack from '@mui/material/Stack';
import MuiAlert from '@mui/material/Alert';

// import * as React from 'react';
import React, { useEffect, useRef, useState } from "react";
import { useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { Alert, Button, Grid, IconButton, List } from '@mui/material';

import { Box, display } from '@mui/system';
import { useCookies, Cookies } from "react-cookie";

const SnackBarComp = (props) => {

    const dispatch = useDispatch(); // ---- Dispatch -----
    const navigate = useNavigate(); //**** FOR PAGE NAVIGATION****//

    // ---- States ----
    const [loading, setLoading] = useState(true);  //*****FOR LODING****//

    // ---- Cookies ---
    const [userInfo, setuserInfoCookie] = useCookies(["userinfocookies"]);

    // ---- UseSelectors ----
    const users = useSelector((state) => state.userManagment.usersList)
    // const usersToken = useSelector((state) => state.loggedinuser.usertoken)


    // console.warn("<<<<< props from popupToogle modal compo >>>>", props)


    // ------- onModalClose --------
    function ModalClose() {
        // console.warn("<<< modal close hit >>>")
        props.setSnackFire(false);
    }

    useEffect(() => {
        setTimeout(() => {
            props.setSnackFire(false);
        }, 2000);
    }, [])


    return (
        <>

            {/* ------ SnackBar ------ */}

            <Stack     className="snack">
                {   props.endpointResStatus == "success" ?
   
                        (<Snackbar 
                        open={props.snackFire} autoHideDuration={10000}> 
                            <Alert severity="success" className='success' >
                                {props.resMessage}
                            </Alert>
                        </Snackbar>) :
                        props.endpointResStatus == "failed" ?
                            (<Snackbar open={props.snackFire} autoHideDuration={10000}>
                                <Alert severity="error" >
                                    {props.resMessage}

                                </Alert>
                            </Snackbar>) : ""}


            </Stack>

            
        </>
    )
}


export default SnackBarComp;