
import Snackbar from '@mui/material/Snackbar';
import Modal from '@mui/material/Modal';
import Typography from '@mui/material/Typography';
import CloseIcon from '@mui/icons-material/Close';
import store from "../../store";

// import * as React from 'react';
import React, { useEffect, useRef, useState } from "react";
import { useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { Button, Grid, LinearProgress, List, Tooltip } from '@mui/material';

import { Box, display } from '@mui/system';
import { useCookies, Cookies } from "react-cookie";
import { Form } from '../../form/Form';
export default function FormPreviewModal(props) {
    console.log("props", props);

    const showFiled = {
        id: "forshowformfiled",
        endPointBodyWrapper: "data",
        formWrapperClass: "formWrapperClass", // Can be undefined default will be formCls
        formInlineCLass: "AddEditQuiz forprviewform ", // Can be undefined default will be formInline
        formButtonClass: "submitbtnsection",
        submitBtnName: "Submit",
        resetBtnName: "Reset",
        formAdditionalSubmissionData: {
            // label: "Form",

        },
        fields: props.dataset,
    };



    return (



        <Modal
            className="modalblock "
            open={props.openfieldpreview}
            onClose={() => props.setOpenfieldpreview(false)}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
        >

            <Box className="BoxMui_modal delete_modal previewfield_modal " sx={{
                position: "absolute",
                top: "50%",
                left: "50%",
                transform: "translate(-50%, -50%)",
                // width: 400,
                bgcolor: "background.paper",
                border: "2px solid #000",
                boxShadow: 24,
                // p: 4,
                textAlign: "center",
            }}>


                <Typography id="modal-modal-title" variant="h6" component="h3" sx={{ p: 10 }}>

                    <p className='form_preview modal_title'>{props.titel} </p>

                </Typography>

                {props.dataset && props.dataset.length > 0 && <div className='dataform1 addedit_wrp datamodalwrper'>
                    <div className='dataform2_wrapper'>

                        <div className='adminformbody'>

                            <Form formData={showFiled} />
                        </div>
                    </div>
                </div>}

                <Button onClick={() => props.setOpenfieldpreview(false)} className="closeModalBtn">

                    <Tooltip title="Close" placement="right-start">

                        <CloseIcon />
                    </Tooltip>

                </Button>

            </Box>

        </Modal >
    )
}
