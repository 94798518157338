import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormControl from '@mui/material/FormControl';
import React, { useEffect, useState } from 'react'
import { FormHelperText, InputLabel } from '@mui/material';
import { useDispatch } from 'react-redux';
import { resetFormFieldError, resetTempFormUpdateData } from '../formReducer';
import store from '../../store';

export const RadioSelection = ({ fielddata, value, setValue, getValues, errors, clearErrors, formId, setError }) => {

  const [val, setVal] = useState(null)
  const [customError, setCustomError] = useState(null)

  const dispatch = useDispatch()

  ///////////////////////////// Set Default Value //////////////////////////
  useEffect(() => {
    if (fielddata.defaultValue)
      setValue(fielddata.name, fielddata.defaultValue);

  }, [])

  useEffect(() => {
    if (val !== null) {
      setValue(fielddata.name, val);
      dispatch(resetTempFormUpdateData({ formId, fieldName: fielddata.name }))
    }
  }, [val])

  //////////////////////////////////////// Effect the field error from outside //////////////////////////////
  useEffect(() => {
    if (customError !== null) {
      setError(fielddata.name, customError);
      dispatch(resetFormFieldError({ formId, fieldName: fielddata.name }))
    }
  }, [customError])


  store.subscribe(() => {
    const storeData = store.getState();

    if (Object.keys(storeData.formSlice.tempFormUpdateData).length > 0 && storeData.formSlice.tempFormUpdateData[formId] !== undefined && storeData.formSlice.tempFormUpdateData[formId][fielddata.name] !== undefined) {
      console.log("storing====>")
      setVal(storeData.formSlice.tempFormUpdateData[formId][fielddata.name])
    }

    //////////////////////////////////////////// Set Error from Outside ///////////////////////////////////////////////////////////
    if (Object.keys(storeData.formSlice.tempFormFieldError).length > 0 && storeData.formSlice.tempFormFieldError[formId] !== undefined && storeData.formSlice.tempFormFieldError[formId][fielddata.name] !== undefined) {
      console.log("storing====>")
      setCustomError(storeData.formSlice.tempFormFieldError[formId][fielddata.name])
    }
  })

  return (
    <div className={"form_item " + fielddata.className}>
      {/* HEADING */}
      {fielddata.heading && <InputLabel ><div dangerouslySetInnerHTML={{ __html: fielddata.heading }}></div></InputLabel>}
      {/* FIELD */}
      <FormControl>
        <RadioGroup
          value={getValues(fielddata.name) ? getValues(fielddata.name) : ""}
          onChange={(e) => {
            setValue(fielddata.name, e.target.value);
            clearErrors(fielddata.name); // Clearing errors on focus or change
          }}
        >
          {fielddata.values.map(eachVal => <FormControlLabel
            key={eachVal.key}
            value={eachVal.val}
            control={<Radio />}
            label={eachVal.val}
          />)}
        </RadioGroup>
        {/* ERROR */}
        {errors[fielddata.name]?.type && <FormHelperText sx={{ color: 'red' }}>{fielddata.errorMessage[errors[fielddata.name].type]}</FormHelperText>}
      </FormControl>
    </div>
  )
}
