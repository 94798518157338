import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";

export const fetchTrainingMaterial = createAsyncThunk("fetch/TrainingMaterial", async (reqBody) => {
    console.log("process.env==>", process.env);
    let req_body = {};
    if (reqBody === undefined || reqBody === null) {
        req_body = {
            "condition": {
                "limit": 10,
                "skip": 0
            },
            "sort": {
                "field": "_id",
                "type": "desc"
            },
            "searchcondition": {},
            "project": {},
            "token": ""
        }
    } else {
        req_body = reqBody;
    }
    const requestOptions = {
        method: 'post',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify(req_body)
    };
    console.log("process.env==>", process.env);
    const response = await fetch(process.env.REACT_APP_API_URL + "training-material/training-material-list-count", requestOptions);
    const respdata = await response.json();
    // console.log("respdata after endpoint fetch==>", respdata);
    return respdata;
});

export const fetchTrainingMaterialsingle = createAsyncThunk("fetch/fetchTrainingMaterialsingle", async (reqBody) => {
    console.log("process.env==>", process.env);
    let req_body = {
        "condition": {
            "limit": 5,
            "skip": 0
        },
        "sort": {
            "field": "_id",
            "type": "desc"
        },
        "searchcondition": { "_id": reqBody },
        "project": {},
        "token": ""
    }

    const requestOptions = {
        method: 'post',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify(req_body)
    };
    console.log("process.env==>", process.env);
    const response = await fetch(process.env.REACT_APP_API_URL + "training-material/fetch-training-material", requestOptions);
    const respdata = await response.json();
    // console.log("respdata after endpoint fetch==>", respdata);
    return respdata;
});



export const fetchAutoCompltMaterialTrainingList = createAsyncThunk("fetch/AutoCompltMaterialTrainingList", async (reqBody) => {
    let req_body = {
        "source": "program_list",
        "sort": {
            "type": "asc",
            "field": "name"
        },
        "searchcondition": {

        }
    };

    const requestOptions = {
        method: 'post',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify(req_body)
    };
    console.log("process.env==>", process.env);
    const response = await fetch(process.env.REACT_APP_API_URL + "program-manage/auto-complete-program-list", requestOptions);
    const respdata = await response.json();
    // console.log("respdata after endpoint fetch==>", respdata);
    return respdata;
})

export const fetchSingleTrainingMaterial = createAsyncThunk("fetch/singleTrainingMaterial", async (reqBody) => {
    // console.warn("<<<<<< inside fetchUserDataReducer >>>>", reqBody)
    const reqOption = {
        method: "POST",
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify(reqBody)
    };
    const response = await fetch(process.env.REACT_APP_API_URL + "training-material/fetch-training-material", reqOption);
    const respdata = await response.json();
    // console.log("respdata after endpoint fetch==>", respdata);
    return respdata;
});

export const fetchQuizList = createAsyncThunk("fetch/QuizList", async (reqBody) => {
    let req_body = {};
    if (reqBody === undefined || reqBody === null) {
        req_body = {
            "condition": {
                "limit": 5,
                "skip": 0
            },
            "sort": {
                "field": "_id",
                "type": "desc"
            },
            "searchcondition": {},
            "project": {},
            "token": "",
            "count": false
        }
    } else {
        req_body = reqBody;
    }
    const requestOptions = {
        method: 'post',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify(req_body)
    };
    console.log("process.env==>", process.env);
    const response = await fetch(process.env.REACT_APP_API_URL + "training-manage/quiz-list", requestOptions);
    const respdata = await response.json();
    // console.log("respdata after endpoint fetch==>", respdata);
    return respdata;
});
export const fetchSingleQuiz = createAsyncThunk("fetch/SingleQuiz", async (reqBody) => {

    const requestOptions = {
        method: 'post',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify(reqBody)
    };
    console.log("process.env==>", process.env);
    const response = await fetch(process.env.REACT_APP_API_URL + "training-manage/quiz-list", requestOptions);
    const respdata = await response.json();
    // console.log("respdata after endpoint fetch==>", respdata);
    return respdata;
});
export const addEditQuiz = createAsyncThunk("addEdit/Quiz", async (reqBody) => {
    const requestOptions = {
        method: 'post',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify(reqBody)
    };
    console.log("process.env==>", process.env);
    const response = await fetch(process.env.REACT_APP_API_URL + "training-manage/addupdate-quiz", requestOptions);
    const respdata = await response.json();
    // console.log("respdata after endpoint fetch==>", respdata);
    return respdata;
});




// API call for users-list
export const TrainingMaterialCenterlist = createAsyncThunk("trainingmaterial-center/list", async (reqbody) => {
    if (reqbody == null && reqbody === undefined) {
        reqbody = {
            "roles": ["is_admin"],
            "searchcondition":{},
        }
    }
    // console.log("reqbody==>", reqbody);
    let req_body = {};
    req_body = { ...reqbody };
    // console.log("req_body==", req_body);
    const requestOptions = {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify(req_body)
    };
    // console.log("process.env==>", process.env);
    const response = await fetch(process.env.REACT_APP_API_URL + "training-material/program-wise-training-material", requestOptions);
    const respdata = await response.json();
    // console.log("respdata after endpoint fetch==>", respdata);
    return respdata;
});

const TrainingMaterialReducer = createSlice({
    name: "TrainingMaterialReducer",
    initialState: {
        loading: false,
        success: false,
        error: false,
        materialList: [],
        viewMaterialData: {},
        quizList: [],
        fetchSingleQuizData: null,
        addTrainingListData: [],
        siglematerialdata: {},
        fetchTrainingMaterialSingleLoader: false,
        trainingmaterialListData: [],
        trainingmaterialListDataCount:null,
    },
    reducers: {
        clearListData(state, action) {
            state.materialList = [];
    
        },
        
        clearMetarialListData(state, action) {
            state.trainingmaterialListData = [];
        },
        setInitialData(state, action) {
            state.loading = false;
            state.success = false;
            state.addTrainingListData = [];
        },
        cleareditquizdata(state, action) {
            state.fetchSingleQuizData = null
        },
        clearsiglematerialdata(state, action) {
            state.siglematerialdata = {}
        },
        clearViewMaterialData(state, action) {
            state.viewMaterialData = null
        }
    },

    extraReducers: {

    [fetchTrainingMaterialsingle.pending](state, action) {
        state.fetchTrainingMaterialSingleLoader = true;
    },
    [fetchTrainingMaterialsingle.fulfilled](state, action) {
        state.fetchTrainingMaterialSingleLoader = false;
        if (action.payload.status === "success") {

            state.siglematerialdata = action.payload.results?.res[0]
        }
        console.log("action ", action)
    },
    [fetchTrainingMaterialsingle.rejected](state, action) {
        state.fetchTrainingMaterialSingleLoader = false;
    },
    [fetchTrainingMaterial.pending](state, action) {
        state.loading = true;
    },
    [fetchTrainingMaterial.fulfilled](state, action) {
        state.loading = false;
        if (action.payload.status === "success") {
            state.success = true;
            state.materialList = action.payload.results?.res
        }
        console.log("action ", action)
    },
    [fetchTrainingMaterial.rejected](state, action) {
        state.loading = false;
    },
    [fetchSingleTrainingMaterial.pending](state, action) {
        state.fetchTrainingMaterialSingleLoader = true;
    },
    [fetchSingleTrainingMaterial.fulfilled](state, action) {
        state.fetchTrainingMaterialSingleLoader = false;
        if (action.payload.status === "success") {
            state.success = true;
            state.viewMaterialData = action.payload.results?.res[0]
        }
        console.log("action ", action)
    },
    [fetchSingleTrainingMaterial.rejected](state, action) {
        state.fetchTrainingMaterialSingleLoader = false;
    },
    [fetchQuizList.pending](state, action) {
        state.loading = true;
    },
    [fetchQuizList.fulfilled](state, action) {
        state.loading = false;
        if (action.payload.status === "success") {
            state.success = true;
            state.quizList = action.payload.results?.res
        }
        // console.log("action fetchQuizList", action)
    },
    [fetchQuizList.rejected](state, action) {
        state.loading = false;
    },
    [addEditQuiz.pending](state, action) {
        state.loading = true;
    },
    [addEditQuiz.fulfilled](state, action) {
        state.loading = false;
        console.log("action ", action)
        if (action.payload.status === "success") {
            state.success = true;
            // state.quizList = action.payload.results?.res
        }
        console.log("action ", action)
    },
    [addEditQuiz.rejected](state, action) {
        state.loading = false;
    },
    [fetchSingleQuiz.pending](state, action) {
        state.loading = true;
    },
    [fetchSingleQuiz.fulfilled](state, action) {
        state.loading = false;
        console.log("action fetchSingleQuiz ", action)
        if (action.payload.status === "success") {
            state.success = true;
            state.fetchSingleQuizData = action.payload.results?.res
        }
        console.log("action ", action)
    },
    [fetchSingleQuiz.rejected](state, action) {
        state.loading = false;
    },



    // <-------------ADD TRAINING LIST----------->

    [fetchAutoCompltMaterialTrainingList.pending](state, action) {
        state.loading = true;
    },
    [fetchAutoCompltMaterialTrainingList.fulfilled](state, action) {
        state.loading = false;
        if (action.payload.status === "success") {
            state.success = true;
            state.addTrainingListData = action.payload.results?.res
        }
    },
    [fetchAutoCompltMaterialTrainingList.rejected](state, action) {
        state.loading = false;
    },

    // <-------------ADD TRAINING MATERIAL CENTAR LIST----------->

    [TrainingMaterialCenterlist.rejected]: (state, action) => {
        state.endpointsuccess[action.type] = false
        // state.loading = false;
        state.successAction = false;

    },
    [TrainingMaterialCenterlist.pending]: (state, action) => {


        console.log("TrainingMaterialCenterlist=========================>")
        state.loading = true;

    },
    [TrainingMaterialCenterlist.fulfilled]: (state, action) => {

        console.log("action from storedata", action)
        if (action.payload.status === 'success') {
            if(action.payload?.results?.res){
                state.trainingmaterialListData = action.payload?.results?.res;
            }
            state.loading = false;
            if(action.payload.count){
                state.success = true;
                state.trainingmaterialListDataCount=action.payload?.count;
            }
        }

    },


}
})

export default TrainingMaterialReducer.reducer;
export const { clearListData, cleareditquizdata, clearsiglematerialdata, clearViewMaterialData,clearMetarialListData,resetresolvenew } = TrainingMaterialReducer.actions;