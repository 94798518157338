import { createAsyncThunk, createSlice } from "@reduxjs/toolkit"


//--------------------Data Fetch For Landing page listing--------------//

export const getLandingListData = createAsyncThunk("getLandingList", async (reqBody) => {

    console.log("reqbody==>", reqBody);

    if (!reqBody) {

        reqBody = {
            "condition": {
                "limit": 5,
                "skip": 0
            },
            "sort": {
                "field": "createdon_datetime",
                "type": "desc"
            },
            "searchcondition": {
                
            },
            "project": {},
            "token": "",
            "count": false
        }
    }


    let req_body = {};
    req_body = { ...reqBody };
    console.log("req_body==", req_body);
    const requestOptions = {
        method: 'post',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify(req_body)
    };
    console.log("process.env==>", process.env);
    const response = await fetch(process.env.REACT_APP_API_URL + "landing-page-manage/landing-page-list", requestOptions);
    const respdata = await response.json();
    // console.log("respdata after endpoint fetch==>", respdata);
    return respdata;


})

//----------------------Data fetch for single data and Edit Time--------------//

export const getLandingPageEdit = createAsyncThunk("getLandingPageEdit", async (id) => {
    let reqBody = {
        "condition": {},
        "sort": {
            "field": "name",
            "type": "asc"
        },
        "searchcondition": { "_id": id },
        "project": {},
        "token": ""
    }
    console.log("reqbody==>", reqBody);
    let req_body = {};
    req_body = { ...reqBody };
    console.log("req_body==", req_body);
    const requestOptions = {
        method: 'post',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify(req_body)
    };
    console.log("process.env==>", process.env);
    const response = await fetch(process.env.REACT_APP_API_URL + "landing-page-manage/single-fetch-data", requestOptions);
    const respdata = await response.json();
    // console.log("respdata after endpoint fetch==>", respdata);
    return respdata;


})



// export const getAssociatedProgram = createAsyncThunk("getAssociatedProgram ", async () => {

//     // console.log("reqbody==>", reqbody);
//     let req_body = {
//         "condition": {
//             "limit": 10,
//             "skip": 0
//         },
//         "sort": {
//             "type": "asc",
//             "field": "name"
//         },
//         "searchcondition": {
//             "key": {
//                 "$in":  ""
//             }
//         }

        
//     };

//     const requestOptions = {
//         method: 'POST',
//         headers: { 'Content-Type': 'application/json' },
//         body: JSON.stringify(req_body)
//     };
//     // console.log("process.env==>", process.env);
//     const response = await fetch(process.env.REACT_APP_API_URL + "program-manage/auto-complete-program-list", requestOptions);
//     const respdata = await response.json();
//     // console.log("respdata after endpoint fetch==>", respdata);
//     return respdata;

// });



const LandingPageReducer = createSlice({
    name: 'LandingPageReducer',
    initialState: {

        submitting: false,
        loading: false,
        editloading: false,
        landingpagedata: [],
        editlandingpagedata: [],
        message: null,
        getassociatedProgram:[],


    },

    reducers: {
        clearlandingpagedata(state, action) {
            state.landingpagedata = []
        },
        cleareditdata(state, action) {
            state.editlandingpagedata = []
        }
    },

    extraReducers: {

        // ------------------For LandingPage List Data------------//

        [getLandingListData.pending]: (state, action) => {
            console.log("getroledata=========================>")
            state.loading = true;
        },
        [getLandingListData.fulfilled]: (state, action) => {

            console.log("action from storedata", action)
            if (action.payload.status === 'success') {

                state.landingpagedata = action.payload.results.res;
                state.loading = false;

            }

        },
        [getLandingListData.rejected]: (state, action) => {
            state.endpointsuccess[action.type] = false
            // state.loading = false;
            state.successAction = false;

        },

       



         // --------------------------For Landing Page Edit Data-----------------//

        [getLandingPageEdit.pending]: (state, action) => {
           console.log("getroleeditdata=========================>")
            state.editloading = true;
        },
        [getLandingPageEdit.fulfilled]: (state, action) => {

            console.log("action from storedata", action)
            if (action.payload.status === 'success') {

                state.editlandingpagedata = [action.payload.results.res];
                state.editloading = false;

            }

        },
        [getLandingPageEdit.rejected]: (state, action) => {
            state.endpointsuccess[action.type] = false
            // state.loading = false;
            // state.successAction = false;

        },

    }







})

export default LandingPageReducer.reducer;
export const {clearlandingpagedata,cleareditdata } = LandingPageReducer.actions;



