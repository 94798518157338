
import store from "../../../../store";
// import * as React from 'react';
import React, { useEffect, useRef, useState } from "react";
import { useLocation, useNavigate, useParams, } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { Button, Grid, IconButton, LinearProgress, Snackbar } from '@mui/material';
import { useCookies } from "react-cookie";
import { fetchUserData, setInitData } from '../fetchUserDataReducer';
import CircularProgress from '@mui/material/CircularProgress';
import AddEditForm from './userManagementAddEditForm';
import CloseIcon from "@mui/icons-material/Close";
import { Box } from '@mui/system';
import '../../../../assets/Form.css'
import { resolvefunction } from "../../../../helper/helperFunctions";
import { getusersList } from "../userList/userListReducer";

const AddEdituser = (props) => {
  // const [users, setusers] = useState([]);
  const dispatch = useDispatch();
  const navigate = useNavigate(); //**** FOR PAGE NAVIGATION****//
  const param = useParams();//---- userParam 



  // ---- States here -----
  const [loading, setLoading] = useState(true);  //*****FOR LODING****//
  const [userInfo, setUserInfo] = useState(null); // Set User Information from Cookie
  const [formSubmissionStateUserForm, setFormSubmissionStateUserForm] = useState(0) //**** FOR FORM SUBMIT ****//
  const [queryParamId, setQueryParamId] = useState();  //*****FOR Seeting QueryP****//
  const [errPopup, setErrPopup] = React.useState(false); // snackbar const
  const [errMsgCopy, setErrMsgCopy] = React.useState(null); // snackbar const

  const [open, setOpen] = React.useState(false); // snackbar const
  // const [loader, setLoader] = useState(false);

  // ---- Cookies here ----
  const [cookieData, setCookieData] = useCookies();
  // console.warn("<<<<< cookieData from addEditFrom component>>>>>", cookieData)

  // ------ useSelector here ------
  // const userType = useSelector((state) => state.addUserSlice?.routeType)


  // --- loader --- 
  const loader = useSelector(state => (state.fetchUserDataReducer?.loading
  ) ? state.fetchUserDataReducer.loading
    : null);


  const endpointProcessState = useSelector(state => (state.formSlice?.formSubmissionState?.userForm) ? state.formSlice.formSubmissionState.userForm : false);
  
  const errMsg = useSelector(state => (state.formSlice?.message?.userForm) ? state.formSlice.message.userForm : null);


  // snackbar Close
  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setOpen(false);
    setErrPopup(false);
    setErrMsgCopy(null);
  };

  const action = (
    <React.Fragment>
      {/* <Button color="secondary" size="small" onClick={handleClose}>
        UNDO
      </Button> */}
      <IconButton
        size="small"
        aria-label="close"
        color="inherit"
        onClick={handleClose}
      >
        <CloseIcon fontSize="small" />
      </IconButton>
    </React.Fragment>
  );



  //---- based on endpoint call ---
  useEffect(() => {
    if (endpointProcessState == 1) {
      // setLoader(true);
      setOpen(false);
    } else if (endpointProcessState == 2) {
      // setLoader(false);
      setOpen(true);

      // setTimeout(() => {
      //   navigate('/usermanagement');
      // }, 1500);

      setTimeout(() => {
        resolvefunction(navigate, "/usermanagement", getusersList())
      }, 1000);


    } else if (endpointProcessState == 3) {

      setErrMsgCopy(errMsg);
      setErrPopup(true)

    }
  }, [endpointProcessState])


  useEffect(() => {
    if (cookieData && cookieData?.userinfocookies) {
      setUserInfo(cookieData.userinfocookies);
    }
  }, []);



  // -------- Getting QueryParam and Calling thunk for fetching data through endpoint ---------

  const queryParam = useLocation();
  const paramId = queryParam.search.split("=");

  useEffect(() => {
    if (paramId[1]) {
      setQueryParamId(paramId[1])
    }
  }, [paramId])


  useEffect(() => {
    if (queryParamId != undefined && queryParamId != null) {
      dispatch(fetchUserData(queryParamId));
    }
  }, [queryParamId])

  // console.warn("<<<<< query param from addEditFrom component>>>>>",queryParamId)

  //----------------------------------------------------------------------------------------



  // --- fetched data from query param --- 
  const fetchData = useSelector(state => (state.fetchUserDataReducer?.fetchUserDataReducer
  ) ? state.fetchUserDataReducer.fetchUserDataReducer
    : null);
//   console.warn("<<<<< fetchData goes here>>>>>",fetchData)


  useEffect(() => {
    return () => {
      dispatch(setInitData());
    }
  }, [])



  return (
    <>
      <h1 className='page_heading'>User {queryParamId ? "Edit" : "Add"} {props.formType}</h1>
      
      

      

      <div className='dataform2_wrapper'>

          {loader ? <Box sx={{ width: "100%" }}><LinearProgress /></Box> : null}

          <div className='adminformbody'>
          {(queryParamId) ? (fetchData && <AddEditForm
            queryParamId={queryParamId}
            formType={props.formType}
            fetchData={fetchData}
            userType={props.userType}
          />) : (<AddEditForm
            // userType={props.userType}
            // formType={props.formType}

          />)}

          {/* ---- SnackBar on data Submisstion */}
          {endpointProcessState == 2 ? (<Snackbar
            open={open}
            autoHideDuration={6000}
            onClose={handleClose}
            message={queryParamId ?  "Updated Sucessfully" : "Created Sucessfully"}
            action={action}
          />) : ""}


          {/* {errMsgCopy ? (<Snackbar
            open={errPopup}
            autoHideDuration={4000}
            onClose={handleClose}
            message={errMsgCopy}
            action={action}
          />) : ""} */}


        </div>
      
        </div>

    </>
  )
}

export default AddEdituser;