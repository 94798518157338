import React from 'react'
import SummarizeIcon from "@mui/icons-material/Summarize";
import FolderIcon from "@mui/icons-material/Folder";
import AssessmentIcon from "@mui/icons-material/Assessment";
import { IconButton, Tooltip } from '@mui/material';
const CommonBtn = ({setOpenMoadlforNotes,openModal}) => {
  return (
    <>
    <Tooltip title="Notes">
        <IconButton>
          <SummarizeIcon
            onClick={() => setOpenMoadlforNotes(true)}
          />
        </IconButton>
      </Tooltip>

      <Tooltip title="Folder View">
        <IconButton onClick={() => openModal()}>
          <FolderIcon />
        </IconButton>
      </Tooltip>

      <Tooltip title="Training Report">
        <IconButton onClick={() => openModal()}>
          <AssessmentIcon />
        </IconButton>
      </Tooltip></>
  )
}

export default CommonBtn