import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";


// API call for users-list
// export const gettraingcatagorydata = createAsyncThunk("gettraingcatagorydata", async (reqbody) => {

//     console.log("reqbody==>", reqbody);
//     let req_body = {};
//     req_body = { ...reqbody };
//     console.log("req_body==", req_body);
//     const requestOptions = {
//         method: 'POST',
//         headers: { 'Content-Type': 'application/json' },
//         body: JSON.stringify(req_body)
//     };
//     console.log("process.env==>", process.env);
//     const response = await fetch(process.env.REACT_APP_API_URL + "api1/traninglist", requestOptions);
//     const respdata = await response.json();
//     // console.log("respdata after endpoint fetch==>", respdata);
//     return respdata;
// }, { unwrap: true });



const ResolveReducer = createSlice({
    name: 'ResolveReducer',
    initialState: {
        loader: false,
        resolved: {}


    },
    reducers: {
        startresolve(state, action) {
            console.log("in resolve,", action.payload);
            if (action?.payload && action.payload && Object.keys(action.payload).length > 0) {

                if (action.payload.loader !== null && action.payload.loader !== undefined) {
                    state.loader = action.payload.loader;
                }
                if (action.payload.path !== null && action.payload.path !== undefined) {
                   state.resolved[action.payload.path]=true;
                }

            }

        },
        resetresolvenew(state, action){
            if(state.resolved && Object.keys(state.resolved).length>0&& state.resolved[action.payload]){
                delete state.resolved[action.payload]
            }
            // state.resolved={};
            state.loader=false;
          }

    },
    extraReducers: {




    }
})

export default ResolveReducer.reducer;
export const { startresolve ,resetresolvenew} = ResolveReducer.actions;