import Snackbar from "@mui/material/Snackbar";
import Modal from "@mui/material/Modal";
import Typography from "@mui/material/Typography";

import store from "../../store";
import CloseIcon from "@mui/icons-material/Close";

// import * as React from 'react';
import React, { useEffect, useRef, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { Button, Chip, Grid, IconButton, List, Tooltip } from "@mui/material";
import { timeConverter } from "../../helper/helperFunctions";
import { Box, display } from "@mui/system";
// import { useCookies, Cookies } from "react-cookie";
import { clearViewData } from "../../listing/listReducer";
import { clearViewMaterialData } from "../../Pages/Backend/Training/TrainingMaterial/TrainingMaterialReducer";
import { Carousel } from "react-responsive-carousel";

const PopupModal = (props) => {
  const dispatch = useDispatch(); // ---- Dispatch -----
  const navigate = useNavigate(); //**** FOR PAGE NAVIGATION****//

  // ---- States ----
  const [loading, setLoading] = useState(true); //*****FOR LODING****//

  // ---- Cookies ---
  // const [userInfo, setuserInfoCookie] = useCookies(["userinfocookies"]);

  // // ---- UseSelectors ----
  // const users = useSelector((state) => state.userManagment.usersList)
  // const usersToken = useSelector((state) => state.loggedinuser.usertoken)

  console.log("<<<<< props from popupToogle modal compo >>>>", props);

  // ------- onModalClose --------
  function ModalClose() {
    props.popuptooglefunc(false);
    if (props.type === "eventInfo") {
      dispatch(clearViewData());
    }
    if (props.type === "materialInfo") {
      dispatch(clearViewMaterialData());
    }
  }
  const isVideoFile = (value) => {
    var re = /(?:\.([^.]+))?$/;
    let extension = re.exec(value)[1];
    // console.log("extension", extension);
    // if (extension == "pdf") {
    //   return "pdf";
    // } else if (extension == "jpg" || extension == "jpeg" || extension == "png" || extension == "webp") {
    //   return "image";
    // }
    // return "video";
    if (extension !== '' && extension !== undefined && (extension == 'webp' || extension == 'WEBP' || extension == 'png' || extension == 'PNG' || extension == 'jpg' || extension == 'JPG' || extension == 'jpeg' || extension == 'JPEG' || extension == 'gif' || extension == 'GIF' || extension == 'bmp' || extension == 'BMP' || extension == 'ico' || extension == 'ICO' || extension == 'svg' || extension == 'SVG' || extension == 'tif' || extension == 'TIF' || extension == 'tiff' || extension == 'TIFF')) {
      return 'img'
    }
    if (extension !== '' && extension !== undefined && (extension == 'mp4' || extension == 'MP4' || extension == 'mkv' || extension == 'MKV' || extension == 'wemb' || extension == 'WEMB' || extension == 'flv' || extension == 'FLV' || extension == 'avi' || extension == 'AVI' || extension == 'mov' || extension == 'MOV' || extension == 'swf' || extension == 'SWF' || extension == 'wmv' || extension == 'WMV')) {
      return 'video'
    }
    if (extension !== '' && extension !== undefined && (extension == 'mp3' || extension == 'MP3' || extension == 'aif' || extension == 'AIF' || extension == 'cda' || extension == 'CDA' || extension == 'mid' || extension == 'MID' || extension == 'mpa' || extension == 'MPA' || extension == 'ogg' || extension == 'OGG' || extension == 'wav' || extension == 'WAV' || extension == 'wma' || extension == 'WMA' || extension == 'wpl' || extension == 'WPL')) {
      return 'audio'
    }
    if (extension !== '' && extension !== undefined && (extension == 'pdf' || extension == 'PDF' || extension == 'tex' || extension == 'TEX' || extension == 'txt' || extension == 'TXT')) {
      return 'pdf'
    }
    if (extension !== '' && extension !== undefined && (extension == 'doc' || extension == 'DOC' || extension == 'docx' || extension == 'DOCX' || extension == 'xlsx' || extension == 'XLSX' || extension == 'xlsm' || extension == 'XLSM' || extension == 'pptx' || extension == 'PPTX' || extension == 'xml' || extension == 'XML' || extension == 'odp' || extension == 'ODP' || extension == 'ppt' || extension == 'PPT' || extension == 'pps' || extension == 'PPS' || extension == 'ods' || extension == 'ODS' || extension == 'xls' || extension == 'XLS' || extension == 'odt' || extension == 'ODT' || extension == 'rtf' || extension == 'RTF')) {
      return 'doc';
    }
    if (extension !== '' && extension !== undefined && (extension == 'csv' || extension == 'CSV')) {
      return 'csv';
    }
  };

  const downloadFileWithLink = (href) => {
    // var link = document.createElement("a");
    // let name = (href?.split("/") || [])
    // name = name[name?.length - 1]
    // link.setAttribute('download', name);
    // link.href = href;
    // document.body.appendChild(link);
    // link.click();
    // link.remove();


    const element = document.createElement('a');
    element.setAttribute('href', href);
    element.setAttribute('download', 'Download');

    element.style.display = 'none';
    element.target = "_blank";

    document.body.appendChild(element);
    element.click();
    document.body.removeChild(element);
  }
  const openVideoModal = (url, type) => {
    props.openVideoModal({ url, type });
    props.popuptooglefunc(false);
  };

  return (
    <>
      {/* ------ Confirmation Modal ------ */}

      <Modal
        className=" modalblock userList_modal"
        open={props.popuptoogle}
        onClose={ModalClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box
          className={`BoxMui_modal ${props.type === "materialInfo" ? "onlymaterial_BoxMui_modal" : "other_BoxMui_modal"}`}
          sx={{
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            width: 400,
            bgcolor: "background.paper",
            border: "2px solid #000",
            boxShadow: 24,
            p: 4,
            textAlign: "center",
          }}
        >
          {props.type == "profileInfo" ? (
            <div className=" ModalBox_scroll">
              <div className="heading">
                <h2>
                  User Details
                </h2>
              </div>

              {
                <ul className="listflexblock">
                  {props.popupdata ? (
                    <li>
                      {" "}
                      <strong>Full Name :</strong>{" "}
                      <span>{props.popupdata?.firstname + " " + props.popupdata?.lastname}</span>
                    </li>
                  ) : (
                    ""
                  )}
                  {props.popupdata?.email ? (
                    <li>
                      {" "}
                      <strong>E-mail :</strong>{" "}
                      <span className="emailText">{props.popupdata.email}</span>
                    </li>
                  ) : (
                    ""
                  )}
                  {props.popupdata?.phone ? (
                    <li>
                      {" "}
                      <strong>Contact :</strong>{" "}
                      <span>{props.popupdata.phone}</span>
                    </li>
                  ) : (
                    ""
                  )}
                  {props.popupdata?.address ? (
                    <li>
                      {" "}
                      <strong>Address :</strong>
                      <span> {props.popupdata.address}</span>
                    </li>
                  ) : (
                    ""
                  )}
                  {props.popupdata?.state ? (
                    <li>
                      {" "}
                      <strong>State :</strong>
                      <span> {props.popupdata.state}</span>
                    </li>
                  ) : (
                    ""
                  )}
                  {props.popupdata?.city ? (
                    <li>
                      {" "}
                      <strong>City :</strong>{" "}
                      <span>{props.popupdata.city}</span>
                    </li>
                  ) : (
                    ""
                  )}
                  {props.popupdata?.zip ? (
                    <li>
                      {" "}
                      <strong>Zip :</strong> <span>{props.popupdata.zip}</span>
                    </li>
                  ) : (
                    ""
                  )}
                  {props.popupdata?.roles !== undefined && Object.keys(props.popupdata?.roles).length > 0 ? (
                    <li>
                      {" "}
                      <strong>Type :</strong>{" "}
                      <span>


                        {props.popupdata?.roles?.is_admin === 1 ? <Chip className="viewModChips" label="Admin" variant="outlined" /> : ""} {props.popupdata?.customer_support === 1 ? <Chip label="Customer Support" className="viewModChips" variant="outlined" /> : ""}
                        {props.popupdata?.roles?.hr_manager === 1 ? <Chip label="Hiring Manager" className="viewModChips" variant="outlined" /> : ""}
                        {props.popupdata?.roles?.tech_support === 1 ? <Chip label="Tech Support" className="viewModChips" variant="outlined" /> : ""}
                        {props.popupdata?.roles?.is_rep === 1 ? <Chip label="Rep" className="viewModChips" variant="outlined" /> : ""}
                        {props.popupdata?.roles?.is_aer === 1 ? <Chip label="AER" className="viewModChips" variant="outlined" /> : ""}
                        {props.popupdata?.roles?.is_bdd === 1 ? <Chip label="BDD" className="viewModChips" variant="outlined" /> : ""}

                      </span>

                    </li>
                  ) : ""}
                  {props.popupdata?.status == 1 ? (
                    <li>
                      {" "}
                      <strong>Status:</strong> <span>Active</span>
                    </li>
                  ) : (
                    <li>
                      {" "}
                      <strong>Status:</strong> <span>Inactive</span>
                    </li>
                  )}

                  {props.popupdata?.createdon_datetime ? (
                    <li>
                      {" "}
                      <strong> Added On :</strong>{" "}
                      <span>
                        {timeConverter({
                          time: props.popupdata?.createdon_datetime,
                          format: "DD/MM/YYYY hh:mm A",
                        })}
                      </span>
                    </li>
                  ) : (
                    ""
                  )}

                  {props.popupdata?.login_time ? (
                    <li>
                      {" "}
                      <strong> Last Login :</strong>{" "}
                      <span>
                        {timeConverter({
                          time: props.popupdata?.login_time,
                          format: "DD/MM/YYYY hh:mm A",
                        })}
                      </span>
                    </li>
                  ) : (
                    ""
                  )}
                </ul>
              }
            </div>
          ) : props.type === "materialInfo" ? (
            <div className=" ModalBox_scroll">
              <div className="heading">
                <h2> Material Details </h2>
              </div>

              {
                <ul className="listflexblock">
                  {props.popupdata?.name ? (
                    <li>
                      {" "}
                      <strong>Name :</strong>{" "}
                      <span>{props.popupdata.name}</span>
                    </li>
                  ) : (
                    ""
                  )}
                  {props.popupdata?.description ? (
                    <li>
                      {" "}
                      <strong>Description :</strong>{" "}
                      <span className="descriptionText">
                        {props.popupdata.description}
                      </span>
                    </li>
                  ) : (
                    ""
                  )}
                  {props.popupdata?.program_names ? (
                    <li>
                      {" "}
                      <strong>Program Name :</strong>{" "}
                      <span dangerouslySetInnerHTML={{ __html: props.popupdata.program_names }}></span>
                    </li>
                  ) : (
                    ""
                  )}
                  {props.popupdata?.role_names ? (
                    <li>
                      {" "}
                      <strong>Role Name :</strong>{" "}
                      <span dangerouslySetInnerHTML={{ __html: props.popupdata.role_names }}></span>
                    </li>
                  ) : (
                    ""
                  )}
                  {props.popupdata?.files ? (
                    <li>
                      {" "}
                      <strong>Files :</strong>
                      <span>
                        {props.popupdata.files &&
                          props.popupdata.files.length > 0
                          ? props.popupdata.files.map((file, index) => (
                            <div key={index}>
                              <>
                                <div>Name : {file.name}</div>
                                <div>Description : {file.description}</div>
{file.upload_file && file.upload_file.url?
                                <div id="uploadFile">
                                  {isVideoFile(file.upload_file.url) == 'img' ?
                                    <><img src={file.upload_file.url} />
                                      {/* <a href={file.upload_file.url} download target="_blank">Download</a> */}
                                    </> :
                                    (isVideoFile(file.upload_file.url) == 'video') ?
                                      <video controls><source src={file.upload_file.url} /></video> :
                                      (isVideoFile(file.upload_file.url) == 'pdf') ?
                                        <embed src={file.upload_file.url} width="800px" height="2100px" class='docshowifram' /> :
                                        (isVideoFile(file.upload_file.url) == 'doc') ?
                                          <iframe src={`https://view.officeapps.live.com/op/embed.aspx?src=${file.upload_file.url}`} width='1366px' height='623px' frameborder='0' class='docshowifram' ></iframe> :
                                          (isVideoFile(file.upload_file.url) == 'audio') ?
                                            <><audio controls> <source src={file.upload_file.url} /> </audio>  </> :
                                            "Not Suported"
                                  }
                                  <button onClick={() => downloadFileWithLink(file.upload_file.url)}>Download</button>
                                </div>
:""}

                                {/* {file.upload_file && file.upload_file.url && isVideoFile(file.upload_file.url) == "pdf" && (
                                      <Button
                                        className=""
                                        onClick={() =>
                                          window.open(file.upload_file.url, "_blank")
                                        }
                                      >
                                        Open PDF
                                        <img
                                          className="endiconimg"
                                          src="https://all-frontend-assets.s3.amazonaws.com/wound-care-for-home_Assets/DashBoard_bg/IWC_PDF_logo.png"
                                        />
                                      </Button>
                                    )}
                                    {file.upload_file && file.upload_file.url && isVideoFile(file.upload_file.url) == 'video' && (
                                      <Button
                                        className=""
                                        onClick={() => openVideoModal(file.upload_file.url, "video")}
                                      >
                                        Open Video
                                      </Button>
                                    )}
                                    {file.upload_file && file.upload_file.url && isVideoFile(file.upload_file.url) == 'image' && (
                                      <Button
                                        className=""
                                        onClick={() => openVideoModal(file.upload_file.url, "image")}
                                      >
                                        Open Image
                                      </Button>
                                    )} */}
                              </>
                            </div>
                          ))
                          : ""}
                      </span>
                    </li>
                  ) : (
                    ""
                  )}

                  {props.popupdata?.status == 1 ? (
                    <li>
                      {" "}
                      <strong>Status:</strong> <span>Active</span>
                    </li>
                  ) : (
                    <li>
                      {" "}
                      <strong>Status:</strong> <span>Inactive</span>
                    </li>
                  )}

                  {props.popupdata?.createdon_datetime ? (
                    <li>
                      {" "}
                      <strong> Added On :</strong>{" "}
                      <span>
                        {timeConverter({
                          time: props.popupdata?.createdon_datetime,
                          format: "DD/MM/YYYY hh:mm A",
                        })}
                      </span>
                    </li>
                  ) : (
                    ""
                  )}
                </ul>
              }
            </div>
          ) : props.type === "eventInfo" ? (
            <div className=" ">
              <div className='heading'>
                <h2>Event Details </h2>
              </div>
              {<ul className='listflexblock'>
                {props.popupdata?.event_title ? (<li> <strong>Event Title :</strong> <span>{props.popupdata.event_title}</span></li>) : ""}
                {props.popupdata?.description ? (<li> <strong>Description :</strong> <span>{props.popupdata.description.replace(/(<([^>]+)>)/ig, '')}</span></li>) : ""}
                {props.popupdata?.start_date_unix ? (<li> <strong>Start Date :</strong> <span>{(timeConverter({ time: props.popupdata.start_date_unix, format: "MM/DD/YY " }))}</span></li>) : ""}
                {props.popupdata?.end_date_unix ? (<li> <strong>End Date :</strong> <span>{(timeConverter({ time: props.popupdata.end_date_unix, format: "MM/DD/YY " }))}</span></li>) : ""}
                {props.popupdata?.start_time ? (<li> <strong>Start Time :</strong> <span>{props.popupdata.start_time}</span></li>) : ""}
                {props.popupdata?.end_time ? (<li> <strong>End Time :</strong> <span>{props.popupdata.end_time}</span></li>) : ""}
                {props.popupdata?.timezone ? (<li> <strong>Timezone :</strong> <span>{props.popupdata.timezone}</span></li>) : ""}
                {props.popupdata?.timespanstr ? (<li> <strong>Time span :</strong> <span>{props.popupdata.timespanstr}</span></li>) : ""}
                <li> <strong>Week Day :</strong> <span>{(props.popupdata?.mon ? "Monday, " : '') + (props.popupdata?.tues ? "Tuesday, " : '') + (props.popupdata?.wed ? "Wednesday, " : '') + (props.popupdata?.thurs ? "Thursday, " : '') + (props.popupdata?.fri ? "Friday, " : '') + (props.popupdata?.sat ? "Saturday " : '')}</span></li>
              </ul>}
            </div>
          ) : props.type === "lessonQuestionInfo" ? (
            <div className=" ">
              <div className='heading'>
                <h2>Question Details </h2>
              </div>
              <ul className='listflexblock'>
                {/* <Carousel autoPlay interval="5000" infiniteLoop> */}
                <Carousel >
                  {props.popupdata.map((question, index) =>
                    <div className='divul' key={index}>
                      {question.question ? (<li> <strong>Question :</strong> <span>{question.question}</span></li>) : ""}
                      {question.training_name ? (<li> <strong>Training Name :</strong> <span>{question.training_name}</span></li>) : ""}
                      {question.allAnswers ? (<li> <strong>All Answers :</strong> <span dangerouslySetInnerHTML={{ __html: question.allAnswers }}></span></li>) : ""}
                      {question.showAnswers ? (<li> <strong>Correct Answer :</strong> <span dangerouslySetInnerHTML={{ __html: question.showAnswers }}></span></li>) : ""}
                      {question.priority ? (<li> <strong>Priority :</strong> <span>{question.priority}</span></li>) : ""}
                    </div>
                  )}</Carousel>
              </ul>
            </div>
          ) : ""}


          <Tooltip title="Close" placement="right-start">
            <IconButton className="modalCloseBtn" onClick={ModalClose}>
              <CloseIcon />
            </IconButton>
          </Tooltip>


        </Box>
      </Modal>
    </>
  );
};

export default PopupModal;
