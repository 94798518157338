import {
  Button,
  Drawer,
  IconButton,
  LinearProgress,
  Modal,
} from "@mui/material";
import React, { useEffect } from "react";
import CancelIcon from "@mui/icons-material/Cancel";
import { Box } from "@mui/system";
import CloseIcon from "@mui/icons-material/Close";
import store from "../../../store";
import { useDispatch, useSelector } from "react-redux";
import { setSnackbar } from "../../Layout/layoutReducer";
import ReactPlayer from "react-player";

const InformationSentOption = ({
  close,
  open,
  data,
  loading,
  addedById,
  addedForId,
  submistionstate,
}) => {
  const dispatch = useDispatch(); // ---- Dispatch -----
  console.log("dataInfo", data);

  const [openMoadl, setOpenMoadl] = React.useState(false);
  const [moadlData, setMoadlData] = React.useState(null);

  useEffect(() => {
    console.log("submistionstate", submistionstate);
    if (submistionstate === 2) {
      setOpenMoadl(false);
      store.dispatch(
        setSnackbar({
          open: true,
          message: "Sucessfully send Information Link",
          status: "sucess",
        })
      );
    }

    return () => {
      //   store.dispatch(reSetSubmistionstate());
    };
  }, [submistionstate]);

  const openModal = (data) => {
    console.log("data123", data);
    setOpenMoadl(true);
    setMoadlData(data);
  };
  const closeModal = () => {
    setOpenMoadl(false);
  };

  const sendInformation = (id) => {
    let body = {
      added_for: addedForId,
      added_by: addedById,
      template_id: id,
    };

    // store.dispatch(sendMailForUser(body));
  };

  return (
    <>
      <Drawer anchor="bottom" open={open} className="contractdrawer">
        <div className="drawer-content">
          <div className="heading">
            <h2> CHOOSE INFORMATION LINK</h2>
          </div>

          <div className="btn-grp">
            {
              //   props.contractdata.name? (<li> </li>):""
              data?.map((data) => (
                <Button
                  variant="contained"
                  className="conts"
                  id={data._id}
                  onClick={() => openModal(data)}
                >
                  {data.title}
                </Button>
              ))
            }
          </div>

          <CancelIcon
            onClick={() => {
              close();
              //   dispatch(clearEmailSlagedata());
            }}
            className="drawerCloseIcon"
          />
        </div>
      </Drawer>

      <div>
        <Modal
          classname="image_card_modal modalBaseStyle"
          open={openMoadl}
          onClose={closeModal}
          closeAfterTransition
        >
          <Box className="modalBaseBox largeModal">
            <IconButton className="modal_icon_button" onClick={closeModal}>
              <CloseIcon className="modal_close_icon" />
            </IconButton>
            {loading && <LinearProgress />}

            <div className="heading">
              <h2>Information Links</h2>
            </div>

            <div>
              {moadlData?.file_link.map((data) => {
                console.log("datanew", data);
                return (
                  <div>
                    {data.type == "video-youtube" &&
                     <div className='player-wrapper'>
                     <ReactPlayer
                       className='react-player'
                       url={`https://www.youtube.com/watch?v=${data?.youtube.id}`}
                       width='100%'
                       height='100%'
                       controls={true}
                       light={data?.youtube.thumbnail_link}
     
                     // playing={play}
                     />


                     {/* <iframe src={`https://www.youtube.com/embed/${data?.youtube.id}`}></iframe> */}
                   </div>}
                  </div>
                );
              })}
            </div>

            <div className="btn-grp">
              <Button
                variant="contained"
                onClick={() => sendInformation(moadlData._id)}
              >
                {" "}
                SEND EMAIL
              </Button>
            </div>
          </Box>
        </Modal>
      </div>
    </>
  );
};

export default InformationSentOption;
