import { Button, Dialog, DialogActions, DialogTitle, Icon, IconButton, LinearProgress } from '@mui/material'
import { cloneDeep } from 'lodash';
import React from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { onSubmittingApi } from '../listReducer';

function APIDialog({ open, close, header, title, onNo, buttonData, row, tableId, mainClass }) {
  const dispatch = useDispatch();
  const loading = useSelector((state) => state.tableSlice.apiBtnSubmissionState[tableId] ? state.tableSlice.apiBtnSubmissionState[tableId] : 0)

  const base_url = buttonData?.api_data?.base_url ? buttonData.api_data.base_url : "not_available"
  const endpoint = buttonData?.api_data?.endpoint ? buttonData.api_data.endpoint : "not_available"
  const body = buttonData?.api_data?.body ? cloneDeep(buttonData.api_data.body) : {}



  const onSubmit = () => {
    body.id = Array.isArray(row) ? row : row._id

    if (buttonData.category === 'status') body.status = 1
    dispatch(onSubmittingApi({ BASE_URL: base_url, endPoint: endpoint, reqBody: body, tableId, rowId: Array.isArray(row) ? row : (row._id ? row._id : "") }))
  }

  const onReject = () => {
    if (buttonData.category === 'modal') {
      onNo();
      return;
    }
    body.id = Array.isArray(row) ? row : (row._id ? row._id : "")
    body.status = 0
    dispatch(onSubmittingApi({ BASE_URL: base_url, endPoint: endpoint, reqBody: body, tableId, rowId: Array.isArray(row) ? row : (row._id ? row._id : "") }))
  }

  return (
    <Dialog open={open} onClose={() => !loading && close()} className={`notes_details ${mainClass}`}>
      {header && <DialogTitle className='notes_main_header' dangerouslySetInnerHTML={{ __html: header }} component="div"></DialogTitle>}

      <IconButton title='Close' className='close_icon_btn' onClick={onNo}><Icon>close</Icon></IconButton>
      <DialogTitle className='notes_header' dangerouslySetInnerHTML={{ __html: title }} component="div"></DialogTitle>
      <DialogActions className='action_btn_container'>
        <Button
          className='btn submit_btn'
          title={(buttonData?.btn_label && Array.isArray(buttonData.btn_label) && buttonData.btn_label[0]) ? buttonData.btn_label[0] : 'Yes'}
          disabled={loading === 1}
          onClick={onSubmit}
        >
          {(buttonData?.btn_label && Array.isArray(buttonData.btn_label) && buttonData.btn_label[0]) ? buttonData.btn_label[0] : 'Yes'}
        </Button>
        <Button
          className='btn close_btn'
          disabled={loading === 1}
          onClick={onReject}
        >
          {(buttonData?.btn_label && Array.isArray(buttonData.btn_label) && buttonData.btn_label[1]) ? buttonData.btn_label[1] : 'No'}
        </Button>
      </DialogActions>
      {loading === 1 && <LinearProgress className='progress_bar' />}
    </Dialog>
  )
}

export default APIDialog