
import { Button } from "@mui/material";
import { useEffect, useState } from "react";
import { Cookies, useCookies } from "react-cookie";
import { useSelector } from "react-redux";
import '../Calender.css'
import { BookingList } from "./BookingList";
import { CalendarAllTabs } from "../CalendarAllTabs";
import { ConflictingSlots } from "./ConflictingSlots";
import { ConflictFullDayEvents } from "./ConflictFullDayEvents";
import { RedundantEventArray } from "./RedundantEventArray";
import { RedundantSlotList } from "./RedundantSlotList";
import { EventdayarrNotCreated } from "./EventdayarrNotCreated";
import { UsersSlotForSixMonthList } from "./UsersSlotForSixMonthList";
import { EventSlotNotCreated } from "./EventSlotNotCreated";

export const Miscellaneous = () => {

    const cookies = new Cookies();
    const getcookies = cookies.getAll();

    const [connectgmailaccount, setconnectgmailaccount] = useState(getcookies.userInfo?.connected_gmail);
    const [googlecaledersynctime, setgooglecaledersynctime] = useState(getcookies.userInfo?.google_calendar_connect_time);

    const [bookingListFetchedStatus, setBookingListFetchedStatus] = useState(false);
    const [conflictingSlotFetchedStatus, setConflictingSlotFetchedStatus] = useState(false);
    const [conflictingFulldayEventFetchedStatus, setConflictingFulldayEventFetchedStatus] = useState(false);
    const [redundantEventArrayFetchedStatus, setRedundantEventArrayFetchedStatus] = useState(false);
    const [redundantSlotListFetchedStatus, setRedundantSlotListFetchedStatus] = useState(false);
    const [eventdayarrNotCreatedListFetchedStatus, setEventdayarrNotCreatedListFetchedStatus] = useState(false);
    const [usersSlotForSixMonthListFetchedStatus, setUsersSlotForSixMonthListFetchedStatus] = useState(false);


    const bookingListFetching = (event) => {
        console.log("event", event)
        if (event !== undefined && event !== null) {
            setBookingListFetchedStatus(event);
        }
    }

    const conflictingSlotFetching = (event) => {
        console.log("event", event)
        if (event !== undefined && event !== null) {
            setConflictingSlotFetchedStatus(event);
        }
    }
    const conflictingFulldayEventFetching = (event) => {
        console.log("event", event)
        if (event !== undefined && event !== null) {
            setConflictingFulldayEventFetchedStatus(event);
        }
    }
    const redundantEventArrayFetching = (event) => {
        console.log("event", event)
        if (event !== undefined && event !== null) {
            setRedundantEventArrayFetchedStatus(event);
        }
    }
    const redundantSlotListFetching = (event) => {
        console.log("event", event)
        if (event !== undefined && event !== null) {
            setRedundantSlotListFetchedStatus(event);
        }
    }

    const eventdayarrNotCreatedListFetching = (event) => {
        console.log("event", event)
        if (event !== undefined && event !== null) {
            setEventdayarrNotCreatedListFetchedStatus(event);
        }
    }
    const usersSlotForSixMonthListFetching = (event) => {
        console.log("event", event)
        if (event !== undefined && event !== null) {
            setUsersSlotForSixMonthListFetchedStatus(event);
        }
    }

   

    return (
        <>

            {/* <div className="mainContentWrapper form"> */}
                {/* <div className="ContentWrapper"> */}
                    {/* <div className="Formdiv"> */}
                        {/* Login Form Start Here */}
                        <div className="rolelist_mainwrp">
                            <div className="listing_heading">
                                <h1>Calendar Report</h1>
                            </div>
                            {/* connected email section */}                            
                            {/* <CalendarAllTabs from={'misc'} />
                            <br /> */}
                            <BookingList bookingListFetched={bookingListFetching} />
                            <br />
                            {bookingListFetchedStatus ?
                            <ConflictingSlots conflictingSlotFetched={conflictingSlotFetching} /> : ''
                            }
                            <br />
                            {conflictingSlotFetchedStatus ?
                                <ConflictFullDayEvents conflictingFulldayEventFetched={conflictingFulldayEventFetching} /> : ''}
                            <br />
                            {conflictingFulldayEventFetchedStatus ?
                                <RedundantEventArray redundantEventArrayFetched={redundantEventArrayFetching} /> : ''}
                            <br />
                            {redundantEventArrayFetchedStatus ?
                                <RedundantSlotList redundantSlotListFetched={redundantSlotListFetching} /> : ""}
                            <br />
                            {redundantSlotListFetchedStatus ?
                                <EventdayarrNotCreated eventdayarrNotCreatedListFetched={eventdayarrNotCreatedListFetching} /> : ""}
                            <br />
                            {eventdayarrNotCreatedListFetchedStatus ?
                                <UsersSlotForSixMonthList usersSlotForSixMonthListFetched={usersSlotForSixMonthListFetching} /> : ""
                            }
                            <br />
                            {usersSlotForSixMonthListFetchedStatus ?
                                <EventSlotNotCreated /> : ""
                            }
                        </div>
                    {/* </div> */}
                {/* </div> */}
            {/* </div> */}

        </>
    )
}