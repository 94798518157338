import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";




// -------------------Data Fetch For Information listing Page------//

export const getInformationListData = createAsyncThunk("getInformationList", async (reqBody) => {

    console.log("reqbody==>", reqBody);

    if (!reqBody) {

        reqBody = {
            "condition": {
                "limit": 5,
                "skip": 0
            },
            "sort": {
                "field": "createdon_datetime",
                "type": "desc"
            },
            "searchcondition": {
                
            },
            "project": {},
            "token": "",
            "count": false
        }
    }


    let req_body = {};
    req_body = { ...reqBody };
    console.log("req_body==", req_body);
    const requestOptions = {
        method: 'post',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify(req_body)
    };
    console.log("process.env==>", process.env);
    const response = await fetch(process.env.REACT_APP_API_URL + "info-link/info-link-list-count", requestOptions);
    const respdata = await response.json();
    // console.log("respdata after endpoint fetch==>", respdata);
    return respdata;


})

//----------------------Data fetch for single data and Edit Time--------------//

export const getInformationListEdit = createAsyncThunk("getInformationListEdit", async (id) => {
    let reqBody = {
        "condition": {
            "limit": 10,
            "skip": 0
        },
        "sort": {
            "field": "name",
            "type": "asc"
        },
        "searchcondition": { "_id": id },
        "project": {},
        "token": ""
    }
    console.log("reqbody==>", reqBody);
    let req_body = {};
    req_body = { ...reqBody };
    console.log("req_body==", req_body);
    const requestOptions = {
        method: 'post',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify(req_body)
    };
    console.log("process.env==>", process.env);
    const response = await fetch(process.env.REACT_APP_API_URL + "info-link/info-link-list-single-fetch", requestOptions);
    const respdata = await response.json();
    // console.log("respdata after endpoint fetch==>", respdata);
    return respdata;


})


// ---------------------for add update information data end point-----------------------//

export const getInformationAddUpdate = createAsyncThunk("getInformationAddUpdate", async (reqBody) => {

    console.log("reqbody==>", reqBody);

    if (!reqBody) {

        reqBody = {
            "condition": {
                "limit": 5,
                "skip": 0
            },
            "sort": {
                "field": "createdon_datetime",
                "type": "desc"
            },
            "searchcondition": {
                
            },
            "project": {},
            "token": "",
            "count": false
        }
    }


    let req_body = {};
    req_body = { ...reqBody };
    console.log("req_body==", req_body);
    const requestOptions = {
        method: 'post',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify(req_body)
    };
    console.log("process.env==>", process.env);
    const response = await fetch(process.env.REACT_APP_API_URL + "info-link/add-update-info-link", requestOptions);
    const respdata = await response.json();
    // console.log("respdata after endpoint fetch==>", respdata);
    return respdata;

})










const informationReducer = createSlice({
    name:"informationReducer",
    initialState:{
        submitting: false,
        loading: false,
        editloading: false,
        informationlistdata: [],
        editinformationdata:null,
        message: null,
    },

    reducers:{
        clearinformationlistdata(state, action) {
            state.informationlistdata = []
        },

        cleareditdata(state, action) {
            state.editinformationdata = null
        }
    },

    extraReducers:{
        // -------------for information list Data-----------//
        [getInformationListData.pending]: (state, action) => {
            console.log("getroledata=========================>")
            state.loading = true;
        },


        [getInformationListData.fulfilled]: (state, action) => {

            console.log("action from storedata", action)
            if (action.payload.status === 'success') {

                state.informationlistdata = action.payload.results.res;
                state.loading = false;

            }

        },

        [getInformationListData.rejected]: (state, action) => {
            state.endpointsuccess[action.type] = false
            // state.loading = false;
            state.successAction = false;

        },




        // --------------------------For Information Edit Data-----------------//

        [getInformationListEdit.pending]: (state, action) => {
            console.log("getroleeditdata=========================>")
             state.editloading = true;
         },
         [getInformationListEdit.fulfilled]: (state, action) => {
 
             console.log("action from storedata", action)
             if (action.payload.status === 'success') {
 
                 state.editinformationdata = action.payload.results.res;
                 state.editloading = false;
 
             }
 
         },
         [getInformationListEdit.rejected]: (state, action) => {
             state.endpointsuccess[action.type] = false
             // state.loading = false;
             // state.successAction = false;
 
         },

          // --------------------------For Information Add Update Data-----------------//

        [getInformationAddUpdate.pending]: (state, action) => {
            console.log("getroleeditdata=========================>")
             state.loading = true;
         },
         [getInformationAddUpdate.fulfilled]: (state, action) => {
 
             console.log("action from storedata", action)
             if (action.payload.status === 'success') {
 
                 state.editinformationdata = [action.payload.results.res];
                 state.loading = false;
 
             }
 
         },
         [getInformationAddUpdate.rejected]: (state, action) => {
             state.endpointsuccess[action.type] = false
             // state.loading = false;
             // state.successAction = false;
 
         },







    }


    

})

export default informationReducer.reducer;
export const {clearinformationlistdata,cleareditdata } = informationReducer.actions;