import { TextField } from '@mui/material';
import React, { useEffect } from 'react'

export const HiddenField = ({ fielddata, setValue }) => {
  useEffect(() => {
    if (fielddata.defaultValue)
      setValue(fielddata.name, fielddata.defaultValue);
  }, [])
  return (
    <div>
      <TextField
        sx={{ display: "none" }}
        value={fielddata.defaultValue}
      />
    </div>
  )
}
