import { Alert, Avatar, Box, Button, FormControl, Grid, Icon, IconButton, InputAdornment, LinearProgress, Paper, Snackbar, TextField, Tooltip, Typography } from '@mui/material'
import React, { useEffect, useState } from 'react'
import PersonIcon from '@mui/icons-material/Person';
import { Controller, useForm } from 'react-hook-form';
import { Visibility, VisibilityOff, AccountCircle } from '@mui/icons-material';
import { useDispatch, useSelector } from 'react-redux';
import { clearMessageAfterLoggedIn, clearMessageIsLoggedIn, getipInfo, login } from './loginReducer';
// import styles from './Login.module.css'
import { Link, Navigate, useLocation, useNavigate } from 'react-router-dom';
import store from '../../store';
import { routeHelper } from '../../helper/helperFunctions';
import { getdataforusernext } from './loginReducer';
import { useCookies } from 'react-cookie';
import OtherHousesIcon from '@mui/icons-material/OtherHouses';
// import { getPatientDetails } from '../AdminDashboardBackend/adminDashboardReducer';
// store.dispatch(getipInfo());

import "../login/login.css"
import { Form } from '../../form/Form';
import { resetForm } from '../../form/formReducer';

function ResetPassword() {

  const [userInfo, setuserInfoCookie] = useCookies(["userInfo"]);
  const [token, setUserTokenCookie] = useCookies(["token"]);
  const [isdisabled, setIsdisabled] = useCookies(false);
  const [lastLoginTime, setLastLoginTimeCookie] = useCookies(["lastLoginTime"]);
  const [queryParamId, setQueryParamId] = useState();  //*****FOR Seeting QueryP****//

  const dispatch = useDispatch();
  const navigate = useNavigate();
  //   /////////////////////////////// Compoenent States /////////////////////////////////////
  const [showPassword, setShowPassword] = useState(false)
  const [open, setOpen] = useState(false);
  const [loader, setLoader] = useState(false);
  const [wrongPassword, setWrongPassword] = useState(false);
  const [errorMessages, setErrorMessages] = useState({
    required: 'This Field is Required',
    validate: "Please Enter Valid Email",
    pattern: "Invalid Email Address"
  })



  const message = useSelector(state => state.formSlice?.message?.passwordUpdateForm ? state.formSlice.message.passwordUpdateForm : null)
  // const loading = useSelector(state => state.formSlice?.loading ? state.formSlice.loading : false)
  // const loading0 = useSelector(state => state.userDashboardKey?.loading ? state.userDashboardKey.loading : false)
  const cancelBtn = useSelector(state => state.formSlice?.customButtonClicked?.passwordUpdateForm?.cancel001 ? state.formSlice.customButtonClicked.passwordUpdateForm.cancel001 : false)

  const formSubmissionState = useSelector(state => state.formSlice?.formSubmissionState?.passwordUpdateForm ? state.formSlice.formSubmissionState?.passwordUpdateForm : null);

  //   /////////////////////////////// Hook Form /////////////////////////////////////////////
  const { handleSubmit, control, watch, formState: { errors }, clearErrors, setValue } = useForm();


  // -------- Getting QueryParam ---------

  const queryParam = useLocation();
  const paramId = queryParam.search.split("string=");

  useEffect(() => {
    if (paramId[1]) {
      setQueryParamId(paramId[1])
    }
  }, [paramId])
  // --------------------------------------------------



  useEffect(() => {
    if (cancelBtn) {
      navigate('/login');
    }
  }, [cancelBtn])





  //////////////////// Listener For form submission ///////////////////////


  useEffect(() => {

    if (formSubmissionState === 1) {
      setLoader(true)
    } else if (formSubmissionState === 2) {
      setLoader(false)
      dispatch(resetForm({ formId: "passwordUpdateForm" }));
      setOpen(true);

      setTimeout(() => {
        navigate('/login');
      }, 2000);
    } else if (formSubmissionState === 3) {
      setLoader(false)

    }
  }, [formSubmissionState]);

  // ---------------------------------------------------------------------------



  const dataform = {
    id: "passwordUpdateForm",
    api_url: process.env.REACT_APP_API_URL,
    endPoint: "profile/forgot-change-password",
    // endPointBodyWrapper: "data",
    submitBtnName: "Update",
    formButtonClass: "resatbuttonwrapper",

    formAdditionalSubmissionData: {
      password_identifier: queryParamId ? queryParamId : undefined
    },

    fields: [

      {
        id: 14,
        // heading: "Password",
        label: "New Password",
        name: "new_password",
        className: "formGroup inputBlock1line",
        type: "password",
        //   value: { password },
        // defaultValue: (editdataforform !== undefined && typeof editdataforform?.password !== undefined) ? password : undefined,
        rules: {
          required: true,
          // pattern: /^(?=.*[0-9])(?=.*[!@#$%^&*])[a-zA-Z0-9!@#$%^&*]{7,15}$/,
        },
        errorMessage: {
          required: "This Field is Required",
          custom: "Value is Invalid",
          pattern: "Your password must contain 6 characters with at least one lower case, upper case alphabets and special character",
        },
      },
      {
        id: 12,
        // heading: "Confirm Password",
        label: "Confirm Password",
        name: "confirm_new_password",
        className: "formGroup inputBlock1line",
        type: "password",
        rules: {
          required: true,
          // pattern: /^(?=.*[0-9])(?=.*[!@#$%^&*])[a-zA-Z0-9!@#$%^&*]{7,15}$/,
        },
        errorMessage: {
          required: "This Field is Required",
          errorMessageConfirmPassword: "Password does not match",
          pattern: "Confirm password field value must be matched",
          custom: "Value is Invalid", ////////////////////// Include this for confirm password field //////////
        },
      },
    ],
    customButtons: [
      {
        id: "cancel001",
        label: "Cancel",
        className: "default",
        type: "button",
      },
    ],
  };




  return (
    <div className='loginBody'>

      <img className='logo' src="https://all-frontend-assets.s3.amazonaws.com/aspireLeadFire/logo.webp" />

      <div className='loginBox'>
        <div className='loginBox_body'>


          <h1>
            Reset Password
          </h1>


          <Form formData={dataform} />

          {loader && <LinearProgress sx={{ margin: "5px" }} />}

        </div>
      </div>


      <Snackbar
        className='loginSnack'
        open={open}
        // open={true}
        onClose={() => setOpen(false)}
        anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
        autoHideDuration={2000}
        message={message}
        // message="login sucessfull"
        sx={{ width: '100%' }}
      />


    </div>
  )
}

export default ResetPassword


