import { LinearProgress } from "@mui/material";
import { Box } from "@mui/system";
import { useEffect, useRef, useState } from "react";
import { useSelector } from "react-redux";
import { TableSkeleton } from "../../../helper/TableSkeleton";
import ListingTable from "../../../listing/listing";

export const EventSlotNotCreated = () => {
    const [eventSlotNotCreatedList, setEventSlotNotCreatedList] = useState([]);
    const [listFetchSuccess, setListFetchSuccess] = useState(false);
    const [loaderFlag, setloaderFlag] = useState(true);

    const searchFormData = useSelector((state) => state.formSlice?.formData?.searchForm ? state.formSlice.formData.searchForm : {})


    useEffect(() => {
        eventSlotNotCreatedListObj.current.searchData.formData.fields.forEach((data) => {
            data.defaultValue = searchFormData[data.name] ? searchFormData[data.name] : undefined
            console.log("data1234", data, searchFormData);
        })
        // console.log("searchFormData", searchFormData);
    }, [JSON.stringify(searchFormData)]);

    const eventSlotNotCreatedListObj = useRef({
        tableId: "eventSlotNotCreatedLists",
        tableTitle: "Event Appointment Not Created List",
        showReload: true,
        showFilter: true,
        api: {
            url: process.env.REACT_APP_API_URL,
            endPoint: "calendar-api/event-slot-not-created",
            // deleteSingleUserEndpoint: "api/delete-user",
            tableCountEndpoint: "calendar-api/event-slot-not-created",
        },

        reqBody: {
            "condition": {
                "limit": 10,
                "skip": 0
            },
            "sort": {
                "field": "_id",
                "type": "desc"
            },
            "searchcondition": {
            },
            "count": false
        },
        deleteSingleUserReqBody: {
            source: "users",
            secret: "AZ|lepL`",
            // token: usersToken,
        },
        deleteMultipleReqBody: {
            source: "users",
            secret: "AZ|lepL`",
            // token: usersToken,
        },

        //******************************** By Default Rows per page option will be available**************************//

        rowsPerPageOptions: [5, 10, 20, 30],
        sortFields: ["timezone", "start_datetime_unix", "last_recreated"],
        paginationType: "stackPagination",
        enableRowActionLoader: true,
        customBtnHead: [
            // {
            //     id: "deleteMultiple",
            //     type: "delete_icon",
            //     title: "Delete",
            // },
            // {
            //     id: "editMultiple",
            //     type: "toggle_on_icon",
            //     title: "Status Change",
            // },
            // {
            //     id: "adduser",
            //     type: "add_icon",
            //     title: "ADD USER",
            //     className: "add_user_icon",
            //     default: true,
            // },
        ],
        buttons: [
        ],
        deleteModal: {
            modalClassName: "Delete Modal Deletemodal",
        },
        searchData: {
            heading: "Search Event Appointment Not Created List",
            type: "drawer",
            condition_type: "$or",
            formData: {
                id: 'searchForm',
                formWrapperClass: "formWrapperClass",// Can be undefined default will be formCls
                formInlineCLass: "userForm", // Can be undefined default will be formInline
                formButtonClass: "submitbtnsection",
                submitBtnEndIcon: "search",
                resetBtnEndIcon: "replay",
                resetBtnName: "Refresh",
                submitBtnName: "Search",
                resetForm: true,
                formAdditionalSubmissionData: {},
                fields: [
                    {
                        id: 0,
                        label: "Search By Event Title",
                        name: "event_title",
                        type: "text",
                        className: 'list_search_item countDiv__column--col4 ',
                        sx: { m: 1, minWidth: 300 },
                        payloadFormat: { key: "event_title" },

                    },
                    {
                        id: 1,
                        label: "Timespan",
                        name: "timespan",
                        className: 'list_search_item countDiv__column--col4 ',
                        type: 'text',
                        fullDay: true,
                        sx: { m: 1, width: 300 },
                        field: "timespan",
                        // format: 'dd/MM/yyyy'
                        payloadFormat: { key: "timespan", param: "$lte" }
                    },
                    {
                        id: 2,
                        label: "Start Date Time",
                        name: "start_datetime_unix",
                        className: 'list_search_item countDiv__column--col4 ',
                        type: 'datePicker',
                        fullDay: true,
                        sx: { m: 1, width: 300 },
                        field: "start_datetime_unix",
                        // format: 'dd/MM/yyyy'
                        payloadFormat: { key: "start_datetime_unix", param: "$lte" }
                    },
                    {
                        id: 3,
                        label: "Last Re-created",
                        name: "last_recreated",
                        className: 'list_search_item countDiv__column--col4 ',
                        type: 'text',
                        fullDay: true,
                        sx: { m: 1, width: 300 },
                        field: "last_recreated",
                        // format: 'dd/MM/yyyy'
                        // payloadFormat: { key: "start_datetime_unix", param: "$lte" }
                    },
                ]
            }
        },
        // searchBarData: {
        //     heading: "Search Event Appointment Not Created List",
        //     api: {
        //         url: process.env.REACT_APP_API_URL,
        //         endPoint: "calendar-api/event-slot-not-created",
        //         tableCountEndpoint: "calendar-api/event-slot-not-created",
        //         reqBody: {
        //             // count:true,
        //             // "source": "users",
        //             token: "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJfaWQiOiI2MzYzNTZkNDFjZTE3MzAwMDhiOGFhYTUiLCJzZWNyZXQiOiJ6R3RpMnY1enYiLCJpYXQiOjE2Njc5Njg3MzV9.wzFi1CoHbv9QK5cUHbVgSL6LW8m6fR_BaILcT7OcHBQ",
        //             condition: {
        //                 limit: 5,
        //                 skip: 0,
        //             },
        //             sort: {
        //                 type: "desc",
        //                 field: "_id",
        //             },
        //         },
        //     },

        //     searchSettings: {
        //         datesearch: [
        //             // {
        //             //     id: 0,
        //             //     heading: "Search By Joining Date",
        //             //     startdatelabel: "Start Date",
        //             //     enddatelabel: "End Date",
        //             //     className: "formGroup createdon_datetime countDiv__column--col4",
        //             //     submit: "Search",
        //             //     field: "createdon_datetime",
        //             // },
        //         ],
        //         selectsearch: [
        //             // {
        //             //     id: 1,
        //             //     label: "Search By Timezone",
        //             //     field: "timezone",
        //             //     type: "select",
        //             //     className: "formGroup timezone countDiv__column--col4",
        //             //     values: ["-09:00|America/Anchorage", "-08:00|America/Los_Angeles", "-07:00|America/Phoenix", "-06:00|America/Chicago", "-05:00|America/New_York", "-10:00|Pacific/Honolulu", "+05:30|Asia/Kolkata"],
        //             //     // value: "",
        //             // },


        //         ],
        //         textsearch: [
        //             {
        //                 id: 2,
        //                 // heading: "Search By First Name",
        //                 label: "Search By Event Title ",
        //                 field: "event_title",
        //                 className: "formGroup event_title countDiv__column--col4",
        //                 value: "",
        //             },
        //             // {
        //             //     id: 3,
        //             //     // heading: "Search By First Name",
        //             //     label: "Search By description ",
        //             //     field: "description",
        //             //     className: "formGroup description countDiv__column--col4",
        //             //     value: "",
        //             // },
        //         ],

        //     },
        // },
    });

    var modifyTableHeaders =
        [
            { val: "event_title", name: "Event Title" },
            { val: "timespan", name: "Timespan" },
            { val: "timezone", name: "Timezone" },
            { val: "slot_status", name: "Appointment Status" },
            { val: "start_datetime_unix", name: "Start Date Time", type: "dateTime", format: "DD/MM/YYYY hh:mm A" },
            { val: "last_recreated", name: "Last Re-created", type: "dateTime", format: "DD/MM/YYYY hh:mm A" }

        ];

    const getList = async () => {
        const requestOptions = {
            method: "POST",
            headers: { "Content-type": "application/json" },
            body: JSON.stringify(eventSlotNotCreatedListObj.current.reqBody),
        };
        const response = await fetch(
            process.env.REACT_APP_API_URL + "calendar-api/event-slot-not-created",
            requestOptions
        );
        const respdata = await response.json();
        console.log("++++cv+++++++", respdata);
        if (respdata && respdata.status === "success") {
            setEventSlotNotCreatedList([...respdata.results.res])
        }
        setloaderFlag(false);
        // conflictingSlotFetched(true);
    }

    useEffect(() => {
        if (eventSlotNotCreatedList && eventSlotNotCreatedList.length === 0 && !listFetchSuccess) {
            setListFetchSuccess(true);
            getList()
        }

    }, [eventSlotNotCreatedList]);

    return (
        <>
            {loaderFlag == true ? (
                <Box className="loaderProgress" sx={{ width: "100%", mt: "10px" }}>
                    <LinearProgress />
                </Box>
            ) : (
                ""
            )}

            <div className="reactTableWrapper miscList">
                <div className="rolelist_mainwrp_Table">
                    {!loaderFlag && eventSlotNotCreatedList !== undefined ? (
                        <ListingTable
                            tableData={eventSlotNotCreatedListObj.current}
                            dataset={eventSlotNotCreatedList}
                            modifyHeaders={modifyTableHeaders}
                        />
                    ) : (<TableSkeleton count={5} />)}

                </div>
            </div>
        </>
    )
}