import { createAsyncThunk, createSlice } from "@reduxjs/toolkit"
import moment from "moment";



export const getDataForAerDashboardGridList = createAsyncThunk("Aer-dashboard-grid-list", async (reqBody) => {

    if (!reqBody) {

        reqBody = {
            "condition": {
                "limit": 10,
                "skip": 0
            },
            "sort": {
                "field": "_id",
                "type": "desc"
            },
            "searchcondition": {

            },
            "project": {},
            "token": "",
            "count": false
        }
    }


    console.log("reqbody11==>", reqBody);
    let req_body = {};
    req_body = { ...reqBody };
    console.log("req_body==", req_body);
    const requestOptions = {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify(req_body)
    };
    console.log("process.env==>", process.env);
    const response = await fetch(process.env.REACT_APP_API_URL + "users/aer-dashboard-grid-list", requestOptions);
    const respdata = await response.json();
    // console.log("respdata after endpoint fetch==>", respdata);
    return respdata;


})

export const getDataForAerDashboardChart = createAsyncThunk("Aer-dashboard-chart", async (reqBody) => {

    if (!reqBody) {

        reqBody = {
            "condition": {
                "limit": 10,
                "skip": 0
            },
            "sort": {
                "field": "_id",
                "type": "desc"
            },
            "searchcondition": {

            },
            "project": {},
            "token": "",
            "count": false
        }
    }


    console.log("reqbody11==>", reqBody);
    let req_body = {};
    req_body = { ...reqBody };
    console.log("req_body==", req_body);
    const requestOptions = {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify(req_body)
    };
    console.log("process.env==>", process.env);
    const response = await fetch(process.env.REACT_APP_API_URL + "users/aer-dashboard-chart", requestOptions);
    const respdata = await response.json();
    // console.log("respdata after endpoint fetch==>", respdata);
    return respdata;


})




export const getDataForAerDashboardCard = createAsyncThunk("Aer-dashboard-card", async (reqBody) => {

    if (!reqBody) {

        reqBody = {
            "condition": {
                "limit": 10,
                "skip": 0
            },
            "sort": {
                "field": "_id",
                "type": "desc"
            },
            "searchcondition": {

            },
            "project": {},
            "token": "",
            "count": false
        }
    }


    console.log("reqbody11==>", reqBody);
    let req_body = {};
    req_body = { ...reqBody };
    console.log("req_body==", req_body);
    const requestOptions = {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify(req_body)
    };
    console.log("process.env==>", process.env);
    const response = await fetch(process.env.REACT_APP_API_URL + "users/aer-dashboard-card", requestOptions);
    const respdata = await response.json();
    // console.log("respdata after endpoint fetch==>", respdata);
    return respdata;


})
export const getlandingpageForaer = createAsyncThunk("getlandingpageForaer", async (reqBody) => {

    console.log("reqbody==>", reqBody);

    // if (!reqBody) {

    //     reqBody = {
    //         "condition": {
    //             "limit": 10,
    //             "skip": 0
    //         },
    //         "sort": {
    //             "field": "createdon_datetime",
    //             "type": "desc"
    //         },
    //         "searchcondition": {},
    //         "project": {},
    //         "token": "",
    //         "count": false
    //     }
    // }


    let req_body = {};
    req_body = { ...reqBody };
    console.log("req_body==", req_body);
    const requestOptions = {
        method: 'post',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify(req_body)
    };
    console.log("process.env==>", process.env);
    const response = await fetch(process.env.REACT_APP_API_URL + "landing-page-manage/landing-page-list", requestOptions);
    const respdata = await response.json();
    // console.log("respdata after endpoint fetch==>", respdata);
    return respdata;


})





const AerDashboardReducer = createSlice({
    name: 'AerDashboardReducer',
    initialState: {

        mindate: moment().subtract(7, "days").valueOf(),
        maxDate: moment().valueOf(),

        submitting: false,
        loading: false,
        loaderForAerData: false,
        AerChartData: [],
        AerGridData: [],
        AerCardData: [],
        AerListData: null,
        landingpageData:[]

    },
    reducers: {
        clearAerDashboardData(state, action) {

            state.AerChartData = []
            state.AerGridData = []
            state.AerCardData = []
            state.AerListData = null

        },

        setMinMaxTime(state, action) {

            state.mindate = (moment(action.payload[0]).valueOf())
            state.maxDate = (moment(action.payload[1]).valueOf())
            console.log("action11111", state.mindate, state.maxDate);

        }
    },
    extraReducers: {
        
        [getlandingpageForaer.rejected]: (state, action) => {
            state.endpointsuccess[action.type] = false
            // state.loading = false;
          
        },
        [getlandingpageForaer.pending]: (state, action) => {


            console.log("getlandingpageForaer=========================>")
            state.loading = true;
           


        },
        [getlandingpageForaer.fulfilled]: (state, action) => {

            console.log("action from storedata", action)
            if (action.payload.status === 'success') {

                state.loading = false;
                state.landingpageData = action.payload?.results?.res;

            }

        },

        [getDataForAerDashboardGridList.rejected]: (state, action) => {
            state.endpointsuccess[action.type] = false
            // state.loading = false;
            state.successAction = false;
            state.loaderForAerData = false;

        },
        [getDataForAerDashboardGridList.pending]: (state, action) => {


            console.log("getDataForAerDashboardGridList=========================>")
            state.loading = true;
            state.loaderForAerData = true;


        },
        [getDataForAerDashboardGridList.fulfilled]: (state, action) => {

            console.log("action from storedata", action)
            if (action.payload.status === 'success') {

                state.loading = false;
                state.AerGridData = action.payload.results.grid_data;
                state.AerListData = action.payload.results.list_data;

                ;
                state.loaderForAerData = false;

            }

        },


        [getDataForAerDashboardCard.rejected]: (state, action) => {
            state.endpointsuccess[action.type] = false
            // state.loading = false;
            state.successAction = false;
            state.loaderForAerData = false;

        },
        [getDataForAerDashboardCard.pending]: (state, action) => {


            console.log("getDataForAerDashboardCard=========================>")
            state.loading = true;
            state.loaderForAerData = true;


        },
        [getDataForAerDashboardCard.fulfilled]: (state, action) => {

            console.log("action from storedata", action)
            if (action.payload.status === 'success') {


                state.loading = false;

                state.AerCardData = action.payload.results.card_data;
                state.loaderForAerData = false;

            }

        },


        [getDataForAerDashboardChart.rejected]: (state, action) => {
            state.endpointsuccess[action.type] = false

            state.successAction = false;
            state.loaderForAerData = false;

        },
        [getDataForAerDashboardChart.pending]: (state, action) => {

            state.loaderForAerData = true;


        },
        [getDataForAerDashboardChart.fulfilled]: (state, action) => {

            console.log("action from storedata", action)
            if (action.payload.status === 'success') {
                state.AerChartData = action.payload.results;
                state.loaderForAerData = false;

            }

        },




    }

})

export default AerDashboardReducer.reducer;
export const { clearAerDashboardData, setMinMaxTime } = AerDashboardReducer.actions;

