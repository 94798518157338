import { createAsyncThunk, createSlice } from "@reduxjs/toolkit"





export const getroledata = createAsyncThunk("getroledata", async (reqBody) => {



    
    console.log("reqbody==>", reqBody);
    let req_body = {};
    req_body = { ...reqBody };
    console.log("req_body==", req_body);
    const requestOptions = {
        method: 'post',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify(req_body)
    };
    console.log("process.env==>", process.env);
    const response = await fetch(process.env.REACT_APP_API_URL + "role/roles-list-count", requestOptions);
    const respdata = await response.json();
    // console.log("respdata after endpoint fetch==>", respdata);
    return respdata;


})

export const getroleeditdata = createAsyncThunk("editrole", async (id) => {
    let reqBody={
        "condition": { },
        "sort": {
            "field": "name",
            "type": "asc"
        },
        "searchcondition": {"_id":id},
        "project": {},
        "token": ""
    }
    console.log("reqbody==>", reqBody);
    let req_body = {};
    req_body = { ...reqBody };
    console.log("req_body==", req_body);
    const requestOptions = {
        method: 'post',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify(req_body)
    };
    console.log("process.env==>", process.env);
    const response = await fetch(process.env.REACT_APP_API_URL + "role/fetch-roles", requestOptions);
    const respdata = await response.json();
    // console.log("respdata after endpoint fetch==>", respdata);
    return respdata;


})






const RoleReducer = createSlice({
    name: 'RoleReducer',
    initialState: {

        submitting: false,
        loading: false,
        editloading: false,
        roledata: [],
        editroledata:[],
        testdata: '',


    },
    reducers: {
        changeTestData(state, action) {
            state.testdata = action.payload


        },
        clearroledata(state, action) {
            state.roledata=[]
        },
        cleareditdata(state, action) {
            state.editroledata=[]
        }
        // resetendpointSUccess(state, action) {
        //     state.endpointsuccess= {}
        // },
        // cleartrainingcatagorydata(state, action) {
        //     state.trainingcatagorydata= []
        // }
    },
    extraReducers: {
        /// #################### Training Listing ###############################
        [getroledata.rejected]: (state, action) => {
            state.endpointsuccess[action.type] = false
            // state.loading = false;
            state.successAction = false;

        },
        [getroledata.pending]: (state, action) => {


            console.log("getroledata=========================>")
            state.loading = true;

        },
        [getroledata.fulfilled]: (state, action) => {

            console.log("action from storedata", action)
            if (action.payload.status === 'success') {
            
                state.roledata = action.payload.results.res;
                state.loading = false;

            }

        },
        [getroleeditdata.rejected]: (state, action) => {
            state.endpointsuccess[action.type] = false
            // state.loading = false;
            // state.successAction = false;

        },
        [getroleeditdata.pending]: (state, action) => {


            console.log("getroleeditdata=========================>")
            state.editloading = true;

        },
        [getroleeditdata.fulfilled]: (state, action) => {

            console.log("action from storedata", action)
            if (action.payload.status === 'success') {
            
                state.editroledata = action.payload.results.res;
                state.editloading = false;

            }

        }

    }

})

export default RoleReducer.reducer;
export const { changeTestData,clearroledata,cleareditdata } = RoleReducer.actions;

