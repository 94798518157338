import { Snackbar, Tooltip } from '@mui/material'
import React, { useEffect, useRef, useState } from 'react'
import { useSelector } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import { resolvefunction } from '../../../../helper/helperFunctions'
import { resetresolvenew } from '../../../../helper/ResolveReducer'
import { TableSkeleton } from '../../../../helper/TableSkeleton'
import ListingTable from '../../../../listing/listing'
import store from '../../../../store'
import { clearEmailSlagedata, emailSlugEditdata, getEmailSlagData } from '../EmailTemplateReducer'

const EmailSlug = () => {

  let emailtemplateSlagDataforlist = useSelector(state => (state.EmailTemplateReducer?.emailtemplateSlagData && state.EmailTemplateReducer?.emailtemplateSlagData?.length > 0) ? state.EmailTemplateReducer.emailtemplateSlagData : [])
  let loader = useSelector(state => (state.EmailTemplateReducer?.loading) ? state.EmailTemplateReducer.loading : false)
  const resolved = useSelector(state => state.ResolveReducer.resolved['/email-slug'] ? state.ResolveReducer.resolved['/email-slug'] : false)
  const navigate = useNavigate();
  const [openSnacAddEdit, setOpenSnacAddEdit] = useState(false);
  const editData = useSelector(state => state.tableSlice?.tempRowData?.emailSlagdata ? state.tableSlice.tempRowData.emailSlagdata : {})
  const editClicked = useSelector(state => state.tableSlice?.customButtonClicked?.emailSlagdata?.edittable9 ? state.tableSlice.customButtonClicked.emailSlagdata.edittable9 : false)
  const successMsg = useSelector(state => state.formSlice.message?.EmailSlagAddEditForm)
  const formsubmissionstate = useSelector(state => state.formSlice?.formSubmissionState ? state.formSlice.formSubmissionState : null);



  var modifyTableHeaders =
    [
      { val: "name", name: "Name" },
      { val: "slug_variable", name: "Email Slug Name" },
      { val: "data_base_variable", name: "Email variable Name" },


      //    { val: "description", name: "Description" },
      { val: "status", name: "Status", customVal: { 0: 'Inactive', 1: "Active" } },
      // { val: "createdon_datetime", name: "Created on", type: "datetime", format: "DD/MM/YYYY" },
      { val: "updatedon_datetime", name: "Updated on", type: "datetime", format: "DD/MM/YYYY" },


    ];

  let tableDataContractSlag = useRef({
    tableId: "emailSlagdata",
    tableTitle: "Email Slug List",
    showReload: true,
    showFilter: true,
    // adduser: true,
    api: {
      url: process.env.REACT_APP_API_URL,
      endPoint: "email-slug/email-slug-list-count",
      deleteSingleUserEndpoint: "",
      tableCountEndpoint: "email-slug/email-slug-list-count",
    },

    reqBody: {
      "condition": {
        "limit": 10,
        "skip": 0
      },
      "sort": {
        "field": "updatedon_datetime",
        "type": "desc"
      },
      "searchcondition": {},
      "project": {},
      "token": "",
      "count": false
    },
    deleteSingleUserReqBody: {
      source: "users",
      secret: "AZ|lepL`",

    },
    deleteMultipleReqBody: {
      source: "users",
      secret: "AZ|lepL`",

    },

    //******************************** By Default Rows per page option will be available**************************//

    rowsPerPageOptions: [5, 10, 20, 30],
    sortFields: ["name", "status", "slug_variable", "data_base_variable", "updatedon_datetime"],
    paginationType: "stackPagination",
    enableRowActionLoader: true,
    customBtnHead: [

      {
        id: "delete",
        label: "Multi Delete",
        type: "api",
        category: "modal",
        icon_type: "delete",
        className: "delete_data",
        modalClassName: "delete_modal",
        modalTitle: "<p>Are you sure that you want to delete this record(s)?</p>",
        modalHeader: "<p> Alert !!</p>",
        btn_label: ['Yes', "No"],
        api_data: {
          base_url: process.env.REACT_APP_API_URL,
          endpoint: "email-slug/delete-email-slug",
          body: {
            "source": "users",
            "secret": "AZ|lepL`",
            "token": "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJmb28iOiJiYXIiLCJleHAiOjE2NTIxNjM3MDUsImlhdCI6MTY1MjA3NzMwNX0.3kB4iRr5JbmV81Dl5gKA7fiTaMEEp0xbsWoO-wA0XUo",
          }
        }
      },
      {
        id: "status",
        label: "Status",
        type: "api",
        category: "status",
        icon_type: "toggle_off",
        className: "status_data",
        modalClassName: "delete_modal",
        modalTitle: "<p>Are you sure you want to change the status ?</p>",
        modalHeader: "<p> Alert !!</p>",
        btn_label: ['Active', "Inactive"],
        api_data: {
          base_url: process.env.REACT_APP_API_URL,
          endpoint: "email-slug/email-slug-status-update",
          body: {
            "source": "users",
            "secret": "AZ|lepL`",
            "token": "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJmb28iOiJiYXIiLCJleHAiOjE2NTIxNjM3MDUsImlhdCI6MTY1MjA3NzMwNX0.3kB4iRr5JbmV81Dl5gKA7fiTaMEEp0xbsWoO-wA0XUo",
          }
        }
      },
    ],
    buttons: [



      {
        id: "edittable9",
        type: "other",
        icon_type: "edit_icon",
        label: "Edit",
        name: "edit",
      },

      {
        id: "delete",
        label: "Delete",
        type: "api",
        category: "modal",
        icon_type: "delete",
        className: "delete_data",
        modalClassName: "delete_modal",
        modalHeader: "<p> Alert !!</p>",
        modalTitle: "<p>Are you sure that you want to delete this record(s)?</p>",
        btn_label: ['Yes', "No"],
        api_data: {
          base_url: process.env.REACT_APP_API_URL,
          endpoint: "email-slug/delete-email-slug",
          body: {
            "source": "users",
            "secret": "AZ|lepL`",
            "token": "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJmb28iOiJiYXIiLCJleHAiOjE2NTIxNjM3MDUsImlhdCI6MTY1MjA3NzMwNX0.3kB4iRr5JbmV81Dl5gKA7fiTaMEEp0xbsWoO-wA0XUo",
          }
        }
      },
      {
        id: "status",
        label: "Status",
        type: "api",
        category: "status",
        icon_type: "toggle_off",
        className: "status_data",
        modalClassName: "delete_modal",
        modalHeader: "<p> Alert !!</p>",
        modalTitle: "<p>Are you sure you that want to change the status?</p>",
        btn_label: ['Active', "Inactive"],
        api_data: {
          base_url: process.env.REACT_APP_API_URL,
          endpoint: "email-slug/email-slug-status-update",
          body: {
            "source": "users",
            "secret": "AZ|lepL`",
            "token": "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJmb28iOiJiYXIiLCJleHAiOjE2NTIxNjM3MDUsImlhdCI6MTY1MjA3NzMwNX0.3kB4iRr5JbmV81Dl5gKA7fiTaMEEp0xbsWoO-wA0XUo",
          }
        }
      },


    ],
    deleteModal: {
      modalClassName: "modalblock",
    },

    searchData: {
      heading: "Search Bar",
      type: "drawer",
      // condition_type: "$or",
      formData: {
        id: 'searchForm',
        formWrapperClass: "formWrapperClass",// Can be undefined default will be formCls
        formInlineCLass: "userForm", // Can be undefined default will be formInline
        formButtonClass: "submitbtnsection",
        submitBtnEndIcon: "search",
        resetBtnEndIcon: "replay",
        resetForm: true,
        formAdditionalSubmissionData: {},
        fields: [
          {
            id: 1,
            label: "Search By Name",
            name: "name",
            type: "autoComplete",
            className: 'list_search_item countDiv__column--col6 ',
            base_url: process.env.REACT_APP_API_URL + "email-slug/auto-complete-email-slug-name",
            reqBody: {
              // "formId": 'teamsearch',
              "condition": {
                "limit": 10,
                "skip": 0
              },
              "sort": {
                "type": "asc",
                "field": "name"
              },
              "searchcondition": {

              }
            },
            sx: { m: 1, minWidth: 300 },
            // payloadFormat: { key: "_id", param: "$regex", options: "i" },
            payloadFormat: { key: "name" },

          },
          {
            id: 2,
            label: "Search By Slug Variable",
            name: "slug_variable",
            type: "autoComplete",
            className: 'list_search_item countDiv__column--col6 ',
            base_url: process.env.REACT_APP_API_URL + "email-slug/auto-complete-email-slug",
            reqBody: {
              // "formId": 'teamsearch',
              "condition": {
                "limit": 10,
                "skip": 0
              },
              "sort": {
                "type": "asc",
                "field": "name"
              },
              "searchcondition": {

              }
            },
            sx: { m: 1, minWidth: 300 },
            // payloadFormat: { key: "_id", param: "$regex", options: "i" },
            payloadFormat: { key: "slug_variable" },

          },
          {
            id: 3,
            label: "Search By Database Variable",
            name: "data_base_variable",
            type: "autoComplete",
            className: 'list_search_item countDiv__column--col6 ',
            base_url: process.env.REACT_APP_API_URL + "email-slug/auto-complete-email-slug-database",
            reqBody: {
              // "formId": 'teamsearch',
              "condition": {
                "limit": 10,
                "skip": 0
              },
              "sort": {
                "type": "asc",
                "field": "name"
              },
              "searchcondition": {

              }
            },
            sx: { m: 1, minWidth: 300 },
            // payloadFormat: { key: "_id", param: "$regex", options: "i" },
            payloadFormat: { key: "data_base_variable" },

          },

          {
            id: 4,
            label: "Search by Status",
            name: "status_show",
            type: 'select',
            multiple: false,
            values: [
              { val: 1, name: "Active" },
              { val: 0, name: "Inactive" }
            ],
            sx: { m: 1, minWidth: 120 },
            className: 'list_search_item countDiv__column--col6 ',
            payloadFormat: { key: "status" },
          },
          {
            id: 8,
            //   heading: "Search By Creation Time",
            label: "Search by Created on Start Date",
            name: "start_date",
            className: 'datePicker',
            type: 'datePicker',
            sx: { m: 1, width: 300 },
            className: 'list_search_item countDiv__column--col6 ',
            payloadFormat: { key: "createdon_datetime", param: "$gte" }
          },
          {
            id: 9,
            label: "Search by Created on End Date",
            name: "end_date",
            className: 'list_search_item countDiv__column--col6 ',
            type: 'datePicker',
            fullDay: true,
            sx: { m: 1, width: 300 },
            payloadFormat: { key: "createdon_datetime", param: "$lte" }
          },
          {
            id: 10,
            //   heading: "Search By Creation Time",
            label: "Search by Updated on Start Date",
            name: "start_date_by_update",
            className: 'datePicker',
            type: 'datePicker',
            sx: { m: 1, width: 300 },
            className: 'list_search_item countDiv__column--col6 ',
            payloadFormat: { key: "updatedon_datetime", param: "$gte" }
          },
          {
            id: 11,
            label: "Search by Updated on End Date",
            name: "end_date_by_update",
            className: 'list_search_item countDiv__column--col6 ',
            type: 'datePicker',
            fullDay: true,
            sx: { m: 1, width: 300 },
            payloadFormat: { key: "updatedon_datetime", param: "$lte" }
          },
        ]
      }
    },
  });


  let reqbody = {
    "condition": {
      "limit": 10,
      "skip": 0
    },
    "sort": {
      "field": "updatedon_datetime",
      "type": "desc"
    },
    "searchcondition": {},
    "project": {},
    "token": ""
  }


  useEffect(() => {
    // console.log("RoleList", resolve);
    if (!resolved) store.dispatch(getEmailSlagData(reqbody));

    return () => {

      store.dispatch(clearEmailSlagedata())
      store.dispatch(resetresolvenew('/email-slug'))
    }
  }, [])

  useEffect(() => {
    if (editClicked && Object.keys(editData).length > 0) {
      resolvefunction(navigate, `/email-slug-edit/${editData._id}`, emailSlugEditdata(editData._id))
    }

  }, [editClicked])

  useEffect(() => {
    if (formsubmissionstate.EmailSlagAddEditForm === 2) {
      console.log("formsubmissionstate............", formsubmissionstate)
      setOpenSnacAddEdit(true)
    }
  })


  return (
    <>

      <div className='rolelist_mainwrp'>
        <div className='listing_heading'>

          <h1>Email Slug </h1>
          <Tooltip title="Add Email Slug">
            <button className='addrole_btn' onClick={() => navigate('/email-slug-add')}>+</button>
          </Tooltip>
        </div>

        <div className='rolelist_mainwrp_Table'>

          {!loader ? (
            <ListingTable

              tableData={tableDataContractSlag.current}
              dataset={emailtemplateSlagDataforlist}
              modifyHeaders={modifyTableHeaders}
            />
          ) : (<TableSkeleton count={10} />)}
        </div>
        <Snackbar
          open={openSnacAddEdit}
          onClose={() => setOpenSnacAddEdit(false)}
          autoHideDuration={3000}
          message={successMsg}
        />
      </div> 


    </>
  )
}

export default EmailSlug 