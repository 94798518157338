import { cloneDeep } from "lodash";
import React, { useEffect } from "react";
import { useState } from "react";

import { useDispatch, useSelector } from "react-redux";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { Form } from "../../../../form/Form";
import { resolvefunction } from "../../../../helper/helperFunctions";
import store from "../../../../store";
import { FetchRoles } from "../../userManagement/userList/userListReducer";
import {
  getTagListdata,
  tagAddEditData,
  clearTagEditAddRedicer,
} from "../TagReducer";

const TagAddEdit = () => {
  const [programdataArray, setProgramdataArray] = useState([]);
  const navigate = useNavigate();
  const pathname = useLocation();
  const dispatch = useDispatch();

  const { id } = useParams();
  const editdata = useSelector((state) =>
    state.tagEditAddRedicer?.editTagData &&
    state.tagEditAddRedicer?.editTagData.length > 0
      ? state.tagEditAddRedicer.editTagData[0]
      : null
  );
  const goBackClicked = useSelector((state) =>
    state.formSlice?.customButtonClicked?.TagAddEditForm?.goback001
      ? state.formSlice.customButtonClicked.TagAddEditForm.goback001
      : false
  );
  const resolved = useSelector((state) =>
    state.ResolveReducer?.resolved[`/tag-edit/${id}`]
      ? state.ResolveReducer.resolved[`/tag-edit/${id}`]
      : false
  );

  const getRoles = useSelector((state) =>
    state.userManagment?.fetchRoles &&
    state.userManagment?.fetchRoles.length > 0
      ? state.userManagment.fetchRoles
      : []
  );

  let leadroleObj = { val: "is_lead", name: "Lead" };
  let roleData = cloneDeep(getRoles);
  roleData?.push(leadroleObj);

  let reqbodyfortag = {
    condition: {
      limit: 10,
      skip: 0,
    },
    sort: {
      field: "updatedon_datetime",
      type: "desc",
    },
    searchcondition: {},
    project: {},
    token: "",
  };
  const dataform = {
    id: "TagAddEditForm",
    api_url: process.env.REACT_APP_API_URL,
    endPoint: "tag-management/tag-add-update",

    urlPathOnSuccessfulFormSubmission: "/tag-list",
    // submitBtnName: "Submit",
    submitBtnName: editdata !== null ? "Update" : "Submit",
    cancelBtnName: "Cancel",
    className: "inputBlock inputBlock3line",
    formButtonClass: "Formsubmit_button",
    formWrapperClass: "backend_addform addAdmin",
    formInlineClass: "backendTableInline_addform testwrpr trainingcata",
    formAdditionalSubmissionData: {
      _id: id !== undefined && id !== null ? id : undefined,
    },
    fields: [
      {
        id: 0,
        heading: "Tag Name",
        label: "Tag Name",
        name: "name",

        className: "inputBlock inputBlock3line",
        type: "text",
        rules: { required: true },
        errorMessage: {
          required: "This Field is Required",
        },
        defaultValue:
          editdata &&
          Object.keys(editdata).length > 0 &&
          editdata.name !== undefined
            ? editdata.name
            : undefined,
      },
      {
        id: 8,
        heading: "Roles",
        label: "Roles",
        // label: "Select Program",
        name: "roles",
        className: "inputBlock inputBlock3line",

        type: "select",
        multiple: true,
        checkbox: true,
        values: roleData,
        // rules: { required: true },
        // errorMessage: {
        //   required: "This Field is Required"
        // },
        // sx: { m: 1, minWidth: 120 },
        defaultValue:
          editdata && editdata.roles !== undefined ? editdata.roles : undefined,
      },
      {
        id: 1,
        heading: "Description",
        name: "description",
        placeholder: "Please type here...",
        className: "inputBlock inputBlock1line",
        type: "textarea",
        rules: { required: true },
        errorMessage: {
          required: "This Field is Required",
        },
        defaultValue:
          editdata &&
          Object.keys(editdata).length > 0 &&
          editdata.description !== undefined
            ? editdata.description
            : undefined,
      },
      {
        id: 2,
        // heading: "Status",
        name: "status",
        className: "inputBlock1line statusinput",
        type: "singleCheckbox",
        values: { key: 0, val: "Active" },
        // rules: { required: true },
        // errorMessage: {
        //   required: "This Field is Required"
        // },
        defaultValue:
          editdata &&
          Object.keys(editdata).length > 0 &&
          editdata.status !== undefined
            ? editdata.status
            : undefined,

        //   minLength: "Minimum length should be 15",
        //   custom: "Value is Invalid"
      },
    //   {
    //     id: 3,
    //     // heading: "Lead-only",
    //     label: "Lead-only",
    //     name: "lead_only",
    //     className: "inputBlock inputBlock3line",
    //     type: "singleCheckbox",
    //     values: { key: 0, val: "Lead-only" },
    //     rules: { required: false },
    //     sx: { m: 1, minWidth: 120 },
    //     ddefaultValue:
    //       editdata &&
    //       Object.keys(editdata).length > 0 &&
    //       editdata.status !== undefined
    //         ? editdata.status
    //         : undefined,
    //   },
    ],
    customButtons: [
      {
        id: "goback001",
        label: "Go Back",
        className: "default",
        type: "button",
      },
    ],
  };
  useEffect(() => {
    if (goBackClicked === true) {
      resolvefunction(navigate, "/tag-list", getTagListdata(reqbodyfortag));
    }
  }, [goBackClicked]);

  useEffect(() => {
    if (!resolved) {
      if (
        id !== undefined &&
        pathname !== null &&
        pathname.pathname !== undefined &&
        pathname.pathname.includes("edit")
      ) {
        dispatch(tagAddEditData(id));
      }
    }

    return () => {
      store.dispatch(clearTagEditAddRedicer());
    };
  }, []);

  useEffect(() => {
    store.dispatch(FetchRoles());
  }, []);

  return (
    <>
      <h1 className="page_heading">Tag {id !== undefined ? "Edit" : "Add"} </h1>
      <div className="dataform2_wrapper">
        <div className="adminformbody">
          {id !== undefined &&
          editdata &&
          (editdata.length > 0 || Object.keys(editdata).length > 0) ? (
            <>
              {" "}
              <Form formData={dataform} />
            </>
          ) : (
            !id && <Form formData={dataform} />
          )}
          {/* --- form here -- */}
        </div>
      </div>
    </>
  );
};

export default TagAddEdit;
