import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { createAsyncThunkHelper } from "../helper/helperFunctions";
import { setTableData } from "../listing/listReducer";
import store from "../store";

export const fetchAutocompleteSearchList = createAsyncThunk("search/autoCompleteList", createAsyncThunkHelper);

export const fetchSearchResult = createAsyncThunk("search/result", createAsyncThunkHelper);

const searchSlice = createSlice({
  name: "search",
  initialState: {
    autoCompleteList: {},
    loading: {},
    formSubmissionState: {},
    searchBody: {},
  },
  reducers: {

  },
  extraReducers: {
    /////////////////////////////// Fetch Search Result ////////////////////////////////////////
    [fetchSearchResult.pending]: (state, action) => {
      console.log("Search Pending", action);

    },
    [fetchSearchResult.fulfilled]: (state, action) => {
      console.log("Search Fullfilled", action);
    },
    [fetchSearchResult.rejected]: (state, action) => {
      console.log("Search Rejected", action);
    },
    //////////////////////////// Fetch Auto Complete List /////////////////////////////////////
    [fetchAutocompleteSearchList.pending]: (state, action) => {
      state.loading[action.meta.arg.restResponseAttachment.autoCompleteId] = true;
    },
    [fetchAutocompleteSearchList.fulfilled]: (state, action) => {
      state.loading[action.payload.autoCompleteId] = false;
      console.log(action.payload);
      const initialResult = [];
      const result = action.payload.results.res.reduce((acc, eachResult) => {
        acc.push({ key: eachResult.key, label: eachResult.label });
        return acc;
      }, initialResult);
      state.autoCompleteList[action.payload.autoCompleteId] = result;
    },
    [fetchAutocompleteSearchList.rejected]: (state, action) => {
      state.loading[action.meta.arg.restResponseAttachment.autoCompleteId] = false;
    },
  },
});

export const { } = searchSlice.actions;

export default searchSlice.reducer;
