import { createAsyncThunk, createSlice } from "@reduxjs/toolkit"
import HmDashboardReducer from "./HmDashboardReducer";



export const getHmDashboardListData = createAsyncThunk("getHmDashboardListData",async(reqbody)=>{
    let req_body = {};
    req_body = { ...reqbody };
    const requestOptions = {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify(req_body)
    };
    const response = await fetch(process.env.REACT_APP_API_URL + "users/lead-signup-dashboard-grid-list",requestOptions)
    const respdata = await response.json();
    return respdata;
})
 
const HmDashboardListReducer=createSlice({
    name:"HmDashboardListReducer",
    initialState:{
        listData:[],
        gridData:[],
        listType:null,
        gridListName:"Grid view",
        loader:false
    },
    reducers:{
        cleardashbordListdata(state,action){
            state.listData=[]
            state.gridData=[]
            state.listType=null
        },
        clearGridListName(state,action){
            state.gridListName=null
        }

    },
    extraReducers:{
        [getHmDashboardListData.fulfilled]:(state,action)=>{

        },
        [getHmDashboardListData.pending]:(state,action)=>{
            state.loader = true
        },
        [getHmDashboardListData.fulfilled]:(state,action)=>{
            state.listType = action.meta.arg.type
            if(action.meta.arg.type === "opportunities") state.gridListName = "Opportunities Grid View"
            if(action.meta.arg.type === "training") state.gridListName = "Training Grid View"
            if(action.meta.arg.type === "folder_access_lead") state.gridListName = "Folder Access Lead Grid View"
            if(action.meta.arg.type === "follow_up_call") state.gridListName = "Follow Up Call Grid view"
            if(action.meta.arg.type === "lead_appointment") state.gridListName = " Appointment Grid View"
            state.listData=action.payload.results.list_data
            state.gridData=action.payload.results.grid_data
            state.loader = false
            
        }
    }
})

export default HmDashboardListReducer.reducer
export const {clearHmListData}=HmDashboardListReducer.actions