import { Chip } from '@mui/material'
import React from 'react'
import QuestionMarkIcon from '@mui/icons-material/QuestionMark';
import { format } from 'date-fns';


function ShowTagListMap(props) {


  return (
                    <div className='showTaglistMap'>
                                {props.dataset.map((item) => (
                                    <span className='tagChipwrp'>
                                        <Chip label={item?.tag_name} key={item.tag_slug} variant="outlined" />
                                        <QuestionMarkIcon className='qIcon' />

                                        <span className='chipDesBuble'>
                                            {item?.tag_name} <br />
                                        Added by: {item.added_by_name}<br />
                                        Added for: {item.added_for_name}<br />
                                        Date: {format (item.createdon_datetime, 'dd-MM-yyyy HH:mm')}
                                        </span>
                                    </span>
                                ))}

                    </div>
  )
}

export default ShowTagListMap