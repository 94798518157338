import { useSelector } from "react-redux";
import { LinearProgress } from "@mui/material";
import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import store from "../../../../store";
import {
  editRoundRobinInputs,
  handelproperty,
  handelvalue,
} from "../ManageRoundRobinReducer";
import moment from "moment";
const RoundRobinRow = ({ item }) => {
  const [priority, setPriority] = useState(item.priority ? item.priority : 0);
  const [value, setValue] = useState(item.value ? item.value : 0);
  const [reqBody, setReqbody] = useState(null);
  const [id, setId] = useState("");

  // const [valChang,setValchang]=useState(false)
  // const editLoding = useSelector(state=>state.ManageRoundRodinReducer?.editLoding?state.ManageRoundRodinReducer.editLoding:false)
  // const inputFileldId = useSelector(state=>state.ManageRoundRodinReducer?.id?state.ManageRoundRodinReducer.id:"")

  const handelInput = (e) => {
    if (e.target.name === "priority") {
      setPriority(e.target.value);
      setId(e.target.id);

      setReqbody({
        _id: e.target.id,
        priority: e.target.value,
      });
    }
    if (e.target.name === "value") {
      setValue(e.target.value);
      setId(e.target.id);

      setReqbody({
        _id: e.target.id,
        value: e.target.value,
      });
    }
  };

  useEffect(() => {
    const fetchData = setTimeout(() => {
      if ((reqBody && reqBody.value > 0) || (reqBody && reqBody.priority > 0)) {
        store.dispatch(editRoundRobinInputs(reqBody));
      }
    }, 1000);
    return () => clearTimeout(fetchData);
  }, [priority, value]);
  console.log("item.login_counts======", item.login_counts);

  return (
    <tr>
      <td className="Mrr-user-data" title="user">
        <ul>
          {/* <li>
            <strong>id : -</strong> <p>{item._id}</p>
          </li> */}
          <li>
            <strong>Name : -</strong>{" "}
            <p>{`${item.firstname} ${item.lastname}`}</p>
          </li>
          <li>
            <strong>Email : -</strong> <p>{item.email} </p>{" "}
          </li>
          <li>
            <strong>Contact : -</strong> <p>{item.phone}</p>{" "}
          </li>
          <li>
            <strong>Created On : -</strong> <p> {moment(item.createdon_datetime).format("dddd, MMMM Do YYYY, h:mm a")}</p>{" "}
          </li>
          <li>
            <strong>Updated On : -</strong> <p>{moment(item.updatedon_datetime).format("dddd, MMMM Do YYYY, h:mm a")}</p>{" "}
          </li>
        </ul>
      </td>
      <td className="Mrr-user-data" title="Log In Info">
        <ul>
          <li>
            <strong>Last LogIn : -</strong>{" "}
            <p>
              {moment(item.last_login_time).format(
                "dddd, MMMM Do YYYY, h:mm a"
              )}
            </p>
          </li>
          <li>
            <strong>Log in Count : -</strong>{" "}
            <p>
              {item.login_counts === undefined ||
              item.login_counts === null ||
              item.login_counts === !0
                ? "NA"
                : item.login_counts}
            </p>
          </li>
        </ul>
      </td>
      <td className="roles"  title="Role">
        {item.roles?.hr_manager ? <p>HM</p> : ""}
        {item.roles?.is_bdd ? <p>BDD</p> : ""}
        {item.roles?.is_aer ? <p>AER</p> : ""}
        {item.roles?.is_rep ? <p>REP</p> : ""}
        {item.roles?.is_admin ? <p>ADMIN</p> : ""}
        {item.roles?.tech_support ? <p>TECH SUPPORT</p> : ""}
        {item.roles?.customer_support ? <p>CUSTOMER SUPPOER</p> : ""}
      </td>
      <td className="inputFild" title="Priority" >
        {/* {((id===item._id) && editLoding  && valChang) ? <LinearProgress />:""} */}
        <input
          type="number"
          name="priority"
          min={0}
          id={item._id}
          value={priority && priority !== "0" ? priority : ""}
          onChange={handelInput}
          //   onBlur={fetchData}
        />
      </td>
      <td className="inputFild" title="Value">
        {/* {(((id!==item._id)&&editLoding )&& (value === reqBody?.value)) ? <LinearProgress />:""} */}
        <input
          id={item._id}
          type="number"
          min={0}
          value={value && value !== "0" ? value : ""}
          name="value"
          //   onBlur={fetchData}
          onChange={handelInput}
        />
      </td>
    </tr>
  );
};

export default RoundRobinRow;
