import { Close } from "@mui/icons-material";
import { IconButton, Paper } from "@mui/material";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Form } from "../../form/Form";
import { requestSequencer } from "../../helper/helperFunctions";
import store from "../../store";
import { fetchTableCount, fetchTableList, resetInitPage } from "../listReducer";

function Search({ formData, tableData, close, clickedRowData }) {
  console.log("formData", formData);
  const dispatch = useDispatch();
  const [addedForId, setAddedForId] = useState({}); //***FOR ROW DATA****//

  const formSubmissionState = useSelector((state) =>
    state.formSlice.formSubmissionState[formData.id]
      ? state.formSlice.formSubmissionState[formData.id]
      : 0
  );
  const formDataSet = useSelector((state) =>
    state.formSlice.formData[formData.id]
      ? state.formSlice.formData[formData.id]
      : null
  );


  // --------------------------------------------store.subscribe Section-------------------------------------------- //
  store.subscribe(() => {
    const storeData = store.getState();

    if (
      storeData.tableSlice?.tempRowData[tableData.mainTableId]?._id !=
        undefined ||
      storeData.tableSlice?.tempRowData[tableData.mainTableId]?._id != null
    ) {
      setAddedForId(
        storeData.tableSlice?.tempRowData[tableData.mainTableId]?._id
      );
    }
  });


  useEffect(() => {
    if (formSubmissionState === 4) {
      console.log(
        "formSubmissionState===============================>",
        formSubmissionState,
        formDataSet
      );
      const searchcondition = {};
      Object.keys(formDataSet).forEach((key) => {
        console.log("formDataSet", formDataSet);
        if (formDataSet[key] === undefined) return;
        const fieldData = formData.fields.find((field) => field.name === key);
        console.log("fieldData==================>", fieldData);
        if (
          !fieldData ||
          !fieldData.payloadFormat ||
          !fieldData.payloadFormat.key
        )
          return;

        // Date Range Picker
        if (fieldData.type === "dateRangePicker") {
          searchcondition[fieldData.payloadFormat.key] = {};
          if (formDataSet[key][0])
            searchcondition[fieldData.payloadFormat.key]["$gte"] =
              formDataSet[key][0];
          if (formDataSet[key][1])
            searchcondition[fieldData.payloadFormat.key]["$lte"] =
              formDataSet[key][1];
          return;
        }

        if (!fieldData.payloadFormat.param) {
          searchcondition[fieldData.payloadFormat.key] = formDataSet[key];
          return;
        }

        if (!searchcondition[fieldData.payloadFormat.key])
          searchcondition[fieldData.payloadFormat.key] = {};
        searchcondition[fieldData.payloadFormat.key] = {
          ...searchcondition[fieldData.payloadFormat.key],
          [fieldData.payloadFormat.param]: formDataSet[key],
        };

        if (fieldData.payloadFormat.param === "$regex")
          searchcondition[fieldData.payloadFormat.key]["$options"] = fieldData
            .payloadFormat.options
            ? fieldData.payloadFormat.options
            : "i";
      });
      console.log(
        "searchcondition===========================>",
        tableData.reqBody
      );
      tableData.reqBody.condition.skip = 0;
      console.log("addedForId------->", tableData);
      if (tableData?.mainTableId)
        tableData.reqBody.searchcondition.notes_for = addedForId;

      if (tableData.searchData.condition_type) {
        const cond = { [tableData.searchData.condition_type]: [] };

        Object.keys(searchcondition).forEach((key) => {
          cond[tableData.searchData.condition_type].push({
            [key]: searchcondition[key],
          });
        });
        tableData.reqBody.searchcondition = {
          ...tableData.reqBody.searchcondition,
          ...cond,
        };
      } else {
        tableData.reqBody.searchcondition = {
          ...tableData.reqBody.searchcondition,
          ...searchcondition,
        };
      }

      requestSequencer({
        dispatch: dispatch,
        func: fetchTableList,
        args: {
          BASE_URL: tableData.api.url,
          endPoint: tableData.api.endPoint,
          reqBody: tableData.reqBody,
          restResponseAttachment: { tableId: tableData.tableId },
        },
      });

      if (tableData.api.tableCountEndpoint)
        requestSequencer({
          dispatch: dispatch,
          func: fetchTableCount,
          args: {
            BASE_URL: tableData.api.url,
            endPoint: tableData.api.tableCountEndpoint,
            reqBody: { ...tableData.reqBody, count: true },
            restResponseAttachment: { tableId: tableData.tableId },
          },
        });

      // dispatch(resetForm({ formId: formData.id }))
      if (close) close();
    }
  }, [formSubmissionState]);

  return (
    <Paper elevation={7} className="list_search">
      <div className="searchBarHead DrawerBox">
        {tableData?.searchData?.heading && (
          <h2 className="searchBarHeading">{tableData.searchData.heading}</h2>
        )}
        {tableData?.searchData?.type === "drawer" && (
          <span className="searchBarCloseIcon">
            <IconButton onClick={() => close()}>
              <Close className="closeDrawer" />
            </IconButton>
          </span>
        )}
      </div>

        <Form formData={formData} search={true} tableId={tableData.tableId} />

    </Paper>
  );
}

export default Search;
