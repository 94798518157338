import {
  Drawer,
  IconButton,
  LinearProgress,
  Snackbar,
  Tooltip,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import ManageRoundRobinGrideView from "./Manage Round Robin components/ManageRoundRobinGrideView";
import "./ManageRoundRodin.css";
// import FilterListIcon from "@mui/icons-material/FilterList";
import ReplayIcon from "@mui/icons-material/Replay";
import SearchIcon from '@mui/icons-material/Search';
import { useSelector } from "react-redux";
import SearchRoundRobin from "./Manage Round Robin components/SearchRoundRobin";
import { setSnackbar } from "../../Layout/layoutReducer";
import store from "../../../store";
import { clearSubmitionState } from "./ManageRoundRobinReducer";
const ManageRoundRodin = () => {
  const [openSnacAddEdit, setOpenSnacAddEdit] = useState(false);
  // const [snakmassage, setSnakeMassage] = useState("");

  const [reload, setReload] = useState(false);
  const [openDrawer, setOpenDrawer] = useState(false);

  const editLoading = useSelector((state) =>
    state.ManageRoundRodinReducer?.editLoding
      ? state.ManageRoundRodinReducer.editLoding
      : false
  );
  const loading = useSelector((state) =>
    state.ManageRoundRodinReducer?.loading
      ? state.ManageRoundRodinReducer.loading
      : false
  );
  const submitionState = useSelector((state) =>
    state.ManageRoundRodinReducer?.submitionState
      ? state.ManageRoundRodinReducer.submitionState
      : 0
  );

  const statusCode = useSelector((state) =>
    state.ManageRoundRodinReducer?.statusCode
      ? state.ManageRoundRodinReducer.statusCode
      : null
  );

  const messg = useSelector((state) =>
    state.ManageRoundRodinReducer?.messg
      ? state.ManageRoundRodinReducer.messg
      : null
  );

  useEffect(() => {
    if (submitionState === 3) {
      store.dispatch(
        setSnackbar({ open: true, message: messg, status: statusCode })
      );
      store.dispatch(clearSubmitionState());
    }
  }, [submitionState]);

  const handleCloseDrawer = () => {
    setOpenDrawer(false);
    // setMoadlData(null)
  };


  return (
    <>
      {loading ? <LinearProgress /> : editLoading && <LinearProgress />}
      <div className="rolelist_mainwrp">
        {/* page header  */}
        <div className="listing_heading">
          <h1>Manage Round Robin</h1>
          <div className="srchfld_wrp">
            <Tooltip title="Search">
              <IconButton
                className="filtersearch"
                onClick={() => setOpenDrawer(true)}
              >
                <SearchIcon />
              </IconButton>
            </Tooltip>

            <Tooltip title="Reload">
              <IconButton
                className="reloadsearch"
                onClick={() => setReload(true)}
              >
                <ReplayIcon />
              </IconButton>
            </Tooltip>
          </div>
        </div>

        {/* page grid view list  */}
        <div className="rolelist_mainwrp_Table">
          <ManageRoundRobinGrideView reload={reload} setReload={setReload} />
        </div>
        <Drawer anchor="bottom" open={openDrawer} onClose={handleCloseDrawer}>
            <SearchRoundRobin setOpenDrawer={setOpenDrawer} handleCloseDrawer={handleCloseDrawer} />
        </Drawer>
      </div>
    </>
  );
};

export default ManageRoundRodin;
