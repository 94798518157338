import { LinearProgress } from '@mui/material';
import { Box } from '@mui/system';
import React, { useEffect, useRef, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { Form } from '../../../../form/Form';
import { removeField, setField } from '../../../../form/formReducer';
import { resolvefunction } from '../../../../helper/helperFunctions';
import store from '../../../../store';
import { setSnackbar } from '../../../Layout/layoutReducer';
import { addlandingpageninedata, addlandingpageninedatabluecosde, clearadddsuccessflag, fetchbluecodelead, getlandingpagedata } from '../LandingpageReducer';

function LandingPage9Form() {

  const navigate = useNavigate();

  const dispatch = useDispatch();

  // const [loading, setloading] = useState(false);


  // const [submissionState, setSubmissionState] = useState(0);

  const formInputData = useSelector((state) =>
    state.formSlice.formData.userForm9 ? state.formSlice.formData.userForm9 : null
  );

  const userData = useSelector(state => (state.LandingpagefontReducer?.landingpageoneuserdata && Object.keys(state.LandingpagefontReducer.landingpageoneuserdata).length > 0) ? state.LandingpagefontReducer.landingpageoneuserdata : {});

  const otherRadioButtonClicked = useSelector((state) => (state.formSlice?.formData?.userForm9 && Object.keys(state.formSlice.formData.userForm9)) ? state.formSlice.formData.userForm9 : undefined)

  const submissionState = useSelector(state => (state.formSlice?.formSubmissionState?.userForm9) ? state.formSlice?.formSubmissionState?.userForm9 : 0)
  const loading = useSelector(state => (state.LandingpagefontReducer?.bluecoseloader) ? state.LandingpagefontReducer?.bluecoseloader : false)
  const leadaddeddata = useSelector(state => (state.LandingpagefontReducer?.bluecouseadddata) ? state.LandingpagefontReducer?.bluecouseadddata : null)
  const leadaddeddataFlag = useSelector(state => (state.LandingpagefontReducer?.adddsuccessflag) ? state.LandingpagefontReducer?.adddsuccessflag : false)
  const ipinfo = useSelector(state => (state.loginSlice?.ipInfo && Object.keys(state.loginSlice.ipInfo).length > 0) ? state.loginSlice.ipInfo : null)


  useEffect(() => {

    if (otherRadioButtonClicked && otherRadioButtonClicked.medical_facility_type) {
      if (otherRadioButtonClicked.medical_facility_type && otherRadioButtonClicked.medical_facility_type == 'Other') {

        console.log("otherRadioButtonClicked", otherRadioButtonClicked);


        let field = {

          id: 35,
          // heading: "Other",
          label: "Other",
          name: "other",
          className: 'formfild formfild1',
          // multiple: true,
          type: "text",


        };
        setTimeout(() => dispatch(setField({ formId: "userForm9", after: "medical_facility_type", fieldData: field })), 200);


      } else {
        setTimeout(() => dispatch(removeField({ formId: "userForm9", name: "other" })), 2000)

      }


    }

  }, [JSON.stringify(otherRadioButtonClicked)])



  useEffect(() => {
    if (leadaddeddata && Object.keys(leadaddeddata) && leadaddeddataFlag) {

      store.dispatch(
        setSnackbar({ open: true, message: 'Thankyou for Submiting !!', status: 'success' })
      )
      resolvefunction(navigate, `/thankyou/${leadaddeddata._id}`, fetchbluecodelead(leadaddeddata._id))
      // navigate(`/thankyou/${leadaddeddata._id}`)
      dispatch(clearadddsuccessflag())
    }
  }, [leadaddeddataFlag, JSON.stringify(leadaddeddata)])

  useEffect(() => {
    if (submissionState && submissionState === 4) {
      addapicall(formInputData);
      console.log("formInputData", formInputData, submissionState);
    }
  }, [submissionState]);

  const addapicall = async (val) => {
    console.log("val>>>", val);

    val = { ...val }
    let body = {
      "first_name": val.first_name,
      "last_name": val.last_name,
      "email": val.email,
      "phone": val.phone,
      "prospectbusinessname": val.prospect_business_name,
      "prospectfirstname": val.prospect_first_name,
      "prospectlastname": val.prospect_last_name,
      "prospecttitle": val.prospect_title,
      "prospectemail": val.prospect_email,
      "prospectcontactnumber": val.secondary_phone,
      "medicalfacilitytype": val.medical_facility_type,
      // "facilitytype": val.facility_type,
      "other": val.other,
      "natureofrelationship": val.nature_of_relationship,
      "approxgrossreciept": val.approx_gross_reciepts,
      "createdon_datetime": new Date().getTime(),
      "added_type": 'landingpage',
      "page_id": userData.landing_page_id,
      "page_slug": userData.landing_page_slug,
      "ipinfo":ipinfo,
      "other_info":val.other_info
    }

    if (Object.keys(body).length > 0) {
      console.log("body-----", body);
      // return
      // setloading(true)
      await store.dispatch(addlandingpageninedatabluecosde(body));
      // setActive(false)
      // setloading(false)

    }
  };


  let reqbodyforjobcategory = {
    "page_slug": process.env.REACT_APP_LANDINGPAGE_SLUG,
  }

  useEffect(() => {
    dispatch(getlandingpagedata(reqbodyforjobcategory));
  }, [])

  useEffect(() => {
    console.log("userData", userData)
  }, [userData])

  const formDataL9 = useRef({
    id: 'userForm9',
    formtype: "add",
    // api_url: process.env.REACT_APP_API_URL,
    // endPoint: "landing-page-manage/add-user",
    // endPointBodyWrapper: "data",
    // urlPathOnSuccessfulFormSubmission: "/thankyou/rep",
    msgOnFailedFormSubmission: "Something Went Wrong",
    formWrapperClass: "formWrapperClass",// Can be undefined default will be formCls
    formInlineCLass: "userForm", // Can be undefined default will be formInline
    formButtonClass: "submitbtnsection9",
    submitBtnName: "Submit",
    // submitBtnStartIcon: 'image_search_icon',
    // submitBtnEndIcon: 'search_icon',
    resetBtnName: "Reset",
    formAdditionalSubmissionData: {

      "status": 0,
      // "roles": { is_rep: 1 },

    },
    fields: [
      //////////////////////////////////// Text Input ///////////////////////////////
      {
        id: 0,
        heading: "Your First Name",
        label: " First Name",
        name: "first_name",
        className: 'formfild formfild4',
        type: "text",
        rules: { required: true },
        errorMessage: {
          required: "This Field is Required",
          custom: "Value is Invalid"
        },
      },
      {
        id: 15,
        heading: "Your Last Name",
        label: "Last Name",
        name: "last_name",
        className: 'formfild formfild4',
        type: "text",
        rules: { required: true },
        errorMessage: {
          required: "This Field is Required",
          custom: "Value is Invalid"
        },
      },
      {
        id: 13,
        heading: "Your Email",
        label: "Email",
        name: "email",
        className: 'formfild formfild4',
        type: "text",
        inputType: "email",
        rules: { required: true },
        errorMessage: {
          required: "This Field is Required",
          custom: "Value is Invalid"
        },
      },

      {
        id: 14,
        heading: "Your Contact Number:",
        label: "Contact Number",
        name: "phone",
        className: 'formfild formfild4',
        type: "text",
        inputType: "phone",
        rules: { required: true },
        errorMessage: {
          required: "This Field is Required",
          custom: "Value is Invalid"
        },
      },

      {
        id: 16,
        heading: "Prospect Medical Facility Business Name (Blue Coast Advisor Contact Info)",
        label: "Prospect Medical Facility Business Name (Blue Coast Advisor Contact Info)",
        name: "prospect_business_name",
        className: 'formfild formfild1',
        type: "text",
        rules: { required: true },
        errorMessage: {
          required: "This Field is Required",
          custom: "Value is Invalid"
        },
      },

      {
        id: 21,
        heading: "Prospect Title",
        label: "Prospect Title",
        name: "prospect_title",
        className: 'formfild formfild1',
        type: "text",
        rules: { required: true },
        errorMessage: {
          required: "This Field is Required",
          custom: "Value is Invalid"
        },
      },

      {
        id: 17,
        heading: "Prospect First Name",
        label: "Prospect First Name",
        name: "prospect_first_name",
        className: 'formfild formfild4',
        type: "text",
        rules: { required: true },
        errorMessage: {
          required: "This Field is Required",
          custom: "Value is Invalid"
        },
      },
      {
        id: 18,
        heading: "Prospect Last Name",
        label: "Prospect Last Name",
        name: "prospect_last_name",
        className: 'formfild formfild4',
        type: "text",
        rules: { required: true },
        errorMessage: {
          required: "This Field is Required",
          custom: "Value is Invalid"
        },
      },

      {
        id: 19,
        heading: "Prospect Email",
        label: "Prospect Email",
        name: "prospect_email",
        className: 'formfild formfild4',
        type: "text",
        inputType: "email",
        rules: { required: true },
        errorMessage: {
          required: "This Field is Required",
          custom: "Value is Invalid"
        },
      },

      {
        id: 20,
        heading: "Prospect Contact Number ",
        label: "Prospect Contact Number ",
        name: "secondary_phone",
        className: 'formfild formfild4',
        type: "text",
        inputType: "phone",
        rules: { required: true },
        errorMessage: {
          required: "This Field is Required",
          custom: "Value is Invalid"
        },
      },
      {
        id: 11,
        heading: "Type of medical facility",
        name: "medical_facility_type",
        className: 'form_radio',
        values: [{ key: 'Medical Practice', val: 'Medical Practice' }, { key: 'Surgical Center', val: 'Surgical Center' }, { key: 'Urgent Care', val: 'Urgent Care' }, { key: 'Hospital', val: 'Hospital' }, { key: 'Medical Practice Group', val: 'Medical Practice Group' },{ key: 'Other', val: 'Other' }],
        type: 'radio',
        // hint: 'Yes/No',
        rules: { required: true },
        errorMessage: {
          required: "This Field is Required",
          custom: "Value is Invalid"
        },
        // defaultValue: "No"
      },

      // {
      //   id: 22,
      //   heading: "Type of facility",
      //   name: "facility_type",
      //   className: 'form_radio',
      //   values: [{ key: 'Med Practice', val: 'Med Practice' }, { key: 'Surgical Center', val: 'Surgical Center' }, { key: 'Urgent Care', val: 'Urgent Care' }, { key: 'Hospital', val: 'Hospital' }, { key: 'Other', val: 'Other' }],
      //   type: 'radio',
      //   // hint: 'Yes/No',
      //   rules: { required: true },
      //   errorMessage: {
      //     required: "This Field is Required",
      //     custom: "Value is Invalid"
      //   },
      
      // },



      // {
      //   id: 1,
      //   heading: "Name of medical facility",
      //   label: "Name of medical facility",
      //   name: "medical_facility_name",
      //   className: 'formfild',
      //   type: "text",
      //   rules: { required: true },
      //   errorMessage: {
      //     required: "This Field is Required",
      //     custom: "Value is Invalid"
      //   },
      // },
      // {
      //   id: 2,
      //   heading: "Name of their relationship at facility and that persons title (would be good to have this fill able so that they can type details)",
      //   label: "Name of their relationship at facility and that persons title",
      //   name: "relationship_name",
      //   className: 'formfild',
      //   type: "text",
      //   rules: { required: true},
      //   errorMessage: {
      //     required: "This Field is Required",
      //     custom: "Value is Invalid"
      //   },

      // },


      {
        id: 10,
        heading: "What is the nature of your relationship with the prospect?  ",
        name: "nature_of_relationship",
        className: 'form_radio',
        values: [{ key: 'Client', val: 'Client' }, { key: 'Client/Friend', val: 'Client/Friend' }, { key: 'Friend', val: 'Friend' }, { key: 'Cold', val: 'Cold' }],
        type: 'radio',
        // hint: 'Yes/No',
        rules: { required: true },
        errorMessage: {
          required: "This Field is Required",
          custom: "Value is Invalid"
        },
        // defaultValue: "No"
      },



      {
        id: 3,
        heading: "Approx gross receipts with top 3 insurers ($2M minimum)",
        label: "Approx gross receipts with top 3 insurers ($2M minimum)",
        name: "approx_gross_reciepts",
        className: 'formfild',
        type: "text",
        // inputType: "email",
        rules: { required: true },
        errorMessage: {
          required: "This Field is Required",
          custom: "Value is Invalid"
        },

      },
      {
        id: 20,
        heading: "Any other information we should know?",
        placeholder: 'Any other information we should know?',
        label: "Any other information we should know?",
        name: "other_info",
        className: 'formfild textareafild',
        type: "textarea",
        // rules: { required: true },
        // errorMessage: {
        //   required: "This Field is Required",
        //   custom: "Value is Invalid"
        // }

      }

    ]

  });
  return (
    <div>
      <Form formData={formDataL9.current} />
      {loading ? (<Box className="loaderProgress" sx={{ width: "100%", mt: "0px" }}><LinearProgress /></Box>) : (null)}
    </div>
  )
}

export default LandingPage9Form