import React, { useState } from 'react'
import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { Form } from '../../../../form/Form';
import store from '../../../../store';
import { resolvefunction } from '../../../../helper/helperFunctions'
import { resetresolvenew } from '../../../../helper/ResolveReducer';



import { getjobcategorydata, cleareditjobticketsdata, getjobticketlistdata, geteditjobticketsdata } from '../../Jobtickets/JobTicketReducer';
import { setSnackbar } from '../../../Layout/layoutReducer';



function AddEditJobtickets() {
  const [selecttypeArry, setselecttypeArry] = useState([]);
  const pathname = useLocation();
  const { id } = useParams();
  const navigate = useNavigate()


  let reqbodyforlead = {
    "condition": {
      "limit": 5,
      "skip": 0
    },
    "sort": {
      "field": "_id",
      "type": "desc"
    },
    "searchcondition": {},
    "project": {},
    "token": ""
  }

  let reqbodyforjobcategory = {
    "condition": {
      "limit": 100,
      "skip": 0
    },
    "sort": {
      "field": "_id",
      "type": "desc"
    },
    "searchcondition": { $or: [{ status: 1 }] },
    "project": {},
    "token": ""
  }
  const resolved = useSelector(state => state.ResolveReducer?.resolved[`/edit-jobtickets/${id}`]? state.ResolveReducer.resolved[`/edit-jobtickets/${id}`] : false)
  const editdata = useSelector(state => (state.JobTicketReducer?.editjobticketsdata && state.JobTicketReducer?.editjobticketsdata.length > 0) ? state.JobTicketReducer.editjobticketsdata[0] : null); // ########## Fetch Edit Value From Store Which Display in Form ########### //
  let jobcategorydata = useSelector(state => (state.JobTicketReducer?.jobcategorydata && state.JobTicketReducer?.jobcategorydata?.length > 0) ? state.JobTicketReducer.jobcategorydata : []);
  const goBackClicked = useSelector(state => state.formSlice?.customButtonClicked?.manageJobAddEditForm?.jobgoback001 ? state.formSlice.customButtonClicked.manageJobAddEditForm.jobgoback001 : false);
  const submitbtnclick = useSelector(state => state.formSlice.formSubmissionState.manageJobAddEditForm ? state.formSlice.formSubmissionState.manageJobAddEditForm:0)
  const user_id = useSelector((state) => state.loginSlice.userInfo?._id ? state.loginSlice.userInfo._id : null);





  const dispatch = useDispatch();

  useEffect(() => {
    if (id !== undefined && id !== null && editdata !== null) {
      if (submitbtnclick == 2) {
        dispatch(setSnackbar({ open: true, message: "Update Successfully !", status: 'success' }))
      };
    }
    else {
      if (submitbtnclick == 2) {
        dispatch(setSnackbar({ open: true, message: "Add Successfully !", status: 'success' }))
      };
    }


  }, [submitbtnclick])




  useEffect(() => {
    store.dispatch(getjobcategorydata(reqbodyforjobcategory));

    if (!resolved){

        if (id !== undefined && pathname !== null && pathname.pathname !== undefined) {
          console.log("ppp>>", editdata)
          store.dispatch(geteditjobticketsdata(id));

        }

    }
   
    return () => {
      store.dispatch(cleareditjobticketsdata())
      store.dispatch(resetresolvenew('/edit-jobtickets/:id'))
    }
  }, [])


// useEffect(()=>{
//  console.log(id,'resolved>>>>>0',resolved);
// },[resolved])

  // console.log("resolved>>>", resolved);

  console.log("editdata??", editdata);

  useEffect(() => {

    if (jobcategorydata && jobcategorydata.length > 0) {
      let arr = [];
      for (let i in jobcategorydata) {
        arr.push({ val: jobcategorydata[i]._id, name: jobcategorydata[i].category_name })
      }
      setselecttypeArry(arr)
    }else{
      let arr = [];
      arr.push({ val: '', name: 'No Active Category Found' })
      setselecttypeArry(arr)
    }

  }, [jobcategorydata])

  console.log("jobcategorydata", jobcategorydata);

  useEffect(() => {
    if (goBackClicked) {
      resolvefunction(navigate, '/manage-jobtickets', getjobticketlistdata(reqbodyforlead))
    }

  }, [goBackClicked])

  console.log("selecttypeArry>>>", selecttypeArry);


  useEffect(() => {

  }, [selecttypeArry])

  const dataform = {
    id: 'manageJobAddEditForm',
    api_url: process.env.REACT_APP_API_URL,
    endPoint: "lead-manage/addorupdatejobticket",
    urlPathOnSuccessfulFormSubmission: "/manage-jobtickets",
    submitBtnName: "Submit",
    cancelBtnName: "Cancel",
    className: 'inputBlock inputBlock3line',
    formButtonClass: "Formsubmit_button",
    formWrapperClass: "backend_addform addAdmin",
    formInlineClass: "backendTableInline_addform testwrpr trainingcata",
    formAdditionalSubmissionData: {

      _id: (id !== undefined && id !== null) ? id : undefined,
      ticket_addedby:user_id
    },

    fields: [
      {
        id: 0,
        heading: "Title",
        name: "ticket_name",
        label:"Title",
        // className: "inputBlock inputBlock1line",
        className: 'inputBlock inputBlock2line',
        type: "text",
        rules: { required: true },
        errorMessage: {
          required: "This Field is Required",
        },
        //   minLength: "Minimum length should be 15",
        //   custom: "Value is Invalid"
        defaultValue: (editdata && Object.keys(editdata).length > 0 && editdata.category_name !== undefined) ? editdata.category_name : undefined,

      },

      {
        id: 2,
        heading: "Select Category",
        label: "Select Category",
        name: "ticket_category",
        className: ' inputBlock inputBlock2line',
        type: 'select',
        // multiple: true,
        checkbox: false,
        other: false,
        rules: { required: true },
        values: selecttypeArry,
        defaultValue: (editdata && Object.keys(editdata).length > 0 && editdata.ticket_category !== undefined) ? editdata.ticket_category : undefined,
        errorMessage: {
          required: "This Field is Required",
        },
      },
      {
        id: 1,
        heading: "Description",
        label: "SunEditor",
        name: "ticket_des",
        type: "sunEditor",
        className: "inputBlock1line",
        rules: { required: true },
        errorMessage: {
          required: "This Field is Required",
          //   minLength: "Minimum length should be 15",
          //   custom: "Value is Invalid"
        },

        placeholder: "Please type here...",
        ////////////////////////////// Mandatory or no options will be available /////////////////////
        editorWidth: 300,
        buttonListOptions: [
          ['undo', 'redo', 'font', 'fontSize', 'formatBlock'],
          ['bold', 'underline', 'italic', 'strike', 'subscript', 'superscript', 'removeFormat'],
          ['fontColor', 'hiliteColor', 'outdent', 'indent', 'align', 'horizontalRule', 'list', 'table'],
          ['link', 'image', 'video', 'fullScreen', 'showBlocks', 'codeView', 'preview', 'print', 'save']
        ],
        defaultValue: (editdata && Object.keys(editdata).length > 0 && editdata.ticket_des !== undefined) ? editdata.ticket_des : undefined,

      },

      // {
      //   id: 20,
      //   heading: "Training Image:",
      //   // name: "training_image",
      //   name: "ticket_attachment",
      //   // label: "Upload Image",
      //   className: 'inputBlock inputBlock1line',
      //   type: "fileUploader",
      //   multiple: false,
      //   // rules: { required: true },
      //   // errorMessage: {
      //   //   required: "This Field is Required",
      //   // },

      //   accept: "image/*",//////// Write properly /////////
      //   bucket: "uploaded-files-react",
      //   path: "aspireuploadimage/",
      //   base_url: "https://57lsaxmih2.execute-api.us-east-1.amazonaws.com/dev/",
      //   endpoint: "lead-manage/addorupdatejobticket",
      //   deleteEndpoint: "deletefilefromBucket",
      //   // defaultFileLinks: (editdatatraining && editdatatraining !== undefined && Object.keys(editdatatraining).length > 0 && typeof editdatatraining.lesson_file !== undefined) ? [{
      //   //   url: editdatatraining.training_image.url,
      //   //   type: "image",
      //   //   name: editdatatraining.training_image.file_local_name
      //   // }] : undefined,
       
      // },

      {
        id: 8,
        heading: "Thumbnail",
        lable: "Add Thumbnail",
        name: "ticket_attachment",
        className: "inputBlock inputBlock1line",
        type: "fileUploader",
        multiple: false,

        accept: "image/*",//////// Write properly /////////
        bucket: "uploaded-files-react",
        path: "aspireuploadimage/",
        base_url: "https://57lsaxmih2.execute-api.us-east-1.amazonaws.com/dev/",
        endpoint: "requestUploadURL",
        deleteEndpoint: "deletefilefromBucket",

        // defaultValue: (editdata && Object.keys(editdata).length > 0 && editdata.thumbnail !== undefined) ? editdata.thumbnail[0] : undefined,

        defaultFileLinks: (editdata && Object.keys(editdata).length > 0 && typeof editdata?.ticket_attachment !== undefined && typeof editdata?.ticket_attachment == undefined) ? [{
          
          url: editdata.ticket_attachment.url,
          type: "image",
          name: editdata.ticket_attachment.file_local_name
          
        }] : undefined


      },

      {
        id: 3,
        heading: "Priority",
        label: "priority",
        name: "priority",
        className: 'inputBlock inputBlock2line',
        type: 'select',
        multiple: false,
        values: [
          { val: "high", name: "High" },
          { val: "medium", name: "Medium" },
          { val: "low", name: "Low" }
        ],
        rules: { required: true },
        errorMessage: {
          required: "This Field is Required",
          maxLength: "MaxLength length 20 allowed",
          validate: "Lastnameeeeeeee is not valid",
          custom: "Value is Invalid"
        },
        defaultValue: (editdata && Object.keys(editdata).length > 0 && editdata.priority !== undefined) ? editdata.priority : undefined,
      }


    ],
    customButtons: [
      {
        id: 'jobgoback001',
        label: 'Go Back',
        className: 'default',
        type: 'button',
      },
    ]


  }



  return (
    <>
      <h1 className='page_heading'>ADD JOBTICKET</h1>
      <div className="dataform2_wrapper managejobticketform">
        <div className='adminformbody'>
          {id !== undefined && id !== null && editdata !== null ? <Form formData={dataform} /> : (id === undefined ? <Form formData={dataform} /> : '')}
          
        </div>
      </div>
    </>
  )
}

export default AddEditJobtickets
