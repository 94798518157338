import React from "react";
import "./LandingPage6.css";
import { Box } from "@mui/system";
import { Form } from "../../../../form/Form";
import { useEffect, useState } from "react";
import { LinearProgress } from "@mui/material";
import store from "../../../../store";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import {
  addconverstiondata,
  addlandingpagetwodatalead,
  clearlandingpagesubmittionstate,
  getlandingpagedata,
  getlandingpageoneuserdata,
  updateStages,
  updateTags,
} from "../LandingpageReducer";
import { setSnackbar } from "../../../Layout/layoutReducer";
import { resolvefunction } from "../../../../helper/helperFunctions";
import { fetchCalenderEvents } from "../../../Calendar/calenderReducer";
import { setLeadinfo } from "../../../login/loginReducer";
import StateArray from "../../../../assets/json/state";
import LandingPage6Banner from "./Landingpage6 Componts/LandingPageBaner/LandingPage6Banner";
import LandingPageBlockForVideo from "./Landingpage6 Componts/LandingPageBlockForVideo/LandingPageBlockForVideo";
import "../LandingPage6/LandingPage6.css";
import LandingPage6VideoBlock from "./Landingpage6 Componts/LandingPage6VideoBlock/LandingPage6VideoBlock";


// import LandingPage6Banner from './Landingpage6 Componts/LandingPageBaner/LandingPage6Banner'
const LandingPage6 = () => {
 // const [loading, setloading] = useState(false);
 const [locationArry, setLocationArry] = useState([]);
 const location = useLocation();
 const navigate = useNavigate();
 const dispatch = useDispatch();


 const [submissionState, setSubmissionState] = useState(0);


 const [loader, setLoader] = useState(false);


 const formInputData = useSelector((state) =>
   state.formSlice.formData.userForm6
     ? state.formSlice.formData.userForm6
     : null
 );
 const { uniquename } = useParams();
 console.log("uniquename******", uniquename);
 //  console.warn("process.env>>>>>>>",process.env);


 // console.log("loading", loading)
 let reqbodyforjobcategory = {};
 if (typeof uniquename != "undefined") {
   reqbodyforjobcategory = {
     uniquename: uniquename,
   };
 } else {
   reqbodyforjobcategory = {
     page_slug: process.env.REACT_APP_LANDINGPAGE_SLUG,
   };
   console.warn("process.env>>>>>>>", process.env);
 }


 const userData = useSelector((state) =>
   state.LandingpagefontReducer?.landingpageoneuserdata &&
     Object.keys(state.LandingpagefontReducer.landingpageoneuserdata).length > 0
     ? state.LandingpagefontReducer.landingpageoneuserdata
     : {}
 );
 const landingpageformsubmitionstate = useSelector((state) =>
   state.LandingpagefontReducer?.landingpagesubmittionstate
     ? state.LandingpagefontReducer.landingpagesubmittionstate
     : 0
 );
 const landingpageformdeta = useSelector((state) =>
   state.formSlice?.formData?.userForm6
     ? state.formSlice.formData.userForm6
     : null
 );


 const addedData = useSelector((state) =>
   state.LandingpagefontReducer?.addedinfo &&
     Object.keys(state.LandingpagefontReducer?.addedinfo).length > 0
     ? state.LandingpagefontReducer.addedinfo
     : {}
 );


 // const fetchedStateData = useSelector(state => (state.LandingpagefontReducer?.Stagesdata) ? state.LandingpagefontReducer.Stagesdata : [])
 const fetchedStateData = useSelector((state) =>
   state.userManagment?.FetchStages ? state.userManagment?.FetchStages : []
 );


 const tagsDetails = useSelector((state) =>
   state.userManagment?.FetchTagDetailsData
     ? state.userManagment?.FetchTagDetailsData
     : []
 );

 


 console.log("fetchedStateData", fetchedStateData);


 let currTime = new Date().getTime();
 let dayAfterTomorrow = currTime + 48 * 3600 * 1000;
 const [slotListBody, setSlotListBody] = useState({
   condition: {
     limit: 10,
     skip: 0,
   },
   sort: {
     field: "start_datetime_unix",
     type: "desc",
   },
   searchcondition: {
     // userid: getcookies.userInfo?._id,
     start_datetime_unix: {
       $gte: dayAfterTomorrow,
     },
   }
   // "slot_creation_type": props.type === 'np' ? 2 : 1
 });
 const ipinfo = useSelector((state) =>
   state.loginSlice?.ipInfo && Object.keys(state.loginSlice.ipInfo).length > 0
     ? state.loginSlice.ipInfo
     : null
 );
 const [conversionapicall, setConversionapicall] = useState(false);
 useEffect(() => {
   if (uniquename) {
     store.dispatch(getlandingpageoneuserdata(reqbodyforjobcategory));
   } else {
     store.dispatch(getlandingpagedata(reqbodyforjobcategory));
   }
 }, []);
 useEffect(() => {
   if (userData && ipinfo) {
     console.log("userData", userData);
     let body = {
       // "campaign_id":userData.campaign_id,
       associated_program: userData.associated_program,
       landing_page_id: userData.landing_page_id,
       landing_page_slug: userData.landing_page_slug,
       // "campaign_added_by":userData.user_id,
       // "hiring_manager":userData.hm_id,
       ipInfo: ipinfo,
     };
     if (userData.campaign_id) body["campaign_id"] = userData.campaign_id;
     if (userData.user_id) body["campaign_added_by"] = userData.user_id;
     if (userData.hm_id) body["hiring_manager"] = userData.hm_id;


     console.log("+++++++++++++++++++", body);
     if (body && body.landing_page_id && !conversionapicall) {
       dispatch(addconverstiondata(body));
       setConversionapicall(true);
     }


     //    let bodyc= Object.keys(body).forEach(key => body[key] === undefined && delete body[key])
     //    console.log("bodyc",bodyc);
   }
 }, [JSON.stringify(ipinfo), JSON.stringify(userData)]);


 useEffect(() => {
   console.log("userData", landingpageformsubmitionstate, addedData);
   if (landingpageformsubmitionstate && landingpageformsubmitionstate == 2) {
     const data = {
       stage_slug: "5QH6nS8FXNawY",
       added_by: addedData._id,
       added_for: addedData._id,
       is_delete: 0,
       status: 1,
     };
     console.log("data===========================>", data);
     // return
     dispatch(updateStages(data));


     const tagdata = {
       added_by: addedData._id,
       added_for: addedData._id,
       key: "Wnv14tRS9FlmV",
       label: "hm-policiesandcompliance-signed-success",
       is_delete: 0,
       status: 1,
     };
     console.log("tagdata===========================>", tagdata);
     // return
     dispatch(updateTags(tagdata));
     dispatch(
       setSnackbar({
         open: true,
         message: "Thank you for Submitting !",
         status: "success",
       })
     );
     if (userData.calendar_access && userData.calendar_access == 1) {
       if (userData?.associated_bdd && userData.associated_bdd[0]) {
         slotListBody.searchcondition['userid'] = userData.associated_bdd[0]
         slotListBody['organiser_role'] = 'bdd'
       }
       slotListBody['_id'] = addedData._id;
       resolvefunction(navigate, `/calendar/${addedData._id}/bdd/${userData.associated_bdd[0]}`, [
         fetchCalenderEvents(slotListBody),
       ]);
     } else {
       navigate(`/thankyou`);
     }


     dispatch(setLeadinfo(addedData));
     store.dispatch(clearlandingpagesubmittionstate());
   }
 }, [landingpageformsubmitionstate, fetchedStateData]);


 const [dataCopy, setDataCopy] = useState({}); //--- dataCopy  ----//


 useEffect(() => {
   console.log("addedData", addedData);
 }, [addedData]);


 // useEffect(() => {
 // if (landingpageformsubmitionstate && landingpageformsubmitionstate == 2) {
 //     setDataCopy(addedData)
 //     const data = {
 //         stage_slug: '5QH6nS8FXNawY',
 //         added_by: addedData._id,
 //         added_for: addedData._id,
 //         is_delete:0,
 //         status: 1
 //     }
 //     console.log("data===========================>", data)
 //     // return
 //     dispatch(updateStages(data));


 //     const tagdata = {
 //         added_by: addedData._id,
 //         added_for: addedData._id,
 //         key:"Wnv14tRS9FlmV",
 //         label:"hm-policiesandcompliance-signed-success",
 //         is_delete: 0,
 //         status: 1
 //     }
 //     console.log("tagdata===========================>", tagdata)
 //     // return
 //     dispatch(updateTags(tagdata));


 // }


 // }, [landingpageformsubmitionstate])


 useEffect(() => {
   let Newarr = [];
   if (StateArray && StateArray.length > 0) {
     for (let i in StateArray) {
       Newarr.push({ val: StateArray[i].name, name: StateArray[i].name });
     }
     setLocationArry(Newarr);
   }
 }, []);


 useEffect(() => {
   if (submissionState && submissionState === 4) {
     addapicall(formInputData);
     console.log("formInputData", formInputData, submissionState);
   }
 }, [submissionState]);


 const addapicall = async (val) => {
   console.log("val>>>", val);


   val = { ...val };
   let body = {
     "firstname": val.firstname,
     "lastname": val.lastname,
     "email": val.email,
     "phone": val.phone,
     "city": val.city,
     "state": val.state,
     "address": val.address,
     "zip": val.zip,
     "campaign_id": userData.campaign_id,
     "associated_program": userData.associated_program,
     "page_id": userData.landing_page_id,
     "page_slug": userData.landing_page_slug,
     "user_id": userData.user_id,
     "rep_id": userData.rep_id,
     "hiring_manager": userData.hm_id,
     "createdon_datetime": new Date().getTime(),
     "status": 0,
     // roles: { is_rep: 1 },
     "aer_id": userData.aer_id,
     "hiring_manager": userData.hm_id,
     "associated_bdd": userData.associated_bdd,
     "added_type": "landingpage",
     "calender_access_form_submit_time": userData.calendar_access ? 2 : 1,
     "ipinfo":ipinfo

     // is_rep: true,
   };


   if (Object.keys(body).length > 0) {
     console.log("body-----", body);


     setLoader(true);
     await store.dispatch(addlandingpagetwodatalead(body));
     // setActive(false)
     setLoader(false);
   }
 };


 const formDataL2 = {
   id: "userForm6",
   formtype: "add",
   // api_url: process.env.REACT_APP_API_URL,
   // endPoint: "landing-page-manage/add-user",
   // endPointBodyWrapper: "data",
   // urlPathOnSuccessfulFormSubmission: "/calendar",
   msgOnFailedFormSubmission: "Something Went Wrong",
   formWrapperClass: "formWrapperClass", // Can be undefined default will be formCls
   formInlineCLass: "userForm", // Can be undefined default will be formInline
   formButtonClass: "submitbtnsection",
   submitBtnName: "Submit",
   // submitBtnStartIcon: 'image_search_icon',
   // submitBtnEndIcon: 'search_icon',
   resetBtnName: "Reset",
   formAdditionalSubmissionData: {
     type: "admin",
     status: "active",
   },
   fields: [
     //////////////////////////////////// Text Input ///////////////////////////////
     {
       id: 0,
       // heading: "Full Name",
       label: "First Name",
       name: "firstname",
       className: "formfild",
       type: "text",
       rules: { required: true },
       errorMessage: {
         required: "This Field is Required",
         custom: "Value is Invalid",
       },
     },
     {
       id: 1,
       // heading: "Full Name",
       label: "Last Name",
       name: "lastname",
       className: "formfild",
       type: "text",
       rules: { required: true },
       errorMessage: {
         required: "This Field is Required",
         custom: "Value is Invalid",
       },
     },
     {
       id: 2,
       // heading: "Full Name",
       label: "Email",
       name: "email",
       className: "formfild",
       type: "text",
       inputType: "email",
       rules: { required: true },
       errorMessage: {
         required: "This Field is Required",
         custom: "Value is Invalid",
       },
     },
     {
       id: 3,
       // heading: "Full Name",
       label: "Phone No",
       name: "phone",
       className: "formfild",
       type: "text",
       inputType: "phone",
       rules: { required: true, minLength: 10 },
       errorMessage: {
         required: "This Field is Required",
         custom: "Value is Invalid",
       },
     },


     {
       id: 4,
       // heading: "Address",
       label: "Address",
       name: "address",
       className: "formfild",
       type: "text",
       inputType: "text", /////////////// If not mentioned default will be text //////////////
       rules: { required: true },
       errorMessage: {
         required: "This Field is Required",
         custom: "Value is Invalid",
       },
     },


     {
       id: 5,
       // heading: "Full Name",
       label: "City",
       name: "city",
       className: "formfild",
       type: "text",
       rules: { required: true },
       errorMessage: {
         required: "This Field is Required",
         custom: "Value is Invalid",
       },
     },
     {
       id: 6,
       // heading: "Full Name",
       label: "State",
       name: "state",
       className: "formfild state",
       type: "select",
       // other: true,
       values: locationArry,
       rules: { required: true },
       errorMessage: {
         required: "This Field is Required",
         custom: "Value is Invalid",
       },
       defaultValue: "",
     },
     {
       id: 7,
       // heading: "Full Name",
       label: "Zip",
       name: "zip",
       className: "formfild",
       type: "text",
       inputType: "text",
       rules: { required: true, maxLength: 5, minLength: 5 },
       errorMessage: {
         required: "This Field is Required",
         custom: "Value is Invalid",
         maxLength: "Maxlength length 5 allowed",
         minLength: "Minlength length 5 allowed",
       },
     },


     // {
     //   id: 16,
     //   type: "html",
     //   className: "form_text",
     //   value: "<p>How many established relationships do you have with C - Level executives, decision- makers, and / or business owners?</p>"
     // },
     // {
     //   id: 17,
     //   // heading: "Married",
     //   name: "married",
     //   className: 'form_radio',
     //   values: [{ key: 0, val: '0-1' }, { key: 1, val: '2-9' }, { key: 3, val: '10-49' }, { key: 4, val: '50-99' }, { key: 4, val: '100+' }],
     //   type: 'radio',
     //   hint: 'Yes/No',
     //   rules: { required: true },
     //   errorMessage: {
     //     required: "This Field is Required",
     //     custom: "Value is Invalid"
     //   },
     //   // defaultValue: "No"
     // },
   ],
 };


 store.subscribe(() => {
   const storeData = store.getState();
   if (
     storeData &&
     storeData.formSlice &&
     storeData.formSlice.formSubmissionState &&
     storeData.formSlice.formSubmissionState.userForm6
   ) {
     setSubmissionState(storeData.formSlice.formSubmissionState.userForm6);
   }
   // console.log(setSubmissionState, "setSubmissionState")
   // console.log("landing store", storeData)
 });


 return (
   <>
   <div className="landingpage6_main_cont">
     <div className="landing_page6_bannerblock">
       <div className="landing-container">
         <div className="landing-col-container">
           <div className="landing-banner-left">
             {/* {location.pathname === "/landing-page6-video" && (
               <LandingPage6VideoBlock />
             )} */}


                {process.env.REACT_APP_LANDINGPAGE_SLUG == "jLiUc37STmcj8" ? (
                  <LandingPage6VideoBlock />
                ) : (
                  <div>
                    <div className="lpage_logowrapper">
                      <img src="https://all-frontend-assets.s3.amazonaws.com/aspireLeadFire/landingpage6/l_page6_logo.png" />
                    </div>


                    <div className="main_heading_wrapper">
                      <div>
                        <h1>
                          Digital{" "}
                          <span className="gradient_bluetext">Banking</span> &
                          Payment Services
                        </h1>
                      </div>
                    </div>


                    <div className="signature_textwrapper">
                      <h2>For Employees… Through Employers as a Benefit…</h2>
                      <h2>Financial Wellness ~ Your Money - Anytime…</h2>
                    </div>


                    <div className="sub_heading_wrapper">
                      <h3 className="gradient_bluetext">
                        The challenge for Employer and <br /> For The{" "}
                        <span className="gradient_redtext">HOURLY WORKER</span>
                      </h3>
                    </div>


                    <div className="employer_points_wrapper">
                      <div className="employer_heading">
                        <p>For the Employer…</p>
                      </div>
                      <div className="employer_bullet_wrapper">
                        <div className="employer_bullet_subwrp">
                          <p className="blue_bullets">
                            Hiring struggles to attract new employees in today’s
                            competitive environment
                          </p>
                          <p className="blue_bullets">
                            Labor shortages, increased turnover, skyrocketing
                            training costs and employee defection
                          </p>
                          <p className="blue_bullets">
                            Going to costly lengths to continue operations, trying
                            to get back to “business as usual”
                          </p>
                        </div>
                      </div>
                    </div>


                    <p className="banner_only_para">
                      Addressing an emerging workplace dynamic of worker financial
                      insecurity
                    </p>


                    <div className="land_arrow_wrapper">
                      <h2>
                        Please fill out the form and schedule to talk with our
                        experts!
                      </h2>
                    </div>


                    {/* <img
                 src="https://all-frontend-assets.s3.amazonaws.com/aspireLeadFire/landingpage6/l_page6_demo-image1.png"
                 className="demoimage11"
               /> */}
                    {/* <img
                 src="https://all-frontend-assets.s3.amazonaws.com/aspireLeadFire/landingpage6/l_page6_demo-image3.png"
                 className="demoimage11"
               /> */}


                    {/* <img
                   src="https://all-frontend-assets.s3.amazonaws.com/aspireLeadFire/landingpage6/l_page6_demo-image4.png"
                   className="demoimage11"
                 /> */}
                  </div>
                )}
              </div>
              <div className="landing-banner-right landingOne">
                <div className="formwrapper">
                  <div className="form_title_wrapper">
                    <h2>SPEAK WITH AN</h2>
                    <h4>
                      ASPIRE SAME-DAY PAY <br /> EXPERT RIGHT AWAY!
                    </h4>
                  </div>
                  <div className="form_style">
                    <Form formData={formDataL2} />
                    {loader ? (
                      <Box
                        className="loaderProgress"
                        sx={{ width: "100%", mt: "0px" }}
                      >
                        <LinearProgress />
                      </Box>
                    ) : null}
                  </div>
                </div>
              </div>
            </div>
            <div className="banner_footer">
              {/* <img src="https://all-frontend-assets.s3.amazonaws.com/aspireLeadFire/landingpage6/l_page6_demo-image2.png" /> */}


           <div className="prcntg_main_wrapper">
             <div className="prcntg_wrapper">
               <div className="circle_main_wrap">
                 <div className="circle_sub_wrap">
                   <span>78%</span>
                 </div>
               </div>
               <div className="prcntg_txt">
                 <p>Live paycheck to paycheck</p>
               </div>
             </div>
             <div className="prcntg_wrapper">
               <div className="circle_main_wrap">
                 <div className="circle_sub_wrap">
                   <span>65%</span>
                 </div>
               </div>
               <div className="prcntg_txt">
                 <p>Feel personal financial worries impact their health</p>
               </div>
             </div>
             <div className="prcntg_wrapper">
               <div className="circle_main_wrap">
                 <div className="circle_sub_wrap">
                   <span>44%</span>
                 </div>
               </div>
               <div className="prcntg_txt">
                 <p>Have less than $400 saved for unexpected expenses</p>
               </div>
             </div>
           </div>
         </div>
       </div>
     </div>
     {process.env.REACT_APP_LANDINGPAGE_SLUG == "jLiUc37STmcj8" && (
       <LandingPageBlockForVideo />
     )}


        <div>
          {/* <img
         src="https://all-frontend-assets.s3.amazonaws.com/landing-page6/payroll_img.webp"
         alt=""
         className="preimg"
       />
       <div class="brand_testi">
         <img
           src="https://all-frontend-assets.s3.amazonaws.com/aspireLeadFire/brandtesti.webp"
           alt=""
           class="preimg"
         />
         <button>Schedule an Appointment Now!</button>
       </div> */}
          <div className="whole_wrapper">
            <div className="page6_block1">
              <div className="landingpage6_common_width">
                <h2 className="common_blue_text">
                  ASPIRE SAME-DAY PAY SOLUTION - RELLEVATE - COMMITTED TO
                  IMPROVING THE LIVES OF US WORKERS
                </h2>
                <div className="padding_block">
                  <div className="textwrapper6 green_circle_text red_square_text">
                    <img
                      src="https://all-frontend-assets.s3.amazonaws.com/aspireLeadFire/landingpage6/land6_block1_mobile.webp"
                      alt=""
                      className="block1_mobile"
                    />
                    <div className="block1_wrapper">
                      <div className="sky_gradient">
                        <h2>FINANCIAL WELLNESS - NO COST EMPLOYEE BENEFIT</h2>
                      </div>
                      <h3>
                        Ever wonder why, in today’s day and age, employees are
                        paid every 1, 2 or 4 weeks?
                      </h3>
                      <h3>
                        Why not give employees maximum control over their
                        finances, help reduce their stress?
                      </h3>
                      <div>
                        <h3>
                          <span class="bold_black_text">
                            What if employers could do so:
                          </span>{" "}
                        </h3>
                        <div className="flex_column">
                          <p>At no cost</p>
                          <p>Attracting & retaining more employees?</p>
                        </div>
                        <div className="flex_column">
                          <p>While lowering turnover</p>
                          <p>Be socially responsible</p>
                        </div>
                      </div>
                    </div>
                    <div class="orange_button">
                      <button>Schedule an Appointment Now!</button>
                    </div>
                  </div>
                </div>
              </div>
            </div>


            <div className="page6_block2">
              <div className="landingpage6_common_width">
                <div className="page6_block2_wrapp">
                  <div class="sky_gradient">
                    <h2>
                      OUR PRODUCTS - BUILT FOR EMPLOYEES, ACCESSED THROUGH
                      EMPLOYERS
                    </h2>
                  </div>
                  <div className="block2_cards_wrapper">
                    <div className="block2_card">
                      <div className="block2_card_headng">
                        <h2 className="common_blue_text">
                          RELLEVATE DIGITAL ACCOUNT
                        </h2>
                      </div>
                      <div className="card_innercontnt_wrapper">
                        <h3 className="sub_heading_common">
                          Monthly Subscription -{" "}
                          <span className="orange_text">FREE</span>
                        </h3>
                        <div className="textwrapper6 green_circle_text red_square_text">
                          <div>
                            <h3>Digital Account with Earnings Credit</h3>
                            <p>FDIC Insured - $250,000 per account</p>
                            <p>
                              Visa Debit Card - supports payroll direct deposit
                            </p>
                            <p>Pay Any-Day - $2.95 per advance</p>
                            <p>Bill Pay - ACH 2-day Free (Instant $2.95)</p>
                            <p>Send Money - ACH P2P Free - Instant P2P 2 Free</p>
                            <p>
                              Worldwide ATM Access (2 Free in-network per month)
                            </p>
                            <p>Cross Border Money Transfer (coming soon)</p>
                            <h3>Immediately transfer money to any U.S. bank</h3>
                            <h3>
                              24/7 Access via secure, mobile-friendly platform
                            </h3>
                          </div>
                        </div>
                      </div>
                    </div>


                    <div className="block2_card block2_card_down">
                      <div className="block2_card_headng">
                        <h2 className="common_blue_text">
                          RELLEVATE DIGITAL ACCOUNT WITH PAY ANY-DAY
                        </h2>
                      </div>
                      <div className="card_innercontnt_wrapper">
                        <h3 className="sub_heading_common">
                          Pay Any-Day Features
                        </h3>
                        <div className="textwrapper6 green_circle_text red_square_text">
                          <div>
                            <h3>
                              Access up to <span>50%</span> of employee’s earned
                              wages any day of the week
                            </h3>


                            <h3>
                              <span>$2.95</span> per advance - no limit
                            </h3>
                            <h3>Advance paid back at next pay period</h3>
                            <h3>No credit checks - Everyone qualifies</h3>
                          </div>
                        </div>
                        <div className="card_blue_blck">
                          <h5>Pay Any-Day is NOT a Loan</h5>
                          <h5>No Interest is charged</h5>
                          <h5>Pay Any-Day is an Advance on Earned Wages</h5>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="orange_button">
                <button>Schedule an Appointment Now!</button>
              </div>
            </div>


            <div className="page6_block3">
              <div className="landingpage6_common_width">
                <div className="page6_block3_innerwrapper">

                  <div className="block3_top_sec">
                    <img
                      src="https://all-frontend-assets.s3.amazonaws.com/aspireLeadFire/landingpage6/land6_block3_lady.webp"
                      alt=""
                      className="block3_lady"
                    />
                    <img
                      src="https://all-frontend-assets.s3.amazonaws.com/aspireLeadFire/landingpage6/land6_block3_multiimg.webp"
                      alt=""
                      className="block3_multiimg"
                    />
                    <h2 className="common_blue_text">
                      CLIENT ONBOARDING IS TAILORED TO EACH CLIENT
                    </h2>
                    <div className="textwrapper6 green_circle_text red_square_text">
                      <div>
                        <h3>Virtual, In-person, combined</h3>


                        <h3>
                          Employee sign-up rates range from <span>20-80%</span>
                        </h3>
                        <h3>We share our experience & listen to our clients</h3>
                        <h3>Best results when client leadership is involved</h3>
                      </div>
                    </div>
                    <div class="sky_gradient">
                      <h2>ON-BOARDING & SUPPORT</h2>
                    </div>
                  </div>


                  <div className="block3_bottom_sec">
                    <div className="bottom_card">
                      <div className="card_top_sec">
                        <div className="card_top_inner">
                          <h4>Kick-Off</h4>
                          <h5>Signed Agreement</h5>
                        </div>
                      </div>
                      <div className="card_bottom_sec">
                        <div className="card_bottom_inner">
                          <p>Complete client on-boarding document</p>
                          <p>Intro to key client contracts (HR, IT, Fin.)</p>
                          <p>Project Plan / Kick-Off</p>
                        </div>
                      </div>
                    </div>
                    <div className="bottom_card">
                      <div className="card_top_sec">
                        <div className="card_top_inner">
                          <h4>Week 1</h4>
                          <h5>
                            Data Review & <span>Set-up</span>
                          </h5>
                        </div>
                      </div>
                      <div className="card_bottom_sec">
                        <div className="card_bottom_inner">
                          <p>Pay Any-Day</p>


                          <span>PTO, Overtime</span>
                          <span>Pay Rate</span>


                          <p>Format file(s)</p>
                          <p>Test transmission(s)</p>
                        </div>
                      </div>
                    </div>
                    <div className="bottom_card">
                      <div className="card_top_sec">
                        <div className="card_top_inner">
                          <h4>Week 2</h4>
                          <h5>Employee Communication</h5>
                        </div>
                      </div>
                      <div className="card_bottom_sec">
                        <div className="card_bottom_inner">
                          <p>Launch Messaging</p>
                          <p>Online Enrollment</p>
                          <p>Online Resources</p>
                        </div>
                      </div>
                    </div>
                    <div className="bottom_card">
                      <div className="card_top_sec">
                        <div className="card_top_inner">
                          <h3>Go-Live</h3>
                          <h4>Week 3</h4>
                          <h5>Program Management</h5>
                        </div>
                      </div>
                      <div className="card_bottom_sec">
                        <div className="card_bottom_inner">
                          <p>On-Going Communication</p>
                          <p>Reporting</p>
                          <span>Enrollment</span>
                          <span>Usage</span>
                          <span>Adhoc</span>
                        </div>
                      </div>
                    </div>
                  </div>


                  <div className="cstmr_sprt_sec">
                    <div className="customer_sec_wrp">
                      <div className="employee_sec">
                        <div className="employee_img">
                          <img
                            src="https://all-frontend-assets.s3.amazonaws.com/aspireLeadFire/landingpage6/land6_block3_bottom_support.png"
                            alt=""
                          />
                        </div>
                        <div className="employee_desc">
                          <h2 className="common_blue_text">For Employees:</h2>
                          <h4>Toll-free 24/7 Customer Support</h4>
                        </div>
                      </div>
                      <div className="employee_sec">
                        <div className="employee_img">
                          <img
                            src="https://all-frontend-assets.s3.amazonaws.com/aspireLeadFire/landingpage6/land6_block3_bottom_badgesprt.png"
                            alt=""
                          />
                        </div>
                        <div className="employee_desc">
                          <h2 className="common_blue_text">For Employers:</h2>


                          <h4>Rellevate Account Manager</h4>
                        </div>
                      </div>
                    </div>
                    <div class="orange_button">
                      <button>Schedule an Appointment Now!</button>
                    </div>
                  </div>
                </div>
              </div>
            </div>


            <div className="landing_page6_container">
              {/* <div className="block2">
         <img
           src="https://all-frontend-assets.s3.amazonaws.com/aspireLeadFire/landingpage6/landing6_block1img.webp"
           alt=""
           className="preimg"
         />
         <button>Schedule an Appointment Now!</button>
       </div> */}
              {/* <div className="block3">
             <img
               src="https://all-frontend-assets.s3.amazonaws.com/aspireLeadFire/landingpage6/new_2nd_img.webp"
               alt=""
               className="preimg"
             />
             <button>Schedule an Appointment Now!</button>
           </div> */}
              {/* <div className="block4">
             <img
               src="https://all-frontend-assets.s3.amazonaws.com/aspireLeadFire/landingpage6/new_3rd_img.webp"
               alt=""
               className="preimg"
             />
             <button>Schedule an Appointment Now!</button>
           </div> */}
            </div>


            <div className="page6_block4">
              <div className="landingpage6_common_width">
                <div className="sky_gradient">
                  <h2>Partnership with UKG-Kronos - Payroll integration</h2>
                </div>
                <div className="padding_block">
                  <div className="textwrapper6 green_circle_text red_square_text">
                    <img
                      className="hand_mobile"
                      src="https://all-frontend-assets.s3.amazonaws.com/landing-page6/handmobile_img.webp"
                    />
                    <h3>
                      <span className="bold_black_text">
                        {"Serving >"} <span>50%</span> of Fortune{" "}
                        <span>1000</span>,{" >50k"} companies with tens of
                        millions of employees
                      </span>
                    </h3>
                    <div>
                      <h3>
                        <span className="bold_black_text">
                          UKG-Kronos Partnership enables Rellevate to:
                        </span>
                      </h3>
                      <p>Facilitate integration</p>
                      <p>Open doors across America</p>
                    </div>
                    <h3>
                      <span className="bold_black_text">
                        Work with other Payroll, Time & Attendance providers to
                        integrate Rellevate
                      </span>
                    </h3>
                  </div>
                </div>
              </div>
            </div>


            <div className="page6_logo_block">
              <div className="landingpage6_common_width">
                <div className="block5_inner_wrp">
                  <div className="block6_flex">
                    <div className="block5_box_wrp">
                      <div className="box_text_wrp">
                        <h3>Healtcare</h3>
                      </div>
                      <div className="block6_logo_wrp">
                        <img src="https://all-frontend-assets.s3.amazonaws.com/landing-page6/healthcare_logo.webp" />
                      </div>
                    </div>
                    <div className="block5_box_wrp">
                      <div className="box_text_wrp">
                        <h3>Manufacturing</h3>
                      </div>
                      <div className="block6_logo_wrp">
                        <img src="https://all-frontend-assets.s3.amazonaws.com/landing-page6/manufacture_logo.webp" />
                      </div>
                    </div>
                    <div className="block5_box_wrp">
                      <div className="box_text_wrp">
                        <h3>Retail/Hospitality</h3>
                      </div>
                      <div className="block6_logo_wrp">
                        <img src="https://all-frontend-assets.s3.amazonaws.com/landing-page6/retail_logo.webp" />
                      </div>
                    </div>
                    <div className="block5_box_wrp">
                      <div className="box_text_wrp">
                        <h3>Public Sector</h3>
                      </div>
                      <div className="block6_logo_wrp">
                        <img src="https://all-frontend-assets.s3.amazonaws.com/landing-page6/public_logo.webp" />
                      </div>
                    </div>
                    <div className="block5_box_wrp">
                      <div className="box_text_wrp">
                        <h3>Sports</h3>
                      </div>
                      <div className="block6_logo_wrp">
                        <img src="https://all-frontend-assets.s3.amazonaws.com/landing-page6/sports_logo.webp" />
                      </div>
                    </div>
                    <div className="block5_box_wrp">
                      <div className="box_text_wrp">
                        <h3>Services/Distribution</h3>
                      </div>
                      <div className="block6_logo_wrp">
                        <img src="https://all-frontend-assets.s3.amazonaws.com/landing-page6/services_logo.webp" />
                      </div>
                    </div>
                    <div className="block5_box_wrp">
                      <div className="box_text_wrp">
                        <h3>Finance/Insurance</h3>
                      </div>
                      <div className="block6_logo_wrp">
                        <img src="https://all-frontend-assets.s3.amazonaws.com/landing-page6/finance_logo.webp" />
                      </div>
                    </div>
                    <div className="block5_box_wrp">
                      <div className="box_text_wrp">
                        <h3>Transportation</h3>
                      </div>
                      <div className="block6_logo_wrp">
                        <img src="https://all-frontend-assets.s3.amazonaws.com/landing-page6/transportation_logo.webp" />
                      </div>
                    </div>
                  </div>
                  <div className="orange_button">
                    <button>Schedule an Appointment Now!</button>
                  </div>
                </div>
              </div>
            </div>


            <div className="communication_block">
              <div className="landingpage6_common_width">
                <div className="block5_flex">
                  <div className="textwrapper6 green_circle_text red_square_text">
                    <div className="sky_gradient">
                      <h2>EMPLOYEE COMMUNICATIONS</h2>
                    </div>
                    <div>
                      <h3>
                        <span className="bold_black_text">
                          On-Boarding & On-Going:
                        </span>{" "}
                        Online & Print
                      </h3>
                      <p>
                        <span className="bold_black_text">One Pager:</span> Use to
                        intro new employee benefit, invite employees to lunch mtg,
                        via email, intranet, etc.{" "}
                      </p>
                      <p>
                        <span className="bold_black_text">
                          Posters & Tri-Fold Brochures:
                        </span>{" "}
                        use in breakrooms, cafeteria, etc.
                      </p>
                      <p>
                        <span className="bold_black_text">Employee FAQs:</span>{" "}
                        Addresses top employee questions{" "}
                      </p>
                      <p>
                        <span className="bold_black_text">Videos & Wikis:</span>{" "}
                        Informational, Training illustrate how to sign up, use
                        products.
                      </p>
                    </div>
                    <h3>
                      <span className="bold_black_text">
                        Launch Meeting Support:
                      </span>{" "}
                      Presented by Rellevate
                    </h3>
                    <h3>
                      <span className="bold_black_text">Bi-lingual:</span> Web
                      Site, On-Boarding, Enrollment process
                    </h3>
                  </div>
                  <div className="orange_button">
                    <button>Schedule an Appointment Now!</button>
                  </div>
                </div>
              </div>
            </div>
            <div className="help_clients_block">
              <div className="landingpage6_common_width">
                <div className="block6_flex">
                  <div className="landingpage6_img_wrp">
                    <img src="https://all-frontend-assets.s3.amazonaws.com/landing-page6/help_clients_block_img.webp" />
                  </div>
                  <div className="textwrapper6 red_square_text">
                    <div className="sky_gradient">
                      <h2>HELPING CLIENTS ADDRESS REALLY BIG PROBLEMS</h2>
                    </div>
                    <div className="block6_flex block6_bottom">
                      <div className="goals_wrp">
                        <h4>Goal:</h4>
                        <p>Attract new, retain current employees</p>
                        <p>Stop competitive poaching</p>
                        <p>Limit over-time payroll</p>
                        <p>Limit new hire training, related costs</p>
                      </div>
                      <div className="solution_wrp">
                        <h4>Solution:</h4>
                        <p>
                          Rellevate’s Pay Any-Day feature is a low-cost, high
                          value benefit that gives current employees another
                          reason to stay & attract potential new hires an
                          unmatched unique value proposition
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="cards_block">
              <div className="landingpage6_common_width">
                <div className="cards_block_inner">
                  <h2>GIFT & REWARD CARDS</h2>
                  <div className="block6_flex">
                    <div className="block7_gradient_block">
                      <h3>Gift & Reward Visa Cards</h3>
                    </div>
                    <div className="block7_gradient_block">
                      <h3>Rewards for Employees</h3>
                    </div>
                    <div className="block7_gradient_block">
                      <h3>Promotion for Consumers</h3>
                    </div>
                    <div className="block7_gradient_block">
                      <h3>Individual and Bulk Orders</h3>
                    </div>
                    <div className="block7_gradient_block">
                      <h3>Bulk Order pricing for Employers</h3>
                    </div>
                  </div>
                </div>
              </div>
            </div>


            <div className="faq_block">
              <div className="landingpage6_common_width">
                <div className="block7_flex">
                  <div className="textwrapper6">
                    <div className="sky_gradient">
                      <h2>Frequently Asked Questions (FAQ’s)</h2>
                    </div>
                    <div className="ques_ans_block">
                      <h3>
                        How do I register a lead or prospect with Rellevate?
                      </h3>
                      <p>
                        Click this link or copy/paste to your browser for the
                        Aspire Lead Submission form,{" "}
                        <span>
                          https://aspire.rellevate.com/lead-submission/ and fill
                          out the form in its entirety
                        </span>
                        .
                      </p>
                      <h3>
                        What happens after I submit the Aspire Lead Submission
                        form?
                      </h3>
                      <p>
                        A Rellevate Account Executive will contact you to schedule
                        a call to discuss the lead/prospect you submitted on the
                        Aspire Lead Submission form. Your Account Executive will
                        advise you of steps moving forward and give the calendar
                        link to schedule the Discovery Call with your
                        lead/prospect and your Rellevate Account Executive.
                      </p>
                      <h3>Can I / Should I sit in on the Discovery Call?</h3>
                      <p>
                        Totally up to you. It is not required. However, some
                        Channel Referral Partners prefer to be on the Discovery
                        Calls with their prospects just for first introductions
                        between your Rellevate Account Executive and your
                        lead/prospect.
                      </p>
                      <h3>
                        How will I receive sales updates for the leads/prospects I
                        referred?
                      </h3>
                      <p>
                        You will receive emails anytime your Account Executive
                        makes an update to their CRM for your lead/prospect.
                      </p>
                      <h3>
                        If I have any questions about my compensation, who should
                        I talk to?
                      </h3>
                      <p>
                        Your Hiring Manager will be able to answer any questions
                        you may have regarding your compensation.
                      </p>
                      <h3>
                        Don’t I need to submit a W-9 / Government form to get
                        paid?
                      </h3>
                      <p>
                        Yes, but not until your first deal closes. Once your first
                        deal closes, you will receive information for submitting a
                        W-9 and payment form.
                      </p>
                    </div>
                  </div>
                  <div className="orange_button">
                    <button>Schedule an Appointment Now!</button>
                  </div>
                </div>
              </div>
            </div>
            {/* <div className="emp_com">
         <img
           src="https://all-frontend-assets.s3.amazonaws.com/landing-page6/communication_problem_img.webp"
           alt=""
           className="preimg"
         ></img>
         <button>Schedule an Appointment Now!</button>
       </div>
       <div>
         <img
           src="https://all-frontend-assets.s3.amazonaws.com/aspireLeadFire/giftimg.webp"
           alt=""
           className="preimg"
         ></img>
         <button></button>
       </div>
       <div className="ask_qus">
         <img
           src="https://all-frontend-assets.s3.amazonaws.com/landing-page6/FAQ_img.webp"
           alt=""
           className="preimg"
         ></img>
         <button>Schedule an Appointment Now!</button>
       </div> */}
       </div>
     </div>
     </div>
   </>
 );
};


export default LandingPage6;