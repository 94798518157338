import React, { useState, useEffect } from "react";
import {
    Link,
    NavLink,
    useLocation,
    useNavigate,
    useParams,
} from "react-router-dom";
import MenuIcon from "@mui/icons-material/Menu";
import PersonIcon from "@mui/icons-material/Person";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import { Cookies, useCookies } from "react-cookie";
import {
    Box,
    Button,
    LinearProgress,
    MenuItem,
    Snackbar,
    Tooltip,
} from "@mui/material";
import Menu from "@mui/material/Menu";
import Fade from "@mui/material/Fade";
import { useDispatch, useSelector } from "react-redux";
import { inputExternalPlugin, resolvefunction } from "../../helper/helperFunctions";
import { getroledata } from "../Backend/Role/RoleReducer";
import { logOut, setloginRoute, userLogout } from "../login/loginReducer";
import { Lessonlist, Traininglist } from "../Backend/Training/TrainingReducer";
import { getLandingListData } from "../Backend/LandingPage/LandingPageReducer";
import { trainingCenterCategoryFetching } from "../Backend/Training/TrainingCenter/TrainingCenterCategory/TrainingCenterCategoryReducer";
import {
    getContractListData,
    getContractSlagData,
} from "../Backend/ContractManagement/ContractReducer";
import { getprogramdata } from "../Backend/Program/ProgramReducer";
import { getleaddata } from "../Backend/LeadManagement/LeadReducer";

import { getHmGroupListdata } from "../Backend/HmGroup/HmGroupReducer";

import { fetchQuizList, fetchTrainingMaterial, TrainingMaterialCenterlist } from "../Backend/Training/TrainingMaterial/TrainingMaterialReducer";
import { getusersList } from "../Backend/userManagement/userList/userListReducer";
import { getCampaignListData } from "../CampaignManagement/CampaignReducer";
import fetchUserDataReducer from "../Backend/userManagement/fetchUserDataReducer";
import { MdConfirmationNumber, MdDashboard, MdGroups, MdInventory2, MdKeyboardArrowDown, MdLeaderboard, MdLocalLibrary, MdAppRegistration, MdManageAccounts, MdMiscellaneousServices, MdModelTraining, MdOutlineAssignmentTurnedIn, MdOutlineLink, MdOutlineModelTraining, MdPerson } from "react-icons/md";
import { VscSymbolMisc } from "react-icons/vsc";
import { AiOutlineForm } from "react-icons/ai";

import { getjobcategorydata, clearjobcategorydata, getjobticketlistdata } from '../Backend/Jobtickets/JobTicketReducer';
import { bookingListData, fetchEventsData } from "../Calendar/calenderReducer";
import FieldList from "../Backend/FormManagement/FieldModule/FieldList";
import FormList from "../Backend/FormManagement/FormModule/FormList"
import { fieldList, getformdata, groupList } from "../Backend/FormManagement/FormManagementReducer";
import { getTagListdata } from "../Backend/TagManagement/TagReducer";
import { getEmailSlagData, getEmailTeamplatedata } from "../Backend/EmailTemplateManagement/EmailTemplateReducer";
import { stageEditData, stageListData } from "../Backend/StageDistribution/StageReducer";
import { getHmGridData } from "../Backend/HmDashboard/HmDashboardReducer";
import { getRoundRobinData } from "../Backend/ManageRoundRobin/ManageRoundRobinReducer";
import { gettriggerdata } from "../Backend/EmailTemplateManagement/TriggerReducer";
import { getInformationListData } from "../Backend/InformationlinkModule/InformationReducer";
import { getfaqcategorydata, getfaqdata } from "../Backend/FaqManagement/FaqReducer";

function AdminMenu({ topmenu }) {
    // console.log("Props topmenu -------->", topmenu)
    // ---------------------------------------------Invoking Hooks--------------------------------------------- //
    const navigate = useNavigate();
    const dispatch = useDispatch();

    const cookies = new Cookies();
    const getcookies = cookies.getAll();
    const [childMenuActive, setChildMenuActive] = useState(false);
    // console.warn("<<<<<< getcookies from adminMenu component  >>>>>>", getcookies)

    const location = useLocation();
    const { pathname } = location;
    const { id } = useParams();
    const { _id } = useParams();
    const splitLocation = pathname.split("/");
    let userType = useSelector(state => (state.loginSlice.userInfo?.user_types) ? state.loginSlice.userInfo?.user_types[0]?.slug : null);

    // console.log("splitLocation", _id, pathname);

    // ----------------------------------------Components Private State---------------------------------------- //
    const [anchorEl4, setAnchorEl4] = useState(null);
    const [anchorEl5, setAnchorEl5] = useState(null);
    const [anchorEl6, setAnchorEl6] = useState(null);
    const [anchorElFaq, setAnchorElFaq] = useState(null);
    const [anchorEl7, setAnchorEl7] = useState(null);
    const [anchorEl8, setAnchorEl8] = useState(null);
    const [anchorEl9, setAnchorEl9] = useState(null);
    const [eventBodyList, setEventBodyList] = useState(
        {
            "condition": {
                "limit": 10,
                "skip": 0
            },
            "sort": {
                "field": "start_date_unix",
                "type": "desc"
            },
            "searchcondition": {},
            count: false
        }
    );
    const [bookingBodyList, setBookingBodyList] = useState(
        {
            "condition": {
                "limit": 5,
                "skip": 0
            },
            "sort": {
                "field": "_id",
                "type": "desc"
            },
            "searchcondition": {
            },
            "count": false

        }
    );


    // ---------------------------------------------Other Handelers--------------------------------------------- //
    const handleClick4 = (event) => setAnchorEl4(event.currentTarget);
    const open4 = Boolean(anchorEl4);
    const handleClose4 = () => setAnchorEl4(null);
    const handleClick5 = (event) => {
        console.log("event handleClick5", event)
        setAnchorEl5(event.currentTarget);
        setChildMenuActive(true)
    }
    const open5 = Boolean(anchorEl5);
    const handleClose5 = () => {
        setAnchorEl5(null);

        setChildMenuActive(false)
    }
    const handleClick6 = (event) => setAnchorEl6(event.currentTarget);

    const open6 = Boolean(anchorEl6);
    const handleClose6 = () => setAnchorEl6(null);

    const handleClickFaq = (event) => setAnchorElFaq(event.currentTarget);
    const openFaq = Boolean(anchorElFaq);
    const handleCloseFaq = () => {
        setAnchorElFaq(null)
    };

    const handleClick7 = (event) => {
        setAnchorEl7(event.currentTarget);
        setChildMenuActive(true)
    }
    const open7 = Boolean(anchorEl7);
    const handleClose7 = () => {
        setAnchorEl7(null);
        setChildMenuActive(false)
    }
    const handleClick8 = (event) => {
        setAnchorEl8(event.currentTarget);
        setChildMenuActive(true)
    }
    const open8 = Boolean(anchorEl8);
    const handleClose8 = () => {
        setAnchorEl8(null);
        setChildMenuActive(false)
    }
    const handleClick9 = (event) => setAnchorEl9(event.currentTarget);
    const open9 = Boolean(anchorEl9);
    const handleClose9 = () => setAnchorEl9(null);

    // const [selectMenu, setselectMenu] = React.useState(null);
    // const [anchorEl, setAnchorEl] = React.useState(null);
    // const [anchorE2, setAnchorE2] = React.useState(null);
    // const open = Boolean(anchorEl);
    // const [isActive, setIsActive] = useState(false);
    // const handleClick = (val, event) => {
    //     setIsActive((current) => !current);
    //     setselectMenu(val);
    // };

    // const [isHidedropdown, setHidedropdown] = useState(false);
    // const hidedropdown = (event) => {
    //     setHidedropdown((current) => !current);
    //     setIsActive(false);
    // };

    // const handleToggle = (temp) => {
    //     if (temp == "HeaderMenuToggle") setIsActive(!isActive);
    // };
    // const [userInfo, setuserInfoCookie] = useCookies(["userinfo"]);

    // let logoutSucess = useSelector((state) =>
    //     state.loginSlice?.message !== null &&
    //         state.loginSlice?.message === "Logout successful"
    //         ? true
    //         : false
    // );

    // const clickedLogout = () => {
    //     console.warn("<<<<< inside clicked logut");

    //     let data = {
    //         _id: userInfo?.userinfo?._id,
    //     };

    //     dispatch(logOut());

    //     // setLogoutClick(true);
    //     setAnchorEl(null);

    //     cookies.remove("userinfo", { path: "/" });
    //     cookies.remove("lastLoginTime", { path: "/" });
    //     cookies.remove("token", { path: "/" });
    //     setIsActive(false);

    //     navigate("/login");

    //     // dispatch(logOut());
    //     dispatch(userLogout(data));
    //     // dispatch(setloginRoute(false));
    // };

    // const [anchorEl3, setAnchorEl3] = React.useState(null);

    // const handleClose3 = () => {
    //     setAnchorEl3(null);
    // };

    // const handleClose = () => {
    //     // console.log('before close ..', open, open1, anchorEl, selectMenu)
    //     setAnchorEl(null);
    //     setAnchorE2(null);
    // };

    // const open3 = Boolean(anchorEl3);
    // const handleClick3 = (event) => {
    //     setAnchorEl3(event.currentTarget);
    // };

    // let resolveloader = useSelector((state) =>
    //     state.ResolveReducer?.loader !== null &&
    //         state.ResolveReducer?.loader !== undefined
    //         ? state.ResolveReducer.loader
    //         : false
    // );

    let reqbodyforRole = {
        condition: {
            limit: 10,
            skip: 0,
        },
        sort: {
            field: "updatedon_datetime",
            type: "desc",
        },
        searchcondition: {},
        project: {},
        token: "",
    };
    let reqbodyforprogram = {
        condition: {
            limit: 10,
            skip: 0,
        },
        sort: {
            field: "updatedon_datetime",
            type: "desc",
        },
        searchcondition: {},
        project: {},
        token: "",
    };
    let reqbodyforjobcategory = {
        condition: {
            limit: 10,
            skip: 0,
        },
        sort: {
            field: "createdon_datetime",
            type: "desc",
        },
        searchcondition: {},
        project: {},
        token: "",
    };


    let reqbodyforgetlead = {
        condition: {
            limit: 10,
            skip: 0,
        },
        sort: {
            field: "createdon_datetime",
            type: "desc",
        },
        searchcondition: {},
        project: {},
        token: "",
    };


    let reqbodyforgethmGroup = {
        condition: {
            limit: 10,
            skip: 0,
        },
        sort: {
            field: "_id",
            type: "desc",
        },
        searchcondition: {},
        project: {},
        token: "",
    };










    let userslug = useSelector(state => (state.loginSlice?.user_slug && state.loginSlice?.user_slug.length > 0) ? state.loginSlice.user_slug : [])

    let reqbodyformeterilcenter = {
        "roles": userslug,
        "searchcondition": {},
    }

    const dashboardLoadFunc = async () => {
        await inputExternalPlugin("dashboard", "https://d23yaxy82yyejl.cloudfront.net/pece-marketing-deploy/angular-element/chart-elements.js");
        setTimeout(() => {
            resolvefunction(navigate, '/dashboard');
            document.location.reload();
        }, 1500)
    }



    const hmdashboardLoadFunc = async () => {
        await inputExternalPlugin("hm-dashboard", "https://d23yaxy82yyejl.cloudfront.net/pece-marketing-deploy/angular-element/chart-elements.js");
        setTimeout(() => {
            resolvefunction(navigate, '/hm-dashboard');
            document.location.reload();
        }, 1500)
    }
    const aerdashboardLoadFunc = async () => {
        await inputExternalPlugin("aer-dashboard", "https://d23yaxy82yyejl.cloudfront.net/pece-marketing-deploy/angular-element/chart-elements.js");
        setTimeout(() => {
            resolvefunction(navigate, '/rep-aer-dashboard');
            document.location.reload();
        }, 1500)
    }
    const repdashboardLoadFunc = async () => {
        await inputExternalPlugin("rep-dashboard", "https://d23yaxy82yyejl.cloudfront.net/pece-marketing-deploy/angular-element/chart-elements.js");
        setTimeout(() => {
            resolvefunction(navigate, '/rep-aer-dashboard');
            document.location.reload();
        }, 1500)
    }

    const trainingCenterFUnc = async () => {
        await inputExternalPlugin("training-center", "https://d23yaxy82yyejl.cloudfront.net/pece-marketing-deploy/angular-element/elements.js");
        resolvefunction(navigate, "/training-center", [
            trainingCenterCategoryFetching()
        ]);
        setTimeout(() => {
            document.location.reload();
        }, 3000)
    }

    //----------------------------------------------return-----------------------------------------------//
    return (
        <div>
            <ul>
                {/* Dashboard */}

                {userType === "is_admin" &&


                    <li>
                        <button onClick={() => { dashboardLoadFunc() }} className={(pathname && pathname == '/dashboard') ? 'active' : ''}>
                            <MdDashboard />

                            <span>Dashboard</span>
                        </button>
                    </li>


                }


                {userType === "hr_manager" &&


                    <li>
                        <button onClick={() => { hmdashboardLoadFunc() }} className={(pathname && pathname == '/hm-dashboard') ? 'active' : ''}>
                            <MdDashboard />

                            <span>Dashboard</span>
                        </button>
                    </li>



                }

                {userType === "is_aer" &&


                    <li>
                        <button onClick={() => { aerdashboardLoadFunc() }} className={(pathname && pathname == '/aer-dashboard') ? 'active' : ''}>
                            <MdDashboard />

                            <span>Dashboard</span>
                        </button>
                    </li>


                }

                {userType === "is_rep" &&


                    <li>
                        <button onClick={() => { repdashboardLoadFunc() }} className={(pathname && pathname == '/rep-dashboard') ? 'active' : ''}>
                            <MdDashboard />

                            <span>Dashboard</span>
                        </button>
                    </li>


                }




                {/* Users */}
                {(getcookies?.userinfo?.roles?.is_admin == 1 || getcookies?.userinfo?.roles?.is_aer == 1 || getcookies?.userinfo?.roles?.hr_manager == 1 || getcookies?.userinfo?.roles?.is_bdd == 1) ? (<li>
                    <button
                        onClick={() => resolvefunction(navigate, "/usermanagement", [getusersList()])}

                        className={pathname && pathname == "/usermanagement" ? "active" : ""}
                    >
                        <MdPerson />
                        <span>Users</span>
                    </button>
                </li>) : ""}

                {/* Lead */}
                <li>
                    <button
                        onClick={() => resolvefunction(navigate, "/lead-list", getleaddata(reqbodyforgetlead))}
                        className={
                            pathname &&
                                (pathname == "/lead-list" ||
                                    pathname == `/lead-folder-view/${id}` ||
                                    pathname == `/lead-edit/${id}` ||
                                    pathname == "/lead-add")
                                ? "active"
                                : ""
                        }
                    >
                        <MdLeaderboard />
                        <span>Lead</span>
                    </button>
                </li>

                {/* Training */}
                <li>
                    <Button
                        id="demo-positioned-button"
                        aria-controls={open4 ? "demo-positioned-menu" : undefined}
                        aria-haspopup="true"
                        aria-expanded={open4 ? "true" : undefined}
                        onClick={handleClick4}
                        endIcon={<KeyboardArrowDownIcon />}
                        className={
                            pathname == "/training-category/list" ||
                                pathname == "/add-training" ||
                                pathname == `/edit-training/${_id}` ||
                                pathname == "/training-center" ||
                                pathname == "/training-lesson/list" ||
                                pathname == "/add-lesson" ||
                                pathname == `/edit-lesson/${_id}` ||
                                pathname === "/training-material" ||
                                pathname == "/material-add" ||
                                pathname == `/material-edit/${_id}` ||
                                pathname === "/quizzes" ||
                                pathname === "/quiz-add" ||
                                pathname === `/quiz-edit/${_id}`
                                ? "active"
                                : "inactive"
                        }
                    >


                        <MdOutlineModelTraining />
                        <span>Training</span>
                        <MdKeyboardArrowDown className="leftArrowIcon" />
                    </Button>

                </li>

                <li>
                    <Menu
                        // className="addVid_dropDwn"
                        className={topmenu ? 'addVid_dropDwn topmenu' : 'addVid_dropDwn'}

                        id="demo-positioned-menu"
                        aria-labelledby="demo-positioned-button"
                        anchorEl={anchorEl4}
                        open={open4}
                        onClose={handleClose4}
                        anchorOrigin={{
                            vertical: "top",
                            horizontal: "left",
                        }}
                        transformOrigin={{
                            vertical: "top",
                            horizontal: "left",
                        }}
                    >
                        <MenuItem
                            className={
                                splitLocation[1] === "training-list"
                                    ? "submenu_item active"
                                    : "submenu_item"
                            }
                            onClick={() => {
                                handleClose4();
                                resolvefunction(navigate, "/training-category/list", [
                                    Traininglist(),
                                ]);
                            }}
                        >

                            <span> Training </span>
                        </MenuItem>
                        <MenuItem
                            onClick={() => {
                                handleClose4();
                                trainingCenterFUnc();


                            }}
                        >
                            <span>Training Center</span>
                        </MenuItem>
                        <MenuItem
                            className={
                                splitLocation[1] === "lesson-list"
                                    ? "submenu_item active"
                                    : "submenu_item"
                            }
                            onClick={() => {
                                handleClose4();
                                resolvefunction(navigate, "/training-lesson/list", [
                                    Lessonlist(),
                                ]);
                            }}
                        >
                            <span> Lessons </span>
                        </MenuItem>
                        <MenuItem
                            className={
                                splitLocation[1] === "training-material"
                                    ? "submenu_item active"
                                    : "submenu_item"
                            }
                            onClick={() => {
                                handleClose4();
                                resolvefunction(
                                    navigate,
                                    "/training-material",
                                    fetchTrainingMaterial()
                                );
                            }}
                        >
                            <span> Material </span>
                        </MenuItem>
                        <MenuItem
                            className={
                                splitLocation[1] === "quizzes"
                                    ? "submenu_item active"
                                    : "submenu_item"
                            }
                            onClick={() => {
                                handleClose4();
                                resolvefunction(
                                    navigate,
                                    "/quizzes",
                                    fetchQuizList()
                                );
                            }}
                        >
                            <span> Quizzes </span>
                        </MenuItem>

                        <MenuItem
                            className={
                                splitLocation[1] === "training-material-centar-list"
                                    ? "submenu_item active"
                                    : "submenu_item"
                            }
                            onClick={() => {
                                handleClose4();
                                resolvefunction(
                                    navigate,
                                    "/training-material-centar-list",
                                    TrainingMaterialCenterlist(reqbodyformeterilcenter)
                                );
                            }}
                        >
                            <span> Training Material Center </span>
                        </MenuItem>

                    </Menu>
                </li>

                {/* *********************Drop Down for Job Ticket********************* */}
                <li>
                    <Button
                        id="demo-positioned-button"
                        aria-controls={open6 ? "demo-positioned-menu" : undefined}
                        aria-haspopup="true"
                        aria-expanded={open6 ? "true" : undefined}
                        onClick={handleClick6}
                        endIcon={<KeyboardArrowDownIcon />}
                        className={(pathname == "/jobticket-category" || pathname == "/manage-jobtickets" || pathname == "/add-jobtickets-category") ? "active" : "inactive"}

                    >

                        <MdConfirmationNumber />
                        <span>Job Ticket</span>

                        <MdKeyboardArrowDown className="leftArrowIcon" />
                    </Button>


                </li>
                {/* Job Ticket Menus */}
                <li>
                    <Menu
                        // className="addVid_dropDwn"
                        className={topmenu ? 'addVid_dropDwn topmenu' : 'addVid_dropDwn'}
                        id="demo-positioned-menu"
                        aria-labelledby="demo-positioned-button"
                        anchorEl={anchorEl6}
                        open={open6}
                        onClose={handleClose6}
                        anchorOrigin={{
                            vertical: "top",
                            horizontal: "left",
                        }}
                        transformOrigin={{
                            vertical: "top",
                            horizontal: "left",
                        }}
                    >
                        {/* <MenuItem
                            className={
                                splitLocation[1] === "jobticket-category"
                                    ? "submenu_item active"
                                    : "submenu_item"
                            }
                            onClick={() => {
                                resolvefunction(navigate, "/jobticket-category", [
                                    getjobcategorydata(reqbodyforprogram),
                                ]);
                            }}
                        >
                        </MenuItem> */}
                        <MenuItem
                            className={
                                splitLocation[1] === "jobticket-category"
                                    ? "submenu_item active"
                                    : "submenu_item"
                            }
                            onClick={() => {
                                resolvefunction(navigate, "/jobticket-category", [
                                    getjobcategorydata(reqbodyforjobcategory),
                                ]);
                            }}
                        >
                            <span onClick={handleClose6}> Job Ticket Category</span>
                        </MenuItem>
                        <MenuItem
                            className={
                                splitLocation[1] === "manage-jobtickets"
                                    ? "submenu_item active"
                                    : "submenu_item"
                            }
                            onClick={() => {

                                resolvefunction(navigate, "/manage-jobtickets", [
                                    getjobticketlistdata(reqbodyforjobcategory),
                                ]);
                            }}
                        >
                            <span onClick={handleClose6}>Manage Job Tickets</span>
                        </MenuItem>
                    </Menu>
                </li>














                <li>
                    <Button
                        id="demo-positioned-button"
                        aria-controls={openFaq ? "demo-positioned-menu" : undefined}
                        aria-haspopup="true"
                        aria-expanded={openFaq ? "true" : undefined}
                        onClick={handleClickFaq}
                        endIcon={<KeyboardArrowDownIcon />}
                        className={(pathname == "/faq-category" || pathname == "/faq" || pathname == "/add-faq") ? "active" : "inactive"}

                    >

                        <MdConfirmationNumber />
                        <span>Faq Management</span>

                        <MdKeyboardArrowDown className="leftArrowIcon" />
                    </Button>


                </li>
                {/* Job Ticket Menus */}
                <li>
                    <Menu
                        // className="addVid_dropDwn"
                        className={topmenu ? 'addVid_dropDwn topmenu' : 'addVid_dropDwn'}
                        id="demo-positioned-menu"
                        aria-labelledby="demo-positioned-button"
                        anchorEl={anchorElFaq}
                        open={openFaq}
                        onClose={handleCloseFaq}
                        anchorOrigin={{
                            vertical: "top",
                            horizontal: "left",
                        }}
                        transformOrigin={{
                            vertical: "top",
                            horizontal: "left",
                        }}
                    >
                        {/* <MenuItem
                            className={
                                splitLocation[1] === "jobticket-category"
                                    ? "submenu_item active"
                                    : "submenu_item"
                            }
                            onClick={() => {
                                resolvefunction(navigate, "/jobticket-category", [
                                    getjobcategorydata(reqbodyforprogram),
                                ]);
                            }}
                        >
                        </MenuItem> */}
                        <MenuItem
                            className={
                                splitLocation[1] === "faq"
                                    ? "submenu_item active"
                                    : "submenu_item"
                            }
                            onClick={() => {
                                resolvefunction(navigate, "/faq", [
                                    getfaqdata(reqbodyforjobcategory),
                                ]);
                            }}
                        >
                            <span onClick={handleCloseFaq}> Faq </span>
                        </MenuItem>
                        <MenuItem
                            className={
                                splitLocation[1] === "faq-category"
                                    ? "submenu_item active"
                                    : "submenu_item"
                            }
                            onClick={() => {

                                resolvefunction(navigate, "/faq-category", [
                                    getfaqcategorydata(reqbodyforjobcategory),
                                ]);
                            }}
                        >
                            <span onClick={handleCloseFaq}>Faq Category</span>
                        </MenuItem>
                    </Menu>
                </li>


















                {/* Calendar Availability */}
                <li>
                    <button
                        onClick={() => {
                            resolvefunction(
                                navigate,
                                "/avilibility-list",
                                fetchEventsData(eventBodyList)
                            );
                        }}
                        className={
                            splitLocation[1] === "avilibility-list" || splitLocation[1] === "available-slots" || splitLocation[1] === "create-event" || splitLocation[1] === "booked-slot" ? "active" : ""
                        }
                    >
                        <MdLocalLibrary />
                        <span>Calendar Availability</span>
                    </button>
                </li>

                {/***************Drop Down for Form Miscellaneous************************** */}
                <li>
                    <Button
                        id="demo-positioned-button"
                        aria-controls={open7 ? "demo-positioned-menu" : undefined}
                        aria-haspopup="true"
                        aria-expanded={open7 ? "true" : undefined}
                        onClick={handleClick9}
                        endIcon={<KeyboardArrowDownIcon />}
                        className={
                            pathname == "/contract-list" ||
                                pathname == "/contract-slug-list" ||
                                pathname == "/list-campaign" ||
                                pathname == "/list-landingpage" ||
                                pathname == "/program-list" ||
                                pathname === "/role-list" ||
                                pathname === "/form-module/list" ||
                                pathname === "/group-module/list" ||
                                pathname === "/field-module/list" ||
                                pathname === "/email-template-list" ||
                                pathname === "/email-slug" ||
                                pathname === "/tag-list" ||
                                pathname === "/stage-distribution-list" ||
                                pathname === "/miscellaneous" ||
                                pathname === "/trigger-list" ||
                                pathname === "/trigger-add"
                                ? "active"
                                : "inactive"
                        }
                    >
                        <VscSymbolMisc />
                        <span>Miscellaneous</span>
                        <MdKeyboardArrowDown className="leftArrowIcon" />
                    </Button>
                </li>

                <li>
                    <Menu
                        className={topmenu ? 'addVid_dropDwn topmenu' : 'addVid_dropDwn'}
                        id="demo-positioned-menu"
                        aria-labelledby="demo-positioned-button"
                        anchorEl={anchorEl9}
                        open={open9}
                        onClose={handleClose9}
                        anchorOrigin={{
                            vertical: "top",
                            horizontal: "left",
                        }}
                        transformOrigin={{
                            vertical: "top",
                            horizontal: "left",
                        }}
                    >

                        {/* Role Section */}
                        {/* <MenuItem
                            onClick={() => {
                                handleClose9();
                                resolvefunction(navigate, "/role-list", [getroledata(reqbodyforRole)]);
                            }}
                        >
                            <span>Role</span>
                        </MenuItem> */}

                        {/* Landing Page Section */}
                        <MenuItem
                            onClick={() => {
                                handleClose9();
                                resolvefunction(navigate, "/list-landingpage", [getLandingListData(reqbodyforRole)]);
                            }}
                        >
                            <span>Landing Page</span>
                        </MenuItem>


                        {/* Information link Section */}

                        <MenuItem
                            onClick={() => {
                                handleClose9();
                                resolvefunction(navigate, "/list-information", [getInformationListData(reqbodyforRole)]);
                            }}
                        >
                            <span>Information Link</span>
                        </MenuItem>



                        {/* Campaign Section */}
                        <MenuItem
                            onClick={() => {
                                handleClose9();
                                resolvefunction(navigate, "/list-campaign", [getCampaignListData(reqbodyforRole)]);
                            }}
                        >
                            <span>Campaign</span>
                        </MenuItem>

                        {/* Program Section */}
                        <MenuItem
                            onClick={() => {
                                handleClose9();
                                resolvefunction(navigate, "/program-list", [getprogramdata(reqbodyforprogram)]);
                            }}
                        >
                            <span>Program</span>
                        </MenuItem>

                        {/* Contract Section */}
                        <MenuItem>
                            {/* <-------------------Drop Down for Contract-------------------> */}
                            <li>
                                <Button
                                    id="demo-positioned-button"
                                    aria-controls={open5 ? "demo-positioned-menu" : undefined}
                                    aria-haspopup="true"
                                    aria-expanded={open5 ? "true" : undefined}
                                    onClick={handleClick5}
                                    endIcon={<KeyboardArrowDownIcon />}
                                >
                                    <span>Contract</span>
                                </Button>
                            </li>

                            <li >
                                <Menu
                                    // className="addVid_dropDwn"
                                    className={childMenuActive ? 'childMenuActive topmenu' : 'addVid_dropDwn'}
                                    id="demo-positioned-menu"
                                    aria-labelledby="demo-positioned-button"
                                    anchorEl={anchorEl5}
                                    open={open5}
                                    onClose={handleClose5}
                                    anchorOrigin={{
                                        vertical: "top",
                                        horizontal: "left",
                                    }}
                                    transformOrigin={{
                                        vertical: "top",
                                        horizontal: "left",
                                    }}
                                >
                                    <MenuItem className="childMenu"
                                        onClick={() => resolvefunction(navigate, "/contract-list", [getContractListData()])}
                                    >
                                        <span onClick={() => { handleClose5(); handleClose9() }}> Contract </span>
                                    </MenuItem>
                                    <MenuItem
                                        onClick={() => resolvefunction(navigate, "/contract-slug-list", [getContractSlagData()])}
                                    >
                                        <span onClick={() => { handleClose5(); handleClose9() }}> Contract Slug </span>
                                    </MenuItem>
                                </Menu>
                            </li>
                        </MenuItem>

                        {/* Form Management Section */}
                        <MenuItem>
                            {/* <-------------------Drop Down for Form Management-------------------> */}
                            <li>
                                <Button
                                    id="demo-positioned-button"
                                    aria-controls={open7 ? "demo-positioned-menu" : undefined}
                                    aria-haspopup="true"
                                    aria-expanded={open7 ? "true" : undefined}
                                    onClick={handleClick7}
                                    endIcon={<KeyboardArrowDownIcon />}
                                    className={pathname == "/form-module/list" ? "active" : "inactive"}
                                >
                                    <span>Form Management</span>
                                </Button>

                            </li>

                            <li>
                                <Menu
                                    // className="addVid_dropDwn"
                                    className={childMenuActive ? 'childMenuActive topmenu' : 'addVid_dropDwn'}

                                    id="demo-positioned-menu"
                                    aria-labelledby="demo-positioned-button"
                                    anchorEl={anchorEl7}
                                    open={open7}
                                    onClose={handleClose7}
                                    anchorOrigin={{
                                        vertical: "top",
                                        horizontal: "left",
                                    }}
                                    transformOrigin={{
                                        vertical: "top",
                                        horizontal: "left",
                                    }}
                                >

                                    <MenuItem
                                        onClick={() => {
                                            handleClose7();
                                            handleClose9();
                                            resolvefunction(navigate, "/form-module/list", [getformdata()]);
                                        }}
                                    >
                                        <span> Form Module </span>
                                    </MenuItem>

                                    <MenuItem
                                        onClick={() => {
                                            handleClose7();
                                            handleClose9();
                                            resolvefunction(navigate, "/group-module/list", [groupList()]);
                                        }}
                                    >
                                        <span> Group Module </span>
                                    </MenuItem>

                                    <MenuItem
                                        onClick={() => {
                                            handleClose7();
                                            handleClose9();
                                            resolvefunction(navigate, "/field-module/list", [fieldList()]);
                                        }}
                                    >
                                        <span>Field Module</span>
                                    </MenuItem>
                                </Menu>
                            </li>
                        </MenuItem>

                        {/* Tag Management Under Miscellaneous */}
                        <MenuItem
                            onClick={() => {
                                handleClose9();
                                resolvefunction(navigate, "/tag-list", [getTagListdata()]);
                            }}
                        >
                            <span>Tag Management</span>
                        </MenuItem>

                        {/* Stage Distribution Under Miscellaneous */}
                        <MenuItem
                            onClick={() => {
                                handleClose9();
                                resolvefunction(navigate, "/stage-distribution-list", [stageListData()]);
                            }}
                        >
                            <span>Stage Distribution</span>
                        </MenuItem>

                        {/* Calendar Report Under Miscellaneous */}
                        <MenuItem
                            onClick={() => {
                                handleClose9();
                                resolvefunction(navigate, "/miscellaneous", [bookingListData(bookingBodyList)]);
                            }}
                        >
                            <span>Calendar Report</span>
                        </MenuItem>

                        {/* Email Template Section */}
                        <MenuItem>
                            {/* <-------------------Drop Down for Email Template-------------------> */}
                            <li>
                                <Button
                                    id="demo-positioned-button"
                                    aria-controls={open8 ? "demo-positioned-menu" : undefined}
                                    aria-haspopup="true"
                                    aria-expanded={open8 ? "true" : undefined}
                                    onClick={handleClick8}
                                    endIcon={<KeyboardArrowDownIcon />}
                                >
                                    <span>Email Template</span>
                                </Button>
                            </li>

                            <li>
                                <Menu
                                    className={childMenuActive ? 'childMenuActive topmenu' : 'addVid_dropDwn'}
                                    id="demo-positioned-menu"
                                    aria-labelledby="demo-positioned-button"
                                    anchorEl={anchorEl8}
                                    open={open8}
                                    onClose={handleClose8}
                                    anchorOrigin={{
                                        vertical: "top",
                                        horizontal: "left",
                                    }}
                                    transformOrigin={{
                                        vertical: "top",
                                        horizontal: "left",
                                    }}
                                >
                                    <MenuItem
                                        onClick={() => {
                                            resolvefunction(navigate, "/email-template-list", [
                                                getEmailTeamplatedata(),
                                            ]);
                                        }}
                                    >
                                        <span onClick={() => { handleClose8(); handleClose9(); }}> Email</span>
                                    </MenuItem>
                                    <MenuItem
                                        onClick={() => {
                                            resolvefunction(navigate, "/email-slug", [
                                                getEmailSlagData(),
                                            ]);
                                        }}
                                    >
                                        <span onClick={() => { handleClose8(); handleClose9(); }}> Email Slug </span>
                                    </MenuItem>
                                </Menu>
                            </li>
                        </MenuItem>
                        <MenuItem
                            onClick={() => {
                                handleClose9();
                                resolvefunction(navigate, "/trigger-list");
                            }}
                        >
                            <span>Trigger</span>
                        </MenuItem>
                        <MenuItem
                            onClick={() => {
                                handleClose9();
                                resolvefunction(navigate, "/bluecoast-list");
                            }}
                        >
                            <span>BlueCoast Lead</span>
                        </MenuItem>

                    </Menu>
                </li>


                <li>
                    <button
                        onClick={() => {
                            resolvefunction(
                                navigate,
                                "/hiring-managment",
                                getHmGridData(reqbodyforprogram)
                            );
                        }}
                        className={
                            pathname && pathname == "/hiring-managment" ? "active" : ""
                        }
                    >
                        <MdManageAccounts />

                        <span>HM Setting</span>
                    </button>
                </li>

                {/* HM Group */}
                <li>
                    <button
                        onClick={() => resolvefunction(navigate, "/hm-group-list", getHmGroupListdata(reqbodyforgethmGroup))}
                        className={
                            pathname &&
                                (pathname == "/hm-group-list" ||
                                    pathname == `/hm-group-edit/${id}` ||
                                    pathname == "/hm-group-add")
                                ? "active"
                                : ""
                        }
                    >
                        <MdGroups />
                        <span>HM Group</span>
                    </button>
                </li>


                <li>
                    <button
                        onClick={() => {
                            resolvefunction(
                                navigate,
                                "/manage-round-rodin",
                                getRoundRobinData(reqbodyforprogram)
                            );
                        }}
                        className={
                            pathname && pathname == "/manage-round-rodin" ? "active" : ""
                        }
                    >
                        <MdAppRegistration />

                        <span>Manage Round Robin</span>
                    </button>
                </li>
                {/* <li>
                    <button
                        onClick={() => {
                            resolvefunction(
                                navigate,
                                "/trigger-list",
                                gettriggerdata()
                            );
                        }}
                        className={
                            pathname && pathname == "/trigger-list" ? "active" : ""
                        }
                    >
                        <MdManageAccounts />

                        <span>Trigger</span>
                    </button>
                </li> */}


            </ul>
        </div>
    )
}

export default AdminMenu