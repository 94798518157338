import { IconButton, Paper, Tooltip } from '@mui/material'
import React, { useEffect, useState } from 'react'
import { Form } from '../../../form/Form'
import CloseIcon from '@mui/icons-material/Close';
import { useDispatch, useSelector } from 'react-redux';
import { getHmGridData } from '../HmDashboard/HmDashboardReducer';




const generateBody = (data) => {
 
  console.log("data111", data);
  let modifiedBody = {
    "source": "",
    "condition": {
      "limit": 10,
      "skip": 0
    },
    "sort": {
      "type": "desc",
      "field": "_id"
    },
    "searchcondition": {
      // "createdon_datetime":{
      //   "$gte":data.start_date,
      //   "$lte":data.end_date
      // }
     
    }
    
  };

  // delete modifiedBody.searchcondition.start_date

  Object.keys(data).forEach((curKey) => {
    console.log("data1113", curKey, data[curKey]);



    if (data[curKey] !== undefined && curKey !== 'formId') 
     
    data.start_date || data.end_date?(modifiedBody.searchcondition["createdon_datetime"] = {"$gte":data.start_date,"$lte":data.end_date}):(modifiedBody.searchcondition[curKey] = data[curKey])

    // 
    
    if (Array.isArray(modifiedBody.searchcondition[curKey]) && modifiedBody.searchcondition[curKey].length == 0)
      delete modifiedBody.searchcondition[curKey];
    console.log("modifiedBody111", modifiedBody);
  })
  return modifiedBody;
}




const SearchHm = ({ handleCloseDrawer }) => {

  const hmSearchedData = useSelector(state => state.HmDashboardReducer?.hmGridData ? state.HmDashboardReducer.hmGridData : [])
  const hmGridDataCount = useSelector(state => state.HmDashboardReducer?.hmGridDataCount ? state.HmDashboardReducer.hmGridDataCount : 0)
  const [searchBody, setSearchBody] = useState(null);

  const dispatch = useDispatch();

  const searchFormSubmissionState = useSelector(state => (state.formSlice.formSubmissionState &&
    state.formSlice.formSubmissionState.hmSearchForm === 4) ? true : false);
  const searchFormData = useSelector(state => state.formSlice.formData.hmSearchForm ?
    state.formSlice.formData.hmSearchForm : null);







  const dateCheck = () => {
    console.log("result----->datecount hit ========", searchFormData);
    if (
      searchFormData !== undefined &&
      searchFormData.start_date !== undefined &&
      searchFormData.start_date !== null &&
      searchFormData.end_date !== undefined &&
      searchFormData.end_date !== null &&
      searchFormData.end_date <= searchFormData.start_date
    ) {
      console.log("result----->2222", searchFormData);
      return false;
    }
    return true;
  };

  const dataform = {
    id: 'hmSearchForm',
    // api_url: process.env.REACT_APP_API_URL,
    // endPoint: "api/listingusers",
    submitBtnName: "Search",
    submitBtnEndIcon: 'search_icon',
    resetForm: true,
    resetBtnName: "Refresh",
    resetBtnEndIcon: 'refresh',
    endPointBodyWrapper: "searchcondition",
    // className: 'inputBlock inputBlock3line',
    formButtonClass: "submitbtnsection",
    formWrapperClass: "formWrapperClass formCls",
    formInlineClass: "backendTableInline_addform testwrpr trainingcata",
    formAdditionalSubmissionData: {

    },


    fields: [
      {
        id: 0,
        label: "Search By Name",
        name: "_id",
        type: "autoComplete",
        // className: "inputBlock inputBlock2line",
        className: 'inputBlock inputBlock1line',
        base_url: process.env.REACT_APP_API_URL + "users/auto-complete-user-name",
        reqBody: {
          "condition": {
            "limit": 10,
            "skip": 0
          },
          "sort": {
            "type": "asc",
            "field": "name"
          },
          "searchcondition": {
            "status": 1,
            "roles.hr_manager": 1
          }
        },
        sx: { m: 1, minWidth: 300 },
      },
      {
        id: 1,
        label: "Search By Email",
        name: "email",
        type: "autoComplete",
        // className: "inputBlock inputBlock2line",
        className: 'inputBlock inputBlock3line',
        base_url: process.env.REACT_APP_API_URL + "users/auto-complete-user-mail",
        reqBody: {
          "condition": {
            "limit": 10,
            "skip": 0
          },
          "sort": {
            "type": "asc",
            "field": "name"
          },
          "searchcondition": {
            "status": 1,
            "roles.hr_manager": 1
          },
       
        },
        sx: { m: 1, minWidth: 300 },
      },
      // {
      //   id: 3,
      //   label: "Search by Status",
      //   name: "status",
      //   className: "inputBlock inputBlock3line",

      //   type: 'select',
      //   multiple: false,
      //   values: [
      //     { val: 1, name: "Active" },
      //     { val: 0, name: "InActive" }


      //   ],
      //   sx: { m: 1, minWidth: 120 },
      // },
      {
        id: 8,
        label: "Search by Start Date",
        name: "start_date",
        // className: "inputBlock inputBlock2line",
        className: 'inputBlock inputBlock3line',
        type: 'datePicker',
        sx: { m: 1, width: 300 },
        dateFormat: 'dd/MM/yyyy',
        // payloadFormat: { key: "createdon_datetime", param: "$gte" }

      },
      {
        id: 9,
        label: "Search by End Date",
        name: "end_date",
        className: 'datePicker',
        type: 'datePicker',
        fullDay: true,
        sx: { m: 1, width: 300 },
        dateFormat: 'dd/MM/yyyy',
        // payloadFormat: { key: "createdon_datetime", param: "$lte" },
        // className: "inputBlock inputBlock2line",
        className: 'inputBlock inputBlock3line',

        rules: { validate: { dateCheck } },
        errorMessage: {
          dateCheck: "End Date can not be less than Start Date",
        },
      },



    ],

  }


  useEffect(() => {
    if (searchFormSubmissionState == true && searchFormData !== null) {
      const body = generateBody(searchFormData);
      setSearchBody(body);
      console.log('body', body);
      console.log('searchFormData', searchFormData);

      dispatch(getHmGridData(body));

      let reqBody = { ...body, count: true }

      console.log("reqBody", reqBody);


      dispatch(getHmGridData(reqBody));



    }
  }, [searchFormSubmissionState, searchFormData])





  return (
    <Paper elevation={7} className='list_search team_management_list_search search_img'>


      <div className="searchBarHead DrawerBox">
        <h2 className="searchBarHeading">Search HM</h2>
        <span className="searchBarCloseIcon">
          <Tooltip title='Close' >
            <IconButton >
              <CloseIcon className="closeDrawer" onClick={() => handleCloseDrawer()} />
              {/* <CloseIcon className="closeDrawer"  /> */}
            </IconButton>
          </Tooltip>
        </span>
      </div>

      <Form formData={dataform} />
    </Paper>
  )
}

export default SearchHm