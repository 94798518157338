import { Button, Grid, LinearProgress } from "@mui/material";
import { Box } from "@mui/system";
import { useEffect, useRef, useState } from "react";
import { Cookies } from "react-cookie";
import { useDispatch, useSelector } from "react-redux"
import { useNavigate, useParams } from "react-router-dom";
import CategoryIcon from "@mui/icons-material/Category";
import store from "../../../../../store";
import { fetchInitialLession, resetAllDataInTraingcenter, resetInitialLessionIDshow, resetLessonLoadMore, trainingCenterCategoryFetching, trainingCenterCategoryFetchingLoadMore } from "./TrainingCenterCategoryReducer";

export const TrainingCenterCategory = () => {
    // ---------------------------------------------Invoking Hooks--------------------------------------------- //
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const { cat_id, lesson_id } = useParams();
    // const { lesson_id } = useParams();
    const mediasec = useRef(null);
    const cookies = new Cookies();
    const getcookies = cookies.getAll();

    // ----------------------------------------Global State from Redux---------------------------------------- //
    let categoryList = useSelector((state) =>
        state.trainingCenterCategorySlice?.trainingCategoryList &&
            state.trainingCenterCategorySlice?.trainingCategoryList !== undefined &&
            state.trainingCenterCategorySlice?.trainingCategoryList.length > 0
            ? state.trainingCenterCategorySlice?.trainingCategoryList
            : null
    );
    let lessionList = useSelector((state) =>
        state.trainingCenterCategorySlice?.lessionList &&
            state.trainingCenterCategorySlice?.lessionList !== undefined &&
            state.trainingCenterCategorySlice?.lessionList.length > 0
            ? state.trainingCenterCategorySlice?.lessionList
            : null
    );
    let initialLessionID = useSelector((state) =>
        state.trainingCenterCategorySlice?.initialLessionID &&
            state.trainingCenterCategorySlice?.initialLessionID !== undefined
            ? state.trainingCenterCategorySlice?.initialLessionID
            : null
    );
    let initialLessionData = useSelector((state) =>
        state.trainingCenterCategorySlice?.initialLessionData &&
            state.trainingCenterCategorySlice?.initialLessionData !== undefined
            ? state.trainingCenterCategorySlice?.initialLessionData
            : null
    );
    let loader = useSelector((state) =>
        state.trainingCenterCategorySlice?.loading
            ? state.trainingCenterCategorySlice?.loading
            : false
    );
    let lessionListLoadEmpty = useSelector((state) =>
        state.trainingCenterCategorySlice?.lessionListLoadEmpty
            ? state.trainingCenterCategorySlice?.lessionListLoadEmpty
            : false
    );
    const resolved = useSelector((state) =>
        state.ResolveReducer.resolved["/training-center"]
            ? state.ResolveReducer.resolved["/training-center"]
            : false
    );

    // ----------------------------------------Components Private State---------------------------------------- //
    const [selectedcatid, setselectedcatid] = useState(null);
    const [selectedlessonid, setselectedlessonid] = useState(null);
    const [openlessonId, setOpenlessonId] = useState(null);
    const [useridsetdata, setuseridsetdata] = useState(getcookies.userinfo?._id);
    const [nextTrainingLesson, setNextTrainingLesson] = useState(false);
    const [reqbody, setreqbody] = useState({
        source: "additional_training_list",
        sort: {
            type: "asc",
            field: "training_priority",
        },
        condition: {
            limit: 5,
            skip: 0,
        },
        searchcondition: {},
    });
    // const [loader, setLoader] = useState(false);

    // --------------------------------------------Other Veriables-------------------------------------------- //
    const categoryFetchingReqBody = {
        "source": "additional_training_list",
        "sort": {
            "type": "asc",
            "field": "training_priority"
        },
        "condition": {
            "limit": 5,
            "skip": 0
        },
        "searchcondition": {
        },
        "fetch": "category"
    };

    // -------------------------------------------useEffect Section------------------------------------------- //
    useEffect(() => {
        if (!resolved) {
            if (!cat_id && !lesson_id) store.dispatch(trainingCenterCategoryFetching(categoryFetchingReqBody));
        }
        return () => store.dispatch(resetAllDataInTraingcenter());
    }, []);

    useEffect(() => {
        if (!cat_id && !lesson_id && initialLessionID) {
            console.log("fist");
            let data = {
                "source": "lesson_list",
                "_id": initialLessionID
            }
            store.dispatch(fetchInitialLession(data));
            store.dispatch(resetInitialLessionIDshow());
        }
        // setLoader(true);
    }, [cat_id, lesson_id, initialLessionID]);

    useEffect(() => {
        if (initialLessionData && initialLessionData.length > 0) {
            if (initialLessionData[0]._id) {
                setselectedlessonid(initialLessionData[0]._id);
            }
            if (cat_id) {
                setselectedcatid(cat_id);
            } else if (initialLessionData[0].training_id) {
                setselectedcatid(initialLessionData[0].training_id);
            }
        }
    }, [initialLessionData, cat_id]);

    useEffect(() => {
        if (
            cat_id &&
            reqbody.searchcondition["training_id"] !== cat_id &&
            categoryList &&
            categoryList.length > 0
        ) {
            reqbody.searchcondition["training_id"] = cat_id;
            setreqbody(reqbody);
            store.dispatch(trainingCenterCategoryFetching(reqbody));
        }

        if (
            cat_id &&
            reqbody.searchcondition["training_id"] !== cat_id &&
            !categoryList
        ) {
            reqbody.searchcondition["training_id"] = cat_id;
            setreqbody(reqbody);

            reqbody["fetch"] = "category";

            store.dispatch(trainingCenterCategoryFetching(reqbody));
        }
    }, [cat_id]);

    useEffect(() => {
        if (cat_id && initialLessionID) {
            let data = {
                "source": "lesson_list",
                "_id": initialLessionID
            }
            store.dispatch(fetchInitialLession(data));
            store.dispatch(resetInitialLessionIDshow());
        }
    }, [cat_id, initialLessionID]);

    useEffect(() => {
        if (lesson_id) {
            let data = {
                "source": "lesson_list",
                "_id": lesson_id
            }
            store.dispatch(fetchInitialLession(data));
        }
    }, [lesson_id]);

    useEffect(() => {
        if (initialLessionData && categoryList?.length > 0) {
            mediasec.current.scrollIntoView({ behavior: "smooth" });
        }
    }, [categoryList, initialLessionData]);

    useEffect(() => {
        let domNodeArr = null;
        setTimeout(() => {
            domNodeArr = document.querySelectorAll('app-traing-center');
            // console.log("domNodeArr ------------>", domNodeArr);
            if (domNodeArr && domNodeArr.length > 0) {
                for (let i = 0; i < domNodeArr.length; i++) {
                    if (domNodeArr[i]) domNodeArr[i].addEventListener('app-traing-event', (e) => {
                        let eventData = JSON.parse(e.target.getAttribute('app-traing-center'));
                        // console.log("app-traing-center elem event Data  lessondata --------->", eventData);
                        if (eventData && eventData.type == "next_lesson" && lessionList.length > 0) {
                            lessionList.forEach((e, i) => {
                                if (selectedlessonid === e._id) {
                                    if (i === lessionList.length - 1) {
                                        categoryList.forEach((f, j) => {
                                            if (e.training_id === f._id) {
                                                const currentIndex = categoryList.indexOf(f);
                                                const nextIndex = (currentIndex + 1) % categoryList.length;
                                                setselectedcatid(categoryList[nextIndex]._id)
                                                reqbody.searchcondition["training_id"] = categoryList[nextIndex]._id;
                                                setreqbody(reqbody);
                                                store.dispatch(trainingCenterCategoryFetching(reqbody));
                                                navigate(`/training-center/${categoryList[nextIndex]._id}`);
                                                setNextTrainingLesson(true);
                                            }
                                        })
                                    } else {
                                        const currentIndex = lessionList.indexOf(e);
                                        const nextIndex = (currentIndex + 1) % lessionList.length;
                                        navigate(`/training-center/${selectedcatid}/${lessionList[nextIndex]._id}`);
                                    }
                                }
                            })

                        }
                    })
                }
            }
        })
    }, [selectedlessonid, lessionList, categoryList]);

    useEffect(() => {
        if (nextTrainingLesson) {
            if (lessionList && lessionList.length > 0) {
                navigate(`/training-center/${selectedcatid}/${lessionList[0]._id}`);
            } else {
                navigate(`/training-center/${selectedcatid}`);
            }
        }
    }, [nextTrainingLesson, lessionList]);

    // --------------------------------------------Other Handlers-------------------------------------------- //
    const showcatagorydata = (val, lessondata) => {
        // console.log("lessondata val", val, lessondata);

        return (
            <>
                {/* <div  className="cat_block" > */}
                <div
                    className={
                        selectedcatid && selectedcatid === val._id
                            ? "cat_block active"
                            : "cat_block inactive"
                    }
                >
                    <div
                        className="cat_block_cat_data"
                        onClick={() => [navigate(`/training-center/${val._id}`), resetTrainingLesson()]}
                    >
                        <CategoryIcon fontSize="medium" />
                        <p>{val.training_name}</p>
                    </div>
                    <div className="cat_block_lesson_data">
                        {lessondata &&
                            lessondata.length > 0 &&
                            lessondata.map((curr, index) => (
                                <>
                                    <div key={index}>
                                        {curr.training_id == val._id ? (
                                            <>

                                                <div
                                                    className={
                                                        selectedlessonid && selectedlessonid === curr._id
                                                            ? "lessoncontent active"
                                                            : "lessoncontent inactive"
                                                    }
                                                    onClick={() =>
                                                        navigate(
                                                            `/training-center/${val._id}/${curr._id}`
                                                        )
                                                    }
                                                >
                                                    {/* <img src={curr.youtube_thumbnail} /> */}
                                                    <p>{curr.lesson_name}</p>
                                                </div>
                                            </>
                                        ) : null}
                                    </div>
                                </>
                            ))}
                    </div>
                </div>
                {lessondata && lessondata[0].training_id == val._id && <div className="readmorediv">
                    <Button onClick={() => readmoreClick(val)} className={lessionListLoadEmpty ? "disabled" : ""} disabled={lessionListLoadEmpty}> Load more {lessionListLoadEmpty}</Button>
                </div>}
            </>
        );
    };
    const readmoreClick = (val) => {
        console.log("reloadhit lessionList", lessionListLoadEmpty);
        // console.log("val lessionList", lessionList.length);
        if (lessionList && lessionList.length > 0) {
            reqbody.condition.skip = lessionList.length;

        } else {
            reqbody.condition.skip = 0;

        }
        if (reqbody.fetch) {
            delete reqbody.fetch
        }

        store.dispatch(trainingCenterCategoryFetchingLoadMore(reqbody));
    }
    const lessonData = (value, index) => {
        let val = value;
        // console.log("lessonData val",val)
        // if (userDonetraininglesson && userDonetraininglesson[0]?.completed_lessons?.length > 0) {
        //     for (let i in userDonetraininglesson[0].completed_lessons) {
        //         // console.log("idsss", userDonetraininglesson[0].completed_lessons[i]._id,val._id );
        //         if (val._id === userDonetraininglesson[0].completed_lessons[i]._id) {
        //             // console.log("id match");
        //             val = { ...val, done: 1 }

        //         }
        //     }

        // }
        if (val && openlessonId && openlessonId !== undefined) {
            if (val._id == openlessonId) {
                val = { ...val, isopen: true }
            }
        }

        if (val.poster_image != null && val.poster_image !== undefined) {
            console.log("getdataa", val);

        }

        let trainingdata = {
            media_url: val.video?.url,
            tittle: val.lesson_name,
            html_content: val.editor_description,
            wrapperclass: 'test_html_class tncgCenter_dta',
            _id: val._id,
            user_id: useridsetdata,
            markasdone_api: "training-manage/done-traning",
            traning_id: val.training_id,
            done: (val.done && val.done !== null) ? val.done : 0,
            isopen: (val.isopen && val.isopen !== null && val.isopen !== undefined) ? val.isopen : true,
            only_header_show: false,
            poster: (val.poster_image?.url != null && val.poster_image?.url !== undefined) ? val.poster_image.url : (val.traning_category && val.traning_category !== undefined && val.traning_category == 1) ? undefined : undefined,
            index: index + 1,
            showQuiz: val.showquiz,
            apiUrl: process.env.REACT_APP_API_URL,
            fetchQuiz_api: 'training-manage/quiz-submission',
        };

        // console.log("lessonData trainingdata}}}", trainingdata);
        return <app-traing-center key={val._id} data={JSON.stringify(trainingdata)}></app-traing-center>;

    }
    const resetTrainingLesson = () => {
        setNextTrainingLesson(false);
        reqbody.condition.skip = 0;
        dispatch(resetLessonLoadMore());
    }


    // store.subscribe(() => {
    // const storeData = store.getState();
    // if(storeData.trainingCenterCategorySlice && storeData.trainingCenterCategorySlice.success && storeData.trainingCenterCategorySlice.trainingCategoryList){
    // setCategoryList(storeData.trainingCenterCategorySlice.trainingCategoryList)
    // setLoader(false);
    // console.log("storeData ",storeData.trainingCenterCategorySlice)
    // }
    // })


    //----------------------------------------------return-----------------------------------------------//
    return (
        <>
            <div
                className="trainingCenter_Sub additional_trainingCenter_Sub"
                ref={mediasec}
            >
                <Grid
                    item
                    className="traingcenterdata_wrapper leftdatashow"
                    xl={8}
                >

                    {/* <------------------------------Blog Card List From angular element------------------------------> */}
                    {initialLessionData && initialLessionData.length > 0 && initialLessionData[0]?._id != undefined && initialLessionData.map((curr, index) => lessonData(curr, index))}

                    {/* {initialLessionData && ( */}
                    {/* <div className="leftpart_wrapper">
                            <div className="leftpart_header">
                                <h1>{initialLessionData.lesson_name}</h1>
                            </div>
                            <div className="mediacontentpart"> */}
                    {/* <YouTube
                        videoId={initialLessionData?.youtube_video?.id}
                        opts={opts}
                        onEnd={videoenevd}
                      /> */}
                    {/* </div>
                            <div className="mediacontentpart"> */}
                    {/* <span
                        dangerouslySetInnerHTML={{
                          __html: initialLessionData.descriptions,
                        }}
                        className="description"
                      ></span> */}
                    {/* </div>
                        </div> */}
                    {/*  )} */}
                </Grid>
                <Grid
                    item
                    xl={4}
                    className="traingcenterlist_wrapper rightdatashow"
                >
                    <div className="traingcenterlist_innerwrapper">
                        {categoryList && categoryList.map((category, index) =>
                            <div key={index}>

                                {showcatagorydata(category, lessionList)}
                                {/* <button>{category.training_name}</button> */}
                            </div>
                        )}
                    </div>
                </Grid>
            </div>
        </>
    )
}