
import Snackbar from '@mui/material/Snackbar';
import Modal from '@mui/material/Modal';
import Typography from '@mui/material/Typography';
import CloseIcon from '@mui/icons-material/Close';
import store from "../../../../store";

// import * as React from 'react';
import React, { useEffect, useRef, useState } from "react";
import { useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { Button, Checkbox, Chip, FormControl, Grid, InputLabel, LinearProgress, List, ListItemText, MenuItem, OutlinedInput, Select, Tooltip } from '@mui/material';

import { Box, display, Stack } from '@mui/system';
import { useCookies, Cookies } from "react-cookie";
import { addUserRoles } from './userListReducer';



const ConfigureRoleModal = (props) => {



    const dispatch = useDispatch(); // ---- Dispatch -----
    const navigate = useNavigate(); //**** FOR PAGE NAVIGATION****//

    // ---- States ----
    const [loading, setLoading] = useState(false);  //*****FOR LODING****//

    // ---- Cookies ---
    const [userInfo, setuserInfoCookie] = useCookies(["userinfocookies"]);
    const [userRoles, setUserRoles] = React.useState([]);
    const [copyUserRoles, setcopyUserRoles] = React.useState([]);
    const [open, setopen] = React.useState(false);
    const [openDropDown, setOpenDropDown] = React.useState(false);

    

    // console.warn("<<<<<< props goes here >>>>>>", props)
    // console.warn("<<<<<< cookies goes here >>>>>", userInfo)

    // ---- for loader -----
    const loader = useSelector(state => (state.userManagment?.loading) ? state.userManagment?.loading : false)

    const userRole = useSelector(state => (state.userManagment?.userRoles) ? state.userManagment?.userRoles : [])

    const success = useSelector(state => (state.userManagment?.resMessage) ? state.userManagment?.resMessage : null)



    useEffect(() => {
        if (success) {
            setopen(true)
        }
    }, [success])


    useEffect(() => {
        if (userRole.length > 0) {
        setOpenDropDown(false)

            setcopyUserRoles(userRole);
        }
    }, [userRole])




    // ------ 
    useEffect(() => {
        if (props?.userRoles) {
            setUserRoles(props.userRoles.filter(data => data.status == 1).map(data => data.label))
        }
    }, [])



    useEffect(() => {
        props.setModalResponse(false);
    }, [])


    // ------- onModalClose --------
    function ModalClose() {
        // console.warn("<<< modal close hit >>>")
        props.setModalResponse(false);
        props.tooglefunc(false);
        setOpenDropDown(false)

    }



    // ------- onAddButton Trigger ------
    function formSubmit(event) {
        // event.preventDefault();
        // let setCopyArr = [...props.userRoles]
        // // console.warn("<<<<<<< setCopyArr >>>>>>>", setCopyArr)


        // const finalData = setCopyArr.map((val) => {
        //     // console.warn("<<< map value >>>", val)

        //     const data = { ...val }

        //     if (userRoles.includes(data.label)) {
        //         data.status = 1
        //     } else {
        //         data.status = 0
        //     }
        //     return data;
        // })

        // // console.log('<< final data >>', finalData);

        // dispatch(addUserRoles({ _id: props.UserId, roles_data: finalData }));


    }
    // ----------------------------------------



    // ----------- Delete Chips ----------------------
    const deleteChips = (dltVal) => {
        // console.warn('You clicked the delete icon.', dltVal);
        // console.warn('You clicked the delete icon userRoles.', userRoles);

        let setCopyArr = [...props.userRoles];

        const finalData = setCopyArr.map((val) => {

            const data = { ...val }

            if (data.label.includes(dltVal.label)) {
                data.status = 0
                setUserRoles(userRoles.filter((val) => { return val !== dltVal.label }));
            }
            return data;
        })

        dispatch(addUserRoles({ _id: props.UserId, roles_data: finalData }));


    };
    // --------------------------------


    // ------------- Hnadle Chnage ----------
    const handleChange = (event) => {
        event.preventDefault();


        const { target: { value }, } = event;
        setUserRoles(
            // On autofill we get a stringified value.
            typeof value === 'string' ? value.split(',') : value,
        );
        // console.warn("<<<<< on chnage event >>>>>>", event.target.value)

        let setCopyArr = [...props.userRoles]
        // console.warn("<<<<<<< setCopyArr >>>>>>>", setCopyArr)


        const finalData = setCopyArr.map((val) => {
            // console.warn("<<< map value >>>", val)
            const data = { ...val }

            if (event.target.value.includes(val.label)) {
                data.status = 1
            } else {
                data.status = 0
            }
            return data;
        })

        // console.log('<< final data >>', finalData);

        dispatch(addUserRoles({ _id: props.UserId, roles_data: finalData }));

        openDropDown===true?setOpenDropDown(false):setOpenDropDown(true)

    };

    // ----------------------------



    const ITEM_HEIGHT = 48;
    const ITEM_PADDING_TOP = 0;
    const MenuProps = {
        PaperProps: {
            style: {
                maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
                width: 250,
            },
        },
    };



    return (
        <>

            {/* ------ Confirmation Modal ------ */}


            <Modal
                className="modalblock"
                open={props.toogle}
                onClose={ModalClose}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
            >

                <Box className="BoxMui_modal configure-role" sx={{
                    position: "absolute",
                    top: "50%",
                    left: "50%",
                    transform: "translate(-50%, -50%)",
                    width: 400,
                    bgcolor: "background.paper",
                    border: "2px solid #000",
                    boxShadow: 24,
                    p: 4,
                    textAlign: "center",
                }}>
                    <Typography id="modal-modal-title" variant="h6" component="h2">
                        {loader ? <LinearProgress /> : ""}

                        Configure Role
                    </Typography>


                    {/* ----- Dropdown for selecting roles ----- */}

                    <form onSubmit={(event) => formSubmit(event)}>

                        <FormControl sx={{ m: 1, width: 300 }}>
                            <InputLabel id="demo-multiple-checkbox-label">Select Role</InputLabel>
                            <Select
                                open={openDropDown}
                                onOpen={()=> setOpenDropDown(true)}
                                onClose={()=> setOpenDropDown(false)}
                                labelId="demo-multiple-checkbox-label"
                                id="demo-multiple-checkbox"
                                multiple
                                value={userRoles}
                                onChange={handleChange}
                                input={<OutlinedInput label="tag" />}
                                renderValue={(userRoles) => userRoles.join(', ')}
                                MenuProps={MenuProps}
                            >
                                {props.userRoles.map((Roles) => (
                                    <MenuItem key={Roles.key} value={Roles.label}>
                                        {/* <Checkbox checked={personName.indexOf(userRoles) > -1} /> */}
                                        <Checkbox checked={userRoles.includes(Roles.label)} />
                                        <ListItemText primary={Roles.label} />
                                    </MenuItem>
                                ))}

                            </Select>

                            <Stack direction="row" spacing={1} sx={{
                                marginTop: "10px",
                            }}>

                                <div className='ChipsSet-grp' sx={{
                                    display: "flex",
                                    width: '100%',
                                    flexDirection: "row",
                                    flexWrap: "wrap",
                                    margin: "0 auto",
                                    justifyContent: "flex-start",
                                    alignItems: "flex-start",
                                }}>

                                    {userRoles.length > 0 ? props.userRoles.map((val) => val.status !== 0 ? (<Chip label={val.label} variant="outlined" sx={{ margin: '3px 3px' }} onDelete={() => deleteChips(val)} />) : "") : (<h3> No Role Assigned</h3>)}

                                    {/* {copyUserRoles.length > 0 ? copyUserRoles.map((val) => val.length > 0 ? (<Chip label={val} variant="outlined" onDelete={() => deleteChips(val)} />):"") : (<h3> No Role Assigned</h3>)} */}
                                </div>


                            </Stack>



                        </FormControl>

                        {/* <Button variant="contained" onClick={ModalClose} className="modalBTN">Cancel</Button> */}
                    </form>


                    {/* -------------------------------------- */}



                    <Button onClick={ModalClose} className="closeModalBtn">
                        <Tooltip title="Close" placement="right-start">

                            <CloseIcon />
                        </Tooltip>
                    </Button>
                </Box>
            </Modal>




            {success != null ? (<Snackbar
                open={open}
                autoHideDuration={6000}
                onClose={() => setopen(false)}
                message={success}
            // action={action}
            />) : ""}

        </>
    )
}


export default ConfigureRoleModal;



