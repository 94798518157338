import { createAsyncThunk, createSlice } from "@reduxjs/toolkit"

export const getHmGroupListdata = createAsyncThunk("getHmGroupListdata", async (reqbody) => {

    const requestOptions = {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify(reqbody)
    };
    const response = await fetch(process.env.REACT_APP_API_URL + "manage-hm-group/hm-group-list-count", requestOptions);
    console.log('response', response)
    const FetchStages = await response.json();

    return FetchStages;
});


export const fetchSingleHMgroup = createAsyncThunk("fetchSingleHMgroup", async (reqBody) => {

    const requestOptions = {
        method: 'post',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify(reqBody)
    };
    console.log("process.env==>", process.env);
    const response = await fetch(process.env.REACT_APP_API_URL + "manage-hm-group/single-fetch-hm-group", requestOptions);
    const respdata = await response.json();
    // console.log("respdata after endpoint fetch==>", respdata);
    return respdata;
});


export const geteditHmGroupdata = createAsyncThunk("editrole", async (id) => {
    let reqBody = {
        "condition": {
            "limit": 1,
            "skip": 0

        },
        "sort": {
            "field": "_id",
            "type": "asc"
        },
        "searchcondition": {},
        "project": {},
        "token": ""
    }
    console.log("reqbody==>", reqBody);
    let req_body = {};
    req_body = { ...reqBody };
    console.log("req_body==", req_body);
    const requestOptions = {
        method: 'post',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify(req_body)
    };
    console.log("process.env==>", process.env);
    const response = await fetch(process.env.REACT_APP_API_URL + "manage-hm-group/hm-group-list-count", requestOptions);
    const respdata = await response.json();
    // console.log("respdata after endpoint fetch==>", respdata);
    return respdata;


})



const HmGroupReducer = createSlice({

    name: 'hmgroupreducer',

    initialState: {
        submitting: false,
        loading: false,
        hmGroupListdata: [],
        fetchSingleHMgroupData: null,
        editHmGroupdata: [],
    },

    reducers: {
        clearHmGroupListdata(state, action) {
            state.hmGroupListdata = []
        },
        cleareditHmGroupdata(state, action) {
            console.log('Fueell')
            state.fetchSingleHMgroupData = null
        },

    },

    extraReducers: {

        // ================ For GET DATA ================ //
        [getHmGroupListdata.rejected]: (state, action) => {
            // state.endpointsuccess[action.type] = false
            state.successAction = false;
        },
        [getHmGroupListdata.pending]: (state, action) => {
            state.loading = true;
        },
        [getHmGroupListdata.fulfilled]: (state, action) => {

            // console.log(state,'state============')


            if (action.payload.status === 'success') {
                console.log(action.payload.status, 'getHmGroupListdata action============')
                state.hmGroupListdata = action.payload.results.res;
                console.log('hmGroupListdata', state.hmGroupListdata)

                state.loading = false;
            }
        },



        [fetchSingleHMgroup.pending](state, action) {
            state.loading = true;
        },
        [fetchSingleHMgroup.fulfilled](state, action) {
            state.loading = false;
            console.log("action fetchSingleHMgroup ", action)
            if (action.payload.status === "success") {
                state.success = true;
                state.fetchSingleHMgroupData = action.payload.results?.res
            }
            console.log("action ", action)
        },
        [fetchSingleHMgroup.rejected](state, action) {
            state.loading = false;
        },


        // ================ For EDIT DATA ================ //
        [geteditHmGroupdata.rejected]: (state, action) => {
            state.endpointsuccess[action.type] = false


        },
        [geteditHmGroupdata.pending]: (state, action) => {
            state.editloading = true;
        },
        [geteditHmGroupdata.fulfilled]: (state, action) => {
            if (action.payload.status === 'success') {
                state.editHmGroupdata = action.payload.results.res;
                state.editloading = false;
                console.log('Fullllllllllllllllllllll', state.editHmGroupdata)

            }
        },

    }



})


export default HmGroupReducer.reducer;
export const { clearHmGroupListdata, cleareditHmGroupdata } = HmGroupReducer.actions;