import React, { useEffect } from 'react'

import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import Modal from '@mui/material/Modal';
import { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Navigate } from 'react-router-dom';
import { Dialog, IconButton, LinearProgress, Tooltip } from '@mui/material';
import { Form } from '../../form/Form';
import CloseIcon from "@mui/icons-material/Close";
import { setSnackbar } from '../Layout/layoutReducer';
import { useCookies } from 'react-cookie';
import store from '../../store';
import { setFormFieldError } from '../../form/formReducer';
import { cleareditdata, addusernamedata } from './../CampaignManagement/CampaignReducer';
import { setUSername } from '../login/loginReducer';


function UserNameCreate({ open, close, user_id }) {
  console.log("ALL DATA ======>>")
  let user_name = null;
  const [userInfo, setuserInfoCookie] = useCookies(["userInfo"]);
  const [loader, setLoader] = useState(false)
  const [redundatecall, setredundatecall] = useState(false)
  const dispatch = useDispatch();
 
  const formSubmissionState = useSelector((state) => state.formSlice.formSubmissionState.UserNameCreate ? state.formSlice.formSubmissionState.UserNameCreate : 0)
  const formdeta = useSelector(state => (state.formSlice?.formData?.UserNameCreate) ? state.formSlice.formData.UserNameCreate : null)
  const adduseronstate = useSelector(state => state.camPaignManagementReducer.addusernamestate ? state.camPaignManagementReducer.addusernamestate : 0);
  const user_data = useSelector((state) => state.loginSlice.userInfo ? state.loginSlice.userInfo : null);


  const dataform = {

    id: 'UserNameCreate',
    // api_url: process.env.REACT_APP_API_URL,
    // endPoint: "campaign-manage/set-username",
    endPointBodyWrapper: "data",
    urlPathOnSuccessfulFormSubmission: '',
    submitBtnName: "Submit",
    formWrapperClass: "backend_addform addAdmin",
    formInlineClass: "backendTableInline_addform testwrpr trainingcata",

    formAdditionalSubmissionData: {
    },
    fields: [
      {
        id: 0,
        heading: "Unique User Name",
        lable: "Unique User Name",
        name: "user_name",
        className: "inputBlock inputBlock3line",
        type: "text",
        rules: { required: true },
        errorMessage: {
          required: "This Field is Required",
          custom: "Value is Invalid"
        },
      },

      {
        id: 1,
        heading: "Unique User ID",
        lable: "Unique User ID",
        name: "_id",
        className: "inputBlock inputBlock3line",
        type: "hidden",
        defaultValue: user_id
      },
    ]

  }




  useEffect(() => {
    if (formdeta !== undefined && formdeta !== null && formdeta.user_name !== undefined && formdeta.user_name !== null &&!redundatecall) {
      var format = /[!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?]+/;
      if (format.test(formdeta.user_name)) {
        dispatch(setFormFieldError({ formId: 'UserNameCreate', fieldName: "user_name", error: { type: 'custom' } }))
      } else {
        if (formSubmissionState && formSubmissionState == 4) {
          calladdusernameapi(formdeta)
        }
      }
    }

  }, [formSubmissionState, formdeta])



  const calladdusernameapi = async (val) => {
    val = { ...val }
    let user_name = val.user_name;
    let body = {
      data: {
        "user_name": val.user_name,
        "_id": user_id,
      }
    }
    // console.log("body-----", body);
    if (Object.keys(body).length > 0 && user_name) {
      setLoader(true)
      await store.dispatch(addusernamedata(body));
      setLoader(false)
      // store.subscribe(() => {
        // const storedata = store.getState();
        // if (
        //   storedata?.loginSlice?.userInfo !== undefined &&
        //   storedata?.loginSlice?.userInfo?._id !== null &&
        //   storedata?.loginSlice?.userInfo?._id !== undefined
        // ) {
          const objTwo = { ...user_data, user_name }
          
          setuserInfoCookie(
            "userinfo",
            JSON.stringify(objTwo),
            { path: "/" }
          );
          dispatch(setUSername(user_name))


        // }


      // });
      setredundatecall(true)
      dispatch(setSnackbar({ open: true, message: "User Name Create Successfully !", status: 'success' }));
      close(1,user_name);
    }

  }

  // useEffect(() => {
  //   if (adduseronstate == 2) {
  //     dispatch(setSnackbar({ open: true, message: "User Name Create Successfully !", status: 'success' }));
  //   }

  // }, [adduseronstate])


  return (
    <div>

      <div>

        <Dialog
          classname="hmmodal"
          open={open}
          onClose={() => close}

        >
          <Tooltip title='Close' >
            <Button onClick={close} className="hmmodalCloseBTN"> <CloseIcon /> </Button>
          </Tooltip>
          <Box className='hmmodal_user_Box' >

            <div className='hmmodal_user_Box_wrapper' >
              <h3>Add Unique User Name</h3>
              <Form formData={dataform} />

              {loader && (
                <Box sx={{ width: "100%" }}>
                  <LinearProgress />
                </Box>
              )}

            </div>

          </Box>
        </Dialog>
      </div>
    </div>
  )
}


export default UserNameCreate