import React, { useEffect } from "react";
import ReactPlayer from "react-player";
import { useSelector } from "react-redux";
import store from "../../../store";
import { getInformationListData } from "./InformationReducer";
import "./Information.css";
// import './Training Material Centar.css'
import "./../Training/Training Material Centar/Training Material Centar.css";
import "../../../form/formComponents/formcomp.css";
import { Box } from "@mui/system";
import { Grid, IconButton, Modal, Tooltip, Typography } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";

const Informationtemplate = () => {
  const [previewfile, setPreviewfile] = React.useState(null);
  const [open, setOpen] = React.useState(false);

  let infoLinksData = useSelector((state) =>
    state.informationReducer?.informationlistdata &&
    state.informationReducer?.informationlistdata.length > 0
      ? state.informationReducer.informationlistdata
      : []
  );

  useEffect(() => {
    let reqbody = {
      condition: {
        limit: 5,
        skip: 0,
      },
      sort: {
        field: "_id",
        type: "desc",
      },
      searchcondition: {
        // "added_for": addedForId
      },
      count: false,
      source: "",
    };

    store.dispatch(getInformationListData(reqbody));
  }, []);

  const handleOpen = (val) => {
    setPreviewfile(val);
    setOpen(true);
  };
  useEffect(() => {
    console.log("previewfile",previewfile);

  
    
  }, [previewfile])
  
  const handleClose = () => {
    setOpen(false);
    setPreviewfile(null);
  };

  const findfileextenssion = (val) => {
    console.log("val11", val);
    var re = /(?:\.([^.]+))?$/;

    let extension = re.exec(val)[1];
    console.log("val11==", extension);
    if (
      extension != "" &&
      extension !== undefined &&
      (extension == "webp" ||
        extension == "WEBP" ||
        extension == "png" ||
        extension == "PNG" ||
        extension == "jpg" ||
        extension == "JPG" ||
        extension == "jpeg" ||
        extension == "JPEG" ||
        extension == "gif" ||
        extension == "GIF" ||
        extension == "bmp" ||
        extension == "BMP" ||
        extension == "ico" ||
        extension == "ICO" ||
        extension == "svg" ||
        extension == "SVG" ||
        extension == "tif" ||
        extension == "TIF" ||
        extension == "tiff" ||
        extension == "TIFF")
    ) {
      return "img";
    }
    if (
      extension != "" &&
      extension !== undefined &&
      (extension == "mp4" ||
        extension == "MP4" ||
        extension == "mkv" ||
        extension == "MKV" ||
        extension == "wemb" ||
        extension == "WEMB" ||
        extension == "flv" ||
        extension == "FLV" ||
        extension == "avi" ||
        extension == "AVI" ||
        extension == "mov" ||
        extension == "MOV" ||
        extension == "swf" ||
        extension == "SWF" ||
        extension == "wmv" ||
        extension == "WMV")
    ) {
      return "video";
    }
    if (
      extension != "" &&
      extension !== undefined &&
      (extension == "mp3" ||
        extension == "MP3" ||
        extension == "aif" ||
        extension == "AIF" ||
        extension == "cda" ||
        extension == "CDA" ||
        extension == "mid" ||
        extension == "MID" ||
        extension == "mpa" ||
        extension == "MPA" ||
        extension == "ogg" ||
        extension == "OGG" ||
        extension == "wav" ||
        extension == "WAV" ||
        extension == "wma" ||
        extension == "WMA" ||
        extension == "wpl" ||
        extension == "WPL")
    ) {
      return "audio";
    }
    if (
      extension != "" &&
      extension !== undefined &&
      (extension == "pdf" ||
        extension == "PDF" ||
        extension == "tex" ||
        extension == "TEX" ||
        extension == "txt" ||
        extension == "TXT")
    ) {
      return "pdf";
    }
    if (
      extension != "" &&
      extension !== undefined &&
      (extension == "doc" ||
        extension == "DOC" ||
        extension == "docx" ||
        extension == "DOCX" ||
        extension == "xlsx" ||
        extension == "XLSX" ||
        extension == "xlsm" ||
        extension == "XLSM" ||
        extension == "pptx" ||
        extension == "PPTX" ||
        extension == "csv" ||
        extension == "CSV" ||
        extension == "xml" ||
        extension == "XML" ||
        extension == "odp" ||
        extension == "ODP" ||
        extension == "ppt" ||
        extension == "PPT" ||
        extension == "pps" ||
        extension == "PPS" ||
        extension == "ods" ||
        extension == "ODS" ||
        extension == "xls" ||
        extension == "XLS" ||
        extension == "odt" ||
        extension == "ODT" ||
        extension == "rtf" ||
        extension == "RTF")
    ) {
      return "doc";
    }
    // return false;
  };

  return (
    <>
      <div className="header_portion_information">
        <h2 className="header_title header_top_heading">
          {infoLinksData[0]?.title}
        </h2>
        <span className="header_description header_top_desc">
          {infoLinksData[0]?.description}
        </span>
        <div className="information_template">
          {infoLinksData[0]?.file_link.map((data) => {
            console.log(
              "datanew=============================================================================>",
              infoLinksData[0]?.file_link
            );
            return (
              <>
                {data?.type == "video-youtube" && (
                  <Grid
                    container
                    className="information_template_each"
                    spacing={1}
                  >
                    {
                      <Grid className="titel_wrper block_wrper">
                        <div
                          className="icon_wrper"
                          onClick={() => handleOpen(data)}
                        >
                          <img src="https://upload.wikimedia.org/wikipedia/commons/e/ef/Youtube_logo.png" />
                        </div>
                        <p className="titel_file">
                          Video Type :<span> Youtube </span>
                        </p>
                        <div className="meterial_button_wrapper">
                          <button
                            className="programButtonClass"
                            onClick={() => handleOpen(data)}
                          >
                            Preview
                          </button>
                        </div>
                      </Grid>
                    }
                  </Grid>
                )}

                {data?.type == "video-vimeo" && (
                  <Grid
                    container
                    className="information_template_each"
                    spacing={1}
                  >
                    {
                      <Grid className="titel_wrper block_wrper">
                        <div className="icon_wrper">
                          <div onClick={() => handleOpen(data)}>
                            <img src="https://e7.pngegg.com/pngimages/678/228/png-clipart-logo-vimeo-video-combo-blue-combo.png" />
                          </div>
                        </div>
                        <p className="titel_file">
                          Video Type :<span> Vimeo </span>
                        </p>
                        <div className="meterial_button_wrapper">
                          <button
                            className="programButtonClass"
                            onClick={() => handleOpen(data)}
                          >
                            Preview
                          </button>
                        </div>
                      </Grid>
                    }
                  </Grid>
                )}

                {data?.type == "video-link" && (
                  <Grid
                    container
                    className="information_template_each"
                    spacing={1}
                  >
                    {
                      <Grid className="titel_wrper block_wrper">
                        <div className="icon_wrper">
                          <div
                            className={`video type_block meteralblockk`}
                            onClick={() => handleOpen(data)}
                          ></div>
                        </div>
                        <p className="titel_file">
                          Video Type :<span> Video Link </span>
                        </p>
                        <div className="meterial_button_wrapper">
                          <button
                            className="programButtonClass"
                            onClick={() => handleOpen(data)}
                          >
                            Preview
                          </button>
                        </div>
                      </Grid>
                    }
                  </Grid>
                )}

                {data?.type == "video-upload" && (
                  <Grid
                    container
                    className="information_template_each"
                    spacing={1}
                  >
                    {
                      <Grid className="titel_wrper block_wrper">
                        <div className="icon_wrper">
                          <div
                            className={`video type_block meteralblockk`}
                            onClick={() => handleOpen(data)}
                          ></div>
                        </div>
                        <p className="titel_file">
                          Video Type :<span> Upload Video </span>
                        </p>
                        <div className="meterial_button_wrapper">
                          <button
                            className="programButtonClass"
                            onClick={() => handleOpen(data)}
                          >
                            Preview
                          </button>
                        </div>
                      </Grid>
                    }
                  </Grid>
                )}

                {data?.type == "file" &&
                  findfileextenssion(data?.file?.url) == "video" && (
                    <Grid
                      container
                      className="information_template_each"
                      spacing={1}
                    >
                      {
                        <Grid className="titel_wrper block_wrper">
                          <div className="icon_wrper">
                            <div
                              className={`${findfileextenssion(
                                data?.file?.url
                              )} type_block meteralblockk`}
                            ></div>
                          </div>
                          <p className="titel_file">
                            File Name :
                            <span> {data?.file?.file_local_name}</span>
                          </p>
                          <div className="meterial_button_wrapper">
                            <button
                              className="programButtonClass"
                              onClick={() => handleOpen(data)}
                            >
                              Preview
                            </button>
                          </div>
                        </Grid>
                      }
                    </Grid>
                  )}

                {data?.type == "file" &&
                  findfileextenssion(data?.file?.url) == "pdf" && (
                    <Grid
                      container
                      className="information_template_each"
                      spacing={1}
                    >
                      {
                        <Grid className="titel_wrper block_wrper">
                          <div className="icon_wrper">
                            <div
                              className={`${findfileextenssion(
                                data?.file?.url
                              )} type_block meteralblockk`}
                            ></div>
                          </div>
                          <p className="titel_file">
                            File Name :
                            <span> {data?.file?.file_local_name}</span>
                          </p>
                          <div className="meterial_button_wrapper">
                            <button className="programButtonClass">
                              <a
                                href={data?.file?.url}
                                download
                                target="_blank"
                              >
                                Download
                              </a>
                            </button>

                            <button
                              className="programButtonClass"
                              onClick={() => handleOpen(data)}
                            >
                              Preview
                            </button>
                          </div>
                        </Grid>
                      }
                    </Grid>
                  )}

                {data?.type == "file" &&
                  findfileextenssion(data?.file?.url) == "doc" && (
                    <Grid
                      container
                      className="information_template_each"
                      spacing={1}
                    >
                      {
                        <Grid className="titel_wrper block_wrper">
                          <div className="icon_wrper">
                            <div
                              className={`${findfileextenssion(
                                data?.file?.url
                              )} type_block meteralblockk`}
                            ></div>
                          </div>
                          <p className="titel_file">
                            File Name :
                            <span> {data?.file?.file_local_name}</span>
                          </p>
                          <div className="meterial_button_wrapper">
                            <button className="programButtonClass">
                              <a
                                href={data?.file?.url}
                                download
                                target="_blank"
                              >
                                Download
                              </a>
                            </button>

                            <button
                              className="programButtonClass"
                              onClick={() => handleOpen(data)}
                            >
                              Preview
                            </button>
                          </div>
                        </Grid>
                      }
                    </Grid>
                  )}

                {data?.type == "file" &&
                  findfileextenssion(data?.file?.url) == "audio" && (
                    <Grid
                      container
                      className="information_template_each"
                      spacing={1}
                    >
                      {
                        <Grid className="titel_wrper block_wrper">
                          <div className="icon_wrper">
                            <div
                              className={`${findfileextenssion(
                                data?.file?.url
                              )} type_block meteralblockk`}
                            ></div>
                          </div>

                          <p className="titel_file">
                            File Name :
                            <span> {data?.file?.file_local_name}</span>
                          </p>
                          <div className="meterial_button_wrapper">
                            <button className="programButtonClass">
                              <a
                                href={data?.file?.url}
                                download
                                target="_blank"
                              >
                                Download
                              </a>
                            </button>

                            <button
                              className="programButtonClass"
                              onClick={() => handleOpen(data)}
                            >
                              Preview
                            </button>
                          </div>
                        </Grid>
                      }
                    </Grid>
                  )}

                {data?.type == "file" &&
                  findfileextenssion(data?.file?.url) == "img" && (
                    <Grid
                      container
                      className="information_template_each"
                      spacing={1}
                    >
                      {
                        <Grid className="titel_wrper block_wrper">
                          <div className="icon_wrper">
                            <div
                              className={`jpg type_block meteralblockk`}
                            ></div>
                          </div>
                          <p className="titel_file">
                            File Name :
                            <span> {data?.file?.file_local_name}</span>
                          </p>
                          <div className="meterial_button_wrapper">
                            <button className="programButtonClass">
                              <a
                                href={data?.file?.url}
                                download
                                target="_blank"
                              >
                                Download
                              </a>
                            </button>

                            <button
                              className="programButtonClass"
                              onClick={() => handleOpen(data)}
                            >
                              Preview
                            </button>
                          </div>
                        </Grid>
                      }
                    </Grid>
                  )}
              </>
            );
          })}
        </div>
      </div>

      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
        className=" modalBaseStyle "
      >
        <Box className="Preview_material modalBaseBox mediumModal">
          <Tooltip title="Close">
            <IconButton
              onClick={handleClose}
              // className="CloseModal"
              className="modal_icon_button"
            >
              <CloseIcon />
            </IconButton>
          </Tooltip>
          <Typography id="modal-modal-title" variant="h6" component="h1">
            Preview {findfileextenssion(previewfile)}
          </Typography>
          <Typography id="modal-modal-description" sx={{ mt: 2 }}>
            <div className="modaldatashow_wrapper">
              {findfileextenssion(previewfile?.file?.url) == "img" ? (
                <img width={550} height={500} src={previewfile?.file?.url} />
              ) : findfileextenssion(previewfile?.file?.url) === "video" ? (
                <ReactPlayer url={previewfile?.file?.url} controls={true} />
              ) : findfileextenssion(previewfile?.file?.url) == "pdf" ? (
                <embed
                  src={previewfile?.file?.url}
                  class="docshowifram"
                  width="1366px"
                  height="623px"
                />
              ) : findfileextenssion(previewfile?.file?.url) == "doc" ? (
                <iframe
                  src={`https://view.officeapps.live.com/op/embed.aspx?src=${previewfile?.file?.url}`}
                  width="1366px"
                  height="623px"
                  frameborder="0"
                  class="docshowifram"
                ></iframe>
              ) : findfileextenssion(previewfile?.file?.url) === "audio" ? (
                <ReactPlayer url={previewfile?.file?.url} controls={true} />
              ) : previewfile && previewfile?.type === "video-youtube" ? (
                <ReactPlayer
                  className="react-player"
                  url={`https://www.youtube.com/watch?v=${previewfile?.youtube?.id}`}
                  width="100%"
                  height="100%"
                  controls={true}
                />
              ) : previewfile && previewfile?.type === "video-vimeo" ? (
                <ReactPlayer url={previewfile?.vimeo} controls={true} />
                
              ) : previewfile && previewfile?.type == "video-link" ? (
                <ReactPlayer url={previewfile?.video} controls={true} />
              ) : previewfile && previewfile?.type == "video-upload" ? (
                <ReactPlayer
                  url={previewfile?.videofile?.url}
                  controls={true}
                />
              ) : null}
            </div>
            <div className="bottom_test_desc_wrp">
              <h2 className="header_title">{previewfile?.title}</h2>
              <span className="header_description">{previewfile?.description}</span>
            </div>
          </Typography>
        </Box>
      </Modal>
    </>
  );
};

export default Informationtemplate;
