
import Snackbar from '@mui/material/Snackbar';
import Modal from '@mui/material/Modal';
import Typography from '@mui/material/Typography';
import CloseIcon from '@mui/icons-material/Close';
import store from "../../../../store";

// import * as React from 'react';
import React, { useEffect, useRef, useState } from "react";
import { useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { Button, Checkbox, Chip, FormControl, FormControlLabel, Grid, InputLabel, LinearProgress, List, ListItemText, MenuItem, OutlinedInput, Radio, RadioGroup, Select, Tooltip } from '@mui/material';

import { Box, display, Stack } from '@mui/system';
import { useCookies, Cookies } from "react-cookie";
import { addUserRoles, FetchHMSettingAccessUpdate } from './userListReducer';



const ManageHmSettingModal = (props) => {



    const dispatch = useDispatch(); // ---- Dispatch -----
    const navigate = useNavigate(); //**** FOR PAGE NAVIGATION****//

    // ---- States ----
    const [loading, setLoading] = useState(false);  //*****FOR LODING****//

    // ---- Cookies ---
    const [userInfo, setuserInfoCookie] = useCookies(["userinfocookies"]);
    const [userRoles, setUserRoles] = React.useState([]);
    const [copyUserRoles, setcopyUserRoles] = React.useState([]);
    const [open, setopen] = React.useState(false);




    console.warn("<<<<<< props goes here >>>>>>", props)
    // console.warn("<<<<<< cookies goes here >>>>>", userInfo)

    // ---- for loader -----
    const loader = useSelector(state => (state.userManagment?.loading) ? state.userManagment?.loading : false)

    const userRole = useSelector(state => (state.userManagment?.userRoles) ? state.userManagment?.userRoles : [])

    const success = useSelector(state => (state.userManagment?.resMessage) ? state.userManagment?.resMessage : null)





    useEffect(() => {
        if (success) {
            setopen(true)
        }
    }, [success])


    useEffect(() => {
        props.setModalResponse(false);
    }, [])


    // ------- onModalClose --------
    function ModalClose() {
        // console.warn("<<< modal close hit >>>")
        props.setModalResponse(false);
        props.tooglefunc(false);


    }



    // ------- onAddButton Trigger ------
    function formSubmit(event) {
        // event.preventDefault();
        // let setCopyArr = [...props.userRoles]
        // // console.warn("<<<<<<< setCopyArr >>>>>>>", setCopyArr)


        // const finalData = setCopyArr.map((val) => {
        //     // console.warn("<<< map value >>>", val)

        //     const data = { ...val }

        //     if (userRoles.includes(data.label)) {
        //         data.status = 1
        //     } else {
        //         data.status = 0
        //     }
        //     return data;
        // })

        // // console.log('<< final data >>', finalData);

        // dispatch(addUserRoles({ _id: props.UserId, roles_data: finalData }));


    }
    // ----------------------------------------






    // ------------- Hnadle Chnage ----------
    const handleChange = (event, val) => {
        event.preventDefault();
        console.warn("<<<<<< e >>>>>>", event.target.checked)
        console.warn("<<<<<< val >>>>>>", val)

        const data = {
            [val]: event?.target?.checked,
            "_id": props?.data?._id
        }

        console.warn("final payload data >>>>>>", data)

        dispatch(FetchHMSettingAccessUpdate(data))
    };

    // ----------------------------






    return (
        <>

            {/* ------ Confirmation Modal ------ */}


            <Modal
                className="modalblock"
                open={props.toogle}
                onClose={ModalClose}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
            >

                <Box className="BoxMui_modal configure-role" sx={{
                    position: "absolute",
                    top: "50%",
                    left: "50%",
                    transform: "translate(-50%, -50%)",
                    width: 400,
                    bgcolor: "background.paper",
                    border: "2px solid #000",
                    boxShadow: 24,
                    p: 4,
                    textAlign: "center",
                }}>
                    <Typography id="modal-modal-title" variant="h6" component="h3">

                        Lead Access Settings For {props.data.firstname + " " + props.data.lastname}
                    </Typography>


                    {/* ----- Dropdown for selecting roles ----- */}

                    <form className='manageHMmodal' onSubmit={(event) => formSubmit(event)}>

                        <FormControl sx={{ m: 1, width: 300 }}>
                            {/* <FormLabel id="demo-radio-buttons-group-label">Gender</FormLabel> */}
                            <RadioGroup
                                aria-labelledby="demo-radio-buttons-group-label"
                                defaultValue={props?.data?.calendar_access == 1 ? "calendar_access" : "lead_only"}
                                name="radio-buttons-group"
                            >
                                <FormControlLabel value="lead_only"
                                    control={<Radio />}
                                    label="Lead Only"
                                    name="lead_only"
                                    className='radio-input-block'
                                    onChange={e => {
                                        handleChange(e, "lead_only")
                                    }}
                                />

                                <FormControlLabel value="calendar_access"
                                    name="calendar_access"
                                    control={<Radio />}
                                    label="Calender Access"
                                    className='radio-input-block'
                                    onChange={e => {
                                        handleChange(e, "calendar_access")
                                    }}
                                />

                            </RadioGroup>
                            {loader ? <LinearProgress /> : ""}


                        </FormControl>
                    </form>



                    {/* -------------------------------------- */}



                    <Button onClick={ModalClose} className="closeModalBtn">
                        <Tooltip title="Close" placement="right-start">

                            <CloseIcon />
                        </Tooltip>
                    </Button>
                </Box>
            </Modal>




        </>
    )
}


export default ManageHmSettingModal;



