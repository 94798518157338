import React from 'react'
import ListingTable from '../../../../listing/listing'
import { useNavigate } from 'react-router-dom';
import { useRef, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { resetresolvenew } from '../../../../helper/ResolveReducer';
import { TableSkeleton } from '../../../../helper/TableSkeleton';
import store from '../../../../store';
import { getTagListdata, tagAddEditData } from '../TagReducer';
import { resolvefunction } from '../../../../helper/helperFunctions';
import { Snackbar, Tooltip } from '@mui/material';
import { useState } from 'react';
const TagList = () => {



    // Data to populate our list  
    const navigate = useNavigate()
    const loader = useSelector(state => state.tagEditAddRedicer?.loading ? state.tagEditAddRedicer?.loading : false)
    const tagData = useSelector(state => state.tagEditAddRedicer?.tagListData && state.tagEditAddRedicer?.tagListData.length > 0 ? state.tagEditAddRedicer.tagListData : []) // TagList Body data 
    const resolved = useSelector(state => state.ResolveReducer.resolved['/tag-list'] ? state.ResolveReducer.resolved['/tag-list'] : false)
    const editData = useSelector(state => state.tableSlice?.tempRowData?.tagDataTable ? state.tableSlice.tempRowData.tagDataTable : {})
    const editClicked = useSelector(state => state.tableSlice?.customButtonClicked?.tagDataTable?.edittable9 ? state.tableSlice.customButtonClicked.tagDataTable.edittable9 : false)
    const [openSnacAddEdit, setOpenSnacAddEdit] = useState(false);
    const formsubmissionstate = useSelector(state => state.formSlice?.formSubmissionState ? state.formSlice.formSubmissionState : null);
    const successMsg = useSelector(state => state.formSlice.message?.TagAddEditForm)




    // header data for tabil

    var modifyTableHeaders = [
        { val: "name", name: "Tag Name" },
        // { val: "lead_only", name: "Lead only" },
        { val: "status", name: "Status", customVal: { 0: 'Inactive', 1: "Active" } },
        // { val: "description", name: "Description" },
        { val: "updatedon_datetime", name: "Updated on", type: "datetime", format: "DD/MM/YYYY" },

    ];




    // configuration for tabil 

    let tableDataTag = useRef({
        tableId: "tagDataTable",
        tableTitle: "Tag List",
        showReload: true,
        showFilter: true,
        api: {
            url: process.env.REACT_APP_API_URL,
            endPoint: "tag-management/tag-list-count",
            deleteSingleUserEndpoint: "",
            tableCountEndpoint: "tag-management/tag-list-count",
        },
        reqBody: {
            "condition": {
                "limit": 10,
                "skip": 0
            },
            "sort": {
                "field": "updatedon_datetime",
                "type": "desc"
            },
            "searchcondition": {},
            "project": {},
            "token": "",
            "count": false
        },
        deleteSingleUserReqBody: {
            source: "users",
            secret: "AZ|lepL`",
        },
        deleteMultipleReqBody: {
            source: "users",
            secret: "AZ|lepL`",
        },
        rowsPerPageOptions: [10, 20, 30],
        sortFields: ["name", "updatedon_datetime"],
        paginationType: "stackPagination",
        enableRowActionLoader: true,


        // buttons to manipulate multipul data inside tabil
        customBtnHead: [
            {
                id: "delete",
                label: "Multi Delete",
                type: "api",
                category: "modal",
                icon_type: "delete",
                className: "delete_data",
                modalClassName: "delete_modal",
                modalTitle: "<p>Are you sure that you want to delete this record ?</p>",
                modalHeader: "<p> Alert !!</p>",
                btn_label: ['Yes', "No"],
                api_data: {
                    base_url: process.env.REACT_APP_API_URL,
                    endpoint: "tag-management/delete-tag",
                    body: {
                        "source": "users",
                        "secret": "AZ|lepL`",
                        "token": "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJmb28iOiJiYXIiLCJleHAiOjE2NTIxNjM3MDUsImlhdCI6MTY1MjA3NzMwNX0.3kB4iRr5JbmV81Dl5gKA7fiTaMEEp0xbsWoO-wA0XUo",
                    }
                }
            },
            {
                id: "status",
                label: "Status",
                type: "api",
                category: "status",
                icon_type: "toggle_off",
                className: "status_data",
                modalClassName: "delete_modal",
                modalTitle: "<p>Are you sure you want to change the status ?</p>",
                modalHeader: "<p> Alert !!</p>",
                btn_label: ['Active', "Inactive"],
                api_data: {
                    base_url: process.env.REACT_APP_API_URL,
                    endpoint: "tag-management/tag-status-update",
                    body: {
                        "source": "users",
                        "secret": "AZ|lepL`",
                        "token": "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJmb28iOiJiYXIiLCJleHAiOjE2NTIxNjM3MDUsImlhdCI6MTY1MjA3NzMwNX0.3kB4iRr5JbmV81Dl5gKA7fiTaMEEp0xbsWoO-wA0XUo",
                    }
                }
            },

        ],


        // button to manipulate singel data inside tabil
        buttons: [
            {
                id: "edittable9",
                type: "other",
                icon_type: "edit_icon",
                label: "Edit",
                name: "edit",
            },
            {
                id: "delete",
                label: "Delete",
                type: "api",
                category: "modal",
                icon_type: "delete",
                className: "delete_data",
                modalClassName: "delete_modal",
                modalTitle: "<p>Are you sure that you want to delete this record ?</p>",
                modalHeader: "<p> Alert !!</p>",
                btn_label: ['Yes', "No"],
                api_data: {
                    base_url: process.env.REACT_APP_API_URL,
                    endpoint: "tag-management/delete-tag",
                    body: {
                        "source": "users",
                        "secret": "AZ|lepL`",
                        "token": "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJmb28iOiJiYXIiLCJleHAiOjE2NTIxNjM3MDUsImlhdCI6MTY1MjA3NzMwNX0.3kB4iRr5JbmV81Dl5gKA7fiTaMEEp0xbsWoO-wA0XUo",
                    }
                }
            },
            {
                id: "status",
                label: "Status",
                type: "api",
                category: "status",
                icon_type: "toggle_off",
                className: "status_data",
                modalClassName: "delete_modal",
                modalTitle: "<p>Are you sure you that want to change the status ?</p>",
                modalHeader: "<p> Alert !!</p>",
                btn_label: ['Active', "Inactive"],
                api_data: {
                    base_url: process.env.REACT_APP_API_URL,
                    endpoint: "tag-management/tag-status-update",
                    body: {
                        "source": "users",
                        "secret": "AZ|lepL`",
                        "token": "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJmb28iOiJiYXIiLCJleHAiOjE2NTIxNjM3MDUsImlhdCI6MTY1MjA3NzMwNX0.3kB4iRr5JbmV81Dl5gKA7fiTaMEEp0xbsWoO-wA0XUo",
                    }
                }
            },
        ],
        deleteModal: {
            modalClassName: "modalblock",
        },
        searchData: {
            heading: "Search Tag",
            type: "drawer",
            condition_type: "$or",
            formData: {
                id: 'searchForm',
                formWrapperClass: "formWrapperClass",// Can be undefined default will be formCls
                formInlineCLass: "userForm", // Can be undefined default will be formInline
                formButtonClass: "submitbtnsection",
                submitBtnEndIcon: "search",
                submitBtnName: "Search",
                resetBtnEndIcon: "replay",
                resetBtnName: "Refresh",
                resetForm: true,
                formAdditionalSubmissionData: {},
                fields: [
                    {
                        id: 0,
                        label: "Search By Tag  Name",
                        name: "name",
                        type: "autoComplete",
                        className: 'list_search_item countDiv__column--col6 ',
                        base_url: process.env.REACT_APP_API_URL + "tag-management/auto-complete-tag",
                        reqBody: {
                            // "formId": 'teamsearch',
                            "condition": {
                                "limit": 10,
                                "skip": 0
                            },
                            "sort": {
                                "type": "asc",
                                "field": "name"
                            },
                            "searchcondition": {

                            }
                        },
                        sx: { m: 1, minWidth: 300 },
                        // payloadFormat: { key: "_id", param: "$regex", options: "i" },
                        payloadFormat: { key: "_id" },

                    },
                    {
                        id: 3,
                        label: "Search by Status",
                        name: "status_show",
                        type: 'select',
                        multiple: false,
                        values: [
                            { val: 1, name: "Active" },
                            { val: 0, name: "Inactive" }
                        ],
                        sx: { m: 1, minWidth: 120 },
                        className: 'list_search_item countDiv__column--col6 ',
                        payloadFormat: { key: "status" },
                    },
                    {
                        id: 8,
                        label: "Search by Created on Start Date",
                        name: "start_date",
                        className: 'datePicker',
                        type: 'datePicker',
                        sx: { m: 1, width: 300 },
                        className: 'list_search_item countDiv__column--col6 ',
                        payloadFormat: { key: "createdon_datetime", param: "$gte" }
                    },
                    {
                        id: 9,
                        label: "Search by Created on End Date",
                        name: "end_date",
                        className: 'list_search_item countDiv__column--col6 ',
                        type: 'datePicker',
                        fullDay: true,
                        sx: { m: 1, width: 300 },
                        payloadFormat: { key: "createdon_datetime", param: "$lte" }
                    },
                    {
                        id: 10,
                        //   heading: "Search By Creation Time",
                        label: "Search by Updated on Start Date",
                        name: "start_date_by_update",
                        className: 'datePicker',
                        type: 'datePicker',
                        sx: { m: 1, width: 300 },
                        className: 'list_search_item countDiv__column--col6 ',
                        payloadFormat: { key: "updatedon_datetime", param: "$gte" }
                    },
                    {
                        id: 11,
                        label: "Search by Updated on End Date",
                        name: "end_date_by_update",
                        className: 'list_search_item countDiv__column--col6 ',
                        type: 'datePicker',
                        fullDay: true,
                        sx: { m: 1, width: 300 },
                        payloadFormat: { key: "updatedon_datetime", param: "$lte" }
                    },
                ]
            }
        },
    })
    let reqbody = { // chnag to 10
        "condition": {
            "limit": 10,
            "skip": 0
        },
        "sort": {
            "field": "updatedon_datetime",
            "type": "desc"
        },
        "searchcondition": {},
        "project": {},
        "token": ""
    }

    useEffect(() => {
        if (!resolved) store.dispatch(getTagListdata(reqbody));
        return () => {
            store.dispatch(resetresolvenew('/tag-list'))
        }
    }, [])


    useEffect(() => {
        if (editClicked && Object.keys(editData).length > 0) {
            resolvefunction(navigate, `/tag-edit/${editData._id}`, tagAddEditData(editData._id))
        }
    }, [editClicked])


    useEffect(() => {
        if (formsubmissionstate.TagAddEditForm === 2) {

            setOpenSnacAddEdit(true)
        }
    }, [formsubmissionstate])


    // useEffect(()=>{
    //     console.log("loader-------->",loader);
    //     console.log("tagData-------->",tagData);
    //     console.log("resolved-------->",resolved);
    //     console.log("editData-------->",editData);
    //     console.log("editClicked-------->",editClicked);
    //     console.log("openSnacAddEdit-------->",openSnacAddEdit);
    //     console.log("formsubmissionstate-------->",formsubmissionstate);
    //     console.log("successMsg-------->",successMsg);
    // },[loader,tagData,resolved,editData,editClicked,openSnacAddEdit,formsubmissionstate,successMsg])


    return (<>
        <div className='rolelist_mainwrp'>
            <div className='listing_heading'>
                <h1>Tag Management</h1>
                <Tooltip title='Add Tag ' >
                    <button className='addrole_btn' onClick={() => navigate('/tag-edit')}>+</button>
                </Tooltip>
            </div>
            <div className='rolelist_mainwrp_Table'>
                {!loader ?
                    (
                        <ListingTable
                            tableData={tableDataTag.current}
                            dataset={tagData}
                            modifyHeaders={modifyTableHeaders}
                        />
                    )
                    : (<TableSkeleton count={10} />)}
            </div>
            <Snackbar
                open={openSnacAddEdit}
                onClose={() => setOpenSnacAddEdit(false)}
                autoHideDuration={3000}
                message={successMsg}
            />
        </div>
    </>

    )
}

export default TagList