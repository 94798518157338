import React, { useEffect, useState } from 'react'
import PersonIcon from '@mui/icons-material/Person';
import LocalOfferIcon from '@mui/icons-material/LocalOffer';
import FolderIcon from '@mui/icons-material/Folder';
import { format } from 'date-fns'

import LoginIcon from '@mui/icons-material/Login';
import FollowTheSignsIcon from '@mui/icons-material/FollowTheSigns';
import { IconButton, LinearProgress, Snackbar, Tooltip } from '@mui/material';
import LocalPhoneIcon from '@mui/icons-material/LocalPhone';
import moment from 'moment';
import { useDispatch, useSelector } from 'react-redux';
import { Box } from '@mui/system';
import SearchIcon from '@mui/icons-material/Search';
import FDSearch from './FDSearch';
import { UserActivity } from './folderViewReducer';
import FollowUpcall from '../../LeadManagement/FollowUpcall';
import { useCookies } from 'react-cookie';

export const FollowUpCall = ({ followUpCallList }) => {
  const dispatch = useDispatch()
  //--------state define----------
  const [userInfo, setuserInfoCookie] = useCookies(["userinfocookies"]);
  const [dataCopy, setDataCopy] = useState({});
  const [searchDraw, setSearchDraw] = React.useState(false); // snackbar const  
  const [open, setOpen] = React.useState(false); // snackbar const
  const [queryParamId, setQueryParamId] = useState();  //*****FOR Seeting QueryP****//
  const [followUpModel, setFollowUpModel] = useState(false)//follow up model state
  //---------------------------------------
  console.log("followUpCallList", followUpCallList)
  const loaderForFollowUp = useSelector(state => state.LeadReducer?.loaderForFollowUp ? state.LeadReducer.loaderForFollowUp : false)
  const submissionState = useSelector(state => (state?.formSlice?.formSubmissionState?.Folder_View_Search) ? state.formSlice.formSubmissionState.Folder_View_Search : false)

  const searchData = useSelector(state => (state?.formSlice?.formData?.Folder_View_Search) ? state.formSlice.formData.Folder_View_Search : {})


  // ----------------
  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setOpen(false);
    // setErrPopup(false);
    // setErrMsgCopy(null);
  };

  console.log(followUpCallList, "followUpCallList");
  const searchFields = [
    {
      id: 8,
      label: "Search by Start Date",
      name: "start_date",
      className: 'inputBlock inputBlock3line',
      type: 'datePicker',
      sx: { m: 1, width: 300 },
      dateFormat: 'dd/MM/yyyy',


    },
    {
      id: 9,
      label: "Search by End Date",
      name: "end_date",
      className: 'datePicker',
      type: 'datePicker',
      fullDay: true,
      sx: { m: 1, width: 300 },
      dateFormat: 'dd/MM/yyyy',
      className: 'inputBlock inputBlock3line',
      errorMessage: {
        dateCheck: "End Date can not be less than Start Date",
      },
    },
  ]

  useEffect(() => {
    if (submissionState === 4) {
      setSearchDraw(false)
      let activity_time = {
        "$gte": searchData?.start_date ? searchData.start_date : undefined,
        "$lte": searchData?.end_date ? searchData.end_date : undefined
      }

      activity_time.$lte !== " " || activity_time.$lte !== undefined && delete activity_time["$lte"];

      activity_time.$gte !== " " || activity_time.$gte !== undefined && delete activity_time["$gte"];
      const payload = {
        "condition": {
          "limit": 5,
          "skip": 0
        },
        "sort": {
          "field": "activity_time",
          "type": "desc"
        },
        "searchcondition": {
          "user_id": queryParamId ? queryParamId : undefined,
          // "activity_by": searchData?.activity_by?searchData.activity_by:undefined,
          // "activity_type": searchData?.activity_type?searchData.activity_type:undefined,
          "activity_time": activity_time

        },
        "project": {},
        "token": "",
        "email": ""
      }
      dispatch(UserActivity(payload))
    }
  }, [submissionState])
  const closeModal = () => {
    setFollowUpModel(false)
};

  return (
    <>

      <div>
        <h3>
          <LocalPhoneIcon /> Follow Up Call
          <div className="srchfld_wrp" style={{ marginLeft: "auto" }}>
            <Tooltip title="Search Email">
              <IconButton>
                <SearchIcon onClick={() => setSearchDraw(true)} />
                <FollowTheSignsIcon onClick={() => setFollowUpModel(true)} />
              </IconButton>
            </Tooltip>
          </div>
        </h3>
        {loaderForFollowUp ? (<Box sx={{ width: "100%", marginBottom: '10px' }}><LinearProgress /></Box>) : null}


        {followUpCallList.length > 0 ? followUpCallList.map((x) => (
          <div className='user-activity'>

            <ul className='ua-data-block'>
              {/* <li className='heading'>  {x.activity_type == 'login' ? (<LoginIcon/>) : x.activity_type == 'tag_assigned' ? (<LocalOfferIcon />) : x.activity_type == 'folder_accessed' || x.activity_type == 'folder_accessed_by' ? (<FolderIcon />) : ' '}    {x.activity_type ? x.activity_type : 'N/A'} </li>  */}
              {/* <li>Added By:  {format(x.added_by, 'dd-MM-yyyy HH:mm:ss')} </li> */}
              <li>Added For:  {x.added_for_name} </li>
              <li>Added By:  {x.added_by_name} </li>
              <li>Date:  {moment(x.date).format('MMMM Do YYYY')} </li>
              <li>Time:  {x.start_time} </li>
              <li>Notes:  {x.notes} </li>
              {/* <li>Activity: {x.activity}</li>  */}
            </ul>

          </div>)) : <p> No Data Found !</p>}

      </div>




      {open ? (<Snackbar
        open={open}
        autoHideDuration={6000}
        onClose={handleClose}
        message={queryParamId ? "Updated Sucessfully" : " "}

      />) : ""}

      {searchDraw ? <FDSearch
        open={searchDraw}
        close={setSearchDraw}
        formFields={searchFields}
        searchFields={searchFields}
        type="follow_up_call " /> : ""}

      {followUpModel ? <FollowUpcall
        open={followUpModel}
        close={closeModal}
        addedById={userInfo.userinfo._id}
        addedForId={dataCopy._id}
        listData={followUpCallList}
        loading={loaderForFollowUp}
      /> : ""}
    </>
  )
}
