import React, { useEffect, useState } from "react";
import {
  Alert,
  Button,
  Grid,
  InputAdornment,
  LinearProgress,
  Paper,
  Snackbar,
  TextField,
  Tooltip,
  Typography,
} from "@mui/material";
import { Box } from "@mui/system";
// import { resetForm } from "../form/formReducer";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import store from "../../store";
import { Controller, useForm } from "react-hook-form";
import { clearMessageIsLoggedIn, forgotPassword } from "../login/loginReducer";
import { AccountCircle } from "@mui/icons-material";
// import { forgotPassword } from "./AccountReducer";

export const ForgotPassword = () => {

  // const [loading, setloading] = useState(false);
  const [formSubmissionState, setFormSubmissionState] = useState(0);
  const [opensnack, setOpensnack] = useState(false);
  const [message, setMessage] = useState(null);

  const [open, setOpen] = useState(false);

  const loading = useSelector(state => state.loginSlice?.loading ? state.loginSlice.loading : false)
  // const loading0 = useSelector(state => state?.loading ? state.loading : false)

  // ------- sendResetPassword ------
  const resMessage = useSelector(state => (state.loginSlice && state.loginSlice?.resetPassMSg)? state.loginSlice?.resetPassMSg : null);

  useEffect(() => {
    setOpensnack(false);
    setMessage(null);
  }, [])
  


  useEffect(() => {
   if(resMessage!==null){
    setOpensnack(true);
    setMessage(resMessage)
   }else{
    setOpensnack(false);

   }
  }, [resMessage])
  
  const [errorMessages, setErrorMessages] = useState({
    required: 'This Field is Required',
    validate: "Please Enter Valid Email",
    pattern: "Invalid Email Address"
  })
  const dispatch = useDispatch();
  /////////////////////////////// Hook Form /////////////////////////////////////////////
  const {
    handleSubmit,
    control,
    formState: { errors },
    clearErrors,
    setValue,
  } = useForm();
  //////////////////////////////// Form Submission //////////////////////////////////////
  const onSubmit = async (data) => {
    // data.link = `${process.env.REACT_APP_API_EXTERNAL_URL}forgot-password/`;
    console.warn("<<<<<< from forgot passowrd >>>>>", data);
     dispatch(forgotPassword(data));
  };

  // store.subscribe(() => {
  //   const storeData = store.getState();
  //   setFormSubmissionState(
  //     storeData.formSlice.formSubmissionState.createEventForm
  //   );
  //   // console.log("storeData in pagwwwwww", storeData.formSlice);

  //   if (storeData.formSlice.formSubmissionState.createEventForm === 2) {
  //     setOpensnack(true);
  //   }else{
  //     setOpensnack(false);

  //   }
  //   if (storeData.formSlice.message?.createEventForm) {
  //     setMessage(storeData.formSlice.message.createEventForm);
  //   }
  // });

  useEffect(() => {
    if (formSubmissionState == 1) {
      // setloading(true);
    }

    if (formSubmissionState == 2) {
      // setloading(false);
      // dispatch(resetForm({ formId: "createEventForm" }));
    }
  }, [formSubmissionState]);

  useEffect(() => {
    console.log("opensnack", opensnack);
    if (opensnack) {
      setTimeout(() => {
        setOpensnack(false);
      }, 3000);
    }
  }, [opensnack]);



  

  return (


<div className='loginBody'>



<img className='logo' src="https://all-frontend-assets.s3.amazonaws.com/aspireLeadFire/logo.webp" />



<div className='loginBox'>
    <div className='loginBox_body'>




        <h1>
            Forgot Password
        </h1>

        <form onSubmit={handleSubmit(onSubmit)}>

            <Controller
                control={control}
                name="email"
                rules={{
                    required: true,
                    pattern: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
                }}
                render={() => (
                    <TextField
                        margin="normal"
                        fullWidth
                        id="email"
                        label="Email"
                        name="email"
                        type="text"
                        autoFocus
                        onChange={(e) => {
                            setValue("email", e.target.value?.toLowerCase().trim());
                            clearErrors("email");
                            dispatch(clearMessageIsLoggedIn());
                        }}
                        error={
                            Object.keys(errors).length > 0 && errors.email != null
                        }
                        helperText={
                            errors.email && errorMessages[errors.email.type]
                        }
                        InputProps={{
                            endAdornment: (
                                <InputAdornment position="end" edge="end">
                                    <AccountCircle />
                                </InputAdornment>
                            ),
                        }}
                    />
                )}
            />

       

            <div>
                {/* <Grid item xs /> */}

                <Link className="forgot_password" to="/forget-password">

                </Link>

            </div>

            <Button
                className="passBttn"
                type="submit"
                fullWidth
                variant="contained"
                sx={{ mt: 2, mb: 2 }}
                disabled={loading}
            >
                <span> SUBMIT </span>
            </Button>

        </form>
        {loading && <LinearProgress sx={{ margin: "5px" }} />}

        <Link className="Back_to_Login" to="/login">
            Back to Login
        </Link>

    </div>
</div>


{opensnack && message!=null?(<Snackbar
    className='loginSnack'
    open={opensnack}
    // open={true}
    onClose={() => setOpen(false)}
    anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
    autoHideDuration={4000}
    message={message}
    // message="login sucessfull"
    sx={{ width: '100%' }}
/>):""}


</div>
 
  );
};


export default ForgotPassword;
