import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
// import App from './App';

import store from './store';
import { CookiesProvider, withCookies, Cookies, useCookies } from "react-cookie";
import GetRoutes from './config/routes';
import { Provider, useSelector } from 'react-redux/es';
import { setLogedinUserInfo } from './Pages/login/loginReducer';
import { useEffect } from 'react';
// import './assets/angular-material-css/deeppurple-amber.css';
// import './assets/angular-material-css/indigo-pink.css';
// import './assets/angular-material-css/pink-bluegrey.css';
// import './assets/angular-material-css/purple-green.css';
import "./assets/meterial-css/indigo-pink.css"

function App() {

  const cookies = new Cookies();
  console.log("cookies in layout==>", cookies.getAll());

  const getcookies = cookies.getAll();
  console.log("getcookies from index==>", getcookies);
  if (getcookies !== null && getcookies !== undefined && Object.keys(getcookies).length > 0) {
    store.dispatch(setLogedinUserInfo(getcookies));
    console.log(getcookies, "getcookies");

  }

  // console.log(getcookies, "getcookies");



  return (

    <>
      <CookiesProvider>
        <Provider store={store}>
          <GetRoutes />
        </Provider>
      </CookiesProvider>
    </>
  );
}

const container = document.getElementById('root')
const root = ReactDOM.createRoot(container);
root.render(<App />);
