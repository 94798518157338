import { Button, Icon, IconButton, InputLabel, Paper, Tooltip } from '@mui/material'
import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { removeExternalFormData, resetFormCustomButtonClicked, resetListnerFormData, setFormCustomButtonClicked, setListnerFormData } from '../formReducer';
import { randomId } from '../../helper/helperFunctions';
import { Delete, Edit } from '@mui/icons-material';

function CustomButton({ fielddata, value, setValue, getValues, errors, clearErrors, formId, setError }) {
  const dispatch = useDispatch();
  const formCustomButtonClicked = useSelector((state) => state.formSlice.formCustomButtonClicked[formId] && state.formSlice.formCustomButtonClicked[formId][fielddata.name] ? state.formSlice.formCustomButtonClicked[formId][fielddata.name] : false)
  const externalData = useSelector((state) => (state.formSlice.formExternalInputData[formId] && state.formSlice.formExternalInputData[formId][fielddata.name]) ? state.formSlice.formExternalInputData[formId][fielddata.name] : null)
  const listnerEditFormData = useSelector((state) => (state.formSlice.listnerEditFormData[formId] && state.formSlice.listnerEditFormData[formId][fielddata.name]) ? state.formSlice.listnerEditFormData[formId][fielddata.name] : null)

  const removeData = (data, index) => {
    console.log("data===========================+>", data)
    dispatch(removeExternalFormData({ formId, fieldName: fielddata.name, fieldData: data, index }))
  }

  useEffect(() => {
    if (formCustomButtonClicked === true) {
      setTimeout(() => dispatch(resetFormCustomButtonClicked({ formId, fieldName: fielddata.name })), 1000)
    }
  }, [formCustomButtonClicked])

  useEffect(() => {
    if (externalData) {
      setValue(fielddata.name, externalData.map((data) => data.value))
    }
  }, [externalData])

  useEffect(() => {
    if (listnerEditFormData !== null) {
      setTimeout(() => dispatch(resetListnerFormData({ formId, buttonId: fielddata.name })), 1000)
    }
  }, [listnerEditFormData])


  return (
    <div className={"form_item " + fielddata.className + " custom_btn"}>
      {/* HEADING */}
      {fielddata.heading && <InputLabel ><div dangerouslySetInnerHTML={{ __html: fielddata.heading }}></div></InputLabel>}
      {/* FIELD */}
      <Button
        className='custom_button'
        variant='contained'
        startIcon={fielddata.startIcon && <Icon>{fielddata.startIcon}</Icon>}
        endIcon={fielddata.endIcon && <Icon>{fielddata.endIcon}</Icon>}
        onClick={() => dispatch(setFormCustomButtonClicked({ formId, fieldName: fielddata.name }))}
      >
        {fielddata.label}
      </Button>

      {externalData && externalData.map((data, index) => (
        <Paper key={randomId()} elevation={7} className="data_container">
          {data.label && <div className='data_label' dangerouslySetInnerHTML={{ __html: data.label }}></div>}
          <div className="data_block">
            <div className='data_set' dangerouslySetInnerHTML={{ __html: data.innerHtml }}></div>
            <div className='data_btn_container'>
              <Tooltip title="Delete">
                <IconButton className='close_btn' onClick={() => removeData(data, index)}><Delete /></IconButton>
              </Tooltip>
              <Tooltip title="Edit">
                <IconButton className='edit_btn' onClick={() => dispatch(setListnerFormData({ formId, buttonId: fielddata.name, data: data.value, index }))}><Edit /></IconButton>
              </Tooltip>
            </div>
          </div>
        </Paper>
      ))}

    </div>
  )
}

export default CustomButton