import React from 'react'
import LandingPage9Form from './LandingPage9Form'
import "../LandingPage9/LandingPage9.css"

function LandingPage9() {
  return (
    <div className='LandingPage9Body'>
      <div className='LandingPage9Body_wrapper'>
        <div className='LandingPage9Body_Top'>
          <div className='LandingPage9Body_TopLeft'> <img src='https://all-frontend-assets.s3.amazonaws.com/aspireLeadFire/landing-page9/blueCoastLogo.webp'></img> </div>
          <div className='LandingPage9Body_TopRight'>
            <h2><span>BLUECOAST SAVINGS AND ACCESS DISCOUNT HEALTHCARE</span></h2> 
            <h2><span>ARE GETTING BIG RETURNS FOR MEDICAL.</span></h2> 
            <h3><span>ENTER YOUR</span> <strong>INFORMATION BELOW!</strong></h3>
            

          </div>

        </div>

        <div className='LandingPage9Body_Formwrapper'>
        <div className='LandingPage9Body_Formwrapper_con'>
          <div className='LandingPage9FormCon'>
          <LandingPage9Form />
          </div>
          </div>
        </div>

        <div className='LandingPage9BottomLogo'> 
         <span> <img src='https://all-frontend-assets.s3.amazonaws.com/aspireLeadFire/landing-page9/landingpage9_newLogo.webp'></img></span>
        </div>
            
        {/* <img src='https://all-frontend-assets.s3.amazonaws.com/aspireLeadFire/landing-page9/landingpage9_img1.webp' className='landingpage9_img1'></img> */}
      </div>


    </div>
  )
}

export default LandingPage9