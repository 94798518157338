import { LinearProgress, Snackbar, Tooltip } from '@mui/material'
import React, { useEffect, useRef, useState } from 'react'
import { useSelector } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import { resolvefunction } from '../../../../helper/helperFunctions'
import { resetresolvenew } from '../../../../helper/ResolveReducer'
import { TableSkeleton } from '../../../../helper/TableSkeleton'
import ListingTable from '../../../../listing/listing'
import store from '../../../../store'
import { setSnackbar } from '../../../Layout/layoutReducer'
import { clearContractSlagedata, contractSlugEditdata, getContractSlagData } from '../ContractReducer'

const ContractSlag = () => {
    const [openSnacAddEdit, setOpenSnacAddEdit] = useState(false);//------for snackbar-----//


    // ----------------------------------------Global State from Redux---------------------------------------- //
    let contractSlagDataforlist = useSelector(state => (state.contractReducer?.contractSlagData && state.contractReducer?.contractSlagData.length > 0) ? state.contractReducer.contractSlagData : [])
    let loader = useSelector(state => (state.contractReducer?.loading) ? state.contractReducer.loading : false)
    const resolved = useSelector(state => state.ResolveReducer.resolved['/contract-slug-list'] ? state.ResolveReducer.resolved['/contract-slug-list'] : false)
    const navigate = useNavigate();
    const editData = useSelector(state => state.tableSlice?.tempRowData?.contractSlagdata ? state.tableSlice.tempRowData.contractSlagdata : {})
    const editClicked = useSelector(state => state.tableSlice?.customButtonClicked?.contractSlagdata?.edittable9 ? state.tableSlice.customButtonClicked.contractSlagdata.edittable9 : false)
    const successMsg = useSelector(state => state.formSlice.message?.ContractSlagAddEditForm)//for snackbar massge
    const successStatus = useSelector(state => state.formSlice.status?.ContractSlagAddEditForm)

    const formsubmissionstate = useSelector(state => state.formSlice?.formSubmissionState ? state.formSlice.formSubmissionState : null);








    var modifyTableHeaders =
        [
            { val: "database_variable", name: "Contract variable Name" },
            { val: "slug_variable", name: "Contract Slug Name" },
            //    { val: "description", name: "Description" },
            { val: "status", name: "Status", customVal: { 0: 'Inactive', 1: "Active" } },
            // { val: "createdon_datetime", name: "Created on", type: "datetime", format: "DD/MM/YYYY" },
            { val: "updatedon_datetime", name: "Updated on", type: "datetime", format: "DD/MM/YYYY" },


        ];



    let tableDataContractSlag = useRef({
        tableId: "contractSlagdata",
        tableTitle: "Contract Slug List",
        showReload: true,
        showFilter: true,
        // adduser: true,
        api: {
            url: process.env.REACT_APP_API_URL,
            endPoint: "contract-manage/contract-slug-list-count",
            deleteSingleUserEndpoint: "",
            tableCountEndpoint: "contract-manage/contract-slug-list-count",
        },

        reqBody: {
            "condition": {
                "limit": 10,
                "skip": 0
            },
            "sort": {
                "field": "updatedon_datetime",
                "type": "desc"
            },
            "searchcondition": {},
            "project": {},
            "token": "",
            "count": false
        },
        deleteSingleUserReqBody: {
            source: "users",
            secret: "AZ|lepL`",

        },
        deleteMultipleReqBody: {
            source: "users",
            secret: "AZ|lepL`",

        },

        //******************************** By Default Rows per page option will be available**************************//

        rowsPerPageOptions: [10, 20, 30],
        sortFields: ["database_variable", "status", "updatedon_datetime"],
        paginationType: "stackPagination",
        enableRowActionLoader: true,
        customBtnHead: [

            {
                id: "delete",
                label: "Delete",
                type: "api",
                category: "modal",
                icon_type: "delete",
                className: "delete_data",
                modalClassName: "delete_modal",
                modalTitle: "<p>Are you sure that you want to delete this record(s)?</p>",
                modalClassName: "delete_modal",
                modalHeader: "<p> Alert !!</p>",
                btn_label: ['Yes', "No"],
                api_data: {
                    base_url: process.env.REACT_APP_API_URL,
                    endpoint: "contract-manage/contract-slug-delete",
                    body: {
                        "source": "users",
                        "secret": "AZ|lepL`",
                        "token": "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJmb28iOiJiYXIiLCJleHAiOjE2NTIxNjM3MDUsImlhdCI6MTY1MjA3NzMwNX0.3kB4iRr5JbmV81Dl5gKA7fiTaMEEp0xbsWoO-wA0XUo",
                    }
                }
            },
            {
                id: "status",
                label: "Change Status",
                type: "api",
                category: "status",
                icon_type: "toggle_off",
                className: "status_data",
                modalClassName: "delete_modal",
                modalTitle: "<p>Are you sure you want to change the status ?</p>",
                modalClassName: "delete_modal",
                modalHeader: "<p> Alert !!</p>",
                btn_label: ['Active', "Inactive"],
                api_data: {
                    base_url: process.env.REACT_APP_API_URL,
                    endpoint: "contract-manage/contract-slug-status-update",
                    body: {
                        "source": "users",
                        "secret": "AZ|lepL`",
                        "token": "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJmb28iOiJiYXIiLCJleHAiOjE2NTIxNjM3MDUsImlhdCI6MTY1MjA3NzMwNX0.3kB4iRr5JbmV81Dl5gKA7fiTaMEEp0xbsWoO-wA0XUo",
                    }
                }
            },
        ],
        buttons: [



            {
                id: "edittable9",
                type: "other",
                icon_type: "edit_icon",
                label: "Edit",
                name: "edit",
            },

            {
                id: "delete",
                label: "Delete",
                type: "api",
                category: "modal",
                icon_type: "delete",
                className: "delete_data",
                modalClassName: "delete_modal",
                modalTitle: "<p>Are you sure that you want to delete this record(s)?</p>",
                modalClassName: "delete_modal",
                modalHeader: "<p> Alert !!</p>",
                btn_label: ['Yes', "No"],
                api_data: {
                    base_url: process.env.REACT_APP_API_URL,
                    endpoint: "contract-manage/contract-slug-delete",
                    body: {
                        "source": "users",
                        "secret": "AZ|lepL`",
                        "token": "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJmb28iOiJiYXIiLCJleHAiOjE2NTIxNjM3MDUsImlhdCI6MTY1MjA3NzMwNX0.3kB4iRr5JbmV81Dl5gKA7fiTaMEEp0xbsWoO-wA0XUo",
                    }
                }
            },
            {
                id: "status",
                label: "Change Status",
                type: "api",
                category: "status",
                icon_type: "toggle_off",
                className: "status_data",
                modalClassName: "delete_modal",
                modalTitle: "<p>Are you sure you that want to change the status?</p>",
                modalClassName: "delete_modal",
                modalHeader: "<p> Alert !!</p>",
                btn_label: ['Active', "Inactive"],
                api_data: {
                    base_url: process.env.REACT_APP_API_URL,
                    endpoint: "contract-manage/contract-slug-status-update",
                    body: {
                        "source": "users",
                        "secret": "AZ|lepL`",
                        "token": "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJmb28iOiJiYXIiLCJleHAiOjE2NTIxNjM3MDUsImlhdCI6MTY1MjA3NzMwNX0.3kB4iRr5JbmV81Dl5gKA7fiTaMEEp0xbsWoO-wA0XUo",
                    }
                }
            },


        ],
        deleteModal: {
            modalClassName: "modalblock",
        },

        searchData: {
            heading: "Search Contract Slug",
            type: "drawer",
            condition_type: "$or",
            formData: {
                id: 'searchForm',
                formWrapperClass: "formWrapperClass",// Can be undefined default will be formCls
                formInlineCLass: "userForm", // Can be undefined default will be formInline
                formButtonClass: "submitbtnsection",
                submitBtnEndIcon: "search",
                submitBtnName: "Search",
                resetBtnEndIcon: "replay",
                resetBtnName: "Refresh",
                resetForm: true,
                formAdditionalSubmissionData: {},
                fields: [
                   
                    {
                        id: 0,
                        label: "Search By Contract Variable Name",
                        name: "database_variable",
                        type: "autoComplete",
                        className: 'list_search_item countDiv__column--col6 ',
                        base_url: process.env.REACT_APP_API_URL + "contract-manage/fetch-contract-database-variable",
                        reqBody: {
                            // "formId": 'teamsearch',
                            "condition": {
                                "limit": 10,
                                "skip": 0
                            },
                            "sort": {
                                "type": "asc",
                                "field": "name"
                            },
                            "searchcondition": {

                            }
                        },
                        sx: { m: 1, minWidth: 300 },
                        // payloadFormat: { key: "_id", param: "$regex", options: "i" },
                        payloadFormat: { key: "database_variable" },

                    },
                    {
                        id: 12,
                        label: "Search By Contract Slug Name",
                        name: "slug_variable",
                        type: "autoComplete",
                        className: 'list_search_item countDiv__column--col6 ',
                        base_url: process.env.REACT_APP_API_URL + "contract-manage/fetch-contract-slug",
                        reqBody: {
                            // "formId": 'teamsearch',
                            "condition": {
                                "limit": 10,
                                "skip": 0
                            },
                            "sort": {
                                "type": "asc",
                                "field": "name"
                            },
                            "searchcondition": {

                            }
                        },
                        sx: { m: 1, minWidth: 300 },
                        // payloadFormat: { key: "_id", param: "$regex", options: "i" },
                        payloadFormat: { key: "slug_variable" },

                    },

                    {
                        id: 3,
                        label: "Search by Status",
                        name: "status_show",
                        type: 'select',
                        multiple: false,
                        values: [
                            { val: 1, name: "Active" },
                            { val: 0, name: "Inactive" }
                        ],
                        sx: { m: 1, minWidth: 120 },
                        className: 'list_search_item countDiv__column--col6 ',
                        payloadFormat: { key: "status" },
                    },
                    {
                        id: 8,
                        //   heading: "Search By Creation Time",
                        label: "Search by Created on Start Date",
                        name: "start_date",
                        className: 'datePicker',
                        type: 'datePicker',
                        sx: { m: 1, width: 300 },
                        className: 'list_search_item countDiv__column--col6 ',
                        payloadFormat: { key: "createdon_datetime", param: "$gte" }
                    },
                    {
                        id: 9,
                        label: "Search by Created on End Date",
                        name: "end_date",
                        className: 'list_search_item countDiv__column--col6 ',
                        type: 'datePicker',
                        fullDay: true,
                        sx: { m: 1, width: 300 },
                        payloadFormat: { key: "createdon_datetime", param: "$lte" }
                    },
                    {
                        id: 10,
                        //   heading: "Search By Creation Time",
                        label: "Search by Updated on Start Date",
                        name: "start_date_by_update",
                        className: 'datePicker',
                        type: 'datePicker',
                        sx: { m: 1, width: 300 },
                        className: 'list_search_item countDiv__column--col6 ',
                        payloadFormat: { key: "updatedon_datetime", param: "$gte" }
                    },
                    {
                        id: 11,
                        label: "Search by Updated on End Date",
                        name: "end_date_by_update",
                        className: 'list_search_item countDiv__column--col6 ',
                        type: 'datePicker',
                        fullDay: true,
                        sx: { m: 1, width: 300 },
                        payloadFormat: { key: "updatedon_datetime", param: "$lte" }
                    },
                ]
            }
        },
    });


    let reqbody = {
        "condition": {
            "limit": 10,
            "skip": 0
        },
        "sort": {
            "field": "updatedon_datetime",
            "type": "desc"
        },
        "searchcondition": {},
        "project": {},
        "token": ""
    }


    useEffect(() => {
        // console.log("RoleList", resolve);
        if (!resolved) store.dispatch(getContractSlagData(reqbody));

        return () => {

            store.dispatch(clearContractSlagedata())
            store.dispatch(resetresolvenew('/contract-slug-list'))
        }
    }, [])

    useEffect(() => {
        if (editClicked && Object.keys(editData).length > 0) {
            resolvefunction(navigate, `/edit-contract-slug/${editData._id}`, contractSlugEditdata(editData._id))
        }

    }, [editClicked])

    useEffect(() => {
        if (formsubmissionstate.ContractSlagAddEditForm === 2) {
            store.dispatch(
                setSnackbar({ open: true, message: successMsg, status: successStatus })
            )

        }

        console.log("true==========>");
    }, [formsubmissionstate])







    return (

        <>

            <div className='rolelist_mainwrp contract_slug_wrp'>
            {loader === true && <LinearProgress/>}
                <div className='listing_heading'>

                    <h1>Contract Slug Management</h1>
                    <Tooltip title='Add Contract Slug ' >
                        <button className='addrole_btn' onClick={() => navigate('/add-contract-slug')}>+</button>
                    </Tooltip>
                </div>

                <div className='rolelist_mainwrp_Table'>

                    {!loader ? (
                        <ListingTable

                            tableData={tableDataContractSlag.current}
                            dataset={contractSlagDataforlist}
                            modifyHeaders={modifyTableHeaders}
                        />
                    ) : (<TableSkeleton count={5} />)}

                </div>
            </div>






        </>
    )
}

export default ContractSlag