
import React, { useEffect, useRef, useState } from 'react'
import { useSelector } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import ListingTable from '../../../listing/listing'
import {tempdataset} from "../Dashboard/AccessREP/TempData"
import { Paper, TableContainer } from "@mui/material";



const AppointmentBooked= ({ListData}) => {





    var modifyTableHeaders = [
        { val: "fullname", name: "Name" },
        { val: "attendee", name: "Attendee" },
        { val: "organizer_name", name: "Organizer Name" },
        { val: "organizer_email", name: "Organizer Email" },
        { val: "timespan", name: "Timespan" },
    
      ];

    let tableAccessedREP = useRef({
        tableId: "dashboardTable",
        tableTitle: "Appointment Booked List View",
        showReload: true,
        showFilter: true,
        // adduser: true,
        api: {
            url: process.env.REACT_APP_API_URL,
            endPoint: "",
            deleteSingleUserEndpoint: "",
            tableCountEndpoint: "",
        },

        reqBody: {
            "condition": {
                "limit": 5,
                "skip": 0
            },
            "sort": {
                "field": "_id",
                "type": "desc"
            },
            "searchcondition": {},
            "project": {},
            "token": "",
            "count": false
        },
        deleteSingleUserReqBody: {
            source: "users",
            secret: "AZ|lepL`",

        },
        deleteMultipleReqBody: {
            source: "users",
            secret: "AZ|lepL`",

        },

        //******************************** By Default Rows per page option will be available**************************//

        rowsPerPageOptions: [5, 10, 20, 30],
        sortFields: ["name", "status", "createdon_datetime", "updatedon_datetime"],
        paginationType: "stackPagination",
        enableRowActionLoader: true,
        customBtnHead: [

            {
                id: "delete",
                label: "Delete",
                type: "api",
                category: "modal",
                icon_type: "delete",
                className: "delete_data",
                modalClassName: "delete_modal",
                modalTitle: "<p>Are you sure that you want to delete this record(s)?</p>",
                btn_label: ['Yes', "No"],
                api_data: {
                    base_url: process.env.REACT_APP_API_URL,
                    endpoint: "contract-manage/contract-delete",
                    body: {
                        "source": "users",
                        "secret": "AZ|lepL`",
                        "token": "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJmb28iOiJiYXIiLCJleHAiOjE2NTIxNjM3MDUsImlhdCI6MTY1MjA3NzMwNX0.3kB4iRr5JbmV81Dl5gKA7fiTaMEEp0xbsWoO-wA0XUo",
                    }
                }
            },
            {
                id: "status",
                label: "Change Status",
                type: "api",
                category: "status",
                icon_type: "toggle_off",
                className: "status_data",
                modalClassName: "delete_modal",
                modalHeader: "<p> Alert !!</p>",
                modalTitle: "<p>Are you sure you want to change the status ?</p>",
                btn_label: ['Active', "Inactive"],
                api_data: {
                    base_url: process.env.REACT_APP_API_URL,
                    endpoint: "contract-manage/contract-status-update",
                    body: {
                        "source": "users",
                        "secret": "AZ|lepL`",
                        "token": "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJmb28iOiJiYXIiLCJleHAiOjE2NTIxNjM3MDUsImlhdCI6MTY1MjA3NzMwNX0.3kB4iRr5JbmV81Dl5gKA7fiTaMEEp0xbsWoO-wA0XUo",
                    }
                }
            },

        ],
        buttons: [
            
            {
                id: "summarize_icon",
                label: "Notes",
                type: "other",
                name: "summarize_icon",
                title: "Notes",
            },
            {
                id: "folder_icon",
                label: "Folder View",
                type: "other",
                name: "folder_icon",
                title: "Folder View",
            },
            {
                id: "assessment_icon",
                label: "Training Report",
                type: "other",
                name: "assessment_icon",
                title: "Training Report",
            },
            {
                id: "streetview_icon",
                label: "Leads",
                type: "other",
                name: "streetview_icon",
                title: "Leads",
            }



        ],
        deleteModal: {
            modalClassName: "modalblock",
        },

       
    });



  return (
    <>

 
<TableContainer component={Paper}>
     
            <ListingTable

              tableData={tableAccessedREP.current}
              dataset={ListData}
              modifyHeaders={modifyTableHeaders}
            />

</TableContainer>

    </>
  )
}

export default AppointmentBooked


