import { LinearProgress } from "@mui/material";
import { Box } from "@mui/system";
import { useEffect, useState } from "react";
import { TableSkeleton } from "../../../helper/TableSkeleton";
import ListingTable from "../../../listing/listing";

export const RedundantSlotList = ({ redundantSlotListFetched }) => {
    const [redundantSlotList, setRedundantSlotList] = useState([]);
    const [listFetchSuccess, setListFetchSuccess] = useState(false);
    const [loaderFlag, setloaderFlag] = useState(true);

    const redundantSlot = {
        tableId: "redundantSlotLists",
        tableTitle: "Redundant Appointment List",
        showReload: true,
        showFilter: true,
        api: {
            url: process.env.REACT_APP_API_URL,
            endPoint: "calendar-api/redundant-slot-list",
            // deleteSingleUserEndpoint: "api/delete-user",
            tableCountEndpoint: "calendar-api/redundant-slot-list",
        },

        reqBody: {
            "condition": {
                "limit": 10,
                "skip": 0
            },
            "sort": {
                "field": "_id",
                "type": "desc"
            },
            "searchcondition": {
            },
            "count": false
        },
        deleteSingleUserReqBody: {
            source: "users",
            secret: "AZ|lepL`",
            // token: usersToken,
        },
        deleteMultipleReqBody: {
            source: "users",
            secret: "AZ|lepL`",
            // token: usersToken,
        },

        //******************************** By Default Rows per page option will be available**************************//

        rowsPerPageOptions: [5, 10, 20, 30],
        sortFields: ["lastname", "email", "type"],
        paginationType: "stackPagination",
        enableRowActionLoader: true,
        customBtnHead: [
            // {
            //     id: "deleteMultiple",
            //     type: "delete_icon",
            //     title: "Delete",
            // },
            // {
            //     id: "editMultiple",
            //     type: "toggle_on_icon",
            //     title: "Status Change",
            // },
            // {
            //     id: "adduser",
            //     type: "add_icon",
            //     title: "ADD USER",
            //     className: "add_user_icon",
            //     default: true,
            // },
        ],
        buttons: [
        ],
        deleteModal: {
            modalClassName: "Delete Modal Deletemodal",
        },
        searchData: {
            heading: "Search Redundant Appointment List",
            type: "drawer",
            condition_type: "$or",
            formData: {
                id: 'searchForm',
                formWrapperClass: "formWrapperClass",// Can be undefined default will be formCls
                formInlineCLass: "userForm", // Can be undefined default will be formInline
                formButtonClass: "submitbtnsection",
                submitBtnName: "Search",
                submitBtnEndIcon: "search",
                resetBtnName: "Refresh",
                resetBtnEndIcon: "Refresh",
                resetForm: true,
                formAdditionalSubmissionData: {},
                fields: [
                    {
                        id: 0,
                        label: "Search by Appointment Start Date",
                        name: "slot_start_time_unix",
                        className: 'datePicker formGroup createdon_datetime countDiv__column--col4',
                        type: 'datePicker',
                        sx: { m: 1, width: 300 },
                        format: 'dd/MM/yyyy', //////////////////////////// MM should be capital //////////////////
                        payloadFormat: { key: "slot_start_time_unix", param: "$gte" }
                    },
                    {
                        id: 1,
                        label: "Search by Appointment End Date",
                        name: "slot_end_time_unix",
                        className: 'list_search_item countDiv__column--col4 ',
                        type: 'datePicker',
                        fullDay: true,
                        sx: { m: 1, width: 300 },
                        format: 'dd/MM/yyyy',
                        payloadFormat: { key: "slot_start_time_unix", param: "$lte" }
                    },
                    {
                        id: 2,
                        heading: "Search By Joining Date",
                        startdatelabel: "Start Date",
                        enddatelabel: "End Date",
                        className: "formGroup createdon_datetime countDiv__column--col4",
                        submit: "Search",
                        field: "slot_start_time_unix",
                    },
                    {
                        id: 3,
                        label: "Search By Summary",
                        name: "summary",
                        type: "text",
                        className: 'list_search_item countDiv__column--col6 ',
                        sx: { m: 1, minWidth: 300 },
                        payloadFormat: { key: "summary", param: "$regex", options: "i" },

                    },
                    {
                        id: 4,
                        label: "Search By Organizer Email",
                        name: "organizer_email",
                        type: "text",
                        className: 'list_search_item countDiv__column--col6 ',
                        sx: { m: 1, minWidth: 300 },
                        payloadFormat: { key: "organizer_email", param: "$regex", options: "i" },

                    },

                ]
            }
        },
        // searchBarData: {
        //     heading: "Search Conflicting Fullday Events",
        //     api: {
        //         url: process.env.REACT_APP_API_URL,
        //         endPoint: "calendar-api/redundant-slot-list",
        //         tableCountEndpoint: "calendar-api/redundant-slot-list",
        //         reqBody: {
        //             // count:true,
        //             // "source": "users",
        //             token: "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJfaWQiOiI2MzYzNTZkNDFjZTE3MzAwMDhiOGFhYTUiLCJzZWNyZXQiOiJ6R3RpMnY1enYiLCJpYXQiOjE2Njc5Njg3MzV9.wzFi1CoHbv9QK5cUHbVgSL6LW8m6fR_BaILcT7OcHBQ",
        //             condition: {
        //                 limit: 5,
        //                 skip: 0,
        //             },
        //             sort: {
        //                 type: "desc",
        //                 field: "_id",
        //             },
        //         },
        //     },

        //     searchSettings: {
        //         datesearch: [
        //             {
        //                 id: 0,
        //                 heading: "Search By Joining Date",
        //                 startdatelabel: "Start Date",
        //                 enddatelabel: "End Date",
        //                 className: "formGroup createdon_datetime countDiv__column--col4",
        //                 submit: "Search",
        //                 field: "createdon_datetime",
        //             },
        //         ],
        //         selectsearch: [
        //             // {
        //             //     id: 1,
        //             //     label: "Search By Status",
        //             //     field: "status",
        //             //     type: "select",
        //             //     values: [0, 1],
        //             //     // value: "",
        //             // },


        //         ],
        //         textsearch: [
        //             // {
        //             //     id: 1,
        //             //     // heading: "Search By First Name",
        //             //     label: "Search By Full Name ",
        //             //     field: "name",
        //             //     className: "formGroup firstname countDiv__column--col4",
        //             //     value: "",
        //             // },


        //             // {
        //             //     id: 3,
        //             //     // heading: "Search By Email",
        //             //     label: "Search By Email ",
        //             //     field: "email",
        //             //     className: "formGroup email  inputcol-2",
        //             //     value: "",
        //             // },
        //         ],

        //     },
        // },
    };




    var modifyTableHeaders =
        [
            { val: "summary", name: "Summary" },
            // { val: "connected_gmail", name: "connected_gmail" },
            // { val: "user_email", name: "user_email" },
            { val: "organizer_email", name: "Organizer Email" },
            { val: "timespan", name: "Timespan" },
            { val: "slot_start_time_unix", name: "Appointment Start Time Unix" },
            { val: "slot_end_time_unix", name: "Appointment End Time Unix" },

        ];

    const getList = async () => {
        const requestOptions = {
            method: "POST",
            headers: { "Content-type": "application/json" },
            body: JSON.stringify(redundantSlot.reqBody),
        };
        const response = await fetch(
            process.env.REACT_APP_API_URL + "calendar-api/redundant-slot-list",
            requestOptions
        );
        const respdata = await response.json();
        console.log("++++cv+++++++", respdata);
        if (respdata && respdata.status === "success") {
            setRedundantSlotList([...respdata.results.res]);
        }
        setloaderFlag(false);
        redundantSlotListFetched(true);
    }

    useEffect(() => {
        if (redundantSlotList && redundantSlotList.length === 0 && !listFetchSuccess) {
            setListFetchSuccess(true);
            getList();
        }

    }, [redundantSlotList]);


    return (
        <>
            {loaderFlag == true ? (
                <Box className="loaderProgress" sx={{ width: "100%", mt: "10px" }}>
                    <LinearProgress />
                </Box>
            ) : (
                ""
            )}
            <div className="reactTableWrapper miscList">
                <div className="rolelist_mainwrp_Table">
                    {!loaderFlag && redundantSlotList !== undefined ? (
                        <ListingTable
                            tableData={redundantSlot}
                            dataset={redundantSlotList}
                            modifyHeaders={modifyTableHeaders}
                        />
                    ) : (<TableSkeleton count={5} />)}
                </div>
            </div>
        </>
    )
}