import { Chip, IconButton, LinearProgress, Tooltip } from "@mui/material";
import React, { useEffect, useState } from "react";
import SearchIcon from "@mui/icons-material/Search";
import SegmentIcon from "@mui/icons-material/Segment";
import { Box } from "@mui/system";
import { useDispatch, useSelector } from "react-redux";
import { format } from "date-fns";
import QuestionMarkIcon from "@mui/icons-material/QuestionMark";
import LocalOfferIcon from '@mui/icons-material/LocalOffer';
import { FetchTagDetails } from "../userList/userListReducer";
import ConfigureTagsModal from "../userList/configureTagsModal";
import FDSearch from "./FDSearch";
import { UserActivity } from "./folderViewReducer";
import { useLocation } from "react-router-dom";
const TagLifeCycle = ({ LifeCycleDataTag,folderdata}) => {
  const [searchDraw, setSearchDraw] = React.useState(false); // snackbar const  
  const [queryParamId, setQueryParamId] = useState();  //*****FOR Seeting QueryP****//
  const [openTagModal,setOpenTagModal]=useState(false)
  const [statusAction, setStatusAction] = useState(null);  //--- after Confirmtion modal cofirm button clicked ----//

  const dispatch = useDispatch()
  const loading = useSelector((state) =>
    state?.userFolderViewReducer?.loading
      ? state.userFolderViewReducer.loading
      : false
  );
  const TagManageModalData = useSelector(state => (state.userManagment?.FetchTagDetailsData) ? state.userManagment?.FetchTagDetailsData : []);
  const submissionState = useSelector(state => (state?.formSlice?.formSubmissionState?.Folder_View_Search) ? state.formSlice.formSubmissionState.Folder_View_Search : false)
  const searchData = useSelector(state => (state?.formSlice?.formData?.Folder_View_Search) ? state.formSlice.formData.Folder_View_Search : {})
  
  useEffect(()=>{
    setStatusAction(null);
  },[])

  // ---- geeting and setting QueryParam -----
const queryParam = useLocation();
  
 
const paramId = queryParam.search.split("=");

console.log("data paramId",paramId);

useEffect(() => {
  if (paramId[1]) {
    setQueryParamId(paramId[1])
  }
}, [JSON.stringify(paramId)])

// -------------------------------



  useEffect(() => {
    if (submissionState === 4) {
      dispatch(UserActivity())
    }
  }, [submissionState])
  // ----------------

const handelAddTag = () =>{
  let roles = Object.keys(folderdata?.roles).map((key) => key)
  const data = {
    "_id": folderdata._id,
    "roles": { $in: roles },
    "project": {
        "subject": 1
    },
}
dispatch(FetchTagDetails(data))
}
useEffect(() => {
  if (TagManageModalData.length > 0) {
    setOpenTagModal(true)

  }
}, [TagManageModalData])


const searchFields=[
  {
      id: 8,
      label: "Search by Start Date",
      name: "start_date",
      className: 'inputBlock inputBlock3line',
      type: 'datePicker',
      sx: { m: 1, width: 300 },
      dateFormat: 'dd/MM/yyyy',
     

    },
    {
      id: 9,
      label: "Search by End Date",
      name: "end_date",
      className: 'datePicker',
      type: 'datePicker',
      fullDay: true,
      sx: { m: 1, width: 300 },
      dateFormat: 'dd/MM/yyyy',
      className: 'inputBlock inputBlock3line',
      errorMessage: {
        dateCheck: "End Date can not be less than Start Date",
      },
    },
]

useEffect(() => {
  if (submissionState === 4) {
    setSearchDraw(false)
    let activity_time={
      "$gte":searchData?.start_date?searchData.start_date:undefined,
      "$lte":searchData?.end_date?searchData.end_date:undefined
    }

    activity_time.$lte !== " " || activity_time.$lte !== undefined &&  delete activity_time["$lte"];

    activity_time.$gte !== " " || activity_time.$gte !== undefined &&  delete activity_time["$gte"];

    

    const payload = {
      "condition": {
        "limit": 5,
        "skip": 0
      },
      "sort": {
        "field": "activity_time",
        "type": "desc"
      },
      "searchcondition": {
        "user_id": queryParamId ? queryParamId : undefined,
        "activity_time": activity_time
    

      },
      "project": {},
      "token": "",
      "email": ""
    }
    dispatch(UserActivity(payload ))
  }
}, [submissionState])


  console.log("TagLifeCycle", LifeCycleDataTag);
  return (
    <>
      <div className="blocks">
        <h3>
          <SegmentIcon /> Tag Life Cycle{" "}
          <div className="srchfld_wrp" style={{ marginLeft: "auto" }}>
            <Tooltip title="Search Email">
              <IconButton>
                <SearchIcon onClick={() => setSearchDraw(true)} />
              </IconButton>
            </Tooltip>
            <Tooltip title="Add Tag">
                <IconButton onClick={handelAddTag} >
                  <LocalOfferIcon  />
                </IconButton>
              </Tooltip>
          </div>
        </h3>
        {loading ? (
          <Box sx={{ width: "100%", marginBottom: "10px" }}>
            <LinearProgress />
          </Box>
        ) : null}

        {LifeCycleDataTag.length > 0 ? (
          <div>
            { 
              LifeCycleDataTag?.map((x) => (
                <ul className="Fv-tags">
                  <li className="tagsBlck">
                    <Chip label={x.tag_name} variant="outlined" />
                    <QuestionMarkIcon className="qIcon" />
                    {/* <p className="chipDesBuble">
                      {x.tag_description} <br />
                      Added by : {x.added_by_name} On{" "}
                      {format(x.createdon_datetime, "dd-MM-yyyy HH:mm")}
                    </p> */}
                  </li>
                </ul>
              ))
            }
          </div>
        ) : (
          <p> No tag assigned!</p>
        )}
      </div>


{openTagModal ?  <ConfigureTagsModal
                toogle={openTagModal}
                tooglefunc={setOpenTagModal}
                setModalResponse={setStatusAction}
                tags={TagManageModalData}
                UserId={folderdata._id}
                Userinfo={folderdata}
            />: ""}
   



{searchDraw?<FDSearch 
     open={searchDraw}
     close={setSearchDraw}
     formFields={searchFields}
     searchFields={searchFields}
        type="tag_life_cycle "/>:""}


    </>
  );
};

export default TagLifeCycle;
