import { createAsyncThunk, createSlice } from "@reduxjs/toolkit"







export const getTagListdata = createAsyncThunk("tagListData", async (reqBody) => {
    if (!reqBody) {

        reqBody = {
            "condition": {
                "limit": 10,
                "skip": 0
            },
            "sort": {
                "field": "updatedon_datetime",
                "type": "desc"
            },
            "searchcondition": {

            },
            "project": {},
            "token": "",
            "count": false
        }
    }
    let req_body = {};
    req_body = { ...reqBody };
    const requestOptions = {
        method: 'post',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify(req_body)
    };
    const response = await fetch(process.env.REACT_APP_API_URL + "tag-management/tag-list-count", requestOptions);
    const respdata = await response.json();
    return respdata;
})

export const tagAddEditData = createAsyncThunk("tagAddEditData",
    async (id) => {
        let reqBody = {
            "condition": {},
            "sort": {
            },
            "searchcondition": { "_id": id },
            "project": {},
            "token": ""
        }
        let reqbody = {}
        reqbody = { ...reqBody }
        const requestOptions = {
            method: 'post',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify(reqbody)
        };
        const response = await fetch(process.env.REACT_APP_API_URL + "tag-management/tag-list-count", requestOptions);
        const respdata = await response.json();
        return respdata;
    }
)




const tagEditAddRedicer = createSlice({
    name: "tagEditAddRedicer",
    initialState: {
        loading: false,
        editloading: false,
        editTagData: [],
        tagListData: []
    },
    reducers: {
        clearTagEditAddRedicer: (state, action) => {
            state.editTagData = []
        },
        clearTagdata: (state, action) => {
            state.tagListData = []
        }

    },
    extraReducers: {
        [tagAddEditData.rejected]: (state, action) => {
            state.endpointsuccess[action.type] = false
        },
        [tagAddEditData.pending]: (state, action) => {
            state.editloading = false
        },
        [tagAddEditData.fulfilled]: (state, action) => {
            if (action.payload.status === 'success') {
                state.editTagData = action.payload.results.res;
                state.editloading = false;
            }
        },
        [getTagListdata.rejected]: (state, action) => {
            state.endpointsuccess[action.type] = false
            state.successAction = false;
        },
        [getTagListdata.pending]: (state, action) => {
            state.loading = true
        },
        [getTagListdata.fulfilled]: (state, action) => {
            if (action.payload.status === 'success') {
                state.tagListData = action.payload.results.res
                state.loading = false
            }
        }
    }
})
export default tagEditAddRedicer.reducer
export const { clearTagEditAddRedicer, clearTagdata } = tagEditAddRedicer.actions