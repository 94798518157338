import { LinearProgress } from "@mui/material";
import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { Form } from "../../../../form/Form";
import { resolvefunction } from "../../../../helper/helperFunctions";
import store from "../../../../store";
import {
  clearContractSlugEditdata,
  contractSlugEditdata,
  fetchCollectName,
  getContractSlagData,
} from "../ContractReducer";

const ContractSlagAddEdit = () => {
  const pathname = useLocation();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { id } = useParams();
  console.log("_id++++++", id);

  const goBackClicked = useSelector((state) =>
    state.formSlice?.customButtonClicked?.ContractSlagAddEditForm?.goback001
      ? state.formSlice.customButtonClicked.ContractSlagAddEditForm.goback001
      : false
  );
  const editdata = useSelector((state) =>
    state.contractReducer?.editcontractSlagData &&
    state.contractReducer?.editcontractSlagData.length > 0
      ? state.contractReducer.editcontractSlagData[0]
      : null
  );

 

  const resolved = useSelector((state) =>
    state.ResolveReducer?.resolved[`/edit-contract-slug/${id}`]
      ? state.ResolveReducer.resolved[`/edit-contract-slug/${id}`]
      : false
  );

  const loading = useSelector((state) =>
    state.contractReducer?.editloading
      ? state.contractReducer.editloading
      : false
  );

  let reqbodyforContractSlag = {
    condition: {
      limit: 10,
      skip: 0,
    },
    sort: {
      field: "updatedon_datetime",
      type: "desc",
    },
    searchcondition: {},
    project: {},
    token: "",
  };

  const dataform = {
    id: "ContractSlagAddEditForm",
    api_url: process.env.REACT_APP_API_URL,
    endPoint: "contract-manage/create-update-contract-slug",

    urlPathOnSuccessfulFormSubmission: "/contract-slug-list",
    submitBtnName: editdata !== null ? "Update" : "Submit",
    cancelBtnName: "Cancel",
    className: "inputBlock inputBlock3line",
    formButtonClass: "Formsubmit_button",
    formWrapperClass: "backend_addform addAdmin",
    formInlineClass: "backendTableInline_addform testwrpr trainingcata",

    formAdditionalSubmissionData: {
      _id: id !== undefined && id !== null ? id : undefined,
    },

    fields: [
      {
        id: 0,
        heading: "Slug Variable",
        label: "Slug Variable",
        name: "slug_variable",
        // className: "inputBlock inputBlock1line",
        className: "inputBlock inputBlock2line",
        type: "text",
        rules: { required: true },
        errorMessage: {
          required: "This Field is Required",
        },
        //   minLength: "Minimum length should be 15",
        //   custom: "Value is Invalid"
        defaultValue:
          editdata &&
          Object.keys(editdata).length > 0 &&
          editdata.slug_variable !== undefined
            ? editdata.slug_variable
            : undefined,
      },

      {
        id: 1,
        heading: "Database Variable",
        label: "Database Variable",
        name: "database_variable",
        // className: "inputBlock inputBlock1line",
        className: "inputBlock inputBlock2line",
        type: "text",
        rules: { required: true },
        errorMessage: {
          required: "This Field is Required",
        },
        defaultValue:
          editdata &&
          Object.keys(editdata).length > 0 &&
          editdata.database_variable !== undefined
            ? editdata.database_variable
            : undefined,

        //   minLength: "Minimum length should be 15",
        //   custom: "Value is Invalid"
      },
     
      {
        id: 3,
        heading: "Description",
        // label: "Description",
        placeholder: "Please type here...",
        name: "description",
        className: "inputBlock inputBlock1line",
        type: "textarea",
        // rules: { required: true },
        // errorMessage: {
        //   required: "This Field is Required"
        // },
        defaultValue:
          editdata &&
          Object.keys(editdata).length > 0 &&
          editdata.description !== undefined
            ? editdata.description
            : undefined,

        //   minLength: "Minimum length should be 15",
        //   custom: "Value is Invalid"
      },
      {
        id: 2,
        heading: "Status",
        name: "status",
        className: "inputBlock1line statusinput",
        type: "singleCheckbox",
        values: { key: 0, val: "Active" },
        // rules: { required: true },
        // errorMessage: {
        //   required: "This Field is Required"
        // },
        defaultValue:
          editdata &&
          Object.keys(editdata).length > 0 &&
          editdata.status !== undefined
            ? editdata.status
            : undefined,

        //   minLength: "Minimum length should be 15",
        //   custom: "Value is Invalid"
      },
    ],
    customButtons: [
      {
        id: "goback001",
        label: "Go Back",
        className: "default",
        type: "button",
      },
    ],
  };

  useEffect(() => {
    if (goBackClicked === true) {
      resolvefunction(
        navigate,
        "/contract-slug-list",
        getContractSlagData(reqbodyforContractSlag)
      );
    }
  }, [goBackClicked]);

  useEffect(() => {
    

    if (!resolved) {
      if (
        id !== undefined &&
        pathname !== null &&
        pathname.pathname !== undefined &&
        pathname.pathname.includes("edit")
      ) {
        dispatch(contractSlugEditdata(id));
      }
    }

    return () => {
      dispatch(clearContractSlugEditdata());
    };
  }, []);

  return (
    <>
      <h1 className="page_heading">
        Contract Slug {id !== undefined ? "Edit" : "Add"}{" "}
      </h1>

      {/* <div className='dataform1 addedit_wrp'>
        <div className='dataform1_wrapper'>
          <div className='adminformbody'>
            <Form formData={dataform} />
          </div>
        </div>
      </div> */}

      <div className="dataform2_wrapper">
        {loading == true ? <LinearProgress /> : false}
        <div className="adminformbody">
          {/* <Form formData={dataform} />   */}
          {id !== undefined &&
          editdata &&
          (editdata.length > 0 || Object.keys(editdata).length > 0) ? (
            <>
              {" "}
              <Form formData={dataform} />{" "}
            </>
          ) : (
            !id && <Form formData={dataform} />
          )}
        </div>
      </div>
    </>
  );
};

export default ContractSlagAddEdit;
