import React, { useState } from "react";
import {
  Link,
  NavLink,
  useLocation,
  useNavigate,
  useParams,
} from "react-router-dom";
import MenuIcon from "@mui/icons-material/Menu";
import PersonIcon from "@mui/icons-material/Person";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import { Cookies, useCookies } from "react-cookie";
import {
  Box,
  Button,
  LinearProgress,
  MenuItem,
  Snackbar,
  Tooltip,
} from "@mui/material";
import Menu from "@mui/material/Menu";
import Fade from "@mui/material/Fade";
import { useDispatch, useSelector } from "react-redux";
import { resolvefunction } from "../../helper/helperFunctions";
import { getroledata } from "../Backend/Role/RoleReducer";
import { logOut, setloginRoute, setUserSlugBlank, userLogout } from "../login/loginReducer";
import { Lessonlist, Traininglist } from "../Backend/Training/TrainingReducer";
import { getLandingListData } from "../Backend/LandingPage/LandingPageReducer";
import { trainingCenterCategoryFetching } from "../Backend/Training/TrainingCenter/TrainingCenterCategory/TrainingCenterCategoryReducer";
import {
  getContractListData,
  getContractSlagData,
} from "../Backend/ContractManagement/ContractReducer";
import { getprogramdata } from "../Backend/Program/ProgramReducer";
import { getleaddata } from "../Backend/LeadManagement/LeadReducer";
import { fetchQuizList, fetchTrainingMaterial } from "../Backend/Training/TrainingMaterial/TrainingMaterialReducer";
import { getusersList } from "../Backend/userManagement/userList/userListReducer";
import { getCampaignListData } from "../CampaignManagement/CampaignReducer";
import fetchUserDataReducer from "../Backend/userManagement/fetchUserDataReducer";
import AdminMenu from "./AdminMenu";
import BddMenu from "./BddMenu";
import HmMenu from "./HmMenu";
import AerMenu from "./AerMenu";
import RepMenu from "./RepMenu";
import SuperAdminMenu from "./SuperAdminMenu";
import DeveloperMenu from "./DeveloperMenu";

function BackendHeader() {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const cookies = new Cookies();
  const getcookies = cookies.getAll();
  // console.warn("<<<< cookie details >>>>", cookies.cookies);

  const [selectMenu, setselectMenu] = React.useState(null);
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [anchorE2, setAnchorE2] = React.useState(null);
  const [logoutMsg, setLogoutMsg] = React.useState(false);

  const open = Boolean(anchorEl);
  const [isActive, setIsActive] = useState(false);
  const handleClick = (val, event) => {
    setIsActive((current) => !current);
    setselectMenu(val);
  };
  const [anchorEl4, setAnchorEl4] = React.useState(null);
  const [anchorEl5, setAnchorEl5] = React.useState(null);
  // const [anchorEl6, setAnchorEl6] = React.useState(null);

  const handleClick4 = (event) => {
    setAnchorEl4(event.currentTarget);
  };
  const open4 = Boolean(anchorEl4);
  const handleClose4 = () => {
    setAnchorEl4(null);
  };

  const handleClick5 = (event) => {
    setAnchorEl5(event.currentTarget);
  };
  const open5 = Boolean(anchorEl5);
  const handleClose5 = () => {
    setAnchorEl5(null);
  };
  // const handleClick6 = (event) => {
  //   setAnchorEl6(event.currentTarget);
  // };
  // const open6 = Boolean(anchorEl5);
  // const handleClose6 = () => {
  //   setAnchorEl6(null);
  // };

  const [isHidedropdown, setHidedropdown] = useState(false);
  const hidedropdown = (event) => {
    setHidedropdown((current) => !current);
    setIsActive(false);
  };

  const handleToggle = (temp) => {
    if (temp == "HeaderMenuToggle") setIsActive(!isActive);
  };
  const [userInfo, setuserInfoCookie] = useCookies(["userinfo"]);

  let logoutSucess = useSelector((state) =>
    state.loginSlice?.message !== null &&
      state.loginSlice?.message === "Logout successful"
      ? true
      : false
  );

  const clickedLogout = () => {
    console.warn("<<<<< inside clicked logut",userInfo);
    // return
    setLogoutMsg(true);


    let data = {
      _id: userInfo?.userinfo?._id,
    };

    dispatch(userLogout(data));


    // setLogoutClick(true);
    setAnchorEl(null);

    setTimeout(() => {
    dispatch(logOut());
    cookies.remove("userinfo", { path: "/" });
    cookies.remove("lastLoginTime", { path: "/" });
    cookies.remove("token", { path: "/" });
    setIsActive(false);
    dispatch(setUserSlugBlank());

      navigate("/login");

    }, 2000);



    // dispatch(logOut());
    // dispatch(setloginRoute(false));

  };

  const [anchorEl3, setAnchorEl3] = React.useState(null);

  const handleClose3 = () => {
    setAnchorEl3(null);
  };

  const handleClose = () => {
    // console.log('before close ..', open, open1, anchorEl, selectMenu)
    setAnchorEl(null);
    setAnchorE2(null);
  };

  const open3 = Boolean(anchorEl3);
  const handleClick3 = (event) => {
    setAnchorEl3(event.currentTarget);
  };

  let resolveloader = useSelector((state) =>
    state.ResolveReducer?.loader !== null &&
      state.ResolveReducer?.loader !== undefined
      ? state.ResolveReducer.loader
      : false
  );

  let reqbodyforRole = {
    condition: {
      limit: 5,
      skip: 0,
    },
    sort: {
      field: "_id",
      type: "desc",
    },
    searchcondition: {},
    project: {},
    token: "",
  };
  let reqbodyforprogram = {
    condition: {
      limit: 5,
      skip: 0,
    },
    sort: {
      field: "_id",
      type: "desc",
    },
    searchcondition: {},
    project: {},
    token: "",
  };

  const location = useLocation();
  const { pathname } = location;
  const { id } = useParams();
  const splitLocation = pathname.split("/");
  // console.log("splitLocation", pathname);
  return (
    <>
      {resolveloader && (
        <Box sx={{ width: "100%" }}>
          <LinearProgress />
        </Box>
      )}




      <div className="header_menu_block">
        <div className="headerLogo">
          {" "}
          <img src="https://all-frontend-assets.s3.amazonaws.com/aspireLeadFire/logo.webp" />
        </div>

        <div className="headerRight">
          <div className="admin_topRight">
            <div className="topuserblock">
              <div className="toggle_Container">
                <div className="toggle_button">
                  <button onClick={() => handleToggle("HeaderMenuToggle")}>
                    <MenuIcon />
                  </button>{" "}
                </div>
              </div>
              <div className="name_drop">
                <Button
                  id="demo-positioned-button"
                  aria-controls={open3 ? "demo-positioned-menu" : undefined}
                  aria-haspopup="true"
                  aria-expanded={open3 ? "true" : undefined}
                  onClick={handleClick3}
                // endIcon={<KeyboardArrowDownIcon />}
                >
                  <PersonIcon className="PersonIcon" />
                </Button>
              </div>

              <Menu
                className="userDetail_dropDwn"
                id="demo-positioned-menu"
                aria-labelledby="demo-positioned-button"
                anchorEl={anchorEl3}
                open={open3}
                onClose={handleClose3}
                anchorOrigin={{
                  vertical: "top",
                  horizontal: "left",
                }}
                transformOrigin={{
                  vertical: "top",
                  horizontal: "left",
                }}
              >
                <h1>
                  {" "}
                  {userInfo?.userinfo?.firstname && userInfo?.userinfo?.lastname
                    ? userInfo.userinfo.firstname +
                    " " +
                    userInfo.userinfo.lastname
                    : ""}
                </h1>

                <MenuItem onClick={handleClose}>
                  <NavLink
                    to="/profile"
                    end
                    onClick={() => {
                      resolvefunction(navigate, "/profile", fetchUserDataReducer({ "id": userInfo?.userinfo?._id }));
                      handleClose3();
                    }}
                  >
                    My account
                  </NavLink>
                </MenuItem>

                <MenuItem onClick={() => clickedLogout()}>Logout</MenuItem>
              </Menu>
            </div>
          </div>
          <div className={`header_centerblock ${isActive ? "showdiv" : ""}`}>

            {getcookies?.userinfo?.main_role == 'is_super_admin'  && <SuperAdminMenu topmenu={1} />}
            {getcookies?.userinfo?.main_role == 'is_developer'  && <DeveloperMenu topmenu={1} />}
            {getcookies?.userinfo?.main_role == 'is_admin' && <AdminMenu topmenu={1} />}
            {getcookies?.userinfo?.main_role == 'is_bdd' && <BddMenu topmenu={1} />}
            {getcookies?.userinfo?.main_role == 'hr_manager' && <HmMenu topmenu={1} />}
            {getcookies?.userinfo?.main_role == 'is_aer' && <AerMenu topmenu={1} />}
            {getcookies?.userinfo?.main_role == 'is_rep' && <RepMenu topmenu={1} />}


            {/* <AdminMenu topmenu={1} /> */}
          </div>
        </div>
      </div>


      {logoutMsg ? (<Snackbar
        open={logoutMsg}
        autoHideDuration={6000}
        onClose={handleClose}
        message="Logging out ...."
      // message={signUpMsg}
      // action={action}
      />) : " "}
    </>
  );
}

export default BackendHeader;
