import React from 'react'
import "./Patnership.css"

const Patnership = () => {
  return (
   <>
   <div className='patner_back_wrap'>
   <div className='patner_main_wraper'> 
    <div className='patner_heading_wrap'>
        <h3>Partnership with UKG-Kronos - Payroll integration</h3>
    </div>
    <div className='patner_list_wrap'>
        <ul>
            <li>Serving   50% of Fortune 1000, 50k companies with tens of millions of employees</li>
            <li>UKG-Kronos Partnership enables Rellevate to:
                <div className='patner_inline_list'>
                    <h5>Facilitate integration</h5>
                    <h5>Open doors across America</h5>
                </div>
            </li>

            <li >Work with other Payroll, Time & Attendance providers to integrate Rellevate</li>
        </ul>
    </div>
   </div>
   </div>
   </>
  )
}

export default Patnership