import { IconButton, Tooltip } from "@mui/material";
import React, { useEffect, useState } from "react";
import SummarizeIcon from "@mui/icons-material/Summarize";
import FolderIcon from "@mui/icons-material/Folder";
import AssessmentIcon from "@mui/icons-material/Assessment";
import StreetviewIcon from "@mui/icons-material/Streetview";
import CalendarViewDayIcon from '@mui/icons-material/CalendarViewDay';
import DataSaverOffIcon from '@mui/icons-material/DataSaverOff';
import FactCheckIcon from '@mui/icons-material/FactCheck';
import ForwardToInboxIcon from '@mui/icons-material/ForwardToInbox';
import MovingIcon from '@mui/icons-material/Moving';
import ClearAllIcon from '@mui/icons-material/ClearAll';
import ArticleIcon from '@mui/icons-material/Article';
import LayersIcon from '@mui/icons-material/Layers';
import GroupsIcon from '@mui/icons-material/Groups';
import FollowTheSignsIcon from '@mui/icons-material/FollowTheSigns';
import ModelTrainingIcon from '@mui/icons-material/ModelTraining';
import SettingsSuggestIcon from '@mui/icons-material/SettingsSuggest';
import FolderCopyIcon from '@mui/icons-material/FolderCopy';
import EditIcon from '@mui/icons-material/Edit';
import MarkunreadIcon from '@mui/icons-material/Markunread';
import ToggleOffIcon from '@mui/icons-material/ToggleOff';
import LockIcon from '@mui/icons-material/Lock';
import PreviewIcon from '@mui/icons-material/Preview';
import InfoIcon from '@mui/icons-material/Info';
import { useDispatch, useSelector } from "react-redux";
import { setEnableRowLoader } from "../../../listing/listReducer";
import { fetchContract, FetchHMSettingModal, FetchStages, FetchTagDetails, rolesForUpgrade, roundRobin } from "../userManagement/userList/userListReducer";
import UpgradeRoleModal from "../userManagement/userList/upgradeRoleModal";
import LocalOfferIcon from '@mui/icons-material/LocalOffer';
import { getDatafollowUpCallList } from "../LeadManagement/LeadReducer";






function BtnsComponent({ dataRef, openModal, fromList, rowgrid, setContractPreviewModal }) {
  const [dataCopy, setDataCopy] = useState({}); //--- dataCopy  ----//


  const dispatch = useDispatch();

  // upgrade role
  const upgradeRole = () => {
    dispatch(rolesForUpgrade(dataRef))
  }






  // ManageStage
  const ManageStage = () => {
    let roles = Object.keys(dataRef?.roles).map((key) => key)
    const data = {
      "condition": {},
      "sort": {},
      "searchcondition": {
        "added_for": dataRef._id,
        "roles": { $in: roles },
      },
      "project": {
        "subject": 1
      },
      "token": "",
      "count": false
    }
    dispatch(FetchStages(data))
  }




  // sendContract 
  const sendContracet = () => {
    setContractPreviewModal(false)
    const data = {
      "condition": {},
      "sort": {
        "field": "name",
        "type": "asc"
      },
      "searchcondition": {
        "status": 1,
        "rep_only": 1
      },
      "project": { "name": 1 },
      "token": "",
      "data": { "user_id": dataRef._id }
    }
    dispatch(fetchContract(data))
  }




  // Manage Hm Sating Function 

  const ManageHmSatting = () => {
    const data = {
      "condition": {
        "limit": 10,
        "skip": 0
      },
      "sort": {
        "field": "name",
        "type": "asc"
      },
      "searchcondition": {
        "_id": dataRef._id
      },
      "count": false
    }
    dispatch(FetchHMSettingModal(data))
  }


  const ManageTag = () => {
    let roles = Object.keys(dataRef?.roles).map((key) => key)


    const data = {
      "_id": dataRef._id,
      "roles": { $in: roles },
      "project": {
        "subject": 1
      },


    }

    dispatch(FetchTagDetails(data))
  }

  const manageRoundRobin=()=>{
    const data = {
      condition: {
        limit: 10,
        skip: 0,
      },
      sort: {
        field: "name",
        type: "asc",
      },
      searchcondition: {
        _id: dataRef._id,
      },
      count: false,
    };

    dispatch(roundRobin(data));

  }

  const manageFollowUp=()=>{
    dispatch(
      setEnableRowLoader({
        tableId: "userslist1",
        rowId: dataRef._id,
        load: true,
      })
    ); //---- row loadder ----

    setDataCopy(dataRef);

    let reqbody = {
      condition: {
        limit: 5,
        skip: 0,
      },
      sort: {
        field: "_id",
        type: "desc",
      },
      searchcondition: {
        added_for: dataCopy._id,
      },
      // "project": {},
      // "token": "",
      count: false,
      source: "",
    };

    dispatch(getDatafollowUpCallList(reqbody));
  }


  return (
    <>
      {fromList ? "" : (
        <>
          <Tooltip title="Stage Lifecycle">
            <IconButton onClick={() => openModal()}>
              <CalendarViewDayIcon />
            </IconButton>
          </Tooltip>

          <Tooltip title="Tag Lifecycle">
            <IconButton onClick={() => openModal()}>
              <DataSaverOffIcon />
            </IconButton>
          </Tooltip>

          <Tooltip title="Manage Programe">
            <IconButton onClick={() => openModal()}>
              <FactCheckIcon />
            </IconButton>
          </Tooltip>

          <Tooltip title="Send Email">
            <IconButton onClick={() => openModal()}>
              <ForwardToInboxIcon />
            </IconButton>
          </Tooltip> </>
      )

      }




      <Tooltip title="Upgrade Role">
        <IconButton onClick={() => upgradeRole()}>
          <MovingIcon />
        </IconButton>
      </Tooltip>

      <Tooltip title="Manage Stage">
        <IconButton onClick={() => ManageStage()}>
          <ClearAllIcon />
        </IconButton>
      </Tooltip>

      <Tooltip title="Send Contract">
        <IconButton onClick={() => sendContracet()}>
          <ArticleIcon />
        </IconButton>
      </Tooltip>


      <Tooltip title="Tag Manage">
        <IconButton onClick={() => ManageTag()}>
          <LocalOfferIcon />
        </IconButton>
      </Tooltip>

      {(dataRef?.main_role === "hr_manager") && (<Tooltip title="Manage HM Settings">
        <IconButton onClick={() => ManageHmSatting()}>
          <LayersIcon />
        </IconButton>
      </Tooltip>)}



      <Tooltip title="Manage Round Robin">
        <IconButton onClick={() => manageRoundRobin()}>
          <GroupsIcon />
        </IconButton>
      </Tooltip>

      <Tooltip title="Followup Call">
        <IconButton onClick={() => manageFollowUp()}>
          <FollowTheSignsIcon />
        </IconButton>
      </Tooltip>

      <Tooltip title="Training Status">
        <IconButton onClick={() => openModal()}>
          <ModelTrainingIcon />
        </IconButton>
      </Tooltip>

      <Tooltip title="Assign User Role">
        <IconButton onClick={() => openModal()}>
          <SettingsSuggestIcon />
        </IconButton>
      </Tooltip>

      <Tooltip title="Folder View">
        <IconButton onClick={() => openModal()}>
          <FolderCopyIcon />
        </IconButton>
      </Tooltip>

      <Tooltip title="Edit">
        <IconButton onClick={() => openModal()}>
          <EditIcon />
        </IconButton>
      </Tooltip>

      <Tooltip title="Resend Welcome Mail">
        <IconButton onClick={() => openModal()}>
          <MarkunreadIcon />
        </IconButton>
      </Tooltip>

      <Tooltip title="Change Status">
        <IconButton onClick={() => openModal()}>
          <ToggleOffIcon />
        </IconButton>
      </Tooltip>

      <Tooltip title="Send Reset Password">
        <IconButton onClick={() => openModal()}>
          <LockIcon />
        </IconButton>
      </Tooltip>

      <Tooltip title="preview">
        <IconButton onClick={() => openModal()}>
          <PreviewIcon />
        </IconButton>
      </Tooltip>

      <Tooltip title="Login Info">
        <IconButton onClick={() => openModal()}>
          <InfoIcon />
        </IconButton>
      </Tooltip>




    </>
  );
}

export default BtnsComponent;
