
import Snackbar from '@mui/material/Snackbar';
import Modal from '@mui/material/Modal';
import Typography from '@mui/material/Typography';
import CloseIcon from '@mui/icons-material/Close';
import store from '../../../store'

// import * as React from 'react';
import React, { useEffect, useRef, useState } from "react";
import { useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { Button, Checkbox, Chip, FormControl, Grid, InputLabel, LinearProgress, List, ListItemText, MenuItem, OutlinedInput, Select, Tooltip } from '@mui/material';

import { Box, display, Stack } from '@mui/system';
import { useCookies, Cookies } from "react-cookie";
import { updateTags } from './LeadReducer';
import ShowTagListMap from './ShowTagListMap';



const ShowTagListModal = (props) => {



    const dispatch = useDispatch(); // ---- Dispatch -----


    const [open, setopen] = React.useState(false);
    const [openDropDown, setOpenDropDown] = React.useState(false);
    const [clicked, setClicked] = useState(null)

    // console.warn("<<<<<< props goes here >>>>>>", props)

    const loader = useSelector(state => (state.LeadReducer?.loading) ? state.LeadReducer?.loading : false)

    // const showtaglist = useSelector(state => state.LeadReducer?.taglifecycledata ? state.LeadReducer?.taglifecycledata : [])

    console.log(props.tagsdata, "showtaglist----")

    // const success = useSelector(state => (state.LeadReducer?.resMessage) ? state.LeadReducer?.resMessage : null)
 


    useEffect(() => {
        props.setModalResponse(false);
    }, [])


    // ------- onModalClose --------
    function ModalClose() {
        // console.warn("<<< modal close hit >>>")
        props.setModalResponse(false);
        props.tooglefunc(false);
        setOpenDropDown(false)

    }




    const ITEM_HEIGHT = 48;
    const ITEM_PADDING_TOP = 0;
    const MenuProps = {
        PaperProps: {
            style: {
                maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
                width: 250,
            },
        },
    };

    console.log(props.UserName, "userName>>>>>")




    return (
        <>

            {/* ------ Confirmation Modal ------ */}


            <Modal
                className="modalblock"
                open={props.toogle}
                onClose={ModalClose}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
            >

                <Box className="BoxMui_modal configure-role" sx={{
                    position: "absolute",
                    top: "50%",
                    left: "50%",
                    transform: "translate(-50%, -50%)",
                    width: 400,
                    bgcolor: "background.paper",
                    border: "2px solid #000",
                    boxShadow: 24,
                    p: 4,
                    textAlign: "center",
                }}>
                    <Typography id="modal-modal-title" variant="h6" component="h6">
                        {loader ? <LinearProgress /> : ""}
                        Tag List for {props.UserName}
                    </Typography>


                    {/* ----- show list for all tags ----- */}

                        <div className="showTagListwrpr">
                                    {/*---------map compoenet */}
                                
                                    {props.tagsdata && props.tagsdata.length>0?  <ShowTagListMap dataset={props.tagsdata}/>:<div>NO data found!</div>}  
                        </div>
      

                    <Button onClick={ModalClose} className="closeModalBtn">
                        <Tooltip title="Close" placement="right-start">

                            <CloseIcon />
                        </Tooltip>
                    </Button>
                </Box>
            </Modal>




            {/* {success != null ? (<Snackbar
                open={open}
                autoHideDuration={6000}
                onClose={() => setopen(false)}
                message={success}
            action={action}
            />) : ""} */}

        </>
    )
}


export default ShowTagListModal;










