import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";

export const trainingCenterCategoryFetching = createAsyncThunk("training-center/category-fetching", async (reqBody) => {

    if (reqBody === null || reqBody === undefined) {
        reqBody = {
            "source": "additional_training_list",
            "sort": {
                "type": "asc",
                "field": "training_priority"
            },
            "condition": {
                "limit": 5,
                "skip": 0
            },
            "searchcondition": {},
            "fetch": "category"
        }
    }

    const requestOptions = {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify(reqBody)
    };
    // console.log("process.env==>", process.env);
    const response = await fetch(process.env.REACT_APP_API_URL + "training-manage/training-by-category", requestOptions);
    const respdata = await response.json();
    // console.log("respdata after endpoint fetch==>", respdata);
    return respdata;
});

export const trainingCenterCategoryFetchingLoadMore = createAsyncThunk("training-center/category-fetching-loadmore", async (reqBody) => {

    const requestOptions = {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify(reqBody)
    };
    // console.log("process.env==>", process.env);
    const response = await fetch(process.env.REACT_APP_API_URL + "training-manage/training-by-category", requestOptions);
    const respdata = await response.json();
    // console.log("respdata after endpoint fetch==>", respdata);
    return respdata;
});



export const fetchInitialLession = createAsyncThunk("training-center/inital-lession", async (reqBody) => {
    const requestOptions = {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify(reqBody)
    };
    // console.log("process.env==>", process.env);
    const response = await fetch(process.env.REACT_APP_API_URL + "training-manage/fetch-single-training-lesson", requestOptions);
    const respdata = await response.json();
    // console.log("respdata after endpoint fetch==>", respdata);
    return respdata;
})

const trainingCenterCategory = createSlice({
    name: "TrainingCenterCategory",
    initialState: {
        loading: false,
        success: false,
        error: false,
        trainingCategoryList: [],
        lessionList: [],
        lessionListLoadEmpty: false,
        initialLessionID: null,
        initialLessionData: {}
    },
    reducers: {
        resetAllDataInTraingcenter(state, action) {
            state.initialLessionID = null;
            state.additionalcenter_loader = false;
            state.trainingCategoryList = [];
            state.lessionList = [];
            state.initialLessionData = {};
        },
        resetInitialLessionIDshow(state, action) {
            state.initialLessionID = null
        },
        resetLessonLoadMore(state, action) {
            state.lessionListLoadEmpty = false;
        },
    },
    extraReducers: {
        [fetchInitialLession.pending]: (state, action) => {
            state.loading = true;
        },
        [fetchInitialLession.fulfilled]: (state, action) => {
            state.loading = false;
            // console.log("action fetchInitialLession", action)
            if (action.payload.status === "success") {
                state.initialLessionData = action.payload.results.res
            }
        },
        [fetchInitialLession.rejected]: (state, action) => {
            state.loading = false;
        },

        [trainingCenterCategoryFetching.pending]: (state, action) => {
            state.loading = true
        },
        [trainingCenterCategoryFetching.fulfilled]: (state, action) => {
            state.loading = false;
            state.success = true;
            // console.log("action initialLessionData", action.payload)
            if (action.payload.status === "success") {
                if (action.payload.training) {
                    state.trainingCategoryList = action.payload.training.res
                }
                if (action.payload.results && action.payload.results.res) {
                    state.lessionList = []
                    state.lessionList = action.payload.results.res;
                    // console.log("action initialLessionData", action.payload.results)
                    if (state.initialLessionData && Object.keys(state.initialLessionData).length === 0 && action.payload.results.res.length > 0) {
                        state.initialLessionID = action.payload.results.res[0]._id;
                    }
                }
            }
        },
        [trainingCenterCategoryFetching.rejected]: (state, action) => {
            state.loading = false;
            state.error = true;
        },
        [trainingCenterCategoryFetchingLoadMore.pending]: (state, action) => {
            state.loading = true
        },
        [trainingCenterCategoryFetchingLoadMore.fulfilled]: (state, action) => {
            state.loading = false;
            state.success = true;
            if (action.payload.status === "success") {
                if (action.payload.results && action.payload.results.res) {
                    if (action.payload.results.res.length === 0) {
                        state.lessionListLoadEmpty = true;
                    }
                    state.lessionList = [...state.lessionList, ...action.payload.results.res];
                }
            }
        },
        [trainingCenterCategoryFetchingLoadMore.rejected]: (state, action) => {
            state.loading = false;
            state.error = true;
        }



    }
})

export default trainingCenterCategory.reducer;
export const { resetAllDataInTraingcenter, resetInitialLessionIDshow,resetLessonLoadMore } = trainingCenterCategory.actions;