import { Button, IconButton, TextField } from "@mui/material";
import React, { useEffect, useState } from "react";
import { Controller, useForm } from "react-hook-form";
import { AutoCompleteSearch } from "./components/AutoCompleteSearch";
import { DateSearch } from "./components/DateSearch";
import { SelectSearch } from "./components/SelectSearch";
import { TextSearch } from "./components/TextSearch";
import { useDispatch } from "react-redux";
import { fetchTableCount, fetchTableList } from "../listing/listReducer";
import CloseIcon from "@mui/icons-material/Close";
import { cloneDeep } from "lodash";
import store from "../store";

export const SearchBar = ({
  tableData,
  searchBarData,
  tableId,
  handleCloseDrawer,
  clickedRowData,
}) => {
  const [addedForId, setAddedForId] = useState({}); //***FOR ROW DATA****//

  const dispatch = useDispatch();
  const {
    register,
    handleSubmit,
    control,
    formState: { errors },
    setValue,
    watch,
    getValues,
    clearErrors,
    setError,
  } = useForm();

  ///////////////////////////////////// Form Data Check ////////////////////////////////

  // --------------------------------------------store.subscribe Section-------------------------------------------- //
  store.subscribe(() => {
    const storeData = store.getState();

    if (
      storeData.tableSlice?.tempRowData[tableData.mainTableId]?._id != undefined ||
      storeData.tableSlice?.tempRowData[tableData.mainTableId]?._id != null
    ) {
      setAddedForId(storeData.tableSlice?.tempRowData[tableData.mainTableId]?._id);
      console.log("addedForId------->", addedForId);

    }
  });

  useEffect(() => {
    console.log(
      "formData=====================================>",
      clickedRowData
    );
    console.log("tableData=====================================>", tableData);
  }, [clickedRowData]);

  React.useEffect(() => {
    const subscription = watch((value, { name, type }) => {
      // console.log("value===========================>", value, "name", name, "type", type);
      // console.log("errors", errors);
    });
    return () => subscription.unsubscribe();
  }, [watch]);

  const onSubmit = (data) => {
    console.log("searchcondition data 666", data);
    if (
      searchBarData.api.endPointType &&
      searchBarData.api.endPointType == "GET"
    ) {
      let url = searchBarData.api.endPoint;

      for (let key in data) {
        if (data[key]) {
          url = `${url}?${key}=${data[key]}`;
        }
      }
      dispatch(
        fetchTableList({
          BASE_URL: searchBarData.api.url,
          endPoint: url,
          restResponseAttachment: { tableId: tableData.tableId },
          recieveResponseParam: tableData.recieveResponseParam,
          endPointType: searchBarData.api.endPointType,
        })
      );
    } else {
      let searchcondition = {};
      for (let key in data) {
        if (data[key] !== undefined) {
          console.log("data[key]====>", data[key]);
          searchcondition[key] = {};
          /////////////////////////// For String Input ///////////////////////////
          if (!key.includes("datetime") && typeof data[key] === "string") {
            searchcondition[key]["$regex"] = data[key];
            /////////////////// For Regex Pattern ///////////////////////
            const pattern = searchBarData.searchSettings.selectsearch.find(
              (cur) => cur.field === key
            )?.pattern;
            searchcondition[key]["$options"] =
              pattern !== undefined ? pattern : "ims";
          }

          if (!key.includes("datetime") && typeof data[key] === "number") {
            searchcondition[key] = data[key];
          }

          ///////////////////////// For Array Input /////////////////////////////
          if (!key.includes("time") && Array.isArray(data[key]) === true)
            searchcondition[key]["$in"] = data[key];

          ////////////////////////// For Date Range Input /////////////////////////////
          if (
            key.includes("time") &&
            Array.isArray(data[key]) === true &&
            data[key].length === 2
          ) {
            searchcondition[key] = { $gte: data[key][0], $lte: data[key][1] };
          }

          ////////////////////////// For Date Input /////////////////////////////
          if (
            key.includes("datetime") &&
            Array.isArray(data[key]) === false &&
            typeof (data[key] === "number")
          ) {
            searchcondition[key] = { $gte: data[key] };
          }
          console.log("data[key] searchcondition===>", searchcondition);
        }
      }
      const reqBody = {
        ...searchBarData.api.reqBody,
        searchcondition: {
          ...searchcondition,
          ...searchBarData.api.reqBody.searchcondition,
          
        },
      };


      if(tableData.mainTableId){
        reqBody.searchcondition.notes_for= addedForId
      }
      console.log("tableData.reqBody======>", tableData.reqBody);

      ///////////////////////// manipulating table data request body for next page /////////////////////
      tableData.reqBody.searchcondition = reqBody.searchcondition;

      dispatch(
        fetchTableList({
          BASE_URL: searchBarData.api.url,
          endPoint: searchBarData.api.endPoint,
          reqBody: reqBody,
          restResponseAttachment: { tableId: tableId },
        })
      );

      if (searchBarData.api.tableCountEndpoint) {
        dispatch(
          fetchTableCount({
            BASE_URL: searchBarData.api.url,
            endPoint: searchBarData.api.tableCountEndpoint,
            reqBody: { ...cloneDeep(reqBody), count: true },
            restResponseAttachment: { tableId: tableId },
          })
        );
      }
    }
    handleCloseDrawer();
  };

  return (
    <div className="formCls PatientSearch">
      <div className="searchBarHead DrawerBox">
        <h2 className="searchBarHeading">{searchBarData.heading}</h2>
        <span className="searchBarCloseIcon">
          <IconButton onClick={() => handleCloseDrawer()}>
            <CloseIcon className="closeDrawer" />
          </IconButton>
        </span>
      </div>
      <form className="searchForm formInline" onSubmit={handleSubmit(onSubmit)}>
        {Object.keys(searchBarData.searchSettings).map((searchType) => {
          const mappedFields = searchBarData.searchSettings[searchType].map(
            (eachField) => {
              switch (searchType) {
                case "textsearch":
                  return (
                    <Controller
                      name={eachField.field}
                      key={eachField.id}
                      control={control}
                      render={({ field: { onChange, value } }) => (
                        <TextSearch
                          fielddata={eachField}
                          setValue={setValue}
                          getValues={getValues}
                        />
                      )}
                    />
                  );
                case "selectsearch":
                  return (
                    <Controller
                      name={eachField.field}
                      key={eachField.id}
                      control={control}
                      render={({ field: { onChange, value } }) => (
                        <SelectSearch
                          fielddata={eachField}
                          setValue={setValue}
                          getValues={getValues}
                        />
                      )}
                    />
                  );
                case "datesearch":
                  return (
                    <Controller
                      name={eachField.field}
                      key={eachField.id}
                      control={control}
                      render={({ field: { onChange, value } }) => (
                        <DateSearch
                          fielddata={eachField}
                          setValue={setValue}
                          getValues={getValues}
                        />
                      )}
                    />
                  );
                case "autoCompleteSearch":
                  return (
                    <Controller
                      name={eachField.field}
                      key={eachField.id}
                      control={control}
                      render={({ field: { onChange, value } }) => (
                        <AutoCompleteSearch
                          fielddata={eachField}
                          setValue={setValue}
                          getValues={getValues}
                        />
                      )}
                    />
                  );
                default:
                  return null;
              }
            }
          );
          return mappedFields;
        })}
        <Button className="searchBtn" type="submit" variant="contained">
          Search
        </Button>
      </form>
    </div>
  );
};
