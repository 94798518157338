import { createAsyncThunk, createSlice } from "@reduxjs/toolkit"
import { Cookies } from "react-cookie";
import { getUSerdataFromState } from "../../../helper/helperFunctions";
const cookies = new Cookies().getAll();




export const getleaddata = createAsyncThunk("getleaddata", async (reqBody) => {
    // console.log("reqbody==>", reqBody,cookies,getUSerdataFromState());
    let userData = getUSerdataFromState()
    let req_body = {};
    req_body = { ...reqBody };
    console.log("req_body==", req_body);
    if (userData) {
        if (userData.main_role == 'is_rep' && !reqBody.searchcondition['rep_id']) {
            reqBody.searchcondition['rep_id'] = userData._id
        }
        if (userData.main_role == 'hr_manager' && !reqBody.searchcondition['hiring_manager']) {
            reqBody.searchcondition['hiring_manager'] = userData._id
        }
        if (userData.main_role == 'is_bdd' && !reqBody.searchcondition['associated_bdd']) {
            reqBody.searchcondition['associated_bdd'] = userData._id
        }

    }


    const requestOptions = {
        method: 'post',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify(req_body)
    };
    console.log("process.env==>", process.env);
    const response = await fetch(process.env.REACT_APP_API_URL + "lead-manage/lead-list-count", requestOptions);
    const respdata = await response.json();
    console.log("respdata after endpoint fetch==>", respdata);
    return respdata;
})


export const getleadeditdata = createAsyncThunk("editrole", async (id) => {
    let reqBody = {
        "_id": id 
    }
    console.log("reqbody==>", reqBody);
    let req_body = {};
    req_body = { ...reqBody };
    console.log("req_body==", req_body);
    const requestOptions = {
        method: 'post',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify(req_body)
    };
    console.log("process.env==>", process.env);
    const response = await fetch(process.env.REACT_APP_API_URL + "lead-manage/fetch-lead", requestOptions);
    const respdata = await response.json();
    // console.log("respdata after endpoint fetch==>", respdata);
    return respdata;


})


export const getallprogramdata = createAsyncThunk("getallprogramdata", async (reqBody) => {

    console.log("reqbody==>", reqBody);
    let req_body = {};
    req_body = { ...reqBody };
    console.log("req_body==", req_body);
    const requestOptions = {
        method: 'post',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify(req_body)
    };
    console.log("process.env==>", process.env);
    const response = await fetch(process.env.REACT_APP_API_URL + "program-manage/auto-complete-program-list", requestOptions);
    const respdata = await response.json();
    // console.log("respdata after endpoint fetch==>", respdata);
    return respdata;




})





export const fatchViewData = createAsyncThunk("fatchViewData", async (reqBody) => {
    // console.warn("<<<<<< inside fetchUserDataReducer >>>>", reqBody)
    const reqOption = {
        method: "POST",
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({ _id: reqBody })
    };
    const response = await fetch(process.env.REACT_APP_API_URL + "lead-manage/fetch-lead", reqOption);
    const respdata = await response.json();
    // console.log("respdata after endpoint fetch==>", respdata);
    return respdata;
});



export const getNotesleaddata = createAsyncThunk("getNotesleaddata", async (reqBody) => {

    console.log("reqbody==>", reqBody);
    let req_body = {};
    req_body = { ...reqBody };
    console.log("req_body==", req_body);
    const requestOptions = {
        method: 'post',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify(req_body)
    };
    console.log("process.env==>", process.env);
    const response = await fetch(process.env.REACT_APP_API_URL + "note/note-list-count", requestOptions);
    const respdata = await response.json();
    console.log("respdata after endpoint fetch==>", respdata);
    return respdata;


})


export const getNotesleaddataCount = createAsyncThunk("getNotesleaddataCount", async (reqBody) => {

    console.log("reqbody==>", reqBody);
    let req_body = {};
    req_body = { ...reqBody };
    console.log("req_body==", req_body);
    const requestOptions = {
        method: 'post',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify(req_body)
    };
    console.log("process.env==>", process.env);
    const response = await fetch(process.env.REACT_APP_API_URL + "note/note-list-count", requestOptions);
    const respdata = await response.json();
    console.log("respdata after endpoint fetch==>", respdata);
    return respdata;


})


/////////////////////////////////  FetchStages //////////////////////////////////
export const FetchStages = createAsyncThunk("lead/fetchstages", async (reqbody) => {
    console.warn("<<<<<<<< payload from FetchStages thunk >>>>", reqbody)

    const requestOptions = {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify(reqbody)
    };
    const response = await fetch(process.env.REACT_APP_API_URL + "assign-stage/assigned-stage-list", requestOptions);
    const FetchStages = await response.json();

    return FetchStages;
});




/////////////////////////////////  AssignStages //////////////////////////////////
export const updateStages = createAsyncThunk("lead/updatestages", async (reqbody) => {
    console.warn("<<<<<<<< payload from AssignStages thunk >>>>", reqbody)

    const requestOptions = {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify(reqbody)
    };
    const response = await fetch(process.env.REACT_APP_API_URL + "assign-stage/assign-stage", requestOptions);
    const updateStages = await response.json();

    return updateStages;
});




/////////////////////////////////  FetchTagDetails //////////////////////////////////
export const FetchTagDetails = createAsyncThunk("lead/FetchTagDetails", async (reqbody) => {
    // console.warn("<<<<<<<< payload from logInInfo thunk >>>>", reqbody)

    const requestOptions = {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify(reqbody)
    };
    const response = await fetch(process.env.REACT_APP_API_URL + "users/manage-tags", requestOptions);
    const FetchTagDetails = await response.json();

    return FetchTagDetails;
});


/////////////////////////////////  updateTags //////////////////////////////////
export const updateTags = createAsyncThunk("lead/updateleadTags", async (reqbody) => {
    console.warn("<<<<<<<< payload from updateTags thunk >>>>", reqbody)

    const requestOptions = {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify(reqbody)
    };
    const response = await fetch(process.env.REACT_APP_API_URL + "users/user-tag-add", requestOptions);
    const updateTags = await response.json();

    return updateTags;
});










export const getusersList = createAsyncThunk("getusersList", async (reqBody) => {
    console.log("reqbody==>", reqBody);
    let req_body = {};
    req_body = { ...reqBody };
    console.log("req_body==", req_body);
    if (cookies.userinfo.main_role != 'is_admin') {
        reqBody.searchcondition['added_by'] = cookies.userinfo._id
    }

    const requestOptions = {
        method: 'post',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify(req_body)
    };
    console.log("process.env==>", process.env);
    const response = await fetch(process.env.REACT_APP_API_URL + "users/user-list-or-count", requestOptions);
    const respdata = await response.json();
    console.log("respdata after endpoint fetch==>", respdata);
    return respdata;
})

// For getting CIF Form Configuration
export const getCIFForm = createAsyncThunk("LeadReducer/getCIFForm", async (reqBody) => {
    console.log("reqbody for CIF form --->", reqBody);
    const requestOptions = {
        method: 'post',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify(reqBody)
    };
    const response = await fetch(process.env.REACT_APP_API_URL + "manage-form/preview-form", requestOptions);
    const respdata = await response.json();
    console.log("respdata for CIF form --->", respdata);
    return respdata;
})

// For getting CIF Form Configuration
export const sendCIF_Mail = createAsyncThunk("LeadReducer/sendCIF_Mail", async (reqBody) => {
    console.log("reqbody for CIF form --->", reqBody);
    const requestOptions = {
        method: 'post',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify(reqBody)
    };
    const response = await fetch(process.env.REACT_APP_API_URL + "sample/send-cif-mail", requestOptions);
    const respdata = await response.json();
    console.log("respdata for CIF form --->", respdata);
    return respdata;
})

// For getting Lead Tags
export const getLeadTags = createAsyncThunk("lead/updateUserTags", async (reqbody) => {
    console.warn("<<<<<<<< payload from getLeadTags thunk >>>>", reqbody)

    const requestOptions = {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify(reqbody)
    };
    const response = await fetch(process.env.REACT_APP_API_URL + "lead-manage/single-fetch-tag-data", requestOptions);
    const updateTags = await response.json();

    return updateTags;
});




export const getDatafollowUpCallList = createAsyncThunk("followUpCallList", async (reqBody) => {
    console.log("reqbody==>", reqBody);
    let req_body = {};
    req_body = { ...reqBody };
    console.log("req_body==", req_body);
    const requestOptions = {
        method: 'post',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify(req_body)
    };
    console.log("process.env==>", process.env);
    const response = await fetch(process.env.REACT_APP_API_URL + "users/follow-up-call-list-count", requestOptions);
    const respdata = await response.json();
    console.log("respdata after endpoint fetch==>", respdata);
    return respdata;
})

export const sendMailForUser = createAsyncThunk("sendMailForUser", async (reqBody) => {
    console.log("reqbody==>", reqBody);
    let req_body = {};
    req_body = { ...reqBody };
    console.log("req_body==", req_body);
    const requestOptions = {
        method: 'post',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify(req_body)
    };
    console.log("process.env==>", process.env);
    const response = await fetch(process.env.REACT_APP_API_URL + "email-template/assign-email", requestOptions);
    const respdata = await response.json();
    console.log("respdata after endpoint fetch==>", respdata);
    return respdata;
})
export const getProgramRelatedCIFList = createAsyncThunk("getProgramRelatedCIFList", async (reqBody) => {
    console.log("reqbody==>", reqBody);
    let req_body = {
        "condition": {
            "limit": 10,
            "skip": 0
        },
        "sort": {
            "field": "updatedon_datetime",
            "type": "desc"
        },
        "searchcondition": {
            "related_programs": reqBody
        },
        "project": {},
        "token": ""
    };

    console.log("req_body==", req_body);
    const requestOptions = {
        method: 'post',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify(req_body)
    };
    console.log("process.env==>", process.env);
    const response = await fetch(process.env.REACT_APP_API_URL + "manage-form/cif-form-preview", requestOptions);
    const respdata = await response.json();
    console.log("respdata after endpoint fetch==>", respdata);
    return respdata;
})



export const getProductautocomplete = createAsyncThunk("getproductautocomplete", async () => {

    // console.log("reqbody==>", reqbody);
    let req_body = {
        "source": "program_list",
        "sort": {
            "type": "desc",
            "field": "name"
        },
        "searchcondition": {

        }


    };

    const requestOptions = {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify(req_body)
    };
    // console.log("process.env==>", process.env);
    const response = await fetch(process.env.REACT_APP_API_URL + "program-manage/fetch-programs", requestOptions);
    const respdata = await response.json();
    // console.log("respdata after endpoint fetch==>", respdata);
    return respdata;

});



/////////////////////////////////  ShowStageList //////////////////////////////////
export const ShowStageList = createAsyncThunk("lead/showstages", async (reqbody) => {
    console.warn("<<<<<<<< payload from showstageList thunk >>>>", reqbody)

    const requestOptions = {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify(reqbody)
    };
    const response = await fetch(process.env.REACT_APP_API_URL + "users/single-fetch-stage-data", requestOptions);
    const respdata = await response.json();

    return respdata;
});


export const gettagalldata = createAsyncThunk("lead/gettagalldata", async (reqbody) => {
    console.warn("<<<<<<<< payload from showtagList thunk >>>>", reqbody)

    const requestOptions = {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify(reqbody)
    };
    const response = await fetch(process.env.REACT_APP_API_URL + "lead-manage/single-fetch-tag-data", requestOptions);
    const reponse = await response.json();

    return reponse;
});

export const getcalenderinvitationlink = createAsyncThunk("lead/getcalenderinvitationlink", async (reqbody) => {
    console.warn("<<<<<<<< payload from showtagList thunk >>>>", reqbody)

    const requestOptions = {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify(reqbody)
    };
    const response = await fetch(process.env.REACT_APP_API_URL + "calendar-api/resend-calendar-booking-link", requestOptions);
    const reponse = await response.json();

    return reponse;
});


export const getconversiondone = createAsyncThunk("lead/getconversiondone", async (reqbody) => {
    console.warn("<<<<<<<< payload from showtagList thunk >>>>", reqbody)

    const requestOptions = {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify(reqbody)
    };
    const response = await fetch(process.env.REACT_APP_API_URL + "contract-manage/set-contract", requestOptions);
    console.log("response", response)
    const reponse = await response.json();

    return reponse;
});


export const addlead = createAsyncThunk("addleaddata", async (reqBody) => {
    console.log("reqbody==>", reqBody);
    let req_body = {};
    req_body = { ...reqBody };
    console.log("req_body==", req_body);
    const requestOptions = {
        method: 'post',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify(req_body)
    };
    console.log("process.env==>", process.env);
    const response = await fetch(process.env.REACT_APP_API_URL + "lead-manage/addorupdatelead", requestOptions);
    const respdata = await response.json();
    console.log("respdata after endpoint fetch==>", respdata);
    return respdata;
})






// LeadReducer Slice
const LeadReducer = createSlice({
    name: 'LeadReducer',
    initialState: {

        submitting: false,
        loading: false,
        editloading: false,
        editleaddata: null,
        leaddata: [],
        programdata: [],
        resMessage: null,

        fatchViewData: {},
        Notesleaddata: [],
        notesloading: false,
        notesleaddataCount: 0,

        usersList: [],
        FetchStages: [],
        resMessage: null,
        FetchTagDetailsData: [],
        tokenRequired: false,
        tokenRequiredMsg: '',
        ShowStageListData: [],
        ShowTagListData: [],
        getproduct: [],

        info_CIF: null,
        cif_mail_send_res: null,
        leadTags: null,

        followUpCallData: [],
        loaderForFollowUp: false,
        loaderForsendmail: false,
        submistionstate: 0,
        taglifecycledata: [],
        programRelatedCIFList: [],
        calenderinvitation: null,
        markconversion: null,
        leadadditiondata : null,
        addsuccessflag:true,
        addleadloader:false,

    },
    reducers: {
        clearleaddata(state, action) {
            state.leaddata = []
        },
        clearinvationdata(state, action) {
            state.calenderinvitation = null
        },
        clearconversiondata(state, action) {
            state.markconversion = null
        },

        cleareditleaddata(state, action) {
            console.log('Fueell')
            state.editleaddata = null
        },
        clearprogramdata(state, action) {
            state.programdata = []
        },

        clearfatchViewData(state, action) {
            state.fatchViewData = {}
        },

        cleargetNotesleaddata(state, action) {
            state.Notesleaddata = [];
            state.notesloading = false;
        },

        setStatesBlank(state, action) {
            state.loading = false;
            state.FetchTagDetailsData = null;
            state.FetchStages = [];
            state.resMessage = null;
            state.successAction = false;

            // state.ShowStageListData = []
        },
        reSetSubmistionstate(state, action) {

            state.submistionstate = 0;

        },
        clearaddsuccessflag(state, action) {
            state.addsuccessflag = false
            state.leadadditiondata=null
        
            
        },

        // setTempRowId(state, action) {

        // },



    },
    extraReducers: {


        [gettagalldata.rejected]: (state, action) => {
            state.loading = false

        },
        [gettagalldata.pending]: (state, action) => {
            // state.loading=true
            // 

        },
        [gettagalldata.fulfilled]: (state, action) => {
            if (action.payload.status === 'success') {
                state.taglifecycledata = action.payload.results.res;
                console.log(state.taglifecycledata, "taglifecycledata>>>>>>>>");
                state.loading = false
            }
        },


        // <----------------- For Getting Program Related CIF List ----------------->
        [getProgramRelatedCIFList.pending]: (state, action) => {
            state.loading = true;

        },
        [getProgramRelatedCIFList.fulfilled]: (state, action) => {
            if (action.payload.status === 'success') {
                state.loading = false;
                state.programRelatedCIFList = action.payload.results.res;
            }
        },
        [getProgramRelatedCIFList.rejected]: (state, action) => {
            state.loading = false;
        },
        // <------------------------------------------------------------------------>

        [sendMailForUser.rejected]: (state, action) => {
            state.loaderForsendmail = false;
            state.submistionstate = 3;

        },
        [sendMailForUser.pending]: (state, action) => {
            state.loaderForsendmail = true;
            state.submistionstate = 1;

        },
        [sendMailForUser.fulfilled]: (state, action) => {
            if (action.payload.status === 'success') {
                state.loaderForsendmail = false;
                state.submistionstate = 2;
            }
        },



        [getDatafollowUpCallList.rejected]: (state, action) => {
            // state.endpointsuccess[action.type] = false
            state.loaderForFollowUp = false;
        },
        [getDatafollowUpCallList.pending]: (state, action) => {
            state.loaderForFollowUp = true;
        },
        [getDatafollowUpCallList.fulfilled]: (state, action) => {
            if (action.payload.status === 'success') {
                state.followUpCallData = action.payload.results.res;
                state.loaderForFollowUp = false;
            }
        },




        // ================ For GET DATA ================ //
        [getusersList.rejected]: (state, action) => {
            // state.endpointsuccess[action.type] = false
            state.successAction = false;
        },
        [getusersList.pending]: (state, action) => {
            state.loading = true;
        },
        [getusersList.fulfilled]: (state, action) => {
            if (action.payload.status === 'success') {
                state.usersList = action.payload.results.res;
                state.loading = false;
            }
        },


        // ================ For GET DATA ================ //
        [getleaddata.rejected]: (state, action) => {
            // state.endpointsuccess[action.type] = false
            state.successAction = false;
        },
        [getleaddata.pending]: (state, action) => {
            state.loading = true;
        },
        [getleaddata.fulfilled]: (state, action) => {
            if (action.payload.status === 'success') {
                state.leaddata = action.payload.results.res;
                state.loading = false;
            }
        },



        // ================ For getNotesleaddata ================ //
        [getNotesleaddata.rejected]: (state, action) => {
            state.endpointsuccess[action.type] = false
            state.successAction = false;
            state.notesloading = false
        },
        [getNotesleaddata.pending]: (state, action) => {
            state.notesloading = true
        },
        [getNotesleaddata.fulfilled]: (state, action) => {
            if (action.payload.status === 'success') {
                state.Notesleaddata = action.payload.results.res;
                state.notesloading = false
            }
        },


        [getNotesleaddataCount.rejected]: (state, action) => {
            state.endpointsuccess[action.type] = false
            state.successAction = false;
            state.notesloading = false
        },
        [getNotesleaddataCount.pending]: (state, action) => {
            state.notesloading = true
        },
        [getNotesleaddataCount.fulfilled]: (state, action) => {
            if (action.payload.status === 'success') {
                state.notesleaddataCount = action.payload.count;
                state.notesloading = false
            }
        },





        //-----------########   fatchViewData #######   --------//
        [fatchViewData.rejected]: (state, action) => {
            state.endpointsuccess[action.type] = false
            state.successAction = false;
            state.fatchViewData = {};

        },
        [fatchViewData.pending]: (state, action) => {
            // state.loading = true;
            state.fatchViewData = {};
        },
        [fatchViewData.fulfilled]: (state, action) => {
            if (action.payload.status === 'success') {
                state.fatchViewData = action.payload?.results?.res;
                // state.loading = false;
            }
        },





        // ================ For EDIT DATA ================ //
        [getleadeditdata.rejected]: (state, action) => {
            state.endpointsuccess[action.type] = false


        },
        [getleadeditdata.pending]: (state, action) => {
            state.editloading = true;
        },
        [getleadeditdata.fulfilled]: (state, action) => {
            if (action.payload.status === 'success') {
                state.editleaddata = action.payload.results.res;
                state.editloading = false;
                console.log('Fullllllllllllllllllllll', state.editleaddata)

            }
        },



        [getallprogramdata.rejected]: (state, action) => {
            state.endpointsuccess[action.type] = false
        },
        [getallprogramdata.pending]: (state, action) => {
            state.editloading = true;
        },
        [getallprogramdata.fulfilled]: (state, action) => {
            if (action.payload.status === 'success') {
                state.programdata = action.payload.results.res;
                state.editloading = false;
                console.log('programdata', state.programdata)

            }
        },


        /////////////////////////// FetchStages ///////////////////////////
        [FetchStages.pending]: (state, action) => {

            // state.loading = true;
            state.resMessage = null;
            state.FetchStages = []

        },
        [FetchStages.fulfilled]: (state, action) => {

            if (action.payload.status === "success") {
                state.loading = false;
                state.FetchStages = action.payload?.results?.res ? action.payload.results.res : []
            } else {
                state.loading = false;
                state.resMessage = action.payload?.message ? action.payload.message : 'Something Went Wrong';
                state.FetchStages = []
            }
        },
        [FetchStages.rejected]: (state, action) => {
            state.loading = false;
            state.resMessage = action.payload?.message ? action.payload.message : 'Something Went Wrong';
            state.FetchStages = []

        },


        /////////////////////////// AssignStages ///////////////////////////
        [updateStages.pending]: (state, action) => {

            // state.loading = true;
            state.resMessage = null;

        },
        [updateStages.fulfilled]: (state, action) => {

            if (action.payload.status === "success") {
                state.loading = false;

                console.warn("<<<<<<<<<<<< state.FetchStages >>>>>>>>", [...state.FetchStages])

                state.FetchStages = state.FetchStages.map((stages) => ({ ...stages, status: stages.key === action.meta.arg.stage_slug ? 1 : 0 }))

                state.resMessage = action.payload?.message ? action.payload.message : 'Something Went Wrong';

            } else {
                state.loading = false;
                state.resMessage = action.payload?.message ? action.payload.message : 'Something Went Wrong';

            }
        },
        [updateStages.rejected]: (state, action) => {
            state.loading = false;
            state.resMessage = action.payload?.message ? action.payload.message : 'Something Went Wrong';

        },


        /////////////////////////// FetchTagDetails ///////////////////////////
        [FetchTagDetails.pending]: (state, action) => {

            // state.loading = true;
            state.resMessage = null;
            state.FetchTagDetailsData = []
        },
        [FetchTagDetails.fulfilled]: (state, action) => {

            if (action.payload.status === "success") {
                state.loading = false;
                state.FetchTagDetailsData = action.payload?.results?.res ? action.payload.results.res : [];

            }
        },
        [FetchTagDetails.rejected]: (state, action) => {
            state.loading = false;
            state.resMessage = action.payload?.message ? action.payload.message : 'Something Went Wrong';
            state.FetchTagDetailsData = []


        },

        /////////////////////////// updateTags ///////////////////////////
        [updateTags.pending]: (state, action) => {

            // state.loading = true;
            state.resMessage = null;
            state.tagUpdated = false;

        },
        [updateTags.fulfilled]: (state, action) => {

            if (action.payload.status === "success") {
                state.loading = false;
                state.tagUpdated = true;
                state.FetchTagDetailsData.find((data) => data.key === action.meta.arg.tag_data.key).status = action.meta.arg.tag_data.status
            } else {
                state.loading = false;
                state.resMessage = 'Something Went Wrong';
                state.tagUpdated = false;

            }
        },
        [updateTags.rejected]: (state, action) => {
            state.loading = false;
            state.resMessage = action.payload?.message ? action.payload.message : 'Something Went Wrong';
            state.FetchTagDetailsData = []
            state.tagUpdated = false;



        },

        [getProductautocomplete.rejected]: (state, action) => {
            state.loading = false;
            state.successAction = false;

        },
        [getProductautocomplete.pending]: (state, action) => {
            // console.log("action p", action)
            // state.usersList = [];
            state.loading = true;
        },
        [getProductautocomplete.fulfilled]: (state, action) => {
            console.log("action f storedata", action)
            if (action.payload.status === 'success') {
                console.log("Resyyyyyyyyyyyyyyyyyyy", action.payload.results.res)
                state.getproduct = action.payload.results.res;

            }
        },


        /////////////////////////// ShowStageList ///////////////////////////
        [ShowStageList.pending]: (state, action) => {

            // state.loading = true;
            state.resMessage = null;
            state.ShowStageListData = []

        },
        [ShowStageList.fulfilled]: (state, action) => {

            if (action.payload.status === "success") {
                state.loading = false;
                state.ShowStageListData = action.payload?.results?.res ? action.payload.results.res : []
            } else {
                state.loading = false;
                state.resMessage = action.payload?.message ? action.payload.message : 'Something Went Wrong';
                state.ShowStageListData = []
            }
        },
        [ShowStageList.rejected]: (state, action) => {
            state.loading = false;
            state.resMessage = action.payload?.message ? action.payload.message : 'Something Went Wrong';
            state.ShowStageListData = []

        },




        // <--------------------------------- Extra Reducers for CIF Form Configuration --------------------------------->
        [getCIFForm.pending]: (state, action) => {
            state.loading = true;
        },
        [getCIFForm.fulfilled]: (state, action) => {
            state.loading = false;
            if (action.payload.status === "success") state.info_CIF = action.payload?.results?.res ? action.payload.results.res : null;
        },
        [getCIFForm.rejected]: (state, action) => {
            state.loading = false;
            state.resMessage = action.payload?.message ? action.payload.message : 'Something Went Wrong';
            state.info_CIF = null;
        },

        // <--------------------------------- Extra Reducers for CIF Form Configuration --------------------------------->
        [sendCIF_Mail.pending]: (state, action) => {
            state.loading = true;
        },
        [sendCIF_Mail.fulfilled]: (state, action) => {
            state.loading = false;
            if (action.payload.status === "success") state.cif_mail_send_res = action.payload?.results?.res ? action.payload.results.res : null;
        },
        [sendCIF_Mail.rejected]: (state, action) => {
            state.loading = false;
            state.resMessage = action.payload?.message ? action.payload.message : 'Something Went Wrong';
            state.cif_mail_send_res = null;
        },

        // <---------------------------------------- Extra Reducers for LeadTags ---------------------------------------->
        [getLeadTags.pending]: (state, action) => {
            state.loading = true;
            state.ShowTagListData = []
        },
        [getLeadTags.fulfilled]: (state, action) => {
            state.loading = false;
            if (action.payload.status === "success")
                state.leadTags = action.payload?.results?.res ? action.payload.results.res : null;
            state.ShowTagListData = action.payload?.results?.res ? action.payload.results.res : null;
            console.log(state.ShowTagListData, "ShowTagListData>>>");
        },
        [getLeadTags.rejected]: (state, action) => {
            state.loading = false;
            state.resMessage = action.payload?.message ? action.payload.message : 'Something Went Wrong';
            state.info_CIF = null;
            state.ShowTagListData = [];
        },

        /*****************for calender invitation link******* */
        [getcalenderinvitationlink.pending]: (state, action) => {
            // state.loading = true;

        },
        [getcalenderinvitationlink.fulfilled]: (state, action) => {
            // state.loading = false;
            if (action.payload.status === "success")
                state.calenderinvitation = action.payload?.status ? action.payload.status : null;
            state.resMessage = "email sent successfully";
            state.successAction = true;

        },
        [getcalenderinvitationlink.rejected]: (state, action) => {
            // state.loading = false;

        },


        /*****************for mark as conversion done******* */
        [getconversiondone.pending]: (state, action) => {
            // state.loading = true;

        },
        [getconversiondone.fulfilled]: (state, action) => {
            // state.loading = false;
            if (action.payload.status === "success")
                state.markconversion = action.payload?.status ? action.payload.status : null;
            // state.resMessage = "email sent successfully";
            // state.successAction = true;

        },
        [getconversiondone.rejected]: (state, action) => {
            // state.loading = false;

        },

        /***********for lead add***** */
          [addlead.pending]: (state, action) => {

            // state.loading = true;
            state.addleadloader = true;
          

        },
        [addlead.fulfilled]: (state, action) => {

            if (action.payload.status === "success") {
                state.addleadloader = false;
                state.leadadditiondata=action.payload.results;
                console.log("leadadditiondata",state.leadadditiondata)
                state.addsuccessflag=true

            } 
        },
        [addlead.rejected]: (state, action) => {
            state.addleadloader = false;
        



        },
        ////////
    }
})







export default LeadReducer.reducer;
export const { clearconversiondata, clearleaddata, cleareditleaddata, clearfatchViewData, cleargetNotesleaddata, setStatesBlank, setTempRowId, reSetSubmistionstate, clearinvationdata, clearaddsuccessflag} = LeadReducer.actions;
