import { Button, Dialog, DialogActions, DialogContent, DialogTitle, Icon, IconButton, Paper } from '@mui/material'
import React from 'react'
import { randomId, timeConverter } from '../../helper/helperFunctions'
import store from '../../store';
import { clearExtraButtonClicked, setExtraButtonClicked } from '../listReducer';

function ViewDialog({ open, close, data, buttonData, mainClass,tableID }) {
  console.log("buttonallLog", buttonData,tableID);

  const handelExtraBtnClick = (tableID,buttonData,extrBtnID) => {
    // console.log("apiBtnData111", apiBtnData);


    store.dispatch(setExtraButtonClicked({ tableId:tableID, buttonId:buttonData.id, extraBtnId:extrBtnID }));
    setTimeout(() => store.dispatch(clearExtraButtonClicked({ tableId:tableID, buttonId:buttonData.id, extraBtnId:extrBtnID })), 500)

    // if (apiBtnData && Array.isArray(apiBtnData?.extra_btn)) {
    //     apiBtnData.extra_btn.map((button, index) => {
    //         let tableId = button.tableID
    //         let buttonId = button.id
    //         store.dispatch(setCustomButtonClicked({ tableId, buttonId }));
    //         setTimeout(() => store.dispatch(clearCustomButtonClicked({ tableId, buttonId })), 500)

    //     })
    // } else {
    //     let tableId = apiBtnData.extra_btn.tableID
    //     let buttonId = apiBtnData.extra_btn.id
    //     store.dispatch(setCustomButtonClicked({ tableId, buttonId }));
    //     setTimeout(() => store.dispatch(clearCustomButtonClicked({ tableId, buttonId })), 500)

    // }

}



  return (
    <Dialog open={open} onClose={() => close()} className={`view_details ${mainClass}`} >
      <IconButton title='Close' className='close_icon_btn' onClick={close}><Icon>close</Icon></IconButton>

      <DialogTitle className='view_header previewModalTitle'>
        <div className='modal_title' dangerouslySetInnerHTML={{ __html: buttonData?.modalTitle ? buttonData.modalTitle : "Details" }}></div>
      </DialogTitle>
      <DialogContent className='content_container modalview_group'>
        {
          data?.map((eachData) => (
            <Paper key={() => randomId()} elevation={7} className="data_container">
              <div className="data_block">
                {
                  buttonData?.headers.map((header) => (
                    <div className='property_container'>
                      <label className='property_label' >{header.label ? header.label : "NA"} : </label>
                      <label className='property_value' dangerouslySetInnerHTML={{
                        __html: header.val && eachData[header.val]
                          ? (
                            header?.type === 'datetime'
                              ? (timeConverter({ time: eachData[header.val], format: header.format ? header.format : "MM-DD-YYYY" }))
                              : header?.type === 'image'
                                ? `<img class='image_preview' src=${eachData[header.val]} alt="Not Previewable" />`
                                : header?.type === 'video'
                                  ? (
                                    `<video class='video_preview' controls preload="auto" width="300">
                                      <source src=${eachData[header.val]} type="video/webm" />
                                      <source src=${eachData[header.val]} type="video/mp4" />
                                      <p>Your browser doesn't support HTML5 video. Here is a <a href=${eachData[header.val]}>link to the video</a> instead.</p>
                                    </video>`
                                  )
                                  : header?.type === 'audio'
                                    ? (
                                      ` <audio class='audio_preview' controls src=${eachData[header.val]}>
                                        Your browser does not support the <code>audio</code> element.Here is a <a href=${eachData[header.val]}>link to the video</a> instead.
                                      </audio>`
                                    )
                                    : header?.type === 'pdf'
                                      ? (
                                        `<embed class='pdf_preview' src=${eachData[header.val]} type="application/pdf" />`
                                      )
                                      : eachData[header.val]
                          )
                          : "NA"
                      }}></label>
                    </div>
                  ))
                }
              </div>
            </Paper>
          ))
        }
      </DialogContent>
      <DialogActions className='action_btn_container'>
        <Button title={buttonData?.btn_label ? buttonData.btn_label : 'Close'} className='btn close_btn' onClick={close}>{buttonData?.btn_label ? buttonData.btn_label : 'Close'}</Button>


        {buttonData?.extra_btn && Array.isArray(buttonData?.extra_btn)&& buttonData?.extra_btn.length>0 && buttonData?.extra_btn.map((button, index) => {
          console.log("button123", button);
          return (
            <Button title={button?.level ? button.level : 'Test'} className={`extraBtn ${button.id}`} onClick= {() => handelExtraBtnClick(tableID,buttonData,button.id)}>{button?.level ? button.level : 'Test'}</Button>
          )
        })}

      </DialogActions>
    </Dialog>
  )
}

export default ViewDialog

