import React from 'react'
import "./BrandTestimonial.css"
import brandimg1 from "../../../../../assets/image/brandimg1.png"

const BrandTestimonial = () => {
  return (
   <>
    <div className='box1'>
     <img src={brandimg1} alt=''/>
    </div>
   </>
  )
}

export default BrandTestimonial