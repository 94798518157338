import React, { useEffect, useRef, useState } from 'react'
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { resolvefunction } from '../../../../helper/helperFunctions';
import { resetresolvenew } from '../../../../helper/ResolveReducer';
import { TableSkeleton } from '../../../../helper/TableSkeleton';
import ListingTable from '../../../../listing/listing';
import store from '../../../../store';
import { clearContractdata, contractEditdata, getContractListData } from '../ContractReducer';
import InsertDriveFileIcon from '@mui/icons-material/InsertDriveFile';
import { LinearProgress, Snackbar, Tooltip } from '@mui/material';
import { setSnackbar } from '../../../Layout/layoutReducer';


const Contract = () => {

  const [openSnacAddEdit, setOpenSnacAddEdit] = useState(false);//------for snackbar-----//

  // ----------------------------------------Global State from Redux---------------------------------------- //
  let contractDataforlist = useSelector(state => (state.contractReducer?.contractListData && state.contractReducer?.contractListData.length > 0) ? state.contractReducer.contractListData : [])
  let loader = useSelector(state => (state.contractReducer?.loading) ? state.contractReducer.loading : false)
  const resolved = useSelector(state => state.ResolveReducer.resolved['/contract-list'] ? state.ResolveReducer.resolved['/contract-list'] : false)
  const navigate = useNavigate();
  const editData = useSelector(state => state.tableSlice?.tempRowData?.contractdataTable ? state.tableSlice.tempRowData.contractdataTable : {})

  const editClicked = useSelector(state => state.tableSlice?.customButtonClicked?.contractdataTable?.edittable9 ? state.tableSlice.customButtonClicked.contractdataTable.edittable9 : false)

  const formsubmissionstate = useSelector(state => state.formSlice?.formSubmissionState ? state.formSlice.formSubmissionState : null);
  const successMsg = useSelector(state => state.formSlice.message?.ContractAddEditForm)//for snackbar massge
  const successStatus = useSelector(state => state.formSlice.status?.ContractAddEditForm)









  var modifyTableHeaders =
    [
      { val: "name", name: "Contract  Name" },
      // { val: "program_names", name: "Program Names" },
      //    { val: "description", name: "Description" },
      { val: "status", name: "Status", customVal: { 0: 'Inactive', 1: "Active" } },
      // { val: "createdon_datetime", name: "Created on", type: "datetime", format: "DD/MM/YYYY" },
      { val: "updatedon_datetime", name: "Updated on", type: "datetime", format: "DD/MM/YYYY" },


    ];


  let tableDataContract = useRef({
    tableId: "contractdataTable",
    tableTitle: "Contract List",
    showReload: true,
    showFilter: true,
    // adduser: true,
    api: {
      url: process.env.REACT_APP_API_URL,
      endPoint: "contract-manage/contract-list-count",
      deleteSingleUserEndpoint: "",
      tableCountEndpoint: "contract-manage/contract-list-count",
    },

    reqBody: {
      "condition": {
        "limit": 10,
        "skip": 0
      },
      "sort": {
        "field": "updatedon_datetime",
        "type": "desc"
      },
      "searchcondition": {},
      "project": {},
      "token": "",
      "count": false
    },
    deleteSingleUserReqBody: {
      source: "users",
      secret: "AZ|lepL`",

    },
    deleteMultipleReqBody: {
      source: "users",
      secret: "AZ|lepL`",

    },

    //******************************** By Default Rows per page option will be available**************************//

    rowsPerPageOptions: [10, 20, 30],
    sortFields: ["name", "status", "createdon_datetime", "updatedon_datetime"],
    paginationType: "stackPagination",
    enableRowActionLoader: true,
    customBtnHead: [

      {
        id: "delete",
        label: "Delete",
        type: "api",
        category: "modal",
        icon_type: "delete",
        className: "delete_data",
        modalClassName: "delete_modal",
        modalTitle: "<p>Are you sure that you want to delete this record(s)?</p>",
        modalClassName: "delete_modal",
        modalHeader: "<p> Alert !!</p>",
        btn_label: ['Yes', "No"],
        api_data: {
          base_url: process.env.REACT_APP_API_URL,
          endpoint: "contract-manage/contract-delete",
          body: {
            "source": "users",
            "secret": "AZ|lepL`",
            "token": "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJmb28iOiJiYXIiLCJleHAiOjE2NTIxNjM3MDUsImlhdCI6MTY1MjA3NzMwNX0.3kB4iRr5JbmV81Dl5gKA7fiTaMEEp0xbsWoO-wA0XUo",
          }
        }
      },
      {
        id: "status",
        label: "Change Status",
        type: "api",
        category: "status",
        icon_type: "toggle_off",
        className: "status_data",
        modalClassName: "delete_modal",
        modalTitle: "<p>Are you sure you want to change the status ?</p>",
        modalClassName: "delete_modal",
        modalHeader: "<p> Alert !!</p>",
        btn_label: ['Active', "Inactive"],
        api_data: {
          base_url: process.env.REACT_APP_API_URL,
          endpoint: "contract-manage/contract-status-update",
          body: {
            "source": "users",
            "secret": "AZ|lepL`",
            "token": "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJmb28iOiJiYXIiLCJleHAiOjE2NTIxNjM3MDUsImlhdCI6MTY1MjA3NzMwNX0.3kB4iRr5JbmV81Dl5gKA7fiTaMEEp0xbsWoO-wA0XUo",
          }
        }
      },

    ],
    buttons: [


      {
        id: "edittable9",
        type: "other",
        icon_type: "edit_icon",
        label: "Edit",
        name: "edit",
      },

      {
        id: "delete",
        label: "Delete",
        type: "api",
        category: "modal",
        icon_type: "delete",
        className: "delete_data",
        modalClassName: "delete_modal",
        modalTitle: "<p>Are you sure that you want to delete this record(s)?</p>",
        modalClassName: "delete_modal",
        modalHeader: "<p> Alert !!</p>",
        btn_label: ['Yes', "No"],
        api_data: {
          base_url: process.env.REACT_APP_API_URL,
          endpoint: "contract-manage/contract-delete",
          body: {
            "source": "users",
            "secret": "AZ|lepL`",
            "token": "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJmb28iOiJiYXIiLCJleHAiOjE2NTIxNjM3MDUsImlhdCI6MTY1MjA3NzMwNX0.3kB4iRr5JbmV81Dl5gKA7fiTaMEEp0xbsWoO-wA0XUo",
          }
        }
      },
      {
        id: "status",
        label: "Change Status",
        type: "api",
        category: "status",
        icon_type: "toggle_off",
        className: "status_data",
        modalClassName: "delete_modal",
        modalTitle: "<p>Are you sure you that want to change the status?</p>",
        modalClassName: "delete_modal",
        modalHeader: "<p> Alert !!</p>",
        btn_label: ['Active', "Inactive"],
        api_data: {
          base_url: process.env.REACT_APP_API_URL,
          endpoint: "contract-manage/contract-status-update",
          body: {
            "source": "users",
            "secret": "AZ|lepL`",
            "token": "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJmb28iOiJiYXIiLCJleHAiOjE2NTIxNjM3MDUsImlhdCI6MTY1MjA3NzMwNX0.3kB4iRr5JbmV81Dl5gKA7fiTaMEEp0xbsWoO-wA0XUo",
          }
        }
      },
      {
        id: "viewApi",
        label: "Contract Template",
        type: "api",
        category: "view",
        icon_type: "insert_drive_file_icon",
        className: "preview",
        modalTitle: "Contract Template",
        modalClassName: "contractDetails",
        // extera_btn_lavel:"Download",
        // extra_btn: [{
        //   id: "download",
        //   level: "Download"
        // }],
        api_data: {
          base_url: process.env.REACT_APP_API_URL,
          endpoint: "contract-manage/contract-list-count",
          body: {
            "source": "",
            "condition": { "limit": 1, "skip": 0 },
            "sort": { "type": "asc", "field": "_id" },
            "searchcondition": {}
          }
        },
        headers: [
          { val: "template", label: "Template" },
          // { val: "type", label: "Type" },
          // { val: "createdon_datetime", label: "Added On", type: "datetime", format: "MM/DD/YYYY hh:mm:ss A" },
        ]
      },
      {
        id: "viewApi",
        label: "Contract Related Program",
        type: "api",
        category: "view",
        icon_type: "list_icon",
        className: "preview",
        modalTitle: "Related Program",
        modalClassName: "contract_related_program",
        // extra_btn: [{
        //   id: "download1",
        //   level: "Download1"
        // },
        // {
        //   id: "download2",
        //   level: "Download2"
        // }],
        api_data: {
          base_url: process.env.REACT_APP_API_URL,
          endpoint: "contract-manage/contract-list-count",
          body: {
            "source": "",
            "condition": { "limit": 1, "skip": 0 },
            "sort": { "type": "asc", "field": "_id" },
            "searchcondition": {}
          }
        },
        headers: [
          { val: "program_names", label: "Program" },
          // { val: "type", label: "Type" },
          // { val: "createdon_datetime", label: "Added On", type: "datetime", format: "MM/DD/YYYY hh:mm:ss A" },
        ]
      },
    ],
    deleteModal: {
      modalClassName: "modalblock",
    },


    searchData: {
      heading: "Search Contract",
      type: "drawer",
      condition_type: "$or",
      formData: {
        id: 'searchForm',
        formWrapperClass: "formWrapperClass",// Can be undefined default will be formCls
        formInlineCLass: "userForm", // Can be undefined default will be formInline
        formButtonClass: "submitbtnsection",
        submitBtnEndIcon: "search",
        submitBtnName: "Search",
        resetBtnName: "Refresh",
        resetForm: true,
        formAdditionalSubmissionData: {},
        fields: [
          {
            id: 0,
            label: "Search By Contract  Name",
            name: "name",
            type: "autoComplete",
            className: 'list_search_item countDiv__column--col6 ',
            base_url: process.env.REACT_APP_API_URL + "contract-manage/fetch-contract",
            reqBody: {
              // "formId": 'teamsearch',
              "condition": {
                "limit": 10,
                "skip": 0
              },
              "sort": {
                "type": "asc",
                "field": "name"
              },
              "searchcondition": {

              }
            },
            sx: { m: 1, minWidth: 300 },
            // payloadFormat: { key: "_id", param: "$regex", options: "i" },
            payloadFormat: { key: "_id" },

          },
          {
            id: 0,
            label: "Search By Related Program",
            name: "program",
            type: "autoComplete",
            className: 'list_search_item countDiv__column--col6 ',
            base_url: process.env.REACT_APP_API_URL + "program-manage/auto-complete-program-list",
            reqBody: {
              // "formId": 'teamsearch',
              "condition": {
                "limit": 10,
                "skip": 0
              },
              "sort": {
                "type": "asc",
                "field": "name"
              },
              "searchcondition": {

              }
            },
            sx: { m: 1, minWidth: 300 },
            // payloadFormat: { key: "_id", param: "$regex", options: "i" },
            payloadFormat: { key: "program" },

          },

          {
            id: 3,
            label: "Search by Status",
            name: "status_show",
            type: 'select',
            multiple: false,
            values: [
              { val: 1, name: "Active" },
              { val: 0, name: "Inactive" }
            ],
            sx: { m: 1, minWidth: 120 },
            className: 'list_search_item countDiv__column--col6 ',
            payloadFormat: { key: "status" },
          },
          {
            id: 8,
            label: "Search by Created on Start Date",
            name: "start_date",
            className: 'datePicker',
            type: 'datePicker',
            sx: { m: 1, width: 300 },
            className: 'list_search_item countDiv__column--col6 ',
            payloadFormat: { key: "createdon_datetime", param: "$gte" }
          },
          {
            id: 9,
            label: "Search by Created on End Date",
            name: "end_date",
            className: 'list_search_item countDiv__column--col6 ',
            type: 'datePicker',
            fullDay: true,
            sx: { m: 1, width: 300 },
            payloadFormat: { key: "createdon_datetime", param: "$lte" }
          },
          {
            id: 10,
            //   heading: "Search By Creation Time",
            label: "Search by Updated on Start Date",
            name: "start_date_by_update",
            className: 'datePicker',
            type: 'datePicker',
            sx: { m: 1, width: 300 },
            className: 'list_search_item countDiv__column--col6 ',
            payloadFormat: { key: "updatedon_datetime", param: "$gte" }
          },
          {
            id: 11,
            label: "Search by Updated on End Date",
            name: "end_date_by_update",
            className: 'list_search_item countDiv__column--col6 ',
            type: 'datePicker',
            fullDay: true,
            sx: { m: 1, width: 300 },
            payloadFormat: { key: "updatedon_datetime", param: "$lte" }
          },
        ]
      }
    },
  });


  let reqbody = {
    "condition": {
      "limit": 10,
      "skip": 0
    },
    "sort": {
      "field": "updatedon_datetime",
      "type": "desc"
    },
    "searchcondition": {},
    "project": {},
    "token": ""
  }





  useEffect(() => {
    // console.log("RoleList", resolve);
    if (!resolved) store.dispatch(getContractListData(reqbody));

    return () => {

      store.dispatch(clearContractdata())
      store.dispatch(resetresolvenew('/contract-list'))
    }
  }, [])

  useEffect(() => {
    if (editClicked && Object.keys(editData).length > 0) {
      resolvefunction(navigate, `/edit-contract/${editData._id}`, contractEditdata(editData._id))
    }

  }, [editClicked])

  useEffect(() => {
    if (formsubmissionstate.ContractAddEditForm === 2) {
      console.log("formsubmissionstate............", formsubmissionstate)
      // setOpenSnacAddEdit(true)

      store.dispatch(
        setSnackbar({ open: true, message: successMsg, status: successStatus })
      )

    }

    console.log("true==========>");
  }, [formsubmissionstate])




  return (
    <>



      <div className='rolelist_mainwrp'>
      {loader === true && <LinearProgress/>}
        <div className='listing_heading'>

          <h1>Contract Management</h1>
          <Tooltip title='Add Contract ' >
            <button className='addrole_btn' onClick={() => navigate('/add-contract')}>+</button>
          </Tooltip>
        </div>

        <div className='rolelist_mainwrp_Table'>

          {!loader ? (
            <ListingTable

              tableData={tableDataContract.current}
              dataset={contractDataforlist}
              modifyHeaders={modifyTableHeaders}
            />
          ) : (<TableSkeleton count={5} />)}

        </div>
      </div>



    </>
  )
}

export default Contract