import { LinearProgress } from "@mui/material";
import { Box } from "@mui/system";
import { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { resetresolvenew } from "../../../helper/ResolveReducer";
import { TableSkeleton } from "../../../helper/TableSkeleton";
import ListingTable from "../../../listing/listing";
import { bookingListData } from "../calenderReducer";

export const BookingList = ({ bookingListFetched }) => {

    // const [bookingList, setBookingList] = useState([]);
    const [listFetchSuccess, setListFetchSuccess] = useState(false);
    // const [loaderFlag, setloaderFlag] = useState(true);
    const stroreDispatch = useDispatch();

    const resolved = useSelector(state => state.ResolveReducer?.resolved['/miscellaneous'] ? state.ResolveReducer?.resolved['/miscellaneous'] : false);
    const bookingList = useSelector(state => (state.calenderEventsStore?.bookingList && state.calenderEventsStore?.bookingList.length > 0) ? state.calenderEventsStore.bookingList : null);

    const loaderFlag = useSelector(state => state.calenderEventsStore?.loading ? state.calenderEventsStore?.loading : false);

    const searchFormData = useSelector((state) => state.formSlice?.formData?.searchForm ? state.formSlice.formData.searchForm : {})

    useEffect(() => {
        console.log("data123>>>>", searchFormData)
        bookingListObj.current.searchData.formData.fields.forEach((data) => {
            console.log(data, "dtaDefaultvalue1234", searchFormData[data.name])
            data.defaultValue = searchFormData[data.name] ? searchFormData[data.name] : undefined
            console.log("data1234", data, searchFormData);
        })
        // console.log("searchFormData", searchFormData);
    }, [JSON.stringify(searchFormData)]);



    const bookingListObj = useRef({
        tableId: "bookingLists",
        tableTitle: "Booking Status List",
        showReload: true,
        showFilter: true,
        api: {
            url: process.env.REACT_APP_API_URL,
            endPoint: "calendar-api/booking-list",
            // deleteSingleUserEndpoint: "api/delete-user",
            tableCountEndpoint: "calendar-api/booking-list",
        },

        reqBody: {
            "condition": {
                "limit": 10,
                "skip": 0
            },
            "sort": {
                "field": "_id",
                "type": "desc"
            },
            "searchcondition": {
            },
            "count": false
        },
        deleteSingleUserReqBody: {
            source: "users",
            secret: "AZ|lepL`",
            // token: usersToken,
        },
        deleteMultipleReqBody: {
            source: "users",
            secret: "AZ|lepL`",
            // token: usersToken,
        },

        //******************************** By Default Rows per page option will be available**************************//

        rowsPerPageOptions: [5, 10, 20, 30],
        sortFields: ["slot_start_time_unix", "booking_time_unix", "email", "type"],
        paginationType: "stackPagination",
        enableRowActionLoader: true,
        customBtnHead: [
            // {
            //     id: "deleteMultiple",
            //     type: "delete_icon",
            //     title: "Delete",
            // },
            // {
            //     id: "editMultiple",
            //     type: "toggle_on_icon",
            //     title: "Status Change",
            // },
            // {
            //     id: "adduser",
            //     type: "add_icon",
            //     title: "ADD USER",
            //     className: "add_user_icon",
            //     default: true,
            // },
        ],
        // buttons: [
        // ],
        // deleteModal: {
        //     modalClassName: "Delete Modal Deletemodal",
        // },

        // searchBarData: {
        //     heading: "Search Booking Status List",
        //     api: {
        //         url: process.env.REACT_APP_API_URL,
        //         endPoint: "calendar-api/booking-list",
        //         tableCountEndpoint: "calendar-api/booking-list",
        //         reqBody: {
        //             // count:true,
        //             // "source": "users",
        //             token: "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJfaWQiOiI2MzYzNTZkNDFjZTE3MzAwMDhiOGFhYTUiLCJzZWNyZXQiOiJ6R3RpMnY1enYiLCJpYXQiOjE2Njc5Njg3MzV9.wzFi1CoHbv9QK5cUHbVgSL6LW8m6fR_BaILcT7OcHBQ",
        //             condition: {
        //                 limit: 5,
        //                 skip: 0,
        //             },
        //             sort: {
        //                 type: "desc",
        //                 field: "_id",
        //             },
        //         },
        //     },

        //     searchSettings: {
        //         datesearch: [
        //             {
        //                 id: 0,
        //                 heading: "Search By Appointment Start Time",
        //                 startdatelabel: "Start Date",
        //                 enddatelabel: "End Date",
        //                 className: "formGroup createdon_datetime countDiv__column--col4",
        //                 submit: "Search",
        //                 field: "createdon_datetime",
        //             },
        //         ],
        //         selectsearch: [
        //             {
        //                 id: 1,
        //                 label: "Search By Booking Status",
        //                 field: "booking_status",
        //                 type: "select",
        //                 className: "formGroup booking_status countDiv__column--col2",
        //                 values: ["Booking completed", "Sign-up completed"]
        //             },
        //             {
        //                 id: 2,
        //                 label: "Search By Type",
        //                 field: "type",
        //                 type: "select",
        //                 className: "formGroup type countDiv__column--col2",
        //                 values: ["Frontend", "NP"]
        //             },


        //         ],
        //         textsearch: [
        //             {
        //                 id: 3,
        //                 // heading: "Search By First Name",
        //                 label: "Search By Name ",
        //                 field: "fullname",
        //                 className: "formGroup name countDiv__column--col4",
        //                 value: "",
        //             },
        //             {
        //                 id: 4,
        //                 // heading: "Search By Email",
        //                 label: "Search By Email ",
        //                 field: "email",
        //                 className: "formGroup email countDiv__column--col6",
        //                 value: "",
        //             },
        //         ],

        //     },
        // },


        searchData: {
            heading: "Search Booking Status List",
            type: "drawer",
            condition_type: "$or",
            formData: {
                id: 'searchForm',
                formWrapperClass: "formWrapperClass",// Can be undefined default will be formCls
                formInlineCLass: "userForm", // Can be undefined default will be formInline
                formButtonClass: "submitbtnsection",
                submitBtnName: "Search",
                submitBtnEndIcon: "search",
                resetBtnName: "Refresh",
                resetBtnEndIcon: "Refresh",
                resetForm: true,
                formAdditionalSubmissionData: {},
                fields: [
                    {
                        id: 0,
                        label: "Search By Full Name",
                        name: "fullname",
                        type: "text",
                        className: 'list_search_item countDiv__column--col6 ',
                        sx: { m: 1, minWidth: 300 },
                        payloadFormat: { key: "fullname", param: "$regex", options: "i" },
                        

                    },
                    {
                        id: 2,
                        label: "Search By Email",
                        name: "email",
                        type: "text",
                        className: 'list_search_item countDiv__column--col4 ',
                        sx: { m: 1, minWidth: 300 },
                        payloadFormat: { key: "email" },

                    },
                    {
                        id: 3,
                        label: "Search by Status",
                        name: "booking_status",
                        // className: 'Status',
                        type: 'select',
                        multiple: false,
                        values: [
                            { val: "Booking completed", name: "Booking completed" },
                            { val: "Sign-up completed", name: "Sign-up completed" }],
                        sx: { m: 1, minWidth: 120 },
                        className: 'list_search_item countDiv__column--col4 ',
                        payloadFormat: { key: "booking_status", param: "$eq", $options: "ims" },
                    },
                    // {
                    //     id: 8,
                    //     label: "Search by Start Date",
                    //     name: "start_date_unix",
                    //     className: 'datePicker',
                    //     type: 'datePicker',
                    //     sx: { m: 1, width: 300 },
                    //     // format: 'dd/MM/yyyy', //////////////////////////// MM should be capital //////////////////
                    //     className: 'list_search_item countDiv__column--col4 ',
                    //     payloadFormat: { key: "start_date_unix", param: "$gte" }
                    //   },
                    //   {
                    //     id: 9,
                    //     label: "Search by End Date",
                    //     name: "end_date_unix",
                    //     className: 'list_search_item countDiv__column--col4 ',
                    //     type: 'datePicker',
                    //     fullDay: true,
                    //     sx: { m: 1, width: 300 },
                    //     // format: 'dd/MM/yyyy'
                    //     payloadFormat: { key: "end_date_unix", param: "$lte" }
                    //   },
                ]
            },
        },
    });

    var modifyTableHeaders =
        [
            { val: "booking_status", name: "Booking Status" },
            { val: "fullname", name: "Full Name" },
            { val: "email", name: "Email" },
            { val: "slot_start_time_unix", name: "Appointment Start Time", type: "dateTime", format: "DD/MM/YYYY hh:mm A" },
            { val: "booking_time_unix", name: "Booking Time", type: "dateTime", format: "DD/MM/YYYY hh:mm A" },
            { val: "timespan", name: "Timespan" },
            { val: "type", name: "Type" },
        ];


    useEffect(() => {
        if (bookingList) {
            bookingListFetched(true);
        }

    }, [bookingList])

    // const getList = async () => {
    //     const requestOptions = {
    //         method: "POST",
    //         headers: { "Content-type": "application/json" },
    //         body: JSON.stringify(bookingListObj.reqBody),
    //     };
    //     const response = await fetch(
    //         process.env.REACT_APP_API_URL + "calendar-api/booking-list",
    //         requestOptions
    //     );
    //     const respdata = await response.json();
    //     console.log("++++cv+++++++", respdata);
    //     if (respdata && respdata.status === "success") {
    //         setBookingList([...respdata.results.res])
    //     }
    //     setloaderFlag(false);
    //     bookingListFetched(true);
    //     // if (!resolved) {
    //     //     console.log("++++++zzzz", bookingListObj);
    //     //     stroreDispatch(bookingListData(bookingListObj.reqBody))
    //     // }
    // }

    // useEffect(() => {
    //     if (bookingList && bookingList.length === 0 && !listFetchSuccess && !resolved) {
    //         setListFetchSuccess(true);
    //         getList()
    //     }

    // }, [bookingList]);

    useEffect(() => {
        if (!resolved) stroreDispatch(bookingListData(bookingListObj.current.reqBody));
        return () => {
            stroreDispatch(resetresolvenew('/miscellaneous'))
        }
    }, [])

    return (
        <>
            {loaderFlag == true ? (
                <Box className="loaderProgress" sx={{ width: "100%", mt: "10px" }}>
                    <LinearProgress />
                </Box>
            ) : (
                ""
            )}

            <div className="reactTableWrapper miscList">
                <div className="rolelist_mainwrp_Table">
                    {bookingList ? (
                        <ListingTable
                            tableData={bookingListObj.current}
                            dataset={bookingList}
                            modifyHeaders={modifyTableHeaders}
                        />
                    ) : (<TableSkeleton count={5} />)}
                </div>
            </div>
        </>
    )
}